import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEventHandler,
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { Drawer, Box, IconButton, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AddCircleOutline } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import NodonTextField from './NodonTextField';
import ExpressionSelect from './ExpressionSelect';
import { useExpressionSelect } from '../hooks/useExpressionSelect';
import NodonSelect, {
  NODON_SELECT_ID,
  NODON_SELECT_OPEN_ID,
} from './NodonSelect';
import { useMouseEventCallback } from '../hooks/events.hook';
import {
  ExpressionValue,
  IElement,
} from '../../../shared/models/project.interface';
import {
  QuantityUnit,
  SelectableQuantityUnit,
  selectableQuantityUnits,
} from '../../../shared/models/unit.interface';
import { IElementQuantityExpressionProperty } from '../../../shared/models/element_quantities.interface';
import { IElementExpressionProperty } from '../../../shared/models/element_property.interface';
import { ExpressionVariables } from '../../../shared/helpers/expression_variables_helpers';
import shallowEqual from '../../../shared/helpers/array_helpers';
import { ValidRecordKeys } from '../../../shared/models/type_helpers.interface';
import { useSolveExpression } from '../hooks/useElementExpressionVariables';
import { debounce, isError } from 'lodash';
import { createExpression } from '../../../shared/helpers/expression_factory_helpers';
import { useUIState } from '../store/ui';
import { useIsReadonly } from '../hooks/user.hook';
import { SidePanelStyles } from '../style/constants';
import { isSelectableQuantityUnit } from '../../../shared/helpers/unit_helpers';

const EXPRESSION_INPUT_PANEL_INPUT_ID = 'expression-input-panel-input';
const EXPRESSION_INPUT_PANEL_CONTAINER = 'expression-input-panel-container';
const NODON_CANCEL_BUTTON = 'nodon-cancel-button';
const NODON_SAVE_BUTTON = 'nodon-save-button';

export interface IExpressionInputPanelOutput {
  expressionValue?: ExpressionValue;
  unit?: SelectableQuantityUnit;
}

type ExpressionInputPanelReturnValue = IExpressionInputPanelOutput | undefined;

export interface IExpressionInputOptions {
  expressionValue?: ExpressionValue;
  fallbackExpressionValue?: ExpressionValue;
  min?: number;
  max?: number;
  unit?: QuantityUnit;
  source?:
    | IElement
    | IElementExpressionProperty
    | IElementQuantityExpressionProperty;
  variables?: ExpressionVariables;
  disableUnits?: boolean;
  selectableUnits?: SelectableQuantityUnit[];
  onChange?: (state: IExpressionInputPanelOutput) => void;
  onDebouncedChange?: (state: IExpressionInputPanelOutput) => void;
  onErrorStateChange?: (isError: boolean) => void;
}

type ExpressionInputPanelState = Pick<
  IExpressionInputOptions,
  'expressionValue' | 'unit'
> & {
  expression?: string;
};

type EmitFn = (state: ExpressionInputPanelReturnValue) => void;

export type ExpressionInputPanelOpenFn = (
  options: IExpressionInputOptions,
) => Promise<ExpressionInputPanelReturnValue>;

export type ExpressionInputPanelCloseFn = (
  cancel?: boolean,
) => ExpressionInputPanelReturnValue;

const globals: {
  openFn?: ExpressionInputPanelOpenFn;
  closeFn?: ExpressionInputPanelCloseFn;
} = {};
const voidFn = () => {
  // Empty
};
const promiseFn = () => Promise.resolve();

/**
 * Check if an element is the input field of this component
 * @param element
 * @returns
 */
export const isExpressionInputPanelInput = (
  element?: Element | EventTarget | null,
): element is HTMLInputElement =>
  !!element &&
  'id' in element &&
  element.id === EXPRESSION_INPUT_PANEL_INPUT_ID;

const ExpressionInputPanel: React.FC = () => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLInputElement>();
  const [error, setError] = useState<string>();
  const currentStateRef = useRef<Readonly<ExpressionInputPanelState>>();
  const optionsRef = useRef<Readonly<IExpressionInputOptions>>();
  const promiseRef = useRef<Promise<ExpressionInputPanelReturnValue>>();
  const promiseResolveRef = useRef<EmitFn>();

  const options = optionsRef.current;
  const units = options?.selectableUnits ?? selectableQuantityUnits;
  const placeholder = options?.fallbackExpressionValue?.expression ?? '0';

  const [localExpression, setLocalExpression] = useState<string>('');

  const [localUnit, setLocalUnit] =
    useState<IExpressionInputOptions['unit']>('kg');

  const localExpressionValue = useSolveExpression(localExpression, options);

  const [isInitialInput, setIsInitialInput] = useState(false);

  const {
    matchingExpressions,
    activeExpressionIndex,
    setActiveExpressionIndex,
    handlePlusMenuClick,
    chooseExpression,
    customOnKeyDown: selectKeyDown,
    onKeyUp: selectOnKeyUp,
    close: closeExpressionSelect,
  } = useExpressionSelect(
    inputRef,
    localExpression,
    setLocalExpression,
    options?.variables,
  );

  const { setExpressionInputPanelError, setIsExpressionInputPanelOpen } =
    useUIState('setExpressionInputPanelError', 'setIsExpressionInputPanelOpen');

  const readonly = useIsReadonly();

  const [isOpen, setIsOpen] = useState(false);

  // Check if expression and unit have changed since opening pael
  const hasChanged = useCallback((): boolean => {
    const originalExpressionValue = options?.expressionValue;
    const { expressionValue, unit } = currentStateRef.current ?? {};
    const expression = expressionValue?.expression;

    if (unit !== options?.unit) {
      return true;
    }
    if (expressionValue === options?.expressionValue) {
      return false;
    }

    // Special case for empty expression
    if (!expression) {
      return !!originalExpressionValue?.expression;
    }

    return true;
  }, [options?.expressionValue, options?.unit]);

  // Return an output if expression is valid and properties has changed
  const getReturnValue = useCallback(
    (ignoreHasChanged?: boolean): IExpressionInputPanelOutput | undefined => {
      const { unit, expressionValue, expression } =
        currentStateRef.current ?? {};

      const selectableUnit = isSelectableQuantityUnit(unit) ? unit : undefined;

      if (!ignoreHasChanged && !hasChanged()) {
        return;
      }

      // Only return if expression is valid and has changed (compared to provided options)
      if (expressionValue && !error) {
        const hasFallback = !!options?.fallbackExpressionValue;

        // If expression is empty, return undefined if fallback is provided, else return a zero expression
        if (!expression) {
          return {
            expressionValue: hasFallback ? undefined : createExpression(0),
            unit: selectableUnit,
          };
        }

        return {
          expressionValue,
          unit: selectableUnit,
        };
      }
    },
    [error, hasChanged, options?.fallbackExpressionValue],
  );

  // Should never change
  const closePanel: ExpressionInputPanelCloseFn = useCallback(
    (cancel?: boolean) => {
      // Not opened, no need to close
      if (!promiseRef.current) {
        return;
      }

      closeExpressionSelect();

      const resolve = promiseResolveRef.current;
      const returnValue: ExpressionInputPanelReturnValue = cancel
        ? undefined
        : getReturnValue();

      // Error should force the panel open, unless it's cancelled
      if (!cancel && error) {
        enqueueSnackbar(error, snackbarOptions);

        inputRef.current?.focus();
        return;
      }

      setExpressionInputPanelError(undefined);

      // Resolve promise if it exists
      if (resolve) {
        resolve(returnValue);
      }

      // Reset state
      setError(undefined);
      promiseRef.current = undefined;
      promiseResolveRef.current = undefined;
      currentStateRef.current = undefined;
      optionsRef.current = undefined;

      setIsOpen(false);

      return returnValue;
    },
    [
      closeExpressionSelect,
      enqueueSnackbar,
      error,
      getReturnValue,
      setExpressionInputPanelError,
    ],
  );

  // Should never change
  const openPanel: ExpressionInputPanelOpenFn = useCallback(
    (options) => {
      const currentOptions = optionsRef.current;
      if (
        !promiseRef.current ||
        !shallowEqual(
          options as Record<ValidRecordKeys, any>,
          currentOptions as Record<ValidRecordKeys, any>,
        )
      ) {
        // First close panel if it's open
        closePanel();

        // If there is an error and panel is forced open,
        // return an immediately resolved promise
        if (error) {
          return Promise.resolve(undefined);
        }

        // Store input options
        optionsRef.current = options;

        setLocalExpression(
          options.expressionValue?.expression &&
            options.expressionValue?.expression !== '0'
            ? options.expressionValue?.expression
            : '',
        );

        if (options.unit === undefined) throw new Error('Empty options.unit');
        setLocalUnit(options.unit);

        promiseRef.current = new Promise<ExpressionInputPanelReturnValue>(
          (resolve) => {
            promiseResolveRef.current = resolve;
          },
        );

        setIsOpen(true);
        setTimeout(() => {
          inputRef.current?.focus();

          const exp = options.expressionValue?.expression;
          const input = inputRef.current;

          // Need input field and expression to set selection
          if (!exp || !input) {
            return;
          }

          const isPercentage = exp.endsWith('%');
          const possibleNumberPart = isPercentage ? exp.slice(0, -1) : exp;
          const isNumber = isFinite(Number(possibleNumberPart));

          // Don't select formulas
          if (!isNumber) {
            return;
          }

          const endIndex = exp.length - (isPercentage ? 1 : 0);

          // Select all characters except percentage sign
          inputRef.current?.setSelectionRange(0, endIndex);
        });
      }

      // Return promise for use to use await
      return promiseRef.current;
    },
    [closePanel, error],
  );

  const onExpressionChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const { value } = target;
      const trimmed = value.trim();

      if (!trimmed && !localExpression) {
        return;
      }

      if (localUnit === '%') {
        const isPositiveNumber = Number(value) >= 0;
        const isNewInput = value.length === 1 && localExpression.length === 0;

        setIsInitialInput(isNewInput);

        if (trimmed.startsWith('%')) {
          setLocalExpression('');
          return;
        }

        if (
          !/[0.%]/.test(value + localExpression) &&
          isPositiveNumber &&
          isNewInput
        ) {
          setLocalExpression(value + '%');
          return;
        }
      }
      setLocalExpression(value);
    },
    [localExpression, localUnit],
  );

  const onUnitChange = useCallback(
    (value: string): void => {
      setLocalUnit(value as SelectableQuantityUnit);
      inputRef.current?.focus();
    },
    [inputRef],
  );

  const onScroll = useCallback(
    () => setActiveExpressionIndex(undefined),
    [setActiveExpressionIndex],
  );

  // Prevent clicks on panel to close it
  const onPanelContainerClick = useMouseEventCallback((event) => {
    const buttonOrDiv = event?.currentTarget as HTMLElement;

    if (buttonOrDiv?.classList.contains(EXPRESSION_INPUT_PANEL_CONTAINER)) {
      return;
    }
  });
  const onSaveButtonClick = useCallback(() => {
    closePanel();
  }, [closePanel]);

  const onCancelButtonClick = useCallback(() => {
    closePanel(true);
  }, [closePanel]);

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>): void => {
      const listElement = event.relatedTarget as HTMLLIElement;

      const buttonOrDiv = event.relatedTarget as
        | HTMLButtonElement
        | HTMLDivElement;

      const isListItem = listElement?.tagName === 'LI';

      const isPanelContainer = buttonOrDiv?.classList.contains(
        EXPRESSION_INPUT_PANEL_CONTAINER,
      );

      const isCancelButton =
        buttonOrDiv?.classList.contains(NODON_CANCEL_BUTTON);

      const isSaveButton = buttonOrDiv?.classList.contains(NODON_SAVE_BUTTON);

      if (buttonOrDiv?.classList.contains(NODON_SELECT_ID)) {
        if (buttonOrDiv.classList.contains(NODON_SELECT_OPEN_ID)) {
          setTimeout(() => inputRef.current?.focus(), 0);
        }
        return;
      }
      if (isPanelContainer || isListItem || isCancelButton || isSaveButton) {
        return;
      }
      closePanel();
    },
    [closePanel],
  );

  const onKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        closePanel(true);
      }
      selectOnKeyUp(event);
    },
    [closePanel, selectOnKeyUp],
  );

  const defaultKeyDown: KeyboardEventHandler<
    HTMLInputElement | HTMLButtonElement | HTMLTextAreaElement
  > = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        closePanel();
      }
    },
    [closePanel],
  );

  const inputProps = useMemo(
    () => ({
      endAdornment: localUnit && (
        <>
          <IconButton
            className={NODON_SELECT_ID}
            aria-label="expression menu button"
            onKeyDown={selectKeyDown}
            onClick={handlePlusMenuClick}
            size="large"
          >
            <AddCircleOutline />
          </IconButton>
          {localUnit !== 'none' && (
            <NodonSelect
              buttonLabel={localUnit}
              options={units}
              formatOptionLabels={false}
              disabled={options?.disableUnits}
              onChange={onUnitChange}
            />
          )}
        </>
      ),
      inputProps: {
        onKeyDown:
          matchingExpressions.length > 0 ? selectKeyDown : defaultKeyDown,
      },
    }),
    [
      defaultKeyDown,
      handlePlusMenuClick,
      localUnit,
      matchingExpressions.length,
      onUnitChange,
      options?.disableUnits,
      selectKeyDown,
      units,
    ],
  );

  useEffect(() => {
    setIsExpressionInputPanelOpen(isOpen);
  }, [isOpen, setIsExpressionInputPanelOpen]);

  // Make open and close functions available to hooks
  useEffect(() => {
    globals.openFn = openPanel;
    globals.closeFn = closePanel;
  }, [openPanel, closePanel]);

  // Place caret before percentage sign if unit is percentage
  useEffect(() => {
    if (localUnit === '%') {
      const percentageIndex = localExpression.indexOf('%');

      if (percentageIndex > 0 && isInitialInput) {
        inputRef.current?.setSelectionRange(percentageIndex, percentageIndex);
      }
    }
  }, [isInitialInput, localExpression, localExpressionValue, localUnit]);

  // Update current state when local state changes
  useEffect(() => {
    if (options) {
      const expressionValue = !isError(localExpressionValue)
        ? localExpressionValue
        : undefined;
      const newError = isError(localExpressionValue)
        ? localExpressionValue.message
        : undefined;

      setError(newError);
      setExpressionInputPanelError(newError);

      currentStateRef.current = {
        expressionValue,
        expression: localExpression,
        unit: localUnit,
      };

      const { onChange, onDebouncedChange } = options;
      const returnValue = getReturnValue(true);

      // Note that this can be fired AFTER close & open have happend
      if (returnValue) onChange?.(returnValue);
      if (onDebouncedChange) {
        debounce(() => {
          const returnValue = getReturnValue();
          // Only run if options have not changed since debounce was triggered
          // so user have not swapped expression to edit and we overwrite it with other value
          if (optionsRef === options && returnValue) {
            onDebouncedChange(returnValue);
          }
        }, 200);
      }
    }
  }, [
    getReturnValue,
    hasChanged,
    localExpression,
    localExpressionValue,
    localUnit,
    options,
    setExpressionInputPanelError,
  ]);

  return (
    <Drawer
      tabIndex={0}
      classes={{
        paper: classes.paper,
      }}
      anchor={'bottom'}
      variant="persistent"
      open={isOpen && !readonly}
      className={EXPRESSION_INPUT_PANEL_CONTAINER}
      onClick={onPanelContainerClick}
    >
      <Box className={classes.root}>
        <NodonTextField
          id={EXPRESSION_INPUT_PANEL_INPUT_ID}
          inputRef={inputRef}
          autoComplete="off"
          value={localExpression}
          placeholder={placeholder}
          error={!!error}
          spellCheck={false}
          sx={INPUT_STYLE}
          InputProps={inputProps}
          onKeyUp={onKeyUp}
          onChange={onExpressionChange}
          onBlur={onBlur}
        />

        <Box sx={{ marginTop: 2, float: 'right' }}>
          <Button className={NODON_CANCEL_BUTTON} onClick={onCancelButtonClick}>
            Cancel
          </Button>
          <Button className={NODON_SAVE_BUTTON} onClick={onSaveButtonClick}>
            Save
          </Button>
        </Box>
        <ExpressionSelect
          matchingExpressions={matchingExpressions}
          activeExpressionIndex={activeExpressionIndex}
          onScroll={onScroll}
          onClick={chooseExpression}
        />
      </Box>
    </Drawer>
  );
};

const INPUT_STYLE = { width: '100%' } as const;

const snackbarOptions = {
  variant: 'error',
  autoHideDuration: 5000,
  /**
   * Move snackbar when expression panel is open
   */
  style: {
    height: 40,
    bottom: 105,
    position: 'relative',
    marginLeft: -12,
    padding: '0 8px',
  },
} as const;

const useStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    padding: spacing(2),
    backgroundColor: 'white',
    borderTop: '1px solid' + palette.primary.light,
  },

  paper: {
    backgroundColor: 'unset',
    border: 'none',
    overflow: 'visible',
    width: `calc(100% - ${SidePanelStyles.WIDTH}px)`,
  },
}));

export const useOpenExpressionInputPanel = (): ExpressionInputPanelOpenFn =>
  globals.openFn ?? (promiseFn as ExpressionInputPanelOpenFn);

export const useCloseExpressionInputPanel = (): ExpressionInputPanelCloseFn =>
  globals.closeFn ?? (voidFn as ExpressionInputPanelCloseFn);

export default ExpressionInputPanel;
