import React, { useCallback, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { isElement } from '../../../shared/helpers/recursive_element_helpers';
import {
  IElement,
  OneOfListElements,
} from '../../../shared/models/project.interface';
import { IAddElementOptions, useAddStandardElement } from '../store/project';
import { AddCircle } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { calculateIndentation } from '../hooks/useNestedElementsList';
import {
  isElementWithGeneratedChildren,
  isGenerated,
} from '../../../shared/helpers/element_helpers';
import amplitudeLog from '../amplitude';
import { useMouseEventCallback } from '../hooks/events.hook';
import { useIsReadonly } from '../hooks/user.hook';
import { useIsElementExpanded } from '../hooks/expand-elements.hook';
import { useSortElementsBy } from '../hooks/filter-elements.hook';
import { useIsLastElementVersionInList } from '../hooks/element-version.hook';
interface CreateSiblingButtonProps {
  element: IElement;
  indentation: number;
}

const CreateSiblingButton: React.FC<CreateSiblingButtonProps> = ({
  element,
  indentation,
}) => {
  const { classes } = useStyles();
  const generated = isGenerated(element);
  const hasGeneratedChildren = isElementWithGeneratedChildren(element);
  const expanded = useIsElementExpanded(element);
  const isLastElementVersion = useIsLastElementVersionInList(element);

  const sortBy = useSortElementsBy();
  const addElement = useAddStandardElement();

  const addElementOrProduct = useCallback(async () => {
    const options: IAddElementOptions = {
      isSibling: !expanded,
      placement: 'after',
    };

    amplitudeLog('Element New', {
      Place: 'Between',
    });

    await addElement(element, options);
  }, [expanded, element, addElement]);

  const containerStyles = useMemo(
    () => ({
      left: `${calculateIndentation(indentation)}%`,
      width: `${100 - calculateIndentation(indentation)}%`,
    }),
    [indentation],
  );

  const handleButtonClick = useMouseEventCallback(() => {
    addElementOrProduct();
  });

  if (
    generated ||
    (hasGeneratedChildren && expanded) ||
    sortBy !== 'position' ||
    !isLastElementVersion
  ) {
    return null;
  }

  return (
    <div className={classes.container} style={containerStyles}>
      <span>
        <button className={classes.button} onClick={handleButtonClick}>
          <Tooltip title="New element" disableInteractive>
            <AddCircle
              color="secondary"
              className={classes.addIcon}
              style={iconStyles}
            />
          </Tooltip>
        </button>
      </span>
    </div>
  );
};

const iconStyles = {
  left: 56,
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  container: {
    width: '100%',
    height: spacing(1),
    bottom: 0,
    position: 'absolute',
    zIndex: 2,
    '& span': {
      opacity: 0,
      transition: '250ms',
    },
    '&:hover span': {
      opacity: 1,
    },
    '&:drag': {
      display: 'none',
    },
    '&:-webkit-drag': {
      display: 'none',
    },
  },
  button: {
    width: 'fill-available',
    border: 0,
    height: spacing(1),
    position: 'absolute',
    cursor: 'pointer',
    backgroundColor: palette.secondary.main,
  },
  addIcon: {
    position: 'absolute',
    top: spacing(-2.1),
    borderRadius: '100%',
    cursor: 'pointer',
    backgroundColor: palette.background.paper,
  },
}));

export const useShouldShowCreateSiblingButton = (
  element: OneOfListElements,
) => {
  const readonly = useIsReadonly();

  return !readonly && isElement(element);
};

export default CreateSiblingButton;
