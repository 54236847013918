import { useCallback, useMemo } from 'react';
import { IProduct, ProductID } from '../../../shared/models/product.interface';
import { getProject, useUpdateElements } from '../store/project';
import { IProductElement } from '../../../shared/models/project.interface';
import { asArray, isDefined } from '../../../shared/helpers/array_helpers';
import {
  clearEpdOnProductElements,
  getEPDProductId,
  getGenericProductId,
  setEpdOnProductElements,
} from '../../../shared/helpers/product-element.helpers';
import {
  ArrayOrSingle,
  ItemOrItemId,
} from '../../../shared/models/type_helpers.interface';
import { getId } from '../../../shared/helpers/object_helpers';
import { openProductSelector } from '../projects/EditProject/ProductSelector';
import { createLocalStorageRecordStore } from '../helpers/local-storage.helpers';
import { useProductsLookup } from '../store/product';

export const {
  getItem: getEPDIdsAppliedToGenericProduct,
  setItem: setEPDIdsAppliedToGenericProduct,
  useStoreItem: useEPDIdsAppliedToGenericProduct,
} = createLocalStorageRecordStore<ProductID, ProductID[]>(
  'epds_applied_to_generic_products',
);

/**
 * Store mapping of epds in localstorage based on latest product elements
 * @param productElements
 */
const updateEPDIdsAppliedToGenericProducts = (
  productElements: IProductElement[],
) => {
  for (const element of productElements) {
    const genericProductId = getGenericProductId(element);
    const epdId = getEPDProductId(element);
    // Make sure epd is mapped to the generic product (to reuse conversion factors)
    if (genericProductId && epdId) {
      const ids = getEPDIdsAppliedToGenericProduct(genericProductId) ?? [];
      const uniqueIds = new Set([...ids, epdId]);
      setEPDIdsAppliedToGenericProduct(genericProductId, [...uniqueIds]);
    }
  }
};

export const useSelectProductElementEpds = () => {
  const setEpdOnProductElements = useSetEpdOnProductElements();
  return useCallback(
    async (elements: ArrayOrSingle<IProductElement>) => {
      const product = await openProductSelector();
      if (product) {
        await setEpdOnProductElements(asArray(elements), product.id);
      }
      return product;
    },
    [setEpdOnProductElements],
  );
};

/**
 * Get all products that have been applied to the specific generic product
 * @param genericProductId
 */
export const useAppliedEpdsFromGenericProduct = (
  genericProductId: ProductID,
  appliedEpd: IProduct | undefined,
) => {
  const productsLookup = useProductsLookup();
  const localstorageIds = useEPDIdsAppliedToGenericProduct(genericProductId);

  return useMemo(() => {
    const epdsMappedInLocalstorage: IProduct[] = [];

    for (const id of localstorageIds ?? []) {
      const product = productsLookup[id];

      // epds in localstorage might not be in the lookup
      if (product) epdsMappedInLocalstorage.push(product);
    }

    const epdsMappedInDatabase = Object.values(productsLookup).filter(
      ({ generic_id }) => generic_id === genericProductId,
    );

    // create a map to remove duplicates
    const uniqueMap = new Map(
      [
        ...epdsMappedInLocalstorage,
        ...epdsMappedInDatabase,
        // Always include the active EPD in the list
        appliedEpd,
      ]
        .filter(isDefined)
        .map((product) => [product.id, product]),
    );

    return Array.from(uniqueMap.values());
  }, [productsLookup, genericProductId, appliedEpd, localstorageIds]);
};

/**
 * Clear the EPD on an array of product elements
 * @param productElements
 * @returns
 */
export const useClearEpdOnProductElements = () => {
  const updateElements = useUpdateElements();

  return useCallback(
    async (productElements: IProductElement[]) => {
      const toUpdate = clearEpdOnProductElements(productElements);
      if (toUpdate !== productElements) {
        return await updateElements(...toUpdate);
      }
      return getProject();
    },
    [updateElements],
  );
};

/**
 * Set the EPD on the product elements
 * @param productElements
 * @param productId
 * @returns
 */
export const useSetEpdOnProductElements = () => {
  const updateElements = useUpdateElements();

  return useCallback(
    async (
      productElements: IProductElement[],
      productOrId: ItemOrItemId<IProduct>,
    ) => {
      const epdId = getId(productOrId);
      const toUpdate = setEpdOnProductElements(productElements, epdId);

      // Make sure epd is mapped to the generic product (to reuse conversion factors)
      updateEPDIdsAppliedToGenericProducts(toUpdate);

      if (toUpdate !== productElements) {
        return await updateElements(...toUpdate);
      }
      return getProject();
    },
    [updateElements],
  );
};
