import { v4 } from 'uuid';
import {
  BOVERKET_ID_PREFIX,
  CUSTOM_ID_PREFIX,
  IFactoryProduct,
  NODON_ID_PREFIX,
  OKOBAUDAT_ID_PREFIX,
  IProduct,
  ProductID,
  ProductSources,
} from '../models/product.interface';
import { getTimestamps } from './date.helpers';
import { isEmptyObject, omitUndefined } from './object_helpers';
import { getProductSourceFromId, isBoverketProduct } from './product_helpers';
import { ModelTimestamps } from '../models/base.interface';
import { validateProduct } from '../validation/product.validation';
import {
  createConversionFactors,
  DEFAULT_CONVERSION_FACTORS,
  getUnitFromConversionFactors,
} from './conversion-factors.helpers';

/**
 * Create a custom product based on a provided subset of properties. Can be based on an existing product.
 * @param partial
 * @returns
 */
export const createCustomProduct = (
  partial: IFactoryProduct | IProduct,
): IProduct => {
  const { organizations, id } = partial;
  if (!organizations?.length) {
    throw new Error('Product must be provided with an organization');
  }

  // Take provided boverket id as generic id if it's a boverket product
  const generic_id =
    !partial.generic_id && id && isBoverketProduct(id)
      ? id
      : partial.generic_id;

  return createProduct({
    ...partial,
    source: ProductSources.Custom, // Force source to custom
    id: '', // Reset id to create a new custom product
    categories: undefined, // Use default categories
    organizations,
    generic_id,
  });
};

/**
 * Create a product of a certain type based on a provided subset of properties.
 * @param partial
 * @returns
 */
export const createProduct = (
  partial: IFactoryProduct | IProduct,
): IProduct => {
  // Make sure no keys with undefined values are included
  partial = omitUndefined(partial) as IFactoryProduct;
  const source = getSource(partial);
  const defaults = getDefaultsBySource(source);
  const conversion_factors = createConversionFactors(
    applyDefaultConversionFactors(source, partial.conversion_factors),
  );
  const unit = getUnit(partial, conversion_factors);
  const id = createProductId(source, partial.id);

  return validateProduct({
    ...defaults,
    ...partial,
    ...getTimestamps(),
    conversion_factors,
    unit,
    source,
    id,
  });
};

const applyDefaultConversionFactors = (
  source: IProduct['source'],
  suppliedConversionFactors: IProduct['conversion_factors'] = {},
): IProduct['conversion_factors'] => {
  if (isEmptyObject(suppliedConversionFactors)) {
    return { ...DEFAULT_CONVERSION_FACTORS };
  }
  if (source !== ProductSources.Custom) {
    const { kg, co2e_A4 } = suppliedConversionFactors;

    // Add a default transport factor if kg is provided but not co2e_A4
    if (kg && !co2e_A4) {
      return {
        ...suppliedConversionFactors,
        co2e_A4: DEFAULT_CONVERSION_FACTORS['co2e_A4'] * kg,
      };
    }
  }
  return suppliedConversionFactors;
};

const getUnit = (
  defaults: IFactoryProduct,
  conversion_factors: IProduct['conversion_factors'],
): IProduct['unit'] => {
  const unit = defaults.unit;
  if (unit) {
    return unit;
  }

  return getUnitFromConversionFactors(conversion_factors) ?? 'kg';
};

/**
 * Get the source of a product based on the provided defaults.
 * If no source is provided, the source is inferred from the id.
 * @param defaults
 * @returns
 */
const getSource = (defaults: IFactoryProduct): IProduct['source'] =>
  defaults.source ??
  getProductSourceFromId(defaults.id) ??
  ProductSources.Custom;

/**
 * Get a product id based on the source and a baseId.
 * @param source The source of the product
 * @param baseId Either an already prefixed id or an original id from a source like Boverket
 * @returns
 */
export const createProductId = (
  source: IProduct['source'],
  baseId?: ProductID,
): ProductID => {
  const idSource = getProductSourceFromId(baseId);

  // Provided id is correct and should be used
  if (idSource && baseId) {
    if (idSource !== source) {
      throw new Error('Source and id syntax does not match');
    }
    return baseId;
  }

  // Custom ids can be generated
  if (source === ProductSources.Custom) {
    return `${CUSTOM_ID_PREFIX}${baseId || v4()}`;
  }

  // Other sources require a baseId
  if (!baseId) {
    throw new Error('Product must be provided with an id');
  }

  switch (source) {
    case ProductSources.Boverket:
      return `${BOVERKET_ID_PREFIX}${baseId}`;
    case ProductSources.Nodon:
      return `${NODON_ID_PREFIX}${baseId}`;
    case ProductSources.Ökobaudat:
      return `${OKOBAUDAT_ID_PREFIX}${baseId}`;
    default:
      throw new Error('Could not generate product id');
  }
};

type ProductDefaults = Pick<
  IProduct,
  | 'source'
  | 'external_identifiers'
  | 'characteristics'
  | 'description'
  | 'unit'
  | 'conversion_factors'
  | 'organizations'
  | 'owner'
  | 'categories'
  | 'category_property_value_record'
> &
  ModelTimestamps;

const getDefaultsBySource = (source: IProduct['source']): ProductDefaults => {
  const shared: ProductDefaults = {
    categories: {},
    characteristics: {},
    conversion_factors: { kg: 1 },
    description: '',
    external_identifiers: {},
    category_property_value_record: {},
    source,
    unit: 'kg',
    // These undefined values is needed by bulk_upsert
    owner: undefined,
    organizations: undefined,
    deleted_at: undefined,
    ...getTimestamps(),
  };

  switch (source) {
    case ProductSources.Boverket:
    case ProductSources.Nodon:
      return {
        ...shared,
        categories: { Boverket: {} },
      };
    case ProductSources.Ökobaudat:
      return {
        ...shared,
        categories: { ILCD: {} },
      };
    case ProductSources.Custom:
      return {
        ...shared,
        organizations: [],
        categories: { Custom: true },
      };
    default:
      throw new Error('Unknown product source');
  }
};
