import { getItemById } from '../../helpers/array_helpers';
import {
  ElementCategoryID,
  IElementCategory,
  IMainCategoryElement,
  MainCategoryId,
  mainCategoryIds,
} from '../../models/element_categories.interface';
import { mainCategories } from './categories-main.model';
import { systemCategories } from './categories-system.model';
import { productCategories } from './categories-product.model';
import { installationCategories } from './categories-installation.model';
import { UnknownCategoryId } from '../../helpers/element_category_helpers';
import {
  IBuildingVersion,
  IElement,
  OneOfChildElements,
  OneOfListElements,
} from '../../models/project.interface';
import {
  getChildElements,
  getParentElement,
  isElement,
  isOneOfParentElements,
  isProductElement,
} from '../../helpers/recursive_element_helpers';
import {
  OptionallyRequired,
  OptionallyRequiredOptions,
} from '../../models/type_helpers.interface';

/**
 * All element categories available in the application.
 */
export const elementCategories: Readonly<IElementCategory[]> = Object.freeze([
  ...mainCategories,
  ...systemCategories,
  ...productCategories,
  ...installationCategories,
]);

/**
 * Get all ElementCategoryIDs that have generated children.
 */
const elementCategoriesWithGeneratedChildren = elementCategories.filter(
  (cat) => !!cat.getChildElements,
);

/**
 * Get all ElementCategoryIDs that have generated children.
 * Make this once to avoid triggering new renders of hooks.
 */
const elementCategoryIdsWithGeneratedChildren: ElementCategoryID[] =
  elementCategoriesWithGeneratedChildren.map((ec) => ec.id);

export const isCategoryIdsWithGeneratedChildren = (
  id?: ElementCategoryID | string,
): boolean =>
  !!id &&
  elementCategoryIdsWithGeneratedChildren.includes(id as ElementCategoryID);

/**
 * Get all available categories including those in use,
 * even if they are disabled.
 * @param usedIds
 * @returns
 */
export const getAvailableCategories = (
  ...usedIds: (ElementCategoryID | undefined)[]
): IElementCategory[] => {
  return elementCategories.filter(
    (cat) => !cat.disabled || usedIds.includes(cat.id),
  );
};

export const getElementCategoryById = <
  R extends OptionallyRequiredOptions = true,
>(
  id: ElementCategoryID,
  required: R = true as R,
): OptionallyRequired<IElementCategory, R> =>
  getItemById(
    elementCategories,
    id,
    required ? `Could not find element category "${id}"` : (false as R),
  );

/**
 * Get parent to a specific Element Category
 * @param id
 * @returns
 */
export const getParentCategory = (
  id: ElementCategoryID,
): Readonly<IElementCategory> | undefined => {
  const category = getElementCategoryById(id);
  if (category.parent_id) {
    return getElementCategoryById(category.parent_id);
  }
};

export const isMainCategory = (id?: UnknownCategoryId): id is MainCategoryId =>
  !!id && mainCategoryIds.includes(id as MainCategoryId);

export const isMainCategoryElement = (
  element?: OneOfListElements,
): element is IMainCategoryElement =>
  isElement(element) && isMainCategory(element.category_id);

export const getMainCategoryId = (
  id: UnknownCategoryId,
): MainCategoryId | undefined => (isMainCategory(id) ? id : undefined);

export const getMainCategory = (
  id: UnknownCategoryId,
): IElementCategory | undefined =>
  isMainCategory(id) ? getElementCategoryById(id) : undefined;

export const getMainCategoryElements = (
  version?: IBuildingVersion,
): IMainCategoryElement[] =>
  getChildElements(version).filter(isMainCategoryElement);

export const getMainCategoryElement = (
  version: IBuildingVersion,
  id: UnknownCategoryId,
  getDefault = false,
): IElement | undefined => {
  return getMainCategoryElements(version).find(
    (el) =>
      el.category_id ===
      (!id && getDefault ? ElementCategoryID.MAIN_OTHER : id),
  );
};

/**
 * Get the main category id of a child element.
 * Note that you need to pass the grandparent if you're using list view since main category elements are hidden in list.
 * @param parentOrGrandParent Main category elements are hidden in list but exist in the model (that's)
 * @param child
 * @returns
 */
export const getParentMainCategoryId = (
  searchIn: OneOfListElements | undefined,
  child: OneOfListElements,
): MainCategoryId | undefined => {
  const isPossibleChild = isElement(child) || isProductElement(child);

  if (!isOneOfParentElements(searchIn) || !isPossibleChild) {
    return undefined;
  }

  // Get the real parent
  const parent = getParentElement(searchIn, child);

  if (isElement(parent)) {
    return getMainCategoryId(parent.category_id);
  }
};

export const isMainCategoryMatch = (
  selectedCategoryId?: ElementCategoryID,
  elementCategoryId?: ElementCategoryID,
): boolean => {
  const selectedMainCategoryId = getMainCategoryId(selectedCategoryId);
  const elementMainCategoryId = getMainCategoryId(elementCategoryId);

  return selectedMainCategoryId === elementMainCategoryId;
};

export const mainCategoryElementsAreValid = (
  elements: OneOfChildElements[] = [],
): boolean => {
  return elements.every(
    (element) => isElement(element) && isMainCategory(element.category_id),
  );
};
