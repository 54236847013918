import { IProduct, ProductID } from '../models/product.interface';
import {
  IBuildingVersion,
  IProductElement,
  IProductReference,
  OneOfElements,
} from '../models/project.interface';
import { validateGenericProductId } from '../validation/product.validation';
import { omit } from './object_helpers';
import { shallowEqualMap } from './array_helpers';
import {
  getEpdProduct as getEPDProduct,
  getProductById,
  ProductRecordOwners,
} from './product_helpers';
import { Results } from '../models/unit.interface';
import { getCombinedConversionFactors } from './results.helpers';
import { isProductElement } from './recursive_element_helpers';

export const hasProductElementEpd = ({
  product_id,
  generic_product_id,
}: IProductReference): boolean =>
  !!generic_product_id && !!product_id && generic_product_id !== product_id;

/**
 * Clear the EPD from a product element.
 * @param productElement
 * @returns
 */
export const clearProductElementEpd = (
  productElement: IProductElement,
): IProductElement => {
  // Only clear if the product element has an EPD
  if (getEPDProductId(productElement)) {
    // Use generic product id as product_id
    const product_id = getGenericProductId(productElement);
    return omit({ ...productElement, product_id }, 'generic_product_id');
  }
  return productElement;
};

/**
 * Remove EPD on multiple product elements.
 * @param productElements
 * @returns
 */
export const clearEpdOnProductElements = (productElements: IProductElement[]) =>
  shallowEqualMap(productElements, clearProductElementEpd);

/**
 * Set the EPD on a product element.
 * @param productElement
 * @param product_id
 * @param generic_id Provide a new generic id if you want to override the existing one, else we'll keep the existing one
 * @returns
 */
export const setProductElementEpd = (
  productElement: IProductElement,
  product_id: ProductID,
  generic_id?: ProductID,
): IProductElement => {
  // Only set if the product element does not already have the EPD
  if (getEPDProductId(productElement) !== product_id) {
    const generic_product_id =
      generic_id ?? getGenericProductId(productElement);
    return { ...productElement, product_id, generic_product_id };
  }
  return productElement;
};

/**
 * Set EPD on multiple product elements.
 * @param productElements
 * @param product_id
 * @param generic_id Provide a new generic id if you want to override the existing one, else we'll keep the existing one
 * @returns
 */
export const setEpdOnProductElements = (
  productElements: IProductElement[],
  product_id: ProductID,
  generic_id?: ProductID,
) =>
  shallowEqualMap(productElements, (el) =>
    setProductElementEpd(el, product_id, generic_id),
  );

/**
 * Get generic product id from a product element.
 * Should always exist
 * @param product
 * @returns
 */
export const getGenericProductId = (
  productOrId: IProductReference | ProductID,
): ProductID => {
  if (typeof productOrId === 'string') {
    return validateGenericProductId(productOrId);
  }
  // Product_id is the generic id if generic_product id is not specified
  return validateGenericProductId(
    productOrId.generic_product_id ?? productOrId.product_id,
  );
};

/**
 * Get EPD product id from a product element.
 * If no generic product id is specified it will return undefined.
 * @param product
 * @returns
 */
export const getEPDProductId = (
  productOrId: IProductReference | ProductID,
): ProductID | undefined => {
  if (typeof productOrId === 'string') {
    return productOrId;
  }
  return hasProductElementEpd(productOrId) ? productOrId.product_id : undefined;
};

/**
 * Get the product description from corresponding product element
 * @param element
 * @param version
 * @returns
 */
export const getProductDescription = (
  element: OneOfElements,
  version?: IBuildingVersion,
): string => {
  if (isProductElement(element)) {
    const epdId = getEPDProductId(element);

    if (epdId) {
      const epd = getEPDProduct(version?.products, epdId);
      return epd?.description ?? '';
    }
  }
  return '';
};

/**
 * Get conversion factors for a product element in the product element unut
 * @param versionOrPath
 * @param element
 * @param product
 * @returns
 */
export const getProductElementConversionFactors = (
  productRecordOwner: ProductRecordOwners,
  element: IProductElement,
  product?: IProduct,
): Results => {
  const generic = getProductById(
    productRecordOwner,
    element.generic_product_id,
  );
  const epd = getProductById(productRecordOwner, element.product_id) ?? product;

  // Conversion factors for a single unit of a product
  return getCombinedConversionFactors(epd, generic, element.unit);
};

/**
 * Check if a product element unit is supported. (must exist in the conversion factors)
 * @param productRecordOwner
 * @param element
 * @returns
 */
export const isSupportedProductElementUnit = (
  productRecordOwner: ProductRecordOwners,
  element: IProductElement,
): boolean => {
  const conversionFactors = getProductElementConversionFactors(
    productRecordOwner,
    element,
  );
  return typeof conversionFactors[element.unit] === 'number';
};
