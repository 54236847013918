import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import { IFactoryProperty } from '../../../models/element_property.interface';
import {
  metalProductTree,
  materialProperty,
  formProperty,
  amountProperty,
} from './metal.model';
import { createProductTreeProcessor } from '../processor';
import { DEFAULT_QUANTITY_PROPERTIES } from '../../../models/element_quantities.interface';

const levelProperties: IFactoryProperty[] = [
  materialProperty,
  formProperty,
  amountProperty,
];

const processor = createProductTreeProcessor({
  levelProperties,
  productTree: metalProductTree,
});

export const metal: IElementCategory = {
  defaultSelectedQuantity: 'mass',
  ...processor,
  id: ElementCategoryID.Metal,
  name: 'Metal',
  color: '#96D5DD',
  getQuantityProperties: () => DEFAULT_QUANTITY_PROPERTIES,
};
