import {
  isCostUnit,
  isUnitB,
  isCO2eUnit,
} from '../../../../shared/helpers/unit_helpers';
import { isDefined } from '../../../../shared/helpers/array_helpers';
import { Results } from '../../../../shared/models/unit.interface';
import { orderBy, size, sortBy } from 'lodash';
import { ISimpleBarChartValue } from './SimpleBarChart';

const OPACITY_B = 0.7;

export const getCo2eChartDataFromResults = (
  results: Results,
): ISimpleBarChartValue[] => {
  return sortBy(
    Object.entries(results)
      .map(([key, value]) =>
        isCO2eUnit(key)
          ? { label: key, value, opacity: isUnitB(key) ? OPACITY_B : 1 }
          : undefined,
      )
      .filter(isDefined),
    'label',
  );
};

export const getCostChartDataFromResults = (
  results: Results,
): ISimpleBarChartValue[] => {
  return sortBy(
    Object.entries(results)
      .map(([key, value]) =>
        isCostUnit(key)
          ? { label: key, value, opacity: isUnitB(key) ? OPACITY_B : 1 }
          : undefined,
      )
      .filter(isDefined),
    'label',
  );
};

/**
 * Get the width of a bar in percent.
 * @param value
 * @param maxValue
 * @returns
 */
export const getWidthInPercent = (value: number, maxValue: number): number => {
  return Math.max(0, (value / maxValue) * 100);
};

/**
 * Convert the different types of input values to an array of bar chart values.
 * @param value
 * @returns
 */
export const toBarChartValues = (
  value:
    | number
    | ISimpleBarChartValue[]
    | number[]
    | Record<string, number> = 0,
): ISimpleBarChartValue[] => {
  // Handle empty values
  if (typeof value === 'number') {
    return [{ value }];
  }
  // Handle empty values
  if (size(value) === 0) {
    return [{ value: 0 }];
  }
  if (Array.isArray(value)) {
    return sortBy(
      value.map((v) => (typeof v === 'number' ? { value: v } : v)),
      'label',
    );
  }

  return orderBy(
    Object.entries(value).map(([label, value]) => ({ label, value })),
    ['label', 'value'],
    ['asc', 'desc'],
  );
};

/**
 * Remove empty values from the bar chart values.
 * Make sure to keep at least one value even if it's 0.
 * @param values
 * @returns
 */
export const removeEmptyValues = (
  values: ISimpleBarChartValue[],
): ISimpleBarChartValue[] => {
  const firstValue = values[0];
  if (!firstValue) {
    return [];
  }
  const filtered = values.filter((v) => v.value);
  return filtered.length > 0 ? filtered : [firstValue];
};
