import { IProduct } from '../../../../shared/models/product.interface';
import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import axios from 'axios';
import { toLookup } from '../../../../shared/helpers/utils.helpers';
import { IProductStoreState } from './product-state.model';
import { reloadApp, UpdateClientAction, updateResourceLocally } from '../utils';
import { shallow } from 'zustand/shallow';
import { getSelectedOrganization } from '../organization';
import { createCustomProduct } from '../../../../shared/helpers/product-factory.helpers';
import { initHmrStore, getHmrStoreState } from '../../helpers/vite.helpers';
import { validateProduct } from '../../../../shared/validation/product.validation';
import genericProducts from '../../../../shared/generic_products';

const STORE_NAME = 'products';

export const useProductStore = createWithEqualityFn<IProductStoreState>()(
  devtools(
    (set, get) => ({
      products: [],
      productsLookup: {},
      isLoading: false,
      isUpdating: false,
      isDeleting: false,
      isCreating: false,
      ...getHmrStoreState(STORE_NAME),

      fetchProducts: async () => {
        set(() => ({ isLoading: true }));

        try {
          const { data } = await axios.get<IProduct[]>('products');
          const products = [...genericProducts, ...data];

          set(() => ({
            isLoading: false,
            products,
            productsLookup: toLookup(products),
          }));

          return data;
        } catch (err: any) {
          set(() => ({ isLoading: false }));
          return Promise.reject(err);
        }
      },

      createProduct: async (productPartial) => {
        const { updateProductsLocally } = get();
        set(() => ({ isCreating: true }));

        const organization = getSelectedOrganization(true);

        // Users can only create custom products
        const product = createCustomProduct({
          ...productPartial,
          organizations: [organization],
        });

        if (get().productsLookup[product.id]) {
          throw new Error('Product already exists');
        }
        try {
          const { data } = await axios.post<IProduct>(`products`, product);
          updateProductsLocally({
            action: UpdateClientAction.Add,
            itemOrId: data,
          });
          return data;
        } catch (err: any) {
          set(() => ({ isCreating: false }));
          return Promise.reject(err);
        }
      },

      updateProduct: async (product: IProduct) => {
        const { updateProductsLocally } = get();
        set(() => ({ isUpdating: true }));
        validateProduct(product);

        try {
          const { data } = await axios.put<IProduct>(`/products`, product);
          updateProductsLocally({
            action: UpdateClientAction.Update,
            itemOrId: data,
          });
          return data;
        } catch (err: any) {
          set(() => ({ isUpdating: false }));
          reloadApp(err.response?.status as number);
          return Promise.reject(err);
        }
      },

      deleteProduct: async (id: string) => {
        const { updateProductsLocally } = get();
        set(() => ({ isDeleting: true }));

        try {
          await axios.delete(`/products/${id}`);
          updateProductsLocally({
            action: UpdateClientAction.Remove,
            itemOrId: id,
          });
        } catch (err: any) {
          set(() => ({ isDeleting: false }));
          reloadApp(err.response?.status as number);
          return Promise.reject(err);
        }
      },

      updateProductsLocally: ({
        action: event,
        itemOrId,
        options: {
          isLoading = false,
          isUpdating = false,
          isDeleting = false,
          isCreating = false,
        } = {},
      }) => {
        let { products, productsLookup } = get();

        if (
          event === UpdateClientAction.Add ||
          event === UpdateClientAction.Update
        ) {
          const { collection, lookup } = updateResourceLocally({
            action: event,
            itemOrId,
            collection: products,
          });

          products = collection ?? products;
          productsLookup = lookup ?? productsLookup;
        }

        if (event === UpdateClientAction.Remove) {
          const { collection, lookup } = updateResourceLocally({
            action: event,
            itemOrId,
            collection: products,
          });

          products = collection ?? products;
          productsLookup = lookup ?? productsLookup;
        }

        set(() => ({
          isLoading,
          isUpdating,
          isDeleting,
          isCreating,
          products,
          productsLookup,
        }));
      },
    }),
    { name: STORE_NAME },
  ),
  shallow,
);

initHmrStore(STORE_NAME, useProductStore);
