import React, { useCallback, useState } from 'react';
import {
  getProjectSearchString,
  setProjectSearchString,
} from '../../hooks/filter-projects.hook';
import SearchField from '../SearchField';
import { SxProps, TextFieldProps } from '@mui/material';

const SearchProjects: React.FC = () => {
  const initialSearchString = getProjectSearchString();
  const [searchString, setSearchString] = useState(initialSearchString ?? '');

  const handleSearch = useCallback(
    (str: string) => {
      setSearchString(str);
      setProjectSearchString(str);
    },
    [setSearchString],
  );

  return (
    <SearchField
      autoFocus
      placeholder="Search projects"
      sx={sx}
      slotProps={slotProps}
      value={searchString}
      onChange={handleSearch}
    />
  );
};

const sx: SxProps = {
  width: '40%',
  justifyContent: 'center',
} as const;

const slotProps: TextFieldProps['slotProps'] = {
  input: { sx: { padding: '0 4px 0 0' } },
  htmlInput: { sx: { padding: '5px 0px' } },
} as const;

export default SearchProjects;
