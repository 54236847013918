import {
  NodonEnvironment,
  NodonHost,
  ServerEnvironment,
} from '../models/environment.interface';
import { isOneOf } from './array_helpers';
import dotenv from 'dotenv';
const hostEnvironments = {
  [NodonHost.Localhost]: NodonEnvironment.Localhost,
  [NodonHost.Development]: NodonEnvironment.Development,
  [NodonHost.Production]: NodonEnvironment.Production,
} as const;

// Load environment variables from .env into process.env if in server/migrations
if (typeof process === 'object' && typeof process.cwd === 'function') {
  dotenv.config();
}

export const getProcessEnvironment = (): NodeJS.ProcessEnv | undefined => {
  if (typeof process === 'object') {
    return process.env;
  }
  return undefined;
};

/**
 * Get the server environment from the process environment.
 * In client use getConfig() instead.
 * @returns The server environment.
 */
export const getServerEnvironment = (): ServerEnvironment => {
  const env = getProcessEnvironment() as ServerEnvironment;

  const guide =
    '. Please set the environment variables in the .env file or in the environment varaibles in google cloud.';

  const envs = Object.values(NodonEnvironment);

  if (!isOneOf(envs, env.ENVIRONMENT)) {
    throw new Error(
      'ENVIRONMENT is not set. Environment must be one of: ' +
        envs.join(', ') +
        guide,
    );
  }

  // Either DATABASE_URL or all DB_* must be set
  if (
    !env.DATABASE_URL &&
    ![
      env.DB_HOST,
      env.DB_PORT,
      env.DB_USER,
      env.DB_PASSWORD,
      env.DB_DATABASE,
    ].every(Boolean)
  ) {
    throw new Error(
      'Either DATABASE_URL or all of DB_HOST, DB_PORT, DB_USER, DB_PASSWORD, DB_DATABASE must be set' +
        guide,
    );
  }

  return env;
};

/**
 * Get the environment from the window location or the server environment.
 * @returns The environment.
 */
export const getEnvironment = () => {
  if (typeof window !== 'undefined') {
    return hostEnvironments[window.location.hostname as NodonHost];
  }
  return getServerEnvironment().ENVIRONMENT;
};

/**
 * Check if the current environment is the given environment.
 * @param env The environment to check.
 * @returns True if the current environment is the given environment, false otherwise.
 */
export const isEnvironment = (
  env: ServerEnvironment['ENVIRONMENT'],
): boolean => {
  return getEnvironment() === env;
};
