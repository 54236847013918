/**
 * Validate that a value is a finite number. If not, throw an error.
 * @param value
 * @returns
 */
export const validateFiniteNumber = (value: unknown): number => {
  if (!isValidNumber(value)) {
    throw new Error('Value must be a valid number');
  }
  return value;
};

/**
 * Validate that a number is greater than 0. If not, throw an error.
 * @param value
 * @returns
 */
export const validatePositiveNumber = (value: unknown): number => {
  const num = validateFiniteNumber(value);
  if (num <= 0) {
    throw new Error('Value must be greater than 0');
  }
  return num;
};

/**
 * Test if a value is a valid number.
 * @param value
 * @param requireFinite - If true, will also check if the number is finite (i.e. not Infinity or NaN)
 * @returns
 */
export const isValidNumber = (
  value: unknown,
  requireFinite = true,
): value is number => {
  if (typeof value === 'number') {
    return requireFinite ? isFinite(value) : true;
  }
  return false;
};
