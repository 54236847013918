import { Orientation } from '../../models/orientation.interface';
import { createExpression } from '../../helpers/expression_factory_helpers';
import {
  ElementCategoryID,
  IElementCategory,
} from '../../models/element_categories.interface';
import { ElementPropertyName } from '../../models/element_property.interface';
import { omit } from '../../helpers/object_helpers';
import { DEFAULT_QUANTITY_PROPERTIES } from '../../models/element_quantities.interface';

const defaultMainCategoryQuantities = omit(
  DEFAULT_QUANTITY_PROPERTIES,
  'mass',
  'density',
  'fill',
);

export const mainCategories: IElementCategory[] = [
  {
    id: ElementCategoryID.ROOF,
    disabled: true,
    properties: [
      {
        name: ElementPropertyName.RoofPitch,
        unit: '°',
        min: 0,
        max: 89,
        resolveBeforeProjectVariables: true,
        count: createExpression(20),
      },
    ],
    name: 'Roof',
    getQuantityProperties: () => ({
      ...defaultMainCategoryQuantities,
      area_top: {
        fallbackCount: 'gfa_sky',
        disableInheritance: true,
      },
    }),
  },
  {
    id: ElementCategoryID.FACADES,
    name: 'Facades',
    orientation: Orientation.Vertical,
    disabled: true,
    getQuantityProperties: () => ({
      ...defaultMainCategoryQuantities,
      area_side: {
        fallbackCount: 'external_gwa_above_ground',
        disableInheritance: true,
      },
    }),
  },
  {
    id: ElementCategoryID.FOUNDATION,
    name: 'Foundation',
    disabled: true,
    getQuantityProperties: () => ({
      ...defaultMainCategoryQuantities,
      area_top: {
        fallbackCount: 'gfa_ground_floor_slabs',
        disableInheritance: true,
      },
    }),
  },
  {
    id: ElementCategoryID.FLOORS,
    name: 'Floors',
    orientation: Orientation.Horizonal,
    disabled: true,
    getQuantityProperties: () => ({
      ...defaultMainCategoryQuantities,
      area_top: {
        fallbackCount: 'gfa_floor_slabs',
        disableInheritance: true,
      },
    }),
  },
  {
    id: ElementCategoryID.INTERNAL_WALLS,
    name: 'Internal Walls',
    disabled: true,
    getQuantityProperties: () => ({
      ...defaultMainCategoryQuantities,
      area_side: {
        fallbackCount:
          'gwa_apartment_parting_internal_walls + gwa_stairwell_parting_walls + gwa_elevator_shaft_walls + gwa_partition_walls',
        disableInheritance: true,
      },
    }),
  },
  {
    id: ElementCategoryID.INSTALLATIONS,
    name: 'Installations',
    disabled: true,
    getQuantityProperties: () => ({
      ...defaultMainCategoryQuantities,
      area_top: {
        fallbackCount: 'gfa_installations',
        disableInheritance: true,
      },
      volume: {
        fallbackCount: 'gross_volume',
        disableInheritance: true,
      },
    }),
  },
  {
    id: ElementCategoryID.MAIN_OTHER,
    name: 'Other',
    disabled: true,
    getQuantityProperties: () => omit(defaultMainCategoryQuantities),
  },
];
