import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { useRemoveElements } from '../../../../store/project';
import { useMouseEventCallback } from '../../../../hooks/events.hook';
import { useVersions } from '../../../../hooks/useElement';
import { IBuildingVersion } from '../../../../../../shared/models/project.interface';
import { useIsReadonly } from '../../../../hooks/user.hook';

interface VersionActionsProps {
  versionId: IBuildingVersion['id'];
  isEditingName: boolean;
  hover: boolean;
  onEdit: () => void;
}

const VersionActions: React.FC<VersionActionsProps> = ({
  versionId,
  isEditingName,
  hover,
  onEdit,
}) => {
  const removeVersion = useRemoveElements({ showConfirm: true });
  const versions = useVersions();
  const readonly = useIsReadonly();

  const iconTooltip = useMemo(() => {
    if (isEditingName) {
      return versions.length > 1 ? 'Delete' : "Can't delete the last version";
    }
    return 'Edit';
  }, [isEditingName, versions.length]);

  const handleClick = useMouseEventCallback(() => {
    if (!isEditingName) {
      onEdit();
      return;
    }
    removeVersion(versionId);
  });

  return (
    !readonly &&
    hover && (
      <Tooltip title={iconTooltip} placement="right">
        <div>
          <IconButton
            size="small"
            onClick={handleClick}
            disabled={isEditingName && versions.length <= 1}
          >
            {isEditingName ? (
              <Delete fontSize="small" />
            ) : (
              <Edit fontSize="small" />
            )}
          </IconButton>
        </div>
      </Tooltip>
    )
  );
};

export default VersionActions;
