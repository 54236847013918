import { useCallback, useMemo } from 'react';
import {
  getElementQuantityProperties,
  getSelectedElementQuantityProperty,
  setElementQuantityProperties,
} from '../../../shared/helpers/element_quantity_helpers';
import {
  getElementById,
  getBuilding,
  isElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  FactoryQuantityRecord,
  IElementQuantityProperty,
  IFactoryQuantityProperty,
} from '../../../shared/models/element_quantities.interface';
import { IElement } from '../../../shared/models/project.interface';
import { useUpdateElements } from '../store/project';
import { useErrorSnackbar } from './snackbar.hook';
import amplitudeLog from '../amplitude';

/**
 * Get all quantity properties for an element.
 * @param element
 * @returns
 */
export const useQuantityProperties = (
  element: IElement,
): IElementQuantityProperty[] =>
  useMemo(
    () => getElementQuantityProperties(element.quantity),
    [element.quantity],
  );

/**
 * Get the selected quantity property for an element.
 * Will automatically fallback if undefined.
 * @param element
 * @returns
 */
export const useSelectedQuantityProperty = (element: IElement) =>
  useMemo(() => getSelectedElementQuantityProperty(element), [element]);

/**
 * Get an update function for the quantity properties for an element.
 * @returns
 */
export const useUpdateQuantity = (): ((
  element: IElement,
  changes: FactoryQuantityRecord | IFactoryQuantityProperty[],
) => Promise<IElement>) => {
  const updateElements = useUpdateElements();
  const errorSnackbar = useErrorSnackbar();

  return useCallback(
    async (
      element: IElement,
      changes: FactoryQuantityRecord | IFactoryQuantityProperty[],
    ): Promise<IElement> => {
      const updatedProject = await updateElements(
        errorSnackbar(() => setElementQuantityProperties(element, changes)),
      );

      const updatedElement = getElementById(
        getBuilding(updatedProject),
        element.id,
      );

      if (!isElement(updatedElement)) {
        throw new Error('Updated Element not found');
      }

      amplitudeLog('Element Quantity Set', {
        ElementID: updatedElement.id,
      });

      return updatedElement;
    },
    [errorSnackbar, updateElements],
  );
};
