import {
  ElementPropertyType,
  IFactoryProperty,
  IFactorySelectProperty,
  IFactorySwitchProperty,
} from '../../../models/element_property.interface';

// Property Names
export enum SlabPropertyName {
  WeatherResistance = 'weather_resistance',
  ThermalInsulation = 'thermal_insulation',
  LoadBearing = 'load_bearing',
  SoundInsulation = 'sound_insulation',
}

export enum SoundInsulationOptions {
  None = 'none',
  ClassA = 'Class A',
  ClassB = 'Class B',
  ClassC = 'Class C',
}

const weatherResistanceProperty: IFactorySwitchProperty = {
  type: ElementPropertyType.Switch,
  name: SlabPropertyName.WeatherResistance,
};

const thermalInsulationProperty: IFactorySwitchProperty = {
  type: ElementPropertyType.Switch,
  name: SlabPropertyName.ThermalInsulation,
};

const loadBearingProperty: IFactorySwitchProperty = {
  type: ElementPropertyType.Switch,
  name: SlabPropertyName.LoadBearing,
};

const soundInsulationProperty: IFactorySelectProperty = {
  type: ElementPropertyType.Select,
  name: SlabPropertyName.SoundInsulation,
  options: Object.values(SoundInsulationOptions),
};

export const slabProperties: IFactoryProperty[] = [
  weatherResistanceProperty,
  thermalInsulationProperty,
  loadBearingProperty,
  soundInsulationProperty,
];
