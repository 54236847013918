import { io, Socket } from 'socket.io-client';
import { useAuth0 } from '@auth0/auth0-react';
import * as uuid from 'uuid';
import { getProject, useProjectState } from '../store/project';
import { useProductStoreState } from '../store/product';
import { useSelectedOrganization } from '../store/organization';
import { useCallback, useEffect, useRef } from 'react';
import { Project } from '../../../shared/models/project.interface';
import { useConfig } from '../providers/ConfigProvider';
import { useCommentsStore } from '../store/comment/comment.store';
import { IComment } from '../../../shared/models/comment.interface';
import { useRecipesStore } from '../store/recipe';
import { IProduct } from '../../../shared/models/product.interface';
import { UpdateClientAction, UpdateClientData } from '../store/utils';
import { required } from '../../../shared/helpers/function_helpers';
import { SocketEventName } from '../../../shared/helpers/socket.helpers';
import { Recipe } from '../../../shared/models/recipe.interface';
import { isEqualIds } from '../../../shared/helpers/utils.helpers';

type SocketListenerUtils = () => [() => Promise<void>, () => void];

export const useSocketListeners: SocketListenerUtils = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [config] = useConfig();
  const { updateProjectLocally } = useProjectState('updateProjectLocally');
  const { updateProductsLocally } = useProductStoreState(
    'updateProductsLocally',
  );
  const updateCommentsLocally = useCommentsStore(
    ({ updateCommentsLocally }) => updateCommentsLocally,
  );
  const updateRecipesLocally = useRecipesStore(
    ({ updateRecipesLocally }) => updateRecipesLocally,
  );

  const selectedOrganization = useSelectedOrganization();
  const socketRef = useRef<Socket | undefined>();

  useEffect(() => window.sessionStorage.setItem('id', uuid.v4()), []);

  const handleResource = useCallback(
    <T>(
      event:
        | UpdateClientAction.Add
        | UpdateClientAction.Update
        | UpdateClientAction.Remove,
      cb: (args: UpdateClientData<T>) => void,
    ) =>
      (resource: T, initiatorSessionId: string) => {
        const sessionId = window.sessionStorage.getItem('id') ?? '';

        if (initiatorSessionId === sessionId || !resource) {
          return;
        }
        event === UpdateClientAction.Remove
          ? cb({ action: event, itemOrId: resource as string | number })
          : cb({ action: event, itemOrId: resource });
      },
    [],
  );

  const reloadOnProjectDelete = useCallback(
    ({ itemOrId }: UpdateClientData<Project>) => {
      if (isEqualIds(getProject(), itemOrId)) {
        window.location.reload();
      }
    },
    [],
  );

  const clearSocketListeners = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.removeAllListeners();
      socketRef.current = undefined;
    }
  }, [socketRef]);

  const addSocketListeners = useCallback(async () => {
    clearSocketListeners();
    const token = await getAccessTokenSilently();
    const sessionId = window.sessionStorage.getItem('id') ?? '';
    const socket = io(config.apiUrl, {
      auth: {
        token: `Bearer ${token}`,
      },
      extraHeaders: {
        AppVersion: config.version,
      },
      query: {
        organization: required(
          selectedOrganization,
          'No organization selected',
        ),
        sessionId,
        projectId: getProject().id,
      },
      transports: ['websocket'],
    });
    socketRef.current = socket;

    socket.on(
      SocketEventName.ProjectCreate,
      handleResource<Project>(UpdateClientAction.Add, updateProjectLocally),
    );

    socket.on(
      SocketEventName.ProjectUpdate,
      handleResource<Project>(UpdateClientAction.Update, updateProjectLocally),
    );
    socket.on(
      SocketEventName.ProjectDelete,
      handleResource<Project>(UpdateClientAction.Remove, reloadOnProjectDelete),
    );

    socket.on(
      SocketEventName.RecipeCreate,
      handleResource<Recipe>(UpdateClientAction.Add, updateRecipesLocally),
    );
    socket.on(
      SocketEventName.RecipeUpdate,
      handleResource<Recipe>(UpdateClientAction.Update, updateRecipesLocally),
    );
    socket.on(
      SocketEventName.RecipeDelete,
      handleResource<Recipe>(UpdateClientAction.Remove, updateRecipesLocally),
    );

    socket.on(
      SocketEventName.ProductCreate,
      handleResource<IProduct>(UpdateClientAction.Add, updateProductsLocally),
    );
    socket.on(
      SocketEventName.ProductUpdate,
      handleResource<IProduct>(
        UpdateClientAction.Update,
        updateProductsLocally,
      ),
    );
    socket.on(
      SocketEventName.ProductDelete,
      handleResource<IProduct>(
        UpdateClientAction.Remove,
        updateProductsLocally,
      ),
    );

    socket.on(
      SocketEventName.CommentCreate,
      handleResource<IComment>(UpdateClientAction.Add, updateCommentsLocally),
    );
    socket.on(
      SocketEventName.CommentUpdate,
      handleResource<IComment>(
        UpdateClientAction.Update,
        updateCommentsLocally,
      ),
    );
    socket.on(
      SocketEventName.CommentDelete,
      handleResource<IComment>(
        UpdateClientAction.Remove,
        updateCommentsLocally,
      ),
    );
  }, [
    clearSocketListeners,
    getAccessTokenSilently,
    config.apiUrl,
    config.version,
    selectedOrganization,
    handleResource,
    updateProjectLocally,
    reloadOnProjectDelete,
    updateRecipesLocally,
    updateProductsLocally,
    updateCommentsLocally,
  ]);

  return [addSocketListeners, clearSocketListeners];
};
