import {
  ConversionFactors,
  QuantityUnit,
  quantityUnits,
  SelectableQuantityUnit,
  selectableQuantityUnits,
  CostUnit,
  costUnits,
  CO2eUnit,
  co2Units,
  UnitB,
  unitsB,
  ConversionFactorUnit,
  conversionFactorUnits,
  ConversionFactorQuantityUnit,
  conversionFactorQuantityUnits,
} from '../models/unit.interface';
import { isOneOf } from './array_helpers';
import {
  convert,
  getCorrespondingConversionFactorUnit,
} from './conversion_helpers';
import { getKeys } from './object_helpers';

export const isUnitB = (unit: unknown): unit is UnitB => {
  return isOneOf(unitsB, unit);
};

export const isCO2eUnit = (unit: unknown): unit is CO2eUnit => {
  return isOneOf(co2Units, unit);
};

export const isCostUnit = (unit: unknown): unit is CostUnit => {
  return isOneOf(costUnits, unit);
};

/**
 * Check if unit is a valid QuantityUnit
 * @param unit
 * @returns
 */
export const isQuantityUnit = (unit: unknown): unit is QuantityUnit =>
  isOneOf(quantityUnits, unit);

/**
 * Check if unit is a valid ConversionFactorUnit.
 * @param unit
 * @returns
 */
export const isConversionFactorUnit = (
  unit: unknown,
): unit is ConversionFactorUnit => isOneOf(conversionFactorUnits, unit);

/**
 * Check if unit is a valid ConversionFactorQuantityUnit.
 * @param unit
 * @returns
 */
export const isConversionFactorQuantityUnit = (
  unit: unknown,
): unit is ConversionFactorQuantityUnit =>
  isOneOf(conversionFactorQuantityUnits, unit);

/**
 * Check if unit is a valid SelectableQuantityUnit.
 * I.E. A unit that can be selected for a productElement/quantity property
 * @param unit
 * @returns
 */
export const isSelectableQuantityUnit = (
  unit: unknown,
): unit is SelectableQuantityUnit => {
  return isOneOf(selectableQuantityUnits, unit);
};

/**
 * Convert external units into supported ConversionFactorUnits. Will automatically convert kwh to MJ and l to m³
 * @param quantity
 * @param raw_unit
 * @returns
 */
export const unitAndQuantityHarmonizer = (
  quantity: number,
  raw_unit: string,
): { quantity: number; unit: ConversionFactorUnit } | null => {
  const unit = raw_unit?.toLowerCase();

  // Raw unit is already a valid conversion factor unit
  if (isConversionFactorUnit(raw_unit)) {
    return {
      quantity,
      unit: raw_unit,
    };
  }

  // Lowercase is already valid (like 'M' -> 'm')
  if (isConversionFactorUnit(unit)) {
    return {
      quantity,
      unit,
    };
  }

  switch (unit) {
    case 'm3':
    case 'm^3':
      return {
        quantity,
        unit: 'm³',
      };
    case 'm2':
    case 'm^2':
      return {
        quantity,
        unit: 'm²',
      };
    case 'omg': // seen as 'Kopiering', might mean 'Omgångar' ???
    case 'st':
    case 'stk':
      return {
        quantity,
        unit: 'pcs',
      };

    case 'ton':
      return {
        quantity: convert(quantity, 'tonne', 'kg'),
        unit: 'kg',
      };
    case 'l':
      return {
        quantity: convert(quantity, 'l', 'm³'),
        unit: 'm³',
      };
    case 'mj':
      return {
        quantity: convert(quantity, 'kWh', 'MJ'),
        unit: 'kWh',
      };
  }
  const unitToSave = getCorrespondingConversionFactorUnit(unit);
  if (unitToSave) {
    return {
      quantity,
      unit: unitToSave,
    };
  }
  return null;
};

/**
 * Convert external units into Quantity Units
 * @param raw_unit string
 * @returns SelectableQuantityUnit | undefined
 */
export const selectableUnitHarmonizer = (
  raw_unit: string,
): SelectableQuantityUnit | undefined => {
  switch (raw_unit?.toLowerCase()) {
    case 'm3':
    case 'm^3':
    case 'm³':
      return 'm³';
    case 'm2':
    case 'm^2':
    case 'm²':
      return 'm²';
    case 'kwh':
    case 'mj':
      return 'kWh';
    default:
      return isSelectableQuantityUnit(raw_unit) ? raw_unit : undefined;
  }
};

export const getSelectableUnitsInConversionFactors = (
  conversionFactors: ConversionFactors,
): SelectableQuantityUnit[] =>
  getKeys(conversionFactors).filter(
    isSelectableQuantityUnit,
  ) as SelectableQuantityUnit[];
