import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { OneOfProductListChildren } from '../../../../../../shared/models/project.interface';
import { useIsSelected } from '../../../../store/ui/ui.hook';
import { useToggleElementExpanded } from '../../../../hooks/expand-elements.hook';
import ExpandButton from '../../Buttons/ExpandButton';
import { Row } from '../../Row';
import { RowCell } from '../../RowCell';
import {
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  ELEMENT_LIST_ITEM_HEIGHT,
} from '../../list.constants';
import { useBooleanState } from '../../../../hooks/hooks';
import { useTriggerContextMenu } from '../../../../hooks/menu.hooks';
import { LIST_BORDER, useListRowStyles } from '../../list.style';
import { useMouseEventCallback } from '../../../../hooks/events.hook';
import {
  hasChildren,
  isProductListGroup,
  isProductListItem,
} from '../../../../../../shared/helpers/recursive_element_helpers';
import { useIsFirstElementVersionInList } from '../../../../hooks/element-version.hook';
import ExpressionTypography from '../../../ExpressionTypography';
import { useFilterResultsBySelectedLifecycles } from '../../../../hooks/results.hook';
import { useNavigateTo } from '../../../../hooks/router.hooks';
import { getConversionFactorValue } from '../../../../../../shared/helpers/conversion-factors.helpers';
import { isSelectableQuantityUnit } from '../../../../../../shared/helpers/unit_helpers';
import ProductListItemName from './ProductListItemName';
import ProductListItemBarCharts from './ProductListItemBarCharts';

interface ProductListItemProps {
  element: OneOfProductListChildren;
  indentation?: number;
}

const ProductListItem: React.FC<ProductListItemProps> = ({
  element,
  indentation = 0,
}) => {
  const { classes: listClasses } = useListRowStyles();

  const navigateTo = useNavigateTo();
  const toggleElementExpanded = useToggleElementExpanded(element);
  const triggerContextMenu = useTriggerContextMenu(element.id);

  const selected = useIsSelected(element);
  const isFirstVersionInList = useIsFirstElementVersionInList(element);
  const isExpandAllowed = hasChildren(element);

  const lifecycleFilter = useFilterResultsBySelectedLifecycles();
  const elementResults = useMemo(
    () => lifecycleFilter(element.results ?? {}),
    [element.results, lifecycleFilter],
  );

  const [hover, startHover, endHover] = useBooleanState();

  const handleRowClick = useMouseEventCallback(() => {
    if (isProductListGroup(element) || isProductListItem(element)) {
      navigateTo({ elementId: element.id });
    }
  });

  return (
    <Box
      width="100%"
      onMouseLeave={endHover}
      onMouseOver={startHover}
      onClick={handleRowClick}
      onDoubleClick={toggleElementExpanded}
    >
      <Row
        classes={listClasses}
        height={ELEMENT_LIST_ITEM_HEIGHT}
        onContextMenu={triggerContextMenu}
        hover={hover}
        padding={true}
        selected={selected}
        spacing={LIST_SPACING.DEFAULT}
        borderTop={isFirstVersionInList ? LIST_BORDER : undefined}
      >
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT}
          borderRight={LIST_BORDER}
          paddingRight={LIST_SPACING}
        >
          <Row height={ELEMENT_LIST_ITEM_HEIGHT} spacing={LIST_SPACING.DEFAULT}>
            <RowCell width={ROOT_CELL_WIDTH.ICON} indentation={indentation}>
              <ExpandButton element={element} disabled={!isExpandAllowed} />
            </RowCell>

            <RowCell
              width={CONTENT_CELL_WIDTH.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
            >
              <ProductListItemName element={element} />
            </RowCell>

            <RowCell align="right" width={CONTENT_CELL_WIDTH.UNIT}>
              <ExpressionTypography
                value={getConversionFactorValue(elementResults, element.unit)}
                unit={
                  isSelectableQuantityUnit(element.unit)
                    ? element.unit
                    : undefined
                }
              />
            </RowCell>
          </Row>
        </RowCell>

        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <ProductListItemBarCharts
            results={element.results}
            unit={element.unit}
          />
        </RowCell>
      </Row>
    </Box>
  );
};

export default memo(ProductListItem);
