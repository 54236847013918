import React, { useCallback, useMemo } from 'react';
import { IElement } from '../../../../../../shared/models/project.interface';
import SBEFLabel from '../../../SBEFLabel';
import { ElementSelectPropertyCountType } from '../../../../../../shared/models/element_property.interface';
import { getSelectPropertyOptions } from '../../../../../../shared/helpers/element_property_helpers';
import { useSelectProperty } from '../../../../hooks/element-properties.hook';
import { isProductCategoryElement } from '../../../../../../shared/helpers/element_category_helpers';
import {
  getSBEFProperty,
  getSBEFCountValue,
} from '../../../../../../shared/helpers/sbef_helpers';

interface ElementListItemSBEFLabelProps {
  element: IElement;
  hideIfEmpty?: boolean;
}

const ElementListItemSBEFLabel: React.FC<ElementListItemSBEFLabelProps> = ({
  element,
  hideIfEmpty = false,
}) => {
  const selectProperty = useSelectProperty(element);

  const value = getSBEFCountValue(element);
  const property = useMemo(() => getSBEFProperty(element), [element]);

  const menuItems = useMemo(
    () => (property ? getSelectPropertyOptions(property) : []),
    [property],
  );

  const handleSelect = useCallback(
    (value: ElementSelectPropertyCountType) => {
      if (property) {
        selectProperty(value, property);
      }
    },
    [selectProperty, property],
  );

  if (isProductCategoryElement(element) || !property) {
    return null;
  }

  return (
    <SBEFLabel
      value={value}
      menuItems={menuItems}
      hidden={hideIfEmpty && !value}
      onSelect={handleSelect}
    />
  );
};

export default ElementListItemSBEFLabel;
