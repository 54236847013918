export type ValidationTypes = string | boolean;
type ValidationFn = () => ValidationTypes;

/**
 * Helper function to throw validation errors. Pass a function that returns a string or boolean, or a string or boolean directly.
 * If the result is a string, it will be thrown as an error. If the result is false, a generic error message will be thrown.
 * @param fnOrResult - Function that returns true if valid, or a string or boolean if invalid
 * @param prefix - Prefix for the error message
 * @param onError - Optional callback to add additional logging
 */
export const throwValidationErrors = (
  fnOrResult: ValidationFn | ValidationTypes,
  prefix = 'Validation error',
  onError?: (error: string) => void,
): void => {
  const result = typeof fnOrResult === 'function' ? fnOrResult() : fnOrResult;

  // Valid => do nothing
  if (result === true) {
    return;
  }

  const error = `${prefix}: ${result || 'unspecified validation error'}`;
  onError?.(error);
  throw new Error(error);
};
