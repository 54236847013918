import { ICoordinate } from '../../models/project.interface';
import { convert } from '../conversion_helpers';
import { getNormalYearTemp } from './temperature.helpers';

// Constants for ventilation calculations
const AIR_DENSITY = 1.2; // kg/m³

/**
 * CP_AIR stands for "Specific Heat Capacity of Air" and is measured in kJ/(kg·K).
 * It represents the amount of energy required to raise the temperature of 1 kg of air by 1 Kelvin (or 1°C).
 * The value 1.006 kJ/(kg·K) is the standard value for air at normal atmospheric pressure and room temperature.
 */
const CP_AIR = 1.006; // kJ/(kg·K)
const INDOOR_TEMP = 20; // °C
const TEMP_OUTSIDE_COOLING_HOURS = 25; // °C

interface VentilationParams {
  /**
   * The specific fan power of the ventilation system in kW/(m³/s).
   * See: https://en.wikipedia.org/wiki/Specific_fan_power
   */
  specificFanPower?: number;

  /**
   * The efficiency of the heat recovery system in the ventilation system. A number between 0 and 1.
   * See: https://en.wikipedia.org/wiki/Heat_recovery_ventilation
   */
  heatRecoveryEfficiency?: number; // 0-1

  /**
   * The number of hours the ventilation system is operational in a year (hours/year).
   */
  operationHours?: number;

  /**
   * Target temperature after post heating in °C. If not provided post heating is not calculated.
   * Post heating in a ventilation system is the process of heating the air to a desired temperature after it has passed through the heat exchanger.
   * This is often necessary because the recovered heat from the exhaust air via the heat exchanger might not be sufficient to reach the desired supply air temperature, especially during cold periods.
   * Process flow: Outside Air (cold) → Pre Cooling (final temp)  → Heat Recovery (preheated) → Post Heating (final temp) → Supply Air (to rooms)
   *
   */
  postHeatingTemp?: number;

  /**
   * The number of hours the post heating is operational in a year (hours/year).
   */
  postHeatingHours?: number;

  /**
   * Target temperature after pre cooling in °C. If not provided pre cooling is not calculated.
   * Pre cooling in a ventilation system is the process of cooling the air to a desired temperature before it is supplied to the rooms.
   * This is often necessary because the recovered heat from the exhaust air via the heat exchanger might not be sufficient to reach the desired supply air temperature, especially during cold periods.
   * Process flow: Outside Air (cold) → Pre Cooling (final temp) → Heat Recovery (preheated) → Post Heating (final temp) → Supply Air (to rooms)
   */
  preCoolingTemp?: number;

  /**
   * The number of hours the pre cooling is operational in a year (hours/year).
   */
  preCoolingHours?: number;

  /**
   * The airflow in m³/s. Can be calculated from the supply and exhaust air flow per area and the area.
   */
  airflow: number;

  /**
   * The average temperature difference between the indoor temperature and the normal year temperature in K (same as C in this case).
   */
  avgTempDiff?: number;

  coordinates?: ICoordinate;
}

interface VentilationEnergyResult {
  /**
   * Fan power in kilowatts (kW)
   */
  fanPower: number;

  /**
   * Air flow rate in cubic meters per second (m³/s)
   */
  airflow: number;

  /**
   * Annual fan energy consumption in kilowatt-hours per year (kWh/year)
   */
  annualFanEnergy: number;

  /**
   * Annual post heating energy consumption in kilowatt-hours per year (kWh/year)
   */
  annualPostHeatingEnergy: number;

  /**
   * Annual pre cooling energy consumption in kilowatt-hours per year (kWh/year)
   */
  annualPreCoolingEnergy: number;

  /**
   * Total annual energy consumption (fan energy minus heat recovery) in kilowatt-hours per year (kWh/year)
   */
  totalAnnualEnergy: number;
}

/**
 * Based on the search results, here are some sample energy consumptions of air handling units (AHUs) in typical apartment buildings:
In a case study of a 1,700 square meter office building, the old air handling unit consumed approximately 113,000 kWh/year, while the new, more efficient unit consumed about 28,000 kWh/year. Although this example is for an office building, it provides a reference point for energy consumption in larger buildings.
For residential buildings, energy efficiency is crucial as AHUs typically run 24/7. The actual consumption can vary significantly based on factors such as building design, location, and AHU efficiency.
In one study, the net energy flow of an AHU in a residential setting ranged from -0.46 kWh/m² in August (when fan consumption exceeded savings) to 5.60 kWh/m² in January. This indicates that energy consumption varies seasonally.
HVAC systems, including AHUs, generally account for about 40% of total building energy consumption in typical buildings6. In residential settings, this percentage may vary.
Fan energy in AHUs can be a significant portion of the total energy use. In one breakdown, fans consumed 34% of the HVAC energy6. Implementing energy-efficient fans and control strategies can potentially save up to 80% of the ventilation fan energy4.
 */

/**
 * Calculate the ventilation energy consumption in kWh/year.
 * @param ventilationParams - The ventilation parameters.
 * @returns The ventilation energy consumption in kWh/year.
 */

/**
 * Calculate the post heating or pre cooling energy consumption in kWh/year.
 * @param airflow - The airflow rate in m³/s
 * @param operationHours - Operation time in hours per year
 * @param targetTemp - Target temperature after post heating or pre cooling in °C
 * @param avgTempDiff - Average temperature difference in K
 * @param energyRecoveryEfficiency - The efficiency of the heat recovery system in the ventilation system. A number between 0 and 1.
 * @returns The post heating energy consumption in kWh/year
 */
const calculatePostHeatingOrPrecoolingEnergy = (
  avgTempDiff: number,
  airflow: number,
  operationHours: number,
  energyRecoveryEfficiency: number = 0,
): number => {
  const operationSeconds = convert(operationHours, 'h', 's');

  // Calculate heating power needed in W
  const heatingPower = airflow * AIR_DENSITY * CP_AIR * avgTempDiff;

  // Convert from kJ to kWh
  return (
    convert(heatingPower * operationSeconds, 'kJ', 'kWh') *
    (1 - energyRecoveryEfficiency)
  );
};

const stockholmCoordinates: ICoordinate = {
  lat: 59.334591,
  lng: 18.06324,
};

/**
 * Calculate the airflow in m³/s based on the supply and exhaust air flow per area and the area.
 * @param supplyAirFlowPerArea - The supply air flow per area in l/m²s
 * @param exhaustAirFlowPerArea - The exhaust air flow per area in l/m²s
 * @param area - The area in m²
 * @returns The airflow in m³/s
 */
export const calculateAirflow = (
  supplyAirFlowPerArea: number,
  exhaustAirFlowPerArea: number,
  area: number,
) => {
  return convert(
    Math.max(supplyAirFlowPerArea, exhaustAirFlowPerArea, 0) * area,
    'l',
    'm³',
  );
};

export const calculateVentilationEnergy = ({
  specificFanPower = 1.8,
  heatRecoveryEfficiency = 0.8, // 80% efficiency
  operationHours = 365.25 * 24, // 24/7 operation
  postHeatingTemp,
  postHeatingHours,
  preCoolingTemp,
  preCoolingHours,
  airflow,
  coordinates = stockholmCoordinates,
}: VentilationParams): VentilationEnergyResult => {
  const avgTemp = getNormalYearTemp(coordinates);

  // Calculate fan power consumption
  const fanPower = specificFanPower * airflow; // kW
  const annualFanEnergy = fanPower * operationHours; // kWh/year

  // Heating temp adjusted for operation hours
  const postHeatingTempDiff = postHeatingTemp
    ? calculateOperationHoursTempDiff({
        targetTemp: postHeatingTemp,
        outdoorTemp: avgTemp,
        seasonHours: postHeatingHours,
        operationHours,
      })
    : 0;

  // Calculate post heating energy if post heating temperature is provided
  const annualPostHeatingEnergy = postHeatingTemp
    ? calculatePostHeatingOrPrecoolingEnergy(
        postHeatingTempDiff,
        airflow,
        operationHours,
        heatRecoveryEfficiency,
      )
    : 0;

  // Cooling temp adjusted for operation hours
  const preCoolingTempDiff = preCoolingTemp
    ? calculateOperationHoursTempDiff({
        targetTemp: preCoolingTemp,
        outdoorTemp: TEMP_OUTSIDE_COOLING_HOURS, // TODO: Make this dynamic based on coordinates
        seasonHours: preCoolingHours,
        operationHours,
      })
    : 0;

  // Calculate pre cooling energy if pre cooling temperature is provided
  const annualPreCoolingEnergy = preCoolingTemp
    ? calculatePostHeatingOrPrecoolingEnergy(
        preCoolingTempDiff,
        airflow,
        operationHours,
        heatRecoveryEfficiency,
      )
    : 0;

  return {
    fanPower,
    airflow,
    annualFanEnergy,
    annualPostHeatingEnergy,
    annualPreCoolingEnergy,
    totalAnnualEnergy:
      annualFanEnergy + annualPostHeatingEnergy + annualPreCoolingEnergy,
  };
};

interface SeasonalTempDiffParams {
  /**
   * The target temperature after post heating or pre cooling in °C.
   */
  targetTemp?: number;

  /**
   * The average outdoor temperature in °C during the season.
   */
  outdoorTemp: number;

  /*
   * The number of hours the temperature requires ventilation in a year.
   * In Stockholm you have around 5500 hours with heating (when outdoor temp is below 17°C)
   * and 1000 hours with cooling (when outdoor temp is above 22°C) each year.
   */
  seasonHours?: number;

  /**
   * The number of hours the ventilation is operational in a year.
   */
  operationHours?: number;

  /**
   * The efficiency of the heat recovery system in the ventilation system. A number between 0 and 1.
   * See: https://en.wikipedia.org/wiki/Heat_recovery_ventilation
   */
  heatRecoveryEfficiency?: number;
}

/**
 * Get temp difference in relation to the ventialtion system operation hours.
 * Can be used for heating and cooling.
 * @param param0
 * @returns
 */
export const calculateOperationHoursTempDiff = ({
  targetTemp = INDOOR_TEMP,
  outdoorTemp,
  seasonHours = 5500, // Stockholm ~230 days/year for heating
  operationHours = 8760,
}: SeasonalTempDiffParams): number => {
  if (operationHours <= 0) {
    return 0;
  }

  // Calculate seasonal fractions
  const heatingSeasonFraction = seasonHours / operationHours;

  // Calculate the temperature difference (always positive)
  return Math.abs(targetTemp - outdoorTemp) * heatingSeasonFraction;
};
