import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { utils, writeFile } from 'xlsx';
import { cloneMoveItem } from '../../../shared/helpers/array_helpers';
import {
  getAllBuildingVersions,
  isBuildingVersionElement,
} from '../../../shared/helpers/recursive_element_helpers';
import { getEnrichedProject, getProject } from '../store/project';
import { getSelectedVersion } from '../store/ui';
import {
  getElementsActiveInProposal,
  getProposalsInVersion,
} from '../../../shared/helpers/proposal.helpers';
import { getProjectResultsRecord } from '../../../shared/helpers/results.helpers';
import {
  getVersionsWithUniqueNames,
  versionToSheet,
} from '../helpers/export.helpers';

const format = 'yyyy-MM-dd';

export const useExportSpreadsheet = (): (() => void) => {
  const handleExportSpreadsheet = useCallback(() => {
    const quantityRecord = getProjectResultsRecord(getProject());
    const project = getEnrichedProject(getProject());
    const selectedVersion = getSelectedVersion();

    const timestamp = DateTime.now().toFormat(format);
    if (project && selectedVersion && quantityRecord) {
      const workbook = utils.book_new();

      // Create an index sheet first
      const indexRows = [['Sheet', 'Building Version Name - Proposal Name']];
      const indexSheet = utils.aoa_to_sheet(indexRows);
      utils.book_append_sheet(workbook, indexSheet, 'Index');

      // Move selected version to first position
      const versions = cloneMoveItem(
        getAllBuildingVersions(project),
        selectedVersion,
        0,
      );

      const versionsWithUniqueNames = getVersionsWithUniqueNames(versions);

      // Start sheet numbering from 1 (since 0 is index)
      let sheetNumber = 1;

      versionsWithUniqueNames.forEach((version) => {
        getProposalsInVersion(version).forEach((proposal) => {
          const activeElements = getElementsActiveInProposal(
            version,
            proposal,
          ).filter((element) => !isBuildingVersionElement(element));

          const sheet = versionToSheet(version, quantityRecord, activeElements);
          const fullSheetName = `${version.name.substring(0, 14)} - ${proposal.name.substring(0, 14)}`;
          const safeSheetName = `Sheet ${sheetNumber}`;

          // Add to index
          indexRows.push([safeSheetName, fullSheetName]);

          // Add the data sheet with simple numbering
          utils.book_append_sheet(workbook, sheet, safeSheetName);

          sheetNumber++;
        });
      });

      // Update the index sheet with all entries
      const updatedIndexSheet = utils.aoa_to_sheet(indexRows);
      workbook.Sheets['Index'] = updatedIndexSheet;

      const fileName = `${project.name.slice(0, 50)} ${timestamp}.xlsx`;
      writeFile(workbook, fileName);
    }
  }, []);
  return handleExportSpreadsheet;
};
