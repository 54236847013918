import React, { useEffect } from 'react';
import { createLocalStorageStore } from '../../helpers/local-storage.helpers';
import { useProductListCategoryGroups } from '../../hooks/product-list.hook';
import NodonSwitch from '../switches/NodonSwitch';

export const { useStore: useGroupByCategory, set: setGroupByCategory } =
  createLocalStorageStore('product_list_group_by_category', false);

const GroupProductListByCategorySwitch = () => {
  const groupByCategory = useGroupByCategory();
  const productListCategoryGroups = useProductListCategoryGroups();
  const groupsUnavailable = productListCategoryGroups.length < 1;

  useEffect(() => {
    if (groupsUnavailable) {
      setGroupByCategory(false);
    }
  }, [groupsUnavailable]);

  return (
    <NodonSwitch
      label="Group by category"
      checked={groupByCategory}
      onChange={setGroupByCategory}
    />
  );
};

export default GroupProductListByCategorySwitch;
