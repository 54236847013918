import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  ExpressionValue,
  IElement,
  IProductElement,
} from '../../../../shared/models/project.interface';
import { useSelectedVersionProducts } from '../../store/ui';
import ExpressionInput from '../ExpressionInput';
import { getExpression } from '../../../../shared/helpers/expression_solving_helpers';
import { selectableUnitHarmonizer } from '../../../../shared/helpers/unit_helpers';
import { makeStyles } from 'tss-react/mui';
import { EllipsisText } from '../EllipsisText';
import ConversionFactorInput from '../ConversionFactorInput';
import EPDMenu from '../EPDMenu/EPDMenu';
import { useProjectCostIsDisabled } from '../../store/project';
import {
  getEpdProduct,
  getGenericProduct,
} from '../../../../shared/helpers/product_helpers';

const PRODUCT_NAME_MAX_LENGTH = 30;

export interface ProductSettingsProps
  extends Pick<IProductElement, 'product_id' | 'generic_product_id' | 'unit'> {
  /** PC element like Wood or Metal */
  productCategoryElement?: IElement;

  /** Product elements generated by PC element. These will be mapped to a selected EPD.
   * If not provided, they will instead be retrieved from the PC element (if available). */
  generatedElements?: IProductElement[];

  count: ExpressionValue | number | undefined;
  disableConversionFactorInputs?: boolean;
  disableEPDMenu?: boolean;
}

const ProductSettings: React.FC<ProductSettingsProps> = ({
  product_id,
  unit,
  generic_product_id,
  count,
  productCategoryElement,
  generatedElements,
  disableConversionFactorInputs = false,
  disableEPDMenu = false,
}) => {
  const { classes } = useStyles();
  const disableCost = useProjectCostIsDisabled();
  const products = useSelectedVersionProducts();
  const epd = getEpdProduct(products, { product_id, generic_product_id });
  const genericProduct = getGenericProduct(products, {
    product_id,
    generic_product_id,
  });

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography width="60%" variant="body2" className={classes.text}>
          <EllipsisText
            showTooltip={
              genericProduct &&
              genericProduct.name.length > PRODUCT_NAME_MAX_LENGTH
            }
          >
            {genericProduct ? genericProduct.name : 'Product not found'}
          </EllipsisText>
        </Typography>
        <Box width="40%" display="flex" justifyContent="flex-end">
          <ExpressionInput
            expressionValue={getExpression(count)}
            unit={selectableUnitHarmonizer(unit)}
            disabled
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
        gap={2}
      >
        {!disableEPDMenu && (
          <EPDMenu
            product_id={product_id}
            generic_product_id={generic_product_id}
            productCategoryElement={productCategoryElement}
            generatedElements={generatedElements}
          />
        )}

        {epd && !disableConversionFactorInputs && (
          <Box width="100%" pl={4}>
            <ConversionFactorInput
              factor="co2e_transport"
              label="Transport"
              product_id={product_id}
              generic_product_id={generic_product_id}
            />
            <ConversionFactorInput
              factor="co2e_waste_percent"
              label="Spill"
              product_id={product_id}
              generic_product_id={generic_product_id}
            />
            {!disableCost && (
              <ConversionFactorInput
                factor="sek_A1-A3"
                label="Cost"
                product_id={product_id}
                generic_product_id={generic_product_id}
              />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles()(({ palette }) => ({
  text: {
    display: 'flex',
    fontSize: 13,
    fontWeight: 500,
  },
  discrete: { color: palette.text.disabled },
}));

export default ProductSettings;
