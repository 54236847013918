import React, { useState, useEffect, useCallback } from 'react';
import {
  StandardTextFieldProps,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ErrorIcon from '@mui/icons-material/Error';
import { useUIState } from '../store/ui';
import {
  IElementID,
  ExpressionValue,
} from '../../../shared/models/project.interface';
import { ExpressionVariables } from '../../../shared/helpers/expression_variables_helpers';
import {
  QuantityUnit,
  SelectableQuantityUnit,
} from '../../../shared/models/unit.interface';
import { isSelectableQuantityUnit } from '../../../shared/helpers/unit_helpers';
import { sleep } from '../../../shared/helpers/promise.helpers';
import {
  IExpressionInputPanelOutput,
  useOpenExpressionInputPanel,
} from './ExpressionInputPanel';
import { useGranularEffect } from '../hooks/hooks';
import { ElementPropertyID } from '../../../shared/models/element_property.interface';
import ExpressionTypography from './ExpressionTypography';

interface IExpressionInputProps
  extends Omit<
    StandardTextFieldProps,
    'onChange' | 'onBlur' | 'value' | 'variant'
  > {
  id?: IElementID;
  expressionValue: ExpressionValue | undefined;
  fallbackExpressionValue?: ExpressionValue;
  unit?: QuantityUnit;
  selectableUnits?: SelectableQuantityUnit[];
  variables?: ExpressionVariables;
  propertyId?: ElementPropertyID;
  label?: string;
  tooltip?: string;
  disableExpressionInputPanelUnits?: boolean;
  min?: number;
  max?: number;
  errorMessage?: string;
  onCancel?: (updates: IExpressionInputPanelOutput) => void;
  onSave?: (updates: IExpressionInputPanelOutput) => void;
  onChange?: (updates: IExpressionInputPanelOutput) => void;
  onDebouncedChange?: (updates: IExpressionInputPanelOutput) => void;
}

const ExpressionInput: React.FC<IExpressionInputProps> = ({
  id,
  expressionValue,
  fallbackExpressionValue,
  unit,
  selectableUnits,
  variables,
  propertyId,
  label,
  tooltip,
  onSave,
  onChange,
  onDebouncedChange,
  onCancel,
  autoFocus,
  disableExpressionInputPanelUnits,
  min,
  max,
  errorMessage,
  ...other
}) => {
  const { classes, cx } = useStyles();

  if ((expressionValue ?? fallbackExpressionValue) === undefined) {
    throw new Error(
      'ExpressionInput.tsx: expressionValue and fallbackExpressionValue cannot both be undefined',
    );
  }

  const [isExpressionPanelOpen, setIsExpressionPanelOpen] =
    useState<boolean>(false);

  const [localUnit, setLocalUnit] = useState<QuantityUnit>();

  useEffect(() => {
    setLocalUnit(unit);
  }, [unit]);

  const [localExpressionValue, setLocalExpressionValue] = useState<
    ExpressionValue | undefined
  >();

  useEffect(() => {
    setLocalExpressionValue(expressionValue);
  }, [expressionValue]);

  const isFallback = !localExpressionValue;
  const value = localExpressionValue ?? fallbackExpressionValue;

  const {
    selectedElementPropertyId,
    setSelectedElementPropertyId,
    isExpressionInputPanelOpen,
  } = useUIState(
    'selectedElementPropertyId',
    'setSelectedElementPropertyId',
    'isExpressionInputPanelOpen',
  );

  const openExpressionInputPanel = useOpenExpressionInputPanel();

  const openInputPanel = useCallback(async () => {
    if (other.disabled || !openExpressionInputPanel) {
      return;
    }

    // By using a sleep here we allow any function closing the panel on click without preventing the panel from opening
    await sleep(0);

    const onChangeWrapper = (updates: IExpressionInputPanelOutput): void => {
      if (isSelectableQuantityUnit(updates.unit)) {
        setLocalUnit(updates.unit);
      }
      setLocalExpressionValue(updates.expressionValue);
      return onChange?.(updates);
    };

    setIsExpressionPanelOpen(true);
    const changes = await openExpressionInputPanel({
      expressionValue,
      fallbackExpressionValue,
      unit,
      variables,
      selectableUnits,
      min,
      max,
      disableUnits: !!disableExpressionInputPanelUnits,
      onChange: onChangeWrapper,
      onDebouncedChange,
    });
    // Set to the new value or reset it to the old one if the user cancelled
    setLocalUnit(
      isSelectableQuantityUnit(changes?.unit) ? changes?.unit : unit,
    );
    setLocalExpressionValue(changes?.expressionValue ?? expressionValue);
    setIsExpressionPanelOpen(false);

    if (changes && onSave) {
      onSave(changes);
    } else if (!changes && onCancel) {
      onCancel({ expressionValue, unit: unit as SelectableQuantityUnit });
    }
  }, [
    other.disabled,
    openExpressionInputPanel,
    expressionValue,
    fallbackExpressionValue,
    unit,
    variables,
    selectableUnits,
    min,
    max,
    disableExpressionInputPanelUnits,
    onDebouncedChange,
    onSave,
    onCancel,
    onChange,
  ]);

  // if productItem focused after adding product
  useEffect(() => {
    if (autoFocus) {
      openInputPanel();
    }
  }, [autoFocus, openExpressionInputPanel, openInputPanel]);

  useGranularEffect(
    () => {
      if (isExpressionPanelOpen) {
        openInputPanel();
      }
    },
    [expressionValue],
    [isExpressionPanelOpen],
  );

  const onClick = useCallback(() => {
    setSelectedElementPropertyId(propertyId);
    openInputPanel();
  }, [propertyId, setSelectedElementPropertyId, openInputPanel]);

  return (
    <Tooltip title={tooltip ?? ''}>
      <Box
        className={cx(
          classes.container,
          other.disabled && classes.disabled,
          isExpressionInputPanelOpen &&
            selectedElementPropertyId &&
            selectedElementPropertyId === propertyId &&
            classes.editing,
        )}
        data-id={id} // data-id and tabIndex are important to identify if the same input is clicked twice
        tabIndex={0}
        onClick={onClick}
      >
        {label && <Typography variant="caption">{label}</Typography>}

        {/* Count & Unit */}
        <ExpressionTypography
          value={value}
          unit={isSelectableQuantityUnit(localUnit) ? localUnit : undefined}
          isFallback={isFallback && localExpressionValue === expressionValue}
          disabled={other.disabled}
        />

        {/* Error */}
        {!!errorMessage && !isFallback && (
          <Tooltip title={errorMessage}>
            <Typography
              className={classes.errorMessage}
              color="error"
              variant="caption"
            >
              <ErrorIcon fontSize="small" />
            </Typography>
          </Tooltip>
        )}
      </Box>
    </Tooltip>
  );
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: spacing(1),
    padding: spacing(2),
    borderRadius: 4,
    fontSize: '0.75rem',
    color: palette.text.primary,
    cursor: 'pointer',
    whiteSpace: 'nowrap',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  discrete: {
    color: palette.text.disabled,
  },
  disabled: {
    pointerEvents: 'none',
  },
  disabledCount: {
    color: palette.text.disabled,
  },
  editing: {
    pointerEvents: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  errorMessage: {
    position: 'relative',
    display: 'flex',
    cursor: 'help',
  },
}));

export default ExpressionInput;
