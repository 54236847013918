import React, {
  ReactNode,
  FC,
  Dispatch,
  createContext,
  useContext,
  useReducer,
  useCallback,
} from 'react';
import { ElementMapping } from '../../../shared/models/element_mapping.interface';
import axios from 'axios';
import { IProductElement } from '../../../shared/models/project.interface';
import { useObjectMemo } from '../hooks/object.hook';

export interface State {
  isLoading: boolean;
  elementMapping: Record<string, IProductElement[]>;
}

export type Action =
  | { type: 'loading'; loading?: boolean }
  | {
      type: 'finish fetch';
      elementMapping: Record<string, IProductElement[]>;
    }
  | {
      type: 'finish set';
      element_name: string;
      products: IProductElement[];
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'loading': {
      if (state.isLoading === action.loading) {
        return state;
      }
      return { ...state, isLoading: action.loading || false };
    }
    case 'finish fetch': {
      return {
        ...state,
        isLoading: false,
        elementMapping: action.elementMapping,
      };
    }
    case 'finish set': {
      return {
        ...state,
        isLoading: false,
        elementMapping: {
          ...state.elementMapping,
          [action.element_name]: action.products,
        },
      };
    }
    default: {
      console.error('invalid dispatch action: ', action);
      return state;
    }
  }
};

const ElementMappingStateContext = createContext<State | undefined>(undefined);
const ElementMappingDispatchContext = createContext<
  Dispatch<Action> | undefined
>(undefined);

interface ElementMappingProviderProps {
  children: ReactNode;
}

const ElementMappingProvider: FC<ElementMappingProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    elementMapping: {},
  });

  return (
    <ElementMappingStateContext.Provider value={state}>
      <ElementMappingDispatchContext.Provider value={dispatch}>
        {children}
      </ElementMappingDispatchContext.Provider>
    </ElementMappingStateContext.Provider>
  );
};

interface ElementMappingContext extends State {
  fetchElementMappings: () => Promise<Record<string, IProductElement[]>>;
  setElementMapping: (
    elementName: string,
    products: IProductElement[],
  ) => Promise<ElementMapping | undefined>;
}

const useElementMapping = (): ElementMappingContext => {
  const state = useContext(ElementMappingStateContext);
  const dispatch = useContext(ElementMappingDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error(
      'useElementMapping must be used within a ElementMappingProvider',
    );
  }

  const fetch = useCallback((): Promise<Record<string, IProductElement[]>> => {
    dispatch({ type: 'loading' });
    return axios.get<ElementMapping[]>('/element_mapping').then(({ data }) => {
      const elementMapping = data.reduce<Record<string, IProductElement[]>>(
        (lookup, m) => {
          return { ...lookup, [m.element_name]: m.products };
        },
        {},
      );

      dispatch({
        type: 'finish fetch',
        elementMapping,
      });
      return elementMapping;
    });
  }, [dispatch]);

  const update = useCallback(
    (
      element_name: string,
      products?: IProductElement[],
    ): Promise<ElementMapping> => {
      dispatch({ type: 'loading' });
      if (products) {
        return axios
          .put('/element_mapping', { element_name, products })
          .then(() => {
            dispatch({
              type: 'finish set',
              element_name,
              products,
            });
            return { element_name, products };
          });
      } else {
        return axios.delete('/element_mapping', { data: { element_name } });
      }
    },
    [dispatch],
  );

  return useObjectMemo({
    ...state,
    fetchElementMappings: fetch,
    setElementMapping: update,
  });
};

export { ElementMappingProvider, useElementMapping };
