import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import { IFactoryProperty } from '../../../models/element_property.interface';
import {
  ceramicsProductTree,
  ceramicsMaterialProperty,
  ceramicsFormProperty,
  ceramicsProcessingProperty,
  propertiesOptions,
} from './ceramics.model';
import { createProductTreeProcessor } from '../processor';
import { DEFAULT_QUANTITY_PROPERTIES } from '../../../models/element_quantities.interface';

const levelProperties: IFactoryProperty[] = [
  ceramicsFormProperty,
  ceramicsMaterialProperty,
  ceramicsProcessingProperty,
];

const processor = createProductTreeProcessor({
  levelProperties,
  propertiesOptions,
  productTree: ceramicsProductTree,
});

export const ceramics: IElementCategory = {
  ...processor,
  defaultSelectedQuantity: 'mass',
  id: ElementCategoryID.Ceramics,
  name: 'Ceramics',
  color: '#D0966D',
  getQuantityProperties: () => DEFAULT_QUANTITY_PROPERTIES,
};
