import {
  ElementPropertyType,
  IFactorySelectProperty,
  IFactorySwitchProperty,
} from '../../../models/element_property.interface';
import { PropertiesOptions } from '../../../models/element_categories.interface';
import { ProductTree } from '../processor.model';

const PRODUCT_I_JOIST_BEAMS = 'boverket_sv-SE_6000000186';

export enum WoodPropertyName {
  WoodType = 'wood_type',
}

export enum WoodSpecialPropertyName {
  PhenolCoated = 'phenol_coated',
}

export enum WoodType {
  CrossLaminatedTimber = 'CLT (cross laminated timber)',
  Glulam = 'Glulam',
  IJoistBeams = 'I-joist beams',
  LaminatedVaneerLumber = 'LVL (laminated vaneer lumber)',
  PlanedTimber = 'Planed timber',
  SawnTimber = 'Sawn timber',
  OSB = 'OSB',
  ParticleBoard = 'Particle board',
  Plywood = 'Plywood',
}

export enum Plywood {
  General = 'General',
  PhenolCoated = 'Phenol coated',
}

// productTree

export const woodProductTree: ProductTree = {
  [WoodType.CrossLaminatedTimber]: 'boverket_sv-SE_6000000167',
  [WoodType.Glulam]: 'boverket_sv-SE_6000000168',
  [WoodType.IJoistBeams]: PRODUCT_I_JOIST_BEAMS,
  [WoodType.LaminatedVaneerLumber]: 'boverket_sv-SE_6000000185',
  [WoodType.PlanedTimber]: 'boverket_sv-SE_6000000192',
  [WoodType.SawnTimber]: 'boverket_sv-SE_6000000007',
  [WoodType.OSB]: 'boverket_sv-SE_6000000024',
  [WoodType.ParticleBoard]: 'boverket_sv-SE_6000000000',
  [WoodType.Plywood]: {
    [Plywood.PhenolCoated]: 'boverket_sv-SE_6000000169',
    [Plywood.General]: 'boverket_sv-SE_6000000023',
  },
};

// Properties

export const woodTypeProperty: IFactorySelectProperty = {
  name: WoodPropertyName.WoodType,
  type: ElementPropertyType.Select,
  count: WoodType.SawnTimber,
  options: Object.values(WoodType),
};

export const phenolCoatedProperty: IFactorySwitchProperty = {
  name: WoodSpecialPropertyName.PhenolCoated,
  type: ElementPropertyType.Switch,
};

// Options

export const propertiesOptions: PropertiesOptions[] = [
  {
    [WoodType.Plywood]: {
      propertyType: ElementPropertyType.Switch,
      propertyName: WoodSpecialPropertyName.PhenolCoated,
      property: phenolCoatedProperty,
    },
  },
];
