import {
  ElementPropertyType,
  ElementPropertyName,
  IFactoryExpressionProperty,
  IFactoryProperty,
  IFactorySelectProperty,
} from '../../models/element_property.interface';
import { getFormatedSBEFLabels } from '../../construction/sbef-codes';

export const sbefCodeProperty: IFactorySelectProperty = {
  type: ElementPropertyType.Select,
  name: ElementPropertyName.SBEFCode,
  options: ElementPropertyName.SBEFCode,
  inheritFallback: true,
};

export const lifetimeProperty: IFactoryExpressionProperty = {
  type: ElementPropertyType.Expression,
  name: ElementPropertyName.Lifetime,
  unit: 'year',
  inheritFallback: false,
  fallbackCount: `getSBEFLifetime(${ElementPropertyName.SBEFCode})`,
  min: 0,
};

export const mutualProperties: IFactoryProperty[] = [
  sbefCodeProperty,
  lifetimeProperty,
];

export const SBEF_MENU_VALUES = Object.freeze([
  'none',
  ...getFormatedSBEFLabels(),
]);
