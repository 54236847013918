import {
  ElementPropertyType,
  ElementPropertyTypeMap,
  IElementExpressionProperty,
  IElementProperty,
  IElementSelectProperty,
  IElementSwitchProperty,
} from '../models/element_property.interface';
import { IProductElement } from '../models/project.interface';
import { isDefined } from './array_helpers';
import { isExpressionValue } from './expression_factory_helpers';

type Count = NonNullable<
  IElementProperty['count' | 'fallbackCount'] | IProductElement['count']
>;

/**
 * Check if the count is of one or more specific type
 * @param count
 * @param types
 * @returns
 */
export const isCountOfType = <K extends keyof ElementPropertyTypeMap>(
  count: unknown,
  ...types: K[]
): count is NonNullable<ElementPropertyTypeMap[K]['count']> => {
  if (!isDefined(count) || !types.length) {
    return false;
  }
  const type = types.find((type) => {
    switch (type) {
      case ElementPropertyType.Switch:
        return isSwitchCount(count);
      case ElementPropertyType.Expression:
        return isExpressionCount(count);
      case ElementPropertyType.Select:
        return isSelectCount(count);
    }
  });
  return !!type;
};

/**
 * Check if the count is a boolean
 * @param count
 * @returns
 */
const isSwitchCount = (
  count: unknown,
): count is NonNullable<IElementSwitchProperty['count']> =>
  typeof count === 'boolean';

/**
 * Check if the count is an expression value
 * @param count
 * @returns
 */
const isExpressionCount = (
  count: unknown,
): count is NonNullable<IElementExpressionProperty['count']> =>
  isExpressionValue(count);

/**
 * Check if the count is a string or a finite number
 * @param count
 * @returns
 */
const isSelectCount = (
  count: unknown,
): count is NonNullable<IElementSelectProperty['count']> =>
  typeof count === 'string' || (typeof count === 'number' && isFinite(count));

/**
 * Require the count to be of one or more specific type
 * @param count
 * @param types
 * @returns
 */
export const requireCountOfType = <
  T extends Count,
  K extends keyof ElementPropertyTypeMap,
>(
  count: T,
  ...types: K[]
): NonNullable<ElementPropertyTypeMap[K]['count']> => {
  if (!isCountOfType(count, ...types)) {
    throw new Error(
      `Count type mismatch. Expected ${types.join(' or ')} but got ${typeof count}`,
    );
  }
  return count as NonNullable<ElementPropertyTypeMap[K]['count']>;
};
