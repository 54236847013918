import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { EllipsisText } from './EllipsisText';

interface ProductSelectorButtonProps {
  selected?: boolean;
  onClick?: () => void;
  productName: string;
  error?: boolean;
  disabled?: boolean;
}

const ProductSelectorButton: React.FC<ProductSelectorButtonProps> = ({
  selected,
  onClick,
  productName,
  error,
  disabled,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Button
      disableRipple
      onClick={onClick}
      className={cx(
        classes.button,
        selected && classes.buttonHover,
        error && classes.error,
        disabled && classes.disabled,
      )}
      color="neutral"
    >
      <EllipsisText>{productName}</EllipsisText>
    </Button>
  );
};

const useStyles = makeStyles()(() => ({
  button: {
    maxWidth: '100%',
    textTransform: 'inherit',
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: 'initial',
      cursor: 'initial',
    },
  },

  buttonHover: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
  error: {
    color: 'red',
  },
}));

export default ProductSelectorButton;
