import { Box, BoxProps } from '@mui/material';
import React from 'react';
import {
  ELEMENT_CO2_BAR_HEIGHT,
  ELEMENT_COST_BAR_HEIGHT,
} from '../ElementList/list.constants';
import SimpleBarChart, { ISimpleBarChartInput } from './SimpleBarChart';

interface ResultsBarChartsProps extends BoxProps {
  co2e: ISimpleBarChartInput['value'];
  co2eMax?: number;
  cost: ISimpleBarChartInput['value'];
  costMax?: number;
  color?: string;
  co2eHeight?: number;
  costHeight?: number;
  disableCost?: boolean;
}

const ResultsBarCharts: React.FC<ResultsBarChartsProps> = ({
  co2e,
  co2eMax,
  cost,
  costMax,
  color,
  co2eHeight = ELEMENT_CO2_BAR_HEIGHT,
  costHeight = ELEMENT_COST_BAR_HEIGHT,
  disableCost,
  ...props
}) => {
  return (
    <Box width="100%" {...props}>
      <Box mb={1}>
        <SimpleBarChart
          color={color}
          height={co2eHeight}
          value={co2e}
          maxValue={co2eMax}
        />
      </Box>
      <Box mb={1}>
        {!disableCost && (
          <SimpleBarChart
            color="black"
            height={costHeight}
            value={cost}
            maxValue={costMax}
          />
        )}
      </Box>
    </Box>
  );
};

export default ResultsBarCharts;
