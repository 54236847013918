import React, { useState } from 'react';
import { Box, IconButton, Modal, SxProps } from '@mui/material';
import { NodonTheme } from '../../../../../style';
import { IElement } from '../../../../../../../shared/models/project.interface';
import SearchField from '../../../../SearchField';
import RecipeSelectorTabs, { RecipeSelectorTab } from './RecipeSelectorTabs';
import RecipeSelectorSBEFMenu from './RecipeSelectorSBEFMenu';
import { Close } from '@mui/icons-material';
import RecipeSelectorList from './RecipeSelectorList';
import { getSBEFCountValue } from '../../../../../../../shared/helpers/sbef_helpers';

interface RecipeSelectorProps {
  element: IElement;
  onClose: () => void;
}

const RecipeSelector: React.FC<RecipeSelectorProps> = ({
  element,
  onClose,
}) => {
  const count = getSBEFCountValue(element);

  const [searchString, setSearchString] = useState('');
  const [selectedSBEF, setSelectedSBEF] = useState(
    count === 'none' ? '' : count,
  );
  const [selectedTab, setSelectedTab] = useState<RecipeSelectorTab>();

  return (
    <Modal open onClose={onClose} sx={modalStyles}>
      <Box sx={containerStyles}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={onClose} size="small" sx={closeButtonStyles}>
            <Close />
          </IconButton>
        </Box>

        <SearchField
          autoFocus
          placeholder="Search recipes"
          sx={searchFieldStyles}
          value={searchString}
          onChange={setSearchString}
        />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={10}
          height={HEADER_HEIGHT}
        >
          <RecipeSelectorSBEFMenu
            selectedSBEF={selectedSBEF}
            onSelect={setSelectedSBEF}
          />
          <RecipeSelectorTabs
            selectedTab={selectedTab}
            onChange={setSelectedTab}
          />
        </Box>

        <Box display="flex" height={`calc(100% - ${HEADER_HEIGHT}px)`}>
          <Box display="flex" flexDirection="column" flex={1}>
            <RecipeSelectorList
              element={element}
              sbefCode={selectedSBEF}
              selectedTab={selectedTab}
              searchString={searchString}
              onClose={onClose}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const HEADER_HEIGHT = 50;

const modalStyles: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
} as const;

const containerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 100px)',
  height: 'calc(100% - 100px)',
  background: NodonTheme.palette.background.default,
  borderRadius: 1,
} as const;

const closeButtonStyles: SxProps = { margin: 2 } as const;

const searchFieldStyles: SxProps = { padding: '0px 20px 10px' } as const;

export default RecipeSelector;
