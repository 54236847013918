import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyName,
  IFactoryProperty,
} from '../../../models/element_property.interface';
import { labourProductTree, boardTypeProperty } from './labour.model';
import { createProductTreeProcessor } from '../processor';

const levelProperties: IFactoryProperty[] = [boardTypeProperty];
const processor = createProductTreeProcessor({
  levelProperties,
  productTree: labourProductTree,
});

export const labour: IElementCategory = {
  ...processor,
  id: ElementCategoryID.Labour,
  name: 'Labour',
  color: '#AC91CE',
  defaultSelectedQuantity: 'time',
  excludeMutualProperties: [ElementPropertyName.Lifetime],
  getQuantityProperties: () => ({ time: {} }),
};
