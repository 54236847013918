import sbefJson from './sbef_codes.json';
import { toLookup } from '../helpers/utils.helpers';
import { mapFilterRecord } from '../helpers/object_helpers';

export type SBEFCodeLanguage = 'sv' | 'en';

export interface SBEFCode {
  id: string;
  description: Record<SBEFCodeLanguage, string>;
  lifetime?: number;
}

const formatSBEF = (
  { id, description }: SBEFCode,
  lang: SBEFCodeLanguage = 'en',
): string => `${id}. ${description[lang]}`;

/**
 * Get a list of formatted SBEF labels (same order as data in sbefJson)
 * @returns
 */
export const getFormatedSBEFLabels = () => {
  return sbefCodes.map((sbef) => formatSBEF(sbef, 'en'));
};

export const sbefCodes = sbefJson as SBEFCode[];
export const sbefCodesLookup = toLookup(sbefCodes);
export const sbefLabelLookup = mapFilterRecord(sbefCodesLookup, (sbef) =>
  formatSBEF(sbef),
);
export const sbefLabels = Object.values(sbefLabelLookup);
