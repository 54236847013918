import { isDefined } from '../../../shared/helpers/array_helpers';
import {
  PartialRecord,
  ValidRecordKeys,
} from '../../../shared/models/type_helpers.interface';
import { isEqual } from 'lodash';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

/**
 * Callback function for local storage change event.
 * @param value New value stored in local storage
 * @param isExternalChange True if change was triggered by another instance of the handler
 */
type StorageChangeFn<T> = (
  value: T | undefined,
  isExternalChange: boolean,
) => void;

type LocalStorageRecord<
  K extends ValidRecordKeys = string,
  T = any,
> = PartialRecord<K, T>;

interface ILocalStorageHandler<T> {
  set: (value?: T) => void;
  get: () => T | undefined;
  dispose: () => void;
}

/**
 * Set local storage item as stringified JSON.
 * @param key Key to store value under
 * @param value Value to store in local storage. Pass undefined to remove key.
 * @returns Value stored in local storage (string or undefined)
 */
export const setLocalStorageJSON = <T = any>(
  key: string,
  value: T,
): string | undefined => {
  if (!isDefined(value)) {
    window.localStorage.removeItem(key);
  } else {
    return setLocalStorageString(key, JSON.stringify(value));
  }
};

/**
 * Get a parsed JSON value from local storage.
 * Undefined if it does not exist.
 * @param key
 * @returns
 */
export const getLocalStorageJSON = <T = any>(key: string): T | undefined => {
  const value = getLocalStorageString(key);
  return parse<T>(value);
};

/**
 * Local storage handler with get/set and onChange functionality.
 * @param key
 * @param initialValue
 * @param onChange
 * @returns
 */
export const createLocalStorageHandler = <T = any>(
  key: string,
  initialValue?: T,
  onChange?: StorageChangeFn<T>,
): ILocalStorageHandler<T> => {
  if (!window.localStorage) {
    throw new Error('Local storage not available');
  }

  // Keep track of current value to detect which instance of the handler triggered onChange
  let currentStringValue: string | undefined;

  const set = (value: any) => {
    currentStringValue = setLocalStorageJSON<T>(key, value);
  };
  const get = () => getLocalStorageJSON<T>(key);

  // Set initial value BEFORE adding listener to avoid triggering change event
  // Also, only set initial value if LocalStorage value is empty
  if (initialValue !== undefined && get() === undefined) {
    set(initialValue);
  }

  let dispose = () => {};

  if (typeof onChange === 'function') {
    const onLocalStorageChange = ({
      key: storageKey,
      newValue,
    }: StorageEvent) => {
      // Only trigger changes for this key
      if (storageKey === key) {
        const stringValue = newValue ?? undefined;
        const isExternalChange = stringValue !== currentStringValue;
        onChange(parse(stringValue), isExternalChange);
      }
    };
    dispose = () => window.removeEventListener('storage', onLocalStorageChange);
  }

  return { set, get, dispose };
};

/**
 * Create a zustand store which also store any values in local storage.
 * @param localStorageKey Key to store value under in local storage
 * @param initialValue Default value for the store
 * @returns
 */
export const createLocalStorageStore = <T>(
  localStorageKey: string,
  initialValue?: T,
) => {
  // Zustand only accepts state as an object so make sure to wrap value in an object
  type State = { value: T | undefined };

  // Pick value from state
  const valueSelector = (state: State): T | undefined => state.value;

  const store = createWithEqualityFn<State>()(
    () => ({ value: initialValue }),
    shallow,
  );

  // Make store that can handle a selector function
  const valueStore = <R = T>(
    selector: (value: T | undefined) => R = (value) => value as R,
  ) => store((state) => selector(valueSelector(state)), shallow);

  // Setup local storage handler
  const storage = createLocalStorageHandler<T>(
    localStorageKey,
    initialValue,
    (value, isExternalChange) => {
      // Only if another instance of the handler has changed the value
      if (isExternalChange) {
        store.setState({ value });
      }
    },
  );

  // Set initial state value (storage will handle if it's from initial value or local storage)
  store.setState({ value: storage.get() ?? initialValue });

  // Update local storage when store state changes
  const unsubscribe = store.subscribe(({ value }) => {
    storage.set(value);
  });

  const set = (value: T) => store.setState({ value });
  const get = () => valueSelector(store.getState());

  return {
    get,
    set,
    dispose: () => {
      unsubscribe();
      storage.dispose();
    },
    useStore: valueStore,
  };
};

/**
 * Create a local storage store for a key/value record.
 * @param localStorageKey What the record should be stored under in local storage
 * @param initialRecord Default record to use if none is found in local storage
 * @returns
 */
export const createLocalStorageRecordStore = <
  K extends ValidRecordKeys = string,
  T = any,
>(
  localStorageKey: string,
  initialRecord?: LocalStorageRecord<K, T>,
) => {
  const { dispose, useStore, get, set } = createLocalStorageStore(
    localStorageKey,
    initialRecord ?? ({} as LocalStorageRecord<K, T>),
  );

  // Get specific value
  const getItem = (key: K) => get()?.[key];

  // Set value for a specific key. Will trigger rerenders in useStoreItem(key) hooks
  const setItem = (key: K, value: T) => {
    // Use isEqual since parsed JSON values may not be strictly equal
    if (!isEqual(getItem(key), value)) {
      set({
        ...get(),
        [key]: value,
      });
    }
  };

  // Get a hook to use a specific store item
  const useStoreItem = (key: K) => {
    return useStore((state) => state?.[key]);
  };

  return {
    useStoreRecord: useStore,
    getRecord: get,
    setRecord: set,
    getItem,
    setItem,
    dispose,
    useStoreItem,
  };
};

export const getLocalStorageString = (key: string): string | undefined => {
  return window.localStorage.getItem(key) ?? undefined;
};

const keepItemsOnClear = ['sort_by'] as const;

const setLocalStorageString = (key: string, value: string): string => {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    if (error instanceof DOMException && error.name === 'QuotaExceededError') {
      console.error('Local storage quota exceeded. Clearing local storage.');

      // Save items that should be kept for later restoration
      const items = keepItemsOnClear
        .map((key) => getLocalStorageString(key))
        .filter(isDefined);

      // Clear all items
      window.localStorage.clear();

      // Restore items that should be kept
      items.forEach((item, index) => {
        const key = keepItemsOnClear[index];
        if (!key) {
          console.error('No key found for item', item);
          return;
        }
        window.localStorage.setItem(key, item);
      });

      // Try setting the item again. If it fails, it will throw an error (data might be to big from start)
      window.localStorage.setItem(key, value);
    }
  }
  return value;
};

const parse = <T = any>(value: string | null | undefined): T | undefined =>
  isDefined(value) ? (JSON.parse(value) as T) : undefined;
