import { isObject, uniq } from 'lodash';
import { IID } from '../models/project.interface';
import { isPrimitive } from './object_helpers';
import {
  AllowReadonly,
  ArrayOrRecord,
  ArrayOrSingle,
  FixedSizeArray,
  OptionallyRequired,
  OptionallyRequiredOptions,
  OptionalReadonly,
  RequireProperties,
  ValidRecordKeys,
} from '../models/type_helpers.interface';
import { required } from './function_helpers';

/**
 * Empty array that will not cause rerenders. DO NOT USE OUTSIDE REACT
 * Will throw errors if modified
 */
export const EMPTY_ARRAY: Readonly<any[]> = Object.freeze([]);

/**
 * Filter array based on type
 * @param a
 * @param condition
 * @returns
 */
export function typeFilter<T, R>(a: T[], condition: (e: T | R) => e is R): R[] {
  const r: R[] = [];
  a.forEach((e) => {
    if (condition(e)) r.push(e);
  });
  return r;
}

/**
 * Test if item is of provided type and not undefined/null.
 * Useful for filters etc
 * @param item
 * @returns
 */
export function isDefined<T>(item: T | undefined | null | void): item is T {
  return item !== undefined && item !== null;
}

/**
 * Remove any undefined values from array
 * @param array
 * @returns
 */
export function undefinedFilter<T>(array: (T | undefined | null)[]): T[] {
  return array.filter(isDefined);
}

export const replaceAt = <T>(array: T[], index: number, value: T): T[] => {
  array[index] = value;
  return array;
};

export const cloneReplaceAt = <T>(array: T[], index: number, value: T): T[] => {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
};

/**
 * Replace an item in an array without creating a new array
 * @param array Array
 * @param replace Value to replace
 * @param newValue New value to inject
 * @returns Original array
 */
export const replaceItem = <T>(array: T[], replace: T, newValue: T): T[] => {
  const index = array.indexOf(replace);
  return index > -1 ? replaceAt(array, index, newValue) : array;
};

/**
 * Replace an item in an array by creating a new array
 * @param array
 * @param replace
 * @param newValue
 * @returns A new array
 */
export const cloneReplaceItem = <T>(
  array: T[],
  replace: T,
  newValue: T,
): T[] => {
  const index = array.indexOf(replace);
  return index > -1 ? cloneReplaceAt(array, index, newValue) : array;
};

/**
 * Replace an item in an array without creating a new array
 * @param array Array
 * @param id Id of the value to replace
 * @param newValue New value to inject
 * @returns Original array
 */
export const replaceById = <T extends IID>(
  array: T[],
  id: string,
  newValue: T,
): T[] => {
  const index = array.findIndex((item) => item.id === id);
  return index > -1 ? replaceAt(array, index, newValue) : array;
};

/**
 * Replace an item in an array by creating a new array
 * @param array Array
 * @param id Id of the item to replace
 * @param newValue New value to inject
 * @returns Original array
 */
export const cloneReplaceById = <T extends IID>(
  array: T[],
  id: string,
  newValue: T,
): T[] => {
  const index = array.findIndex((item) => item.id === id);
  if (index === -1) {
    throw new Error('Could not find item to replace');
  }
  return cloneReplaceAt(array, index, newValue);
};

/**
 * Remove items from existing array
 * @param array
 * @param items The values to remove
 * @returns The orignal array instance
 */
export const removeItems = <T>(array: T[], ...items: T[]): T[] => {
  items.forEach((v) => {
    removeAt(array, array.indexOf(v));
  });
  return array;
};

/**
 * Create a new array excluding a list of items
 * @param array
 * @param items The values to remove
 * @returns A new array instance
 */
export const cloneRemoveItems = <T>(array: T[], ...items: T[]): T[] => {
  return array.filter((v) => items.indexOf(v) === -1);
};

/**
 * Remove a specific index from an existing array
 * @param array
 * @param values The values to remove
 * @returns The modified array
 */
export const removeAt = <T>(array: T[], index: number): T[] => {
  if (array.length && index > -1 && index < array.length) {
    array.splice(index, 1);
  }
  return array;
};

/**
 * Create a new array excluding a specific index
 * @param array
 * @param values The values to remove
 * @returns A new array instance
 */
export const cloneRemoveAt = <T>(array: T[], index: number): T[] => {
  return array.filter((v, i) => i !== index);
};

/**
 * Move item in an array by modifiying the original array
 * @param array
 * @param values The value to move
 * @returns the original array
 */
export const moveItem = <T>(array: T[], item: T, to: number): T[] => {
  const from = array.indexOf(item);
  const spliced = array.splice(from, 1);
  const first = spliced[0];

  if (first !== undefined && from !== -1) {
    array.splice(to, 0, first);
  }
  return array;
};

/**
 * Returned a new array with the item moved
 * @param array
 * @param values The value to move
 * @returns the new array
 */
export const cloneMoveItem = <T>(array: T[], item: T, to: number): T[] => {
  return moveItem(array.slice(0), item, to);
};

/**
 * Get last item in an array
 * @param array
 * @returns
 */
export const getLast = <T>(array: T[]): T | undefined =>
  Array.isArray(array) && array.length ? array[array.length - 1] : undefined;

/**
 * Get an item based on id.
 * Crash if nothing is found
 * @param array Array to search in
 * @param id Id of the item to find
 * @returns Found item
 */
export const getItemById = <
  T extends RequireProperties<T, 'id'>,
  E extends OptionallyRequiredOptions = true,
>(
  array: OptionalReadonly<(T | undefined)[]>,
  id: T['id'],
  throwError: E = true as E,
): OptionallyRequired<T, E> =>
  getItemByKey(array, ['id'], id, throwError) as OptionallyRequired<T, E>;

/**
 * Get an item in an array by a spec
 * @param items An array of objects
 * @param keyOrKeys Provide multiple keys to search in multiple fields
 * @param value
 * @param throwError
 * @returns
 */
export const getItemByKey = <
  T extends object,
  K extends keyof T,
  E extends OptionallyRequiredOptions = true,
>(
  items: OptionalReadonly<(T | undefined)[]>,
  keyOrKeys: ArrayOrSingle<K> | undefined,
  value: unknown,
  throwError: E = true as E,
): OptionallyRequired<T, E> => {
  if (value === undefined || keyOrKeys === undefined || !items?.length) {
    return required(undefined, throwError);
  }
  const keys = asArray(keyOrKeys);
  const found = items.find(
    (item) =>
      item !== undefined &&
      keys.some((key) => key in item && item[key] === value),
  );
  return required(found, throwError);
};

/**
 * Find an item by id or name or an object that might have either of them
 * @param array
 * @param idOrName
 * @param throwError
 * @returns
 */
export const getItemByIdOrName = <
  T extends { id?: string; name?: string },
  E extends OptionallyRequiredOptions = true,
>(
  array: T[],
  idOrName: undefined | T['id'] | T['name'] | { id?: string; name?: string },
  throwError: E = true as E,
): OptionallyRequired<T, E> => {
  if (!idOrName || !array?.length) {
    return required(undefined, throwError);
  }

  if (isObject(idOrName)) {
    return required(
      getItemByKey(array, 'id', idOrName.id, false) ??
        getItemByKey(array, 'name', idOrName.name, false),
      throwError,
    );
  }
  return getItemByKey(array, ['id', 'name'], idOrName, throwError);
};

/**
 * Get the next item in an array
 * @param items
 * @param item
 * @returns
 */
export const getNextItem = <T extends RequireProperties<T, 'id'>>(
  items: T[],
  id: T['id'],
): T | undefined => {
  const index = items.findIndex((p) => p.id === id);
  const first = items[0];
  const next = items[index + 1];

  if (next) return next;
  if (first && first.id !== id) return first;
};

/**
 * Create a new array with new items inserted at specified index
 * @param array original array
 * @param index index to insert to
 * @items any items
 * @returns new array
 */
export const insertItems = <T>(array: T[], index: number, items: T[]): T[] => [
  ...array.slice(0, index),
  ...items,
  ...array.slice(index),
];

/**
 * Insert an item after a specific item in an existing array.
 * Note that this will modify the original array
 * @param array Array to modify
 * @param afterItem Item that we should add items after
 * @param items Items to be inserted
 * @returns
 */
export const insertItemsAfter = <T>(
  array: T[],
  afterItem: T,
  ...items: T[]
): T[] => {
  const index = array.indexOf(afterItem);
  if (index === -1) {
    throw new Error('Could not find item to insert after');
  }
  array.splice(index + 1, 0, ...items);

  return array;
};

export const cloneMoveAfter = <T>(array: T[], after: T, ...items: T[]): T[] => {
  // Create a clone with the item removed
  const clone = cloneRemoveItems(array, ...items);
  const index = clone.indexOf(after);
  if (index !== -1) {
    clone.splice(index + 1, 0, ...items);
  }
  return clone;
};

export const cloneMoveBefore = <T>(
  array: T[],
  before: T,
  ...items: T[]
): T[] => {
  // Create a clone with the item removed
  const clone = cloneRemoveItems(array, ...items);
  const index = clone.indexOf(before);
  if (index !== -1) {
    clone.splice(index, 0, ...items);
  }
  return clone;
};

type ArrayValue<T> =
  | Readonly<T[]>
  | ArrayOrRecord<T>
  | null
  | undefined
  | number
  | symbol
  | string
  | object
  | boolean;

/**
 * Check if two arrays or objects is shallow equal.
 * IE object/array references might differ but first level properties should be the same
 * @param arrA
 * @param arrB
 * @returns
 */
export function shallowEqual<T>(
  arrA?: ArrayValue<T>,
  arrB?: ArrayValue<T>,
): boolean {
  if (arrA === arrB) {
    return true;
  }

  // Treat null and undefined as the same
  if (!isDefined(arrA) && !isDefined(arrB)) {
    return true;
  }

  if (!arrA || !arrB) {
    return false;
  }

  // Not of the same type
  if (typeof arrA !== typeof arrB) {
    return false;
  }

  // Primitive values should be compared directly
  if (isPrimitive(arrA) || isPrimitive(arrB)) {
    return arrA === arrB;
  }

  const entriesA = Object.entries(arrA);
  const entriesB = Object.entries(arrB);

  if (entriesA.length !== entriesB.length) {
    return false;
  }

  for (const [keyA, valueA] of entriesA) {
    const [, valueB] = entriesB.find(([key]) => key === keyA) || [];
    if (valueA !== valueB) {
      return false;
    }
  }

  return true;
}

export default shallowEqual;

/**
 * Map an array and return a new array if the result is different from the original array.
 * This is useful for performance when you need to map an array but don't want to create a new array every time
 * @param arr
 * @param mapFn
 * @returns
 */
export const shallowEqualMap = <T>(
  arr: T[],
  mapFn: (item: T, index: number, array: T[]) => T,
): T[] => {
  const newArray = arr.map(mapFn);
  return shallowEqual(newArray, arr) ? arr : newArray;
};

type CompareFn<T> = (a: T, b: T) => number;

/**
 * Sort an array by multiple compare functions
 * @param list Array that will be sorted. Note that the original array will be modified
 * @param compareFunctions
 * @returns The passed in array
 */
export const sort = <T>(
  list: T[],
  ...compareFunctions: CompareFn<T>[]
): T[] => {
  return list.sort((a, b) => {
    for (const fn of compareFunctions) {
      const result = fn(a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  });
};

/**
 * Create a record from an array
 * @param arrayOrRecord Array to create the record from
 * @param keyProperty Which property of the items to use as key
 * @param valueMap Optionl funtion to map values
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const createRecordByKey = <T extends object, P extends keyof T, R = T>(
  arrayOrRecord: ArrayOrRecord<T>,
  keyProperty: P,
  valueMap?: (item: T, key: string) => R | undefined,
): Record<string, R> => {
  const record = Object.entries(arrayOrRecord).reduce(
    (acc, [key, item]) => {
      const recordKey: T[P] = item[keyProperty];
      if (typeof recordKey === 'string') {
        const value = valueMap ? valueMap(item, key) : item;

        if (value !== undefined) {
          // For performance reasons with large arrays, don't create new object every iteration
          acc[recordKey] = value as R;
        }
      }
      return acc;
    },
    {} as Record<string, R>,
  );

  return record;
};

/**
 * Create record from array of keys. Ignore undefined values
 * @param array
 * @param valueMap
 * @returns
 */
export const keyArrayToRecord = <K extends ValidRecordKeys, T>(
  array: K[],
  valueMap: (key: K) => T,
): Record<K, T> =>
  array.reduce(
    (acc, key) => {
      const value = valueMap(key);
      if (value !== undefined) {
        acc[key] = valueMap(key);
      }
      return acc;
    },
    {} as Record<K, T>,
  );

export const hasDuplicates = <T>(array: OptionalReadonly<T[]>): boolean => {
  return new Set(array).size !== array.length;
};

export const findDuplicates = <T>(array: OptionalReadonly<T[]>): T[] => {
  return uniq(array.filter((v, i) => array.indexOf(v, i + 1) !== -1));
};

export const asArray = <T>(a?: ArrayOrSingle<T>): T[] => {
  if (!a) {
    return [] as T[];
  }
  return Array.isArray(a) ? a : [a];
};

export const includesAll = <T>(
  arrayOrRecord: AllowReadonly<ArrayOrRecord<T>>,
  ...items: (T | undefined)[]
): boolean => {
  const array = Object.values(arrayOrRecord);
  return items.filter(isDefined).every((item) => array.includes(item));
};

export const includesSome = <T>(
  arrayOrRecord: ArrayOrRecord<T>,
  ...items: (T | undefined)[]
): boolean => {
  const array = Object.values(arrayOrRecord);
  return items.filter(isDefined).some((item) => array.includes(item));
};

/**
 * Like Array.includes but supports objects and works better with typescript
 * @param arrayOrRecord
 * @param item
 * @returns
 */
export const isOneOf = <T>(
  arrayOrRecord: ArrayOrRecord<T> | undefined,
  item: unknown,
): item is T => {
  return Object.values(arrayOrRecord ?? []).some((value) => value === item);
};

/**
 * Sort array values in the specific order of another array
 */
export const sortInOrderOf = <T extends object, U extends keyof T>(
  order: readonly T[U][],
  a: T,
  b: T,
  accessor: U,
) => {
  return order.indexOf(a[accessor]) - order.indexOf(b[accessor]);
};

/**
 *
 * @param array
 * @param item
 * @returns
 */
export const replaceOrAddIdItem = <T extends IID>(array: T[], item: T): T[] => {
  const index = array.findIndex((i) => i.id === item.id);
  if (index === -1) {
    return [...array, item];
  }
  return cloneReplaceAt(array, index, item);
};

/**
 * Check if value is an empty array
 * @param value
 * @returns
 */
export const isEmptyArray = (value: unknown): boolean =>
  Array.isArray(value) && value.length === 0;

/**
 * Check if value is not an empty array
 * @param value
 * @returns
 */
export const isNotEmptyArray = (value: unknown): boolean => {
  return Array.isArray(value) && value.length > 0;
};

/**
 * Find the first value in the array that is not null/undefined returned by the predicate
 * @param array
 * @param predicate Function that returns the value found or undefind/null to skip to next check
 * @returns
 */
export const findMap = <T, V>(
  array: T[],
  predicate: (value: T) => V | undefined,
): V | undefined => {
  for (const value of array) {
    const result = predicate(value);
    if (isDefined(result)) {
      return result;
    }
  }
  return undefined;
};

export const requireArrayLength = <T, N extends number>(
  array: T[],
  length: N,
): FixedSizeArray<N, T> => {
  if (array.length !== length) {
    throw new Error(`Array must be of length ${length}`);
  }
  return array as FixedSizeArray<N, T>;
};
