import {
  Box,
  Button,
  ButtonProps,
  PopoverOrigin,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EllipsisText } from '../../EllipsisText';
import { makeStyles } from 'tss-react/mui';
import SelectMenu from '../SelectMenu';
import { NodonMenuProps, SelectListChildren } from '../menu.model';
import { useIsReadonly } from '../../../hooks/user.hook';

interface TextMenu<T> extends NodonMenuProps {
  items: T[];
  label?: string;
  children: SelectListChildren<T>;
}

const TextMenu = <T,>({
  items,
  anchor,
  anchorOrigin,
  label,
  tooltip,
  buttonProps,
  buttonLabelProps,
  menuWidth,
  children,
  onOpen,
  onClose,
  onSearch,
}: TextMenu<T>) => {
  const { classes } = useStyles();
  const readonly = useIsReadonly();
  const disabled = readonly || items.length === 0;

  const closeMenu = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      setAnchorEl(undefined);
    }
  }, [onClose]);

  const [anchorEl, setAnchorEl] = useState<Element | undefined>();

  useEffect(() => {
    setAnchorEl(anchor);
  }, [anchor]);

  const openMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled) return;
      // Handle outside
      if (onOpen) {
        onOpen(e.currentTarget);
      } else {
        setAnchorEl(e.currentTarget);
      }
    },
    [onOpen, disabled],
  );

  const renderChildren = useCallback<SelectListChildren<T>>(
    (item, style, index) => children(item, style, index),
    [children],
  );

  const buttonClasses = useMemo<ButtonProps['classes']>(
    () => ({
      root: classes.button,
      endIcon: classes.endIcon,
    }),
    [classes.button, classes.endIcon],
  );

  const menuTransformOrigin = useMemo<PopoverOrigin>(
    () => ({ horizontal: 'center', vertical: 'center' }),
    [],
  );

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={tooltip}>
        <Button
          {...buttonProps}
          classes={buttonClasses}
          variant="text"
          size="small"
          color="inherit"
          disabled={disabled}
          onClick={openMenu}
        >
          <EllipsisText {...buttonLabelProps}>{label}</EllipsisText>
        </Button>
      </Tooltip>

      <SelectMenu
        anchor={anchorEl}
        anchorOrigin={anchorOrigin}
        items={items}
        width={menuWidth}
        enableSearch={items.length > 25}
        enableVirtualization={items.length > 25}
        transformOrigin={menuTransformOrigin}
        onClose={closeMenu}
        onSearch={onSearch}
      >
        {renderChildren}
      </SelectMenu>
    </Box>
  );
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  button: {
    minWidth: 0,
  },
  endIcon: {
    marginLeft: spacing(1),
    color: palette.text.secondary,
  },
  itemButton: {
    fontSize: 12,
  },
}));

export default TextMenu;
