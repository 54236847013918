import { useCallback } from 'react';
import {
  OneOfChildElements,
  Project,
} from '../../../shared/models/project.interface';
import {
  addRecipes,
  ExportObject,
  getCorrespondingProductRecord,
  getCorrespondingRecipeRecord,
  getJSONFromFile,
  getProductsToAdd,
  getRecipesToAdd,
  printRecord,
  replaceProjectRecipeIds,
} from '../helpers/import_helpers';
import { getSelectedOrganization } from '../store/organization';
import { Recipe } from '../../../shared/models/recipe.interface';
import { mapFilterRecord } from '../../../shared/helpers/object_helpers';
import {
  getProductIdsInRecipes,
  getRecipesUsedInProject,
} from '../../../shared/helpers/recipe_helpers';
import {
  isGreaterOrEqualVersion,
  toLookup,
} from '../../../shared/helpers/utils.helpers';
import { getCreateRecipe, getRecipeLookup } from '../store/recipe/recipe.hook';
import { ProductRecord } from '../../../shared/models/product.interface';
import {
  getProjectProductsRecord,
  replaceProductIds,
  updateProductsInProject,
} from '../../../shared/helpers/product_helpers';
import { getCreateProduct, getProductsLookup } from '../store/product';
import { createProject } from '../../../shared/helpers/project_factory_helpers';
import {
  flattenElements,
  getAllBuildingVersions,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  validateProject,
  validateRecipeProducts,
} from '../../../shared/validation';
import { objectPromiseAll } from '../../../shared/helpers/promise.helpers';
import { TMP_PROJECT_ID } from '../../../shared/constants';
import amplitudeLog from '../amplitude';
import { setElementExpanded } from './expand-elements.hook';
import { useConfig } from '../providers/ConfigProvider';
import { useFindFreeProjectName, useCreateProject } from '../store/project';
import { useUIState } from '../store/ui';
import { useUserId } from './user.hook';
import { getElementVersionId } from '../../../shared/helpers/element-version.helpers';
import { FileWithPath } from 'react-dropzone/.';

/**
 * Import element with connected recipes and products.
 * Will add missing products and recipes to the target organization
 * and remap current IDs to the newly created items.
 * Note: This will not add the project in the store.
 * @param element
 * @param recipes
 * @returns A new element ready to add
 */
export const useImportElement = (): ((
  element: OneOfChildElements,
  recipes: Recipe[],
  products?: ProductRecord,
) => Promise<OneOfChildElements>) => {
  const importProject = useImportProject();

  return useCallback(
    async (element, recipes, products) => {
      const project = createProject(
        {
          owner: TMP_PROJECT_ID,
          versions: [{ elements: [element] }],
        },
        false,
      );

      // Make a dummy import to import all recipes and products
      const importedProject = await importProject(project, recipes, products);
      const importedVersion = getAllBuildingVersions(importedProject)[0];
      const importedElement = importedVersion?.elements[0];

      const flattenedImportedElements = flattenElements(importedElement);
      const flattenedOriginalElements = flattenElements(element);

      if (
        flattenedImportedElements.length !== flattenedOriginalElements.length
      ) {
        throw new Error('Import error. Element count mismatch');
      }
      for (let i = 0; i < flattenedImportedElements.length; i++) {
        const imported = flattenedImportedElements[i];
        const original = flattenedOriginalElements[i];
        if (getElementVersionId(imported) !== getElementVersionId(original)) {
          throw new Error('Import error. Element version id mismatch');
        }
      }

      if (!importedElement) {
        throw new Error('Import error. No imported elements found');
      }

      return importedElement;
    },
    [importProject],
  );
};

/**
 * Import project with recipes and products.
 * Will add missing products and recipes to the target organization
 * and remap current IDs to the newly created items.
 * Note: This will not add the project in the store.
 * @param project
 * @param recipes
 * @returns A new project ready to add
 */
export const useImportProject = (): ((
  project: Project,
  recipes: Recipe[],
  products?: ProductRecord,
) => Promise<Project>) => {
  const importProducts = useImportProducts();
  const importRecipes = useImportRecipes();

  return useCallback(
    async (project, recipes, products) => {
      const { project: updatedProject, productMap } = await importProducts(
        project,
        recipes,
        products,
      );

      return await importRecipes(updatedProject, recipes, productMap);
    },
    [importProducts, importRecipes],
  );
};

const useImportProducts = (): ((
  project: Project,
  recipes: Recipe[],
  products?: ProductRecord,
) => Promise<{ project: Project; productMap: ProductRecord }>) => {
  return useCallback(async (project, recipes, products) => {
    const productsLookup = getProductsLookup();
    const organization = getSelectedOrganization(true);

    // Make sure project contains all products
    validateRecipeProducts(project, recipes);

    // From another organization, set it to current one
    if (!project.organizations?.includes(organization)) {
      project.organizations = [organization];
    }

    const importedProductRecord = products ?? getProjectProductsRecord(project);

    // A map of all products that are already available in the target organization.
    // Note that the keys are the id's of imported products
    const correspondingProducts = getCorrespondingProductRecord(
      importedProductRecord,
      productsLookup,
    );

    // Products that should be added
    const productsToAdd = getProductsToAdd(
      importedProductRecord,
      correspondingProducts,
      organization,
      project.owner,
    );

    const addedProductsMap = await getAddedProductsMap(productsToAdd);

    // // Products which can be mapped to an existing ID
    const productsToRemap = mapFilterRecord(
      correspondingProducts,
      (product, id) => (product.id !== id ? product : undefined),
    );

    const addedProductsLookup = toLookup(Object.values(addedProductsMap));

    // All product id's that have new ids (<old id, new product>)
    const productMap: ProductRecord = {
      ...productsToRemap,
      ...addedProductsMap,
    };

    const allProductsWithNewIds = {
      ...productsLookup,
      ...addedProductsLookup,
    };
    const allProductsWithOldToNewIds = {
      ...allProductsWithNewIds,
      ...productMap,
    };

    // Use the custom products that are already available in the target organization
    project = replaceProductIds(project, productMap);

    // Update the product records in all versions
    project = updateProductsInProject(project, allProductsWithNewIds, recipes);

    printRecord('Added products', addedProductsLookup);
    printRecord('Reused products', productsToRemap);

    return {
      project: validateProject(project),
      productMap: validateProductMapForRecipes(
        allProductsWithOldToNewIds,
        recipes,
      ),
    };
  }, []);
};

/**
 * Add products and return a map of the added products <old id, new product>
 * @param products
 * @returns
 */
const getAddedProductsMap = async (
  products: ProductRecord,
): Promise<ProductRecord> => {
  const createProduct = getCreateProduct();
  return objectPromiseAll(
    mapFilterRecord(products, (product) => createProduct(product)),
  );
};

const validateProductMapForRecipes = (
  productMap: ProductRecord,
  recipes: Recipe[],
): ProductRecord => {
  const recipeProducts = getProductIdsInRecipes(...recipes);

  recipeProducts.forEach((id) => {
    if (!productMap[id]) {
      throw new Error(
        `ProductMap validation error. Product with id ${id} is missing in the product map`,
      );
    }
  });
  return productMap;
};

const useImportRecipes = (): ((
  project: Project,
  recipes: Recipe[],
  correspondingProducts: ProductRecord,
) => Promise<Project>) => {
  return useCallback(async (project, recipes, correspondingProducts) => {
    const createRecipe = getCreateRecipe();
    const recipeLookup = getRecipeLookup();
    const organization = getSelectedOrganization(true);

    // Make sure project contains all products
    validateRecipeProducts(project, recipes);

    // Only import recipes that are used in the project (previously we exported all)
    recipes = getRecipesUsedInProject(project, recipes);

    const importedRecipeRecord = toLookup(recipes);

    // All recipes that are already available in the target organization.
    const correspondingRecipes = getCorrespondingRecipeRecord(
      recipes,
      recipeLookup,
    );

    // Recipes which can be mapped to an existing ID
    const recipesToRemap = mapFilterRecord(
      correspondingRecipes,
      (recipe, id) => (recipe.id !== id ? recipe : undefined),
    );

    // All missing recipes
    const recipesToAdd = getRecipesToAdd(
      importedRecipeRecord,
      correspondingRecipes,
      correspondingProducts,
      project.owner,
      organization,
    );

    const addedRecipes = await addRecipes(
      recipesToAdd,
      organization,
      createRecipe,
    );

    project = replaceProjectRecipeIds(project, {
      ...recipesToRemap,
      ...addedRecipes,
    });

    printRecord('Added recipes', addedRecipes);
    printRecord('Reused recipes', recipesToRemap);

    return project;
  }, []);
};

/**
 * Import projects from JSON files.
 * @returns A function that takes an array of Files and imports them as projects.
 */
export const useImportElements = () => {
  const { selectedProjectFolderId } = useUIState('selectedProjectFolderId');
  const [config] = useConfig();
  const owner = useUserId();

  const findFreeProjectName = useFindFreeProjectName();
  const createProject = useCreateProject();
  const importProject = useImportProject();

  return useCallback(
    async (files: readonly FileWithPath[]): Promise<void> => {
      if (!files.length) {
        throw new Error('No files selected');
      }

      for (const file of files) {
        const {
          recipes,
          version,
          project: jsonProject,
        }: ExportObject = await getJSONFromFile(file);

        const fileName = file.name.slice(0, file.name.indexOf('.json'));
        const name = findFreeProjectName(fileName);

        if (!jsonProject || !recipes || !name || !version) {
          throw new Error('Invalid file');
        }

        // Keep this up to date (bump if big model changes are made)
        if (
          !isGreaterOrEqualVersion(version, config.version, {
            patch: Number.MAX_SAFE_INTEGER,
          })
        ) {
          throw new Error(
            `Project version must be newer than "${config.version}", please re-export the project.`,
          );
        }

        let project: Project = {
          ...jsonProject,
          name,
          owner,
          parent_id: selectedProjectFolderId ?? null,
        };

        project = await importProject(project, recipes);

        validateProject(project);

        // Duplicate to make sure a template is not used (if there is one)
        const { id, parent_id } = await createProject(project, {
          successMessage: `Imported ${project.name}`,
          errorMessage: `Failed to import ${project.name}`,
        });

        if (parent_id) {
          setElementExpanded(parent_id, true, true);
        }
        amplitudeLog('Project Import', {
          ProjectId: id,
        });
      }
    },
    [
      findFreeProjectName,
      config.version,
      owner,
      selectedProjectFolderId,
      importProject,
      createProject,
    ],
  );
};
