import { useMemo, useRef } from 'react';
import { useHasChangedDeep, useHasChangedShallow } from './hooks';
import {
  filterObject,
  mapObject,
} from '../../../shared/helpers/object_helpers';

/**
 * Similar to useMemo but will memoize an object until one of the objects properties change using shallow comparison.
 * Works with arrays and objects.
 * @returns
 */
export function useObjectMemo<T extends object>(obj: T): T {
  const hasChanged = useHasChangedShallow(obj);
  const objRef = useRef<T>();

  if (hasChanged) {
    objRef.current = obj;
  }
  return objRef.current as T;
}

/**
 * Similar to useMemo but will memoize an object until one of the objects properties change using deep comparison.
 * Works with arrays and objects.
 * @returns
 */
export function useObjectMemoDeep<T extends object>(obj: T): T {
  const hasChanged = useHasChangedDeep(obj);
  const objRef = useRef<T>();

  if (hasChanged) {
    objRef.current = obj;
  }
  return objRef.current as T;
}

/**
 * Will create a filter object that will update when the filter changes.
 * Will not trigger updates when filter function changes so no need to memoize it.
 * @returns
 */
export const useFilterObject = <T extends object, K extends keyof T>(
  obj: T,
  filter: (value: T[K], key: K) => boolean | undefined,
): Partial<T> => {
  const filterRef = useRef(filter);
  filterRef.current = filter;
  return useMemo(() => filterObject(obj, filterRef.current), [obj]);
};

/**
 * Will map an object using a function and return a new object with the mapped values.
 * Will trigger updates when obj or map function changes.
 * @param obj
 * @param map
 * @returns
 */
export const useMapObject = <T extends object, K extends keyof T, R>(
  obj: T,
  map: (value: T[K], key: K) => R,
): Record<K, R> => {
  const mapped = useMemo(() => mapObject(obj, map), [obj, map]);
  return useObjectMemo(mapped);
};
