import React, { useCallback } from 'react';
import { Box, IconButton } from '@mui/material';
import { useUIState } from '../../../store/ui';
import { Clear } from '@mui/icons-material';
import { useMainCategoryElementNavigation } from '../../../hooks/router.hooks';
import { useKeydownListener } from '../../../hooks/events.hook';

const ElementClose: React.FC = () => {
  const {
    setShowProjectDetailsEditor,
    showProjectSelector,
    setSelectedProductListElementId,
  } = useUIState(
    'setShowProjectDetailsEditor',
    'showProjectSelector',
    'setSelectedProductListElementId',
  );
  const mainCategoryNavigation = useMainCategoryElementNavigation(false);

  const closeSidePanel = useCallback(() => {
    // if project selector is open, do nothing
    if (showProjectSelector) {
      return;
    }
    // reset all properties that opens the side panel
    setShowProjectDetailsEditor(false);
    setSelectedProductListElementId(undefined);
    mainCategoryNavigation();
  }, [
    mainCategoryNavigation,
    setShowProjectDetailsEditor,
    showProjectSelector,
    setSelectedProductListElementId,
  ]);

  useKeydownListener((event) => {
    // check if event originates from an input or textarea, in that case we should not trigger
    // if we need more elements to interrupt, we can add them to the array
    const shouldInterrupt = [HTMLInputElement, HTMLTextAreaElement].some(
      (Element) => event.target instanceof Element,
    );

    if (shouldInterrupt) return;
    else closeSidePanel();
  }, 'Escape');

  return (
    <Box p={2}>
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton size="small" onClick={closeSidePanel}>
          <Clear color="disabled" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ElementClose;
