import { DependencyList } from 'react';
import shallowEqual, { isDefined } from './array_helpers';
import {
  OptionallyRequired,
  OptionallyRequiredOptions,
  TypeMap,
} from '../models/type_helpers.interface';
import { isType } from './object_helpers';
import { getProcessEnvironment } from './environment.helpers';

const inMigrationMode = () =>
  getProcessEnvironment()?.npm_lifecycle_event === 'migrate';

export type AnyFn = (...args: any[]) => any;
export type VoidFn = () => void;

/**
 * Get trigger function that calls provided function with provided arguments
 * Good to use for event handlers like onClick etc
 */
export const getTrigger = <F extends AnyFn, P extends Parameters<F>>(
  fn: F,
  ...args: P
): VoidFn => {
  if (typeof fn !== 'function') {
    throw new TypeError('Expected a function');
  }
  return () => {
    fn(...args);
  };
};

let cacheFactoryValueRecord: Record<string, unknown> = {};
export function cacheFactory<T>(
  factory: () => T,
  key: string,
  deps: DependencyList | undefined = [],
  debug = false,
): T {
  // Don't cache in migration mode
  if (inMigrationMode()) {
    return factory();
  }

  // Run factory function first time or if deps have changed
  if (hasChangedDependencies(key, deps, debug)) {
    if (debug) {
      console.time(`cacheFactory[${key}].miss`);
    }
    try {
      cacheFactoryValueRecord[key] = factory();
    } catch (error) {
      delete changedDependencyRecord[key]; // Clear deps so it's recalculated next time
      throw error instanceof Error
        ? error
        : new Error('Cache factory fn error');
    }
    if (debug) {
      // eslint-disable-next-line no-console
      console.timeEnd(`cacheFactory[${key}].miss`);
    }
  } else if (debug) {
    // eslint-disable-next-line no-console
    console.count(`cacheFactory[${key}].hit`);
  }

  // It should only be undefined if factory returns undefined so cast to T
  return getCacheFactoryValue<T>(key) as T;
}

/**
 * Get cached value from factory
 * @param key Key to get value from
 * @param throwIfNotFound True to throw if value is not found, false to return undefined.
 * Only set this to true if the cacheFn is guaranteed to always return a value
 * @returns
 */
export const getCacheFactoryValue = <
  T,
  E extends OptionallyRequiredOptions = false,
>(
  key: string,
  throwIfNotFound: E = false as E,
): OptionallyRequired<T, E> =>
  required(cacheFactoryValueRecord[key], throwIfNotFound);

/**
 * Reset both cache and changed dependencies (they are reset together to avoid invalidating cache)
 */
export function cacheFactoryReset(): void {
  cacheFactoryValueRecord = {};
  changedDependencyRecord = {};
}
// Register global reset function for unit tests
if (typeof globalThis === 'object') {
  (globalThis as any).cacheFactoryReset = cacheFactoryReset;
}

let changedDependencyRecord: Record<string, DependencyList | undefined> = {};
export function hasChangedDependencies(
  key: string,
  deps: DependencyList | undefined = [],
  debug?: boolean,
): boolean {
  const prevDeps = changedDependencyRecord[key];
  changedDependencyRecord[key] = deps;
  const hasChanged = !shallowEqual(prevDeps, deps);

  if (hasChanged && debug) {
    for (let i = 0; i < deps.length; i++) {
      const dep = deps[i];
      const prevDep = prevDeps ? prevDeps[i] : undefined;
      if (dep !== prevDep) {
        console.info(
          `hasChangedDependencies[${key}], changed:`,
          prevDep,
          'to:',
          dep,
        );
      }
    }
  }
  return hasChanged;
}

/**
 * Optionally require value to be defined and crash if it's not defined
 * @param value Value to check
 * @param requireOrErrorMsg False to not require value, true to throw or string to throw with custom message
 * @returns
 */
export const required = <
  T,
  B extends OptionallyRequiredOptions = true,
  R = OptionallyRequired<T, B>,
>(
  value: T,
  requireOrErrorMsg: B = true as B,
): R => {
  if (requireOrErrorMsg && !isDefined(value)) {
    throw new Error(
      typeof requireOrErrorMsg === 'string'
        ? requireOrErrorMsg
        : 'Value is required',
    );
  }
  return value as any as R;
};

export const requiredType = <T, K extends keyof TypeMap>(
  value: T,
  ...types: K[]
): TypeMap<T>[K] => {
  if (!isType(value, ...types)) {
    throw new Error(`Value is not of type ${types.join(' or ')}`);
  }
  return value;
};
