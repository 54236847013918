import { uniq } from 'lodash';
import { Lifecycle, lifecycles } from '../models/lifecycles.interface';
import { CO2eUnit, CostUnit, Results } from '../models/unit.interface';
import { validateConversionFactorUnit } from '../validation/conversion-factor.validation';
import { includesAll, isOneOf } from './array_helpers';
import { makeSentence } from './string_helpers';
import { omit } from './object_helpers';

const COST_PREFIX = 'sek_';
const CO2E_PREFIX = 'co2e_';

/**
 * Check if the value is a valid lifecycle.
 * @param value - The value to check.
 * @returns True if the value is a valid lifecycle, false otherwise.
 */
export const isLifecycle = (value: unknown): value is Lifecycle => {
  return isOneOf(lifecycles, value);
};

/**
 * Throw error if lifecycle is not valid.
 * @param lifecycle
 * @returns
 */
export const validateLifecycle = (lifecycle: unknown): Lifecycle => {
  if (!isLifecycle(lifecycle)) {
    throw new Error(`Invalid lifecycle: ${String(lifecycle)}`);
  }
  return lifecycle;
};

export const getLifecycleLabel = (cycles: (Lifecycle | string)[]): string => {
  const labels = [];
  const sortedCycles = uniq([...cycles].map(validateLifecycle)).sort();
  if (cycles.length === 0) {
    return 'No lifecycles selected';
  }

  const used: Lifecycle[] = [];

  for (const cycle of sortedCycles) {
    if (used.includes(cycle)) {
      continue;
    }
    used.push(cycle);

    // A1-A5
    if (
      cycle === Lifecycle.A1A3 &&
      includesAll(cycles, Lifecycle.A4, Lifecycle.A5)
    ) {
      labels.push('A1-A5');
      used.push(Lifecycle.A1A3, Lifecycle.A4, Lifecycle.A5);
    }
    // A1-A4
    else if (cycle === Lifecycle.A1A3 && includesAll(cycles, Lifecycle.A4)) {
      labels.push('A1-A4');
      used.push(Lifecycle.A1A3, Lifecycle.A4);
    }
    // A4-A5
    else if (cycle === Lifecycle.A4 && includesAll(cycles, Lifecycle.A5)) {
      labels.push('A4-A5');
      used.push(Lifecycle.A4, Lifecycle.A5);
    } else {
      labels.push(cycle);
    }
  }

  return makeSentence(...labels);
};

/**
 * Get the cost unit (sek_A1-A3 etc) from the lifecycle.
 * @param lifecycle - The lifecycle to get the cost unit from.
 * @returns The cost unit.
 */
export const getCostUnitFromLifecycle = (lifecycle: Lifecycle): CostUnit =>
  validateConversionFactorUnit(
    COST_PREFIX + validateLifecycle(lifecycle),
  ) as CostUnit;

/**
 * Get the CO2e unit (co2e_A1-A3 etc) from the lifecycle.
 * @param lifecycle - The lifecycle to get the CO2e unit from.
 * @returns The CO2e unit.
 */
export const getCO2eUnitFromLifecycle = (lifecycle: Lifecycle): CO2eUnit =>
  validateConversionFactorUnit(
    CO2E_PREFIX + validateLifecycle(lifecycle),
  ) as CO2eUnit;

export const omitLifecyclesFromResults = (
  results: Results,
  excludeLifecycles: Lifecycle[],
): Results => {
  const costUnits = excludeLifecycles.map(getCostUnitFromLifecycle);
  const co2eUnits = excludeLifecycles.map(getCO2eUnitFromLifecycle);

  return omit(results, ...costUnits, ...co2eUnits);
};

export const filterResultsByLifecycles = (
  results: Results,
  includeLifecycles: Lifecycle[],
): Results => {
  const excludeLifecycles = lifecycles.filter(
    (lifecycle) => !includeLifecycles.includes(lifecycle),
  );
  return omitLifecyclesFromResults(results, excludeLifecycles);
};
