import { QuantityUnit } from './unit.interface';
import { ElementCategoryID } from './element_categories.interface';
import { ExpressionValue, OneOfElements } from './project.interface';
import { Recipe, RecipeID } from './recipe.interface';
import {
  PartialRecord,
  Replace,
  SemiPartial,
  SemiRequired,
} from './type_helpers.interface';
import { FactoryCountExpression } from './factory-element.interface';

export type ElementPropertyID = string;
export type ElementPropertySourceID = ElementCategoryID | RecipeID;
export enum ElementPropertySource {
  Category = 'category_id',
  Recipe = 'recipe_id',
}

export type ElementSelectPropertyCountType = string | number;

export interface IElementPropertyOption {
  label: string;
  value: ElementSelectPropertyCountType;
}

/**
 * The type of the property.
 * Will control how the property is displayed in the UI and which values are allowed.
 */
export enum ElementPropertyType {
  Expression = 'expression', // Numeric input
  Select = 'select', // Select (dropdown) input
  Switch = 'switch', // Toggle input
}

export interface IElementPropertySourceIdProperties {
  [ElementPropertySource.Category]?: ElementCategoryID;
  [ElementPropertySource.Recipe]?: RecipeID;
}

interface IElementPropertyBase extends IElementPropertySourceIdProperties {
  /**
   * How the property should be displayed,
   * with expression input, select etc.
   * Should default to expression if undefined
   */
  type?: ElementPropertyType;
  id: ElementPropertyID;
  name: ElementPropertyName | string;

  /**
   * If hidden this property will be available
   * in expressions as normal properties but not visible in UI
   */
  hidden?: boolean;

  /**
   * If readonly property can't be edited by the user.
   */
  readonly?: boolean;

  /**
   * When this is true this property will be available when the project variables resolves (for storeys, activitites etc.).
   * If true this property can't reference any project variables in the expression
   */
  resolveBeforeProjectVariables?: boolean;

  /**
   * If defined this will be used as tooltip title in UI
   */
  description?: string;

  /**
   * If true it indicates that this property's fallbackCount was inherited from a parent element
   */
  inheritFallback?: boolean;
}

export interface IElementExpressionProperty extends IElementPropertyBase {
  type?: ElementPropertyType.Expression;
  count?: ExpressionValue;

  /**
   * If count is not defined by the user we can define a fallback value
   * To use instead. Will show up in UI as a gray placeholder.
   */
  fallbackCount?: ExpressionValue;

  /**
   * If a property have conditional properties referenced in expressions
   * we need to define the default values for those properties.
   */
  expressionDependenciesDefaults?: Record<string, number>;

  /**
   * If the property belongs to a root element, this can be used to disable inheritance
   * in order to not clear default expression values.
   */
  disableInheritance?: boolean;

  unit: QuantityUnit;

  /**
   * If defined this will be used as the unit to display in the UI
   * instead of the unit from the unit property. Is helpful if you want to display hrs/years instead of hr.
   */
  displayUnit?: string;

  /**
   * If defined throw error if count is lower than this min
   */
  min?: number;

  /**
   * If defined throw error if count is larget than this max
   */
  max?: number;
}

/**
 * Special keys that can be used to use hardcoded options that should not be stored in the database
 */
export type ElementPropertyOptionsKey = ElementPropertyName.SBEFCode;

export interface IElementSelectProperty extends IElementPropertyBase {
  type: ElementPropertyType.Select;
  count?: ElementSelectPropertyCountType;
  fallbackCount?: ElementSelectPropertyCountType;

  /**
   * Either a list of options or a key that can be used to get hardcoded options
   */
  options: IElementPropertyOption[] | ElementPropertyOptionsKey;
}

export interface IElementSwitchProperty extends IElementPropertyBase {
  type: ElementPropertyType.Switch;
  count?: boolean;
  fallbackCount?: boolean;
}

export type IElementPropertyParent = OneOfElements | Recipe;

export type IElementProperty =
  | IElementExpressionProperty
  | IElementSelectProperty
  | IElementSwitchProperty;

/**
 * Which defined values can exist in count property
 */
export type ElementPropertyCountType = Required<IElementProperty>['count'];

/**
 * Use ElementPropertyInputTypeMap to get correct type of properties
 */
export type ElementPropertyTypeMap = {
  [ElementPropertyType.Expression]: IElementExpressionProperty;
  [ElementPropertyType.Select]: IElementSelectProperty;
  [ElementPropertyType.Switch]: IElementSwitchProperty;
};

/**
 * Use ElementPropertyResolvedCountMap to get correct primitive value of count property.
 * Note: Better to use ElementPropertyResolvedCount to get the correct type
 */
type ElementPropertyResolvedCountMap = {
  [ElementPropertyType.Expression]: number;
  [ElementPropertyType.Select]: Required<IElementSelectProperty>['count'];
  [ElementPropertyType.Switch]: Required<IElementSwitchProperty>['count'];
};

/**
 * All possible resolved values for count property
 */
export type ElementPropertyResolvedCounts = ElementPropertyResolvedCountMap[
  | ElementPropertyType.Expression
  | ElementPropertyType.Select
  | ElementPropertyType.Switch];

/**
 * Correct return type based on propertyType when using getElementPropertyResolvedCount
 */
export type ElementPropertyResolvedCount<
  T extends keyof ElementPropertyResolvedCountMap | undefined = undefined,
> = T extends string
  ? ElementPropertyResolvedCountMap[T]
  : ElementPropertyResolvedCountMap[ElementPropertyType.Expression];

/**
 * Properties defined by us. Note that names must be valid variable names.
 */
export enum ElementPropertyName {
  Span = 'spännvidd',
  BeamWidth = 'balkbredd',
  BeamHeight = 'balkhöjd',
  CenterToCenter = 'centrumavstånd',
  ClimateClass = 'climate_class',
  WoodClass = 'wood_class',
  RoofPitch = 'roof_pitch',
  SBEFCode = 'sbef_code',

  /**
   * Lifetime of the element in years.
   * Will be used to calculate co2e_B4
   */
  Lifetime = 'lifetime',
}

export type IElementPropertyFactoryOption =
  | ElementSelectPropertyCountType
  | SemiRequired<IElementPropertyOption, 'label'>;

export type IFactorySelectProperty = SemiPartial<
  Replace<
    IElementSelectProperty,
    {
      options: IElementPropertyFactoryOption[] | ElementPropertyOptionsKey;
    }
  >,
  'type'
>;

export type IFactoryExpressionProperty = Partial<
  Replace<
    IElementExpressionProperty,
    {
      count: FactoryCountExpression;

      /**
       * Fallback to use when count is not defined
       */
      fallbackCount: FactoryCountExpression;
    }
  >
>;

export type IFactorySwitchProperty = SemiPartial<
  IElementSwitchProperty,
  'type'
>;

export type IFactoryProperty =
  | IFactoryExpressionProperty
  | IFactorySelectProperty
  | IFactorySwitchProperty;

/**
 * Use FactoryPropertyInputTypeMap to get correct interface based on property type
 */
export type FactoryPropertyInputTypeMap = {
  [ElementPropertyType.Expression]: IFactoryExpressionProperty;
  [ElementPropertyType.Select]: IFactorySelectProperty;
  [ElementPropertyType.Switch]: IFactorySwitchProperty;
};

export type CreatedElementProperty<
  T extends keyof FactoryPropertyInputTypeMap | undefined,
> = T extends string
  ? ElementPropertyTypeMap[T]
  : ElementPropertyTypeMap[ElementPropertyType.Expression];

export type PropertyResolvedCountRecord = PartialRecord<
  string,
  ElementPropertyResolvedCounts
>;

export type SBEFProperty = IElementSelectProperty & {
  name: ElementPropertyName.SBEFCode;
};
