import React, { ChangeEvent, FC, useCallback } from 'react';
import { Switch } from '@mui/material';
import {
  IElement,
  OneOfPropertyElements,
} from '../../../../../../shared/models/project.interface';
import { IElementSwitchProperty } from '../../../../../../shared/models/element_property.interface';
import { useElementPropertiesUtils } from '../../../../hooks/element-properties.hook';
import { isElementQuantitySwitchProperty } from '../../../../../../shared/helpers/element_quantity_helpers';
import { useUpdateQuantity } from '../../../../hooks/quantity-properties.hook';
import { useIsReadonly } from '../../../../hooks/user.hook';
import { isElement } from '../../../../../../shared/helpers/recursive_element_helpers';
import { getCount } from '../../../../../../shared/helpers/element_property_helpers';

interface IElementPropertySwitchProps {
  property: IElementSwitchProperty;
  element: OneOfPropertyElements;
}

const ElementPropertySwitch: FC<IElementPropertySwitchProps> = ({
  property,
  element,
}) => {
  const count = getCount(property);

  const { updateProperty } = useElementPropertiesUtils(element);

  const updateQuantity = useUpdateQuantity();

  const readonly = useIsReadonly();

  const update = useCallback(
    async (
      modified: Partial<IElementSwitchProperty>,
    ): Promise<IElement | undefined> => {
      if (isElement(element) && isElementQuantitySwitchProperty(property)) {
        await updateQuantity(element, [
          {
            ...modified,
            name: property.name,
          },
        ]);
      } else {
        return updateProperty({ ...property, ...modified }).then(
          ({ element }) => element,
        );
      }
    },
    [updateQuantity, updateProperty, property, element],
  );

  const savePropertyCount = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (typeof checked === 'boolean' && checked !== count) {
        update({ count: checked });
      }
    },
    [count, update],
  );

  return (
    <Switch
      disabled={readonly}
      size="small"
      checked={count}
      onChange={savePropertyCount}
    />
  );
};

export default ElementPropertySwitch;
