import {
  co2Units,
  ConversionFactors,
  ConversionFactorUnit,
  Results,
} from '../models/unit.interface';
import { throwValidationErrors, ValidationTypes } from './validation.helpers';
import { includesSome } from '../helpers/array_helpers';
import { getKeys, omit } from '../helpers/object_helpers';
import { ProjectValidationErrors } from './project.validation';
import { isCO2eUnit, isConversionFactorUnit } from '../helpers/unit_helpers';

/**
 * Validate that the results are valid.
 * @param results
 * @returns
 */
export const isValidResults = (
  results: Results | ConversionFactors,
): ValidationTypes => {
  for (const [unit, value] of Object.entries(results)) {
    if (!isConversionFactorUnit(unit)) {
      return `Unit "${unit}" is not valid in results or conversion factors`;
    }

    if (!value || !isFinite(value)) {
      return ProjectValidationErrors.CONVERSION_FACTORS.INVALID_VALUE;
    }
    if (!isCO2eUnit(unit) && value <= 0) {
      return ProjectValidationErrors.CONVERSION_FACTORS.INVALID_VALUE;
    }
  }
  return true;
};

/**
 * Validate that the conversion factors are valid. Same as isValidResults but with additional checks.
 * @param conversionFactors
 * @returns
 */
export const isValidConversionFactors = (
  conversionFactors: ConversionFactors,
  isEpd?: boolean,
): ValidationTypes => {
  const validResults = isValidResults(conversionFactors);

  // Reuse the result validation
  if (validResults !== true) {
    return validResults;
  }

  const units = getKeys(conversionFactors);

  const nonCO2eFactors = omit(conversionFactors, ...co2Units);
  const values = Object.values(nonCO2eFactors);

  if (!values.includes(1)) {
    return ProjectValidationErrors.CONVERSION_FACTORS
      .MISSING_NON_CO2E_VALUE_OF_1;
  }

  // Non-EPD products must have at least one CO2e unit
  if (!isEpd && !includesSome(units, ...co2Units)) {
    return ProjectValidationErrors.CONVERSION_FACTORS.MISSING_CO2E_UNIT;
  }

  return true;
};

/**
 * Validate the conversion factors and throw an error if they are invalid.
 * @param conversionFactors
 * @returns
 */
export const validateConversionFactors = (
  conversionFactors: ConversionFactors,
  isEpd?: boolean,
): ConversionFactors => {
  throwValidationErrors(isValidConversionFactors(conversionFactors, isEpd));
  return conversionFactors;
};

export const validateConversionFactorUnit = (
  unit: unknown,
): ConversionFactorUnit => {
  if (!isConversionFactorUnit(unit)) {
    throw new Error(
      `Unit "${String(unit)}" is not valid in results or conversion factors`,
    );
  }
  return unit;
};
