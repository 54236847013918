import React, { useCallback, useMemo } from 'react';
import {
  IBuildingVersion,
  OneOfElements,
} from '../../../shared/models/project.interface';
import {
  Results,
  ResultsRecord,
  ConversionFactors,
  ConversionFactorGroupKey,
  ConversionFactorUnit,
} from '../../../shared/models/unit.interface';
import {
  getValueFromResultsRecord,
  getElementResults,
  getResultsRecordFromElementResults,
  getProjectResultsRecord,
  getResultsByFactor,
} from '../../../shared/helpers/results.helpers';
import { isElement } from '../../../shared/helpers/recursive_element_helpers';
import { useSelectedVersion } from '../store/ui';
import { useSortedFlattenedElements } from './filter-elements.hook';
import { isMainCategoryElement } from '../../../shared/templates/categories';
import { useAllProposalResultRecords } from './proposals.hook';
import { IProposal } from '../../../shared/models/proposals.interface';
import { ItemOrItemId } from '../../../shared/models/type_helpers.interface';
import { required } from '../../../shared/helpers/function_helpers';
import { useElementById, useVersions } from './useElement';
import {
  getProject,
  useProject,
  useProjectBuildingGFA,
} from '../store/project';
import { getMaxValuesInArray } from '../../../shared/helpers/math_helpers';
import {
  getCo2eChartDataFromResults,
  getCostChartDataFromResults,
} from '../components/charts/bar-chart.helpers';
import { ISimpleBarChartValue } from '../components/charts/SimpleBarChart';
import { useSelectedLifecycles } from '../components/SelectLifecycles';
import { filterResultsByLifecycles } from '../../../shared/helpers/lifecycles.helpers';
import { mapObject } from '../../../shared/helpers/object_helpers';

/**
 * Get latest results for each element in the entire project.
 */
export function useProjectResultsRecord(): ResultsRecord {
  const project = useProject();
  const lifecycleFilter = useFilterResultsRecordBySelectedLifecycles();
  return useMemo(
    () => lifecycleFilter(getProjectResultsRecord(project)),
    [project, lifecycleFilter],
  );
}

/**
 * Get latest conversion factors totals for each element for a specific version.
 * @param version The version to get the conversion factors for, if not provided, the selectedVersion is used.
 */
export function useVersionResultRecord(
  version?: IBuildingVersion,
): ResultsRecord {
  const selectedVersion = useSelectedVersion();
  const lifecycleFilter = useFilterResultsRecordBySelectedLifecycles();
  if (!version) {
    version = selectedVersion;
  }

  return React.useMemo(() => {
    if (!version) {
      return {};
    }
    return lifecycleFilter(
      getResultsRecordFromElementResults(getProject(), version),
    );
  }, [version, lifecycleFilter]);
}

/**
 * Get function that calculates results per GFA
 * @returns
 */
export const useGetResultsPerGFA = () => {
  const selectedProjectGFA = useProjectBuildingGFA();

  return useCallback(
    <T extends Results | ConversionFactors | number>(
      results: T | undefined,
      gfa = selectedProjectGFA,
    ): T => getResultsByFactor(results, gfa),
    [selectedProjectGFA],
  );
};

/**
 * Get results per GFA
 * @param results
 * @param gfa - optional, if not provided, the selected project GFA is used
 * @returns
 */
export const useResultsPerGFA = <
  T extends Results | ConversionFactors | number,
>(
  results: T,
  gfa?: number,
): T => {
  const getResultsPerGFA = useGetResultsPerGFA();
  return useMemo(() => {
    return getResultsPerGFA(results, gfa);
  }, [results, getResultsPerGFA, gfa]);
};

export const useCo2eChartData = (results: Results): ISimpleBarChartValue[] => {
  return useMemo(() => getCo2eChartDataFromResults(results), [results]);
};

export const useCostChartData = (results: Results): ISimpleBarChartValue[] => {
  return useMemo(() => getCostChartDataFromResults(results), [results]);
};

/**
 * Get the largest results in version
 */
export const useElementMaxResults = (): Results => {
  const elements = useSortedFlattenedElements();
  const resultRecord = useVersionResultRecord();
  const proposalResultRecords = useAllProposalResultRecords();
  const resultRecords = useMemo(
    () => [resultRecord, ...Object.values(proposalResultRecords)],
    [proposalResultRecords, resultRecord],
  );

  return useMemo(() => {
    const resultArray: Results[] = elements
      .filter((e) => !isMainCategoryElement(e)) // No main category elements
      .filter((e) => !isElement(e) || (!e.isDeactivated && !e.isHidden)) // Filter out deactivated and hidden elements
      .flatMap((el) => resultRecords.map((r) => r?.[el.id] ?? {}));

    return getMaxValuesInArray(resultArray);
  }, [elements, resultRecords]);
};

/**
 * Get function that filters results record by selected lifecycles
 * @param results
 * @returns
 */
export const useFilterResultsRecordBySelectedLifecycles = (): ((
  results: ResultsRecord,
) => ResultsRecord) => {
  const filter = useFilterResultsBySelectedLifecycles();

  return useCallback(
    (results: ResultsRecord) => mapObject(results, filter),
    [filter],
  );
};

/**
 * Get function that filters results by selected lifecycles
 * @param results
 * @returns
 */
export const useFilterResultsBySelectedLifecycles = (): ((
  results: Results,
) => Results) => {
  const selectedLifecycles = useSelectedLifecycles();
  return useCallback(
    (results: Results) => {
      return filterResultsByLifecycles(results, selectedLifecycles);
    },
    [selectedLifecycles],
  );
};

/**
 * Filter results by selected lifecycles
 * @param results
 * @returns
 */
export const useFilteredResultsBySelectedLifecycles = (
  results: Results | undefined,
) => {
  const filter = useFilterResultsBySelectedLifecycles();
  return useMemo(() => filter(results ?? {}), [filter, results]);
};

/**
 * Use results for a specific elements
 * @param element
 * @param inProposal
 * @returns
 */
export const useElementResults = (
  elementOrId: ItemOrItemId<OneOfElements> | undefined,
  inProposal?: ItemOrItemId<IProposal>,
): Results => {
  const version = required(useSelectedVersion());
  const element = useElementById(elementOrId);
  const lifecycleFilter = useFilterResultsBySelectedLifecycles();

  return useMemo(
    () =>
      lifecycleFilter(
        getElementResults(getProject(), version, element, inProposal),
      ),
    [version, element, inProposal, lifecycleFilter],
  );
};

export const useProjectVersionsMax = (
  unit: ConversionFactorUnit | ConversionFactorGroupKey = 'co2e',
): number => {
  const versions = useVersions();
  const projectsTotals = useProjectResultsRecord();
  return useMemo(() => {
    const versionFactors = versions.map((version) =>
      getValueFromResultsRecord(projectsTotals, version.id, unit),
    );
    return Math.max(...versionFactors, 0);
  }, [projectsTotals, unit, versions]);
};
