import {
  ElementCategoryID,
  IElementCategory,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyType,
  IFactorySelectProperty,
} from '../../../models/element_property.interface';
import {
  DEFAULT_QUANTITY_EXPRESSIONS,
  DEFAULT_QUANTITY_PROPERTIES,
} from '../../../models/element_quantities.interface';
import {
  createProductTreeProcessor,
  getAvailableProductIdsFromTree,
} from '../processor';
import genericProducts, {
  genericProductsLookup,
} from '../../../generic_products';
import { ceramicsProductTree } from '../ceramics/ceramics.model';
import { gypsumProductTree } from '../gypsum/gypsum.model';
import { insulationProductTree } from '../insulation/insulation.model';
import { metalProductTree } from '../metal/metal.model';
import { windowsDoorsProductTree } from '../windows_doors/windows_doors.model';
import { woodProductTree } from '../wood/wood.model';
import {
  concreteQualityRecord,
  concreteTypeRecord,
} from '../concrete/concrete.model';

import { sortBy } from 'lodash';
import { energyProducts } from '../energy';
import { OTHER_PRODUCT_ID } from '../../../constants';
import { omit } from '../../../helpers/object_helpers';
import { reusedContentProductId } from '../processor.model';
import { ProductSources } from '../../../models/product.interface';

export const otherNodonProduct = genericProductsLookup[OTHER_PRODUCT_ID];

export const OTHER_PRODUCTS_SELECT_PROPERTY_NAME = 'products';

const categoryProductIds =
  // getAvailableProductIdsFromTree is initially undefined when spinning up the server (npm run dev)
  getAvailableProductIdsFromTree
    ? [
        ...getAvailableProductIdsFromTree({
          concreteQualityRecord,
          concreteTypeRecord,
        }),
        ...getAvailableProductIdsFromTree(ceramicsProductTree),
        ...getAvailableProductIdsFromTree(gypsumProductTree),
        ...getAvailableProductIdsFromTree(insulationProductTree),
        ...getAvailableProductIdsFromTree(metalProductTree),
        ...getAvailableProductIdsFromTree(windowsDoorsProductTree),
        ...getAvailableProductIdsFromTree(woodProductTree),
        ...energyProducts.map(({ id }) => id),
      ]
    : [];

// find Boverket products that are not in other categories or climate improved
const filteredBoverketProducts = genericProducts.filter(
  ({ source, id, name }) => {
    const nameWithNoSpacesOrSpecialChars = name
      .replace(/\s+/g, '')
      .replace(/[^\w\s]/gi, '');

    return (
      source === ProductSources.Boverket &&
      id !== reusedContentProductId &&
      !categoryProductIds.includes(id) &&
      !nameWithNoSpacesOrSpecialChars.includes('climateimproved')
    );
  },
);

const sorted = sortBy(filteredBoverketProducts, 'name');

const otherProducts = otherNodonProduct
  ? [otherNodonProduct, ...sorted]
  : sorted;

const selectProperty: IFactorySelectProperty = {
  name: OTHER_PRODUCTS_SELECT_PROPERTY_NAME,
  type: ElementPropertyType.Select,
  count: otherProducts[0]?.name,
  options: otherProducts.map(({ name }) => name),
};

const processor = createProductTreeProcessor({
  levelProperties: [selectProperty],
  productTree: otherProducts.reduce(
    (acc, { id, name }) => ({ ...acc, [name]: id }),
    {},
  ),
});

export const otherProductCategory: IElementCategory = {
  ...processor,
  id: ElementCategoryID.OtherProduct,
  defaultSelectedQuantity: 'mass',
  name: 'Other product',
  getQuantityProperties: (element) => {
    const productElements = element && processor.getChildElements(element);
    const productEl = productElements?.[0];

    const product = productEl
      ? genericProducts.find(
          (p) =>
            p.id === productEl.product_id ||
            p.id === productEl.generic_product_id,
        )
      : undefined;

    return product?.conversion_factors['m²']
      ? {
          ...omit(DEFAULT_QUANTITY_PROPERTIES, 'density'),
          density_areal_side: {
            fallbackCount: DEFAULT_QUANTITY_EXPRESSIONS.density_areal_side,
          },
        }
      : DEFAULT_QUANTITY_PROPERTIES;
  },
};
