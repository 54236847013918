import type {
  ElementKindMap,
  IBuildingVersion,
  IElement,
  IProductElement,
  OneOfChildElements,
  OneOfElements,
  OneOfListElements,
} from '../models/project.interface';
import { IProduct, ProductRecord } from '../models/product.interface';
import { ElementCategoryID } from '../models/element_categories.interface';
import {
  getElementAndQuantityProperties,
  hasCount,
  isElementPropertyListEqual,
} from './element_property_helpers';
import { isEqualExpressionValues } from './expression_solving_helpers';
import {
  flattenElements,
  getChildElementsOfVersions,
  getElementById,
  getElementKind,
  isElement,
  isProductListCategoryGroup,
  isProductElement,
  isProductListGroup,
  isProductListItem,
  OneOfSearchableElements,
  hasChildren,
  isOneOfChildElements,
} from './recursive_element_helpers';
import { ElementPropertySource } from '../models/element_property.interface';
import { getElementCategory } from './element_category_helpers';
import { isNameMatch, makeSentence } from './string_helpers';
import {
  getElementCategoryById,
  getMainCategory,
} from '../templates/categories';
import { getSelectableUnitsInConversionFactors } from './unit_helpers';
import { SelectableQuantityUnit } from '../models/unit.interface';
import {
  isActiveElementVersion,
  isElementVersionElement,
  isInactiveElementVersion,
} from './element-version.helpers';
import { genericProductsLookup } from '../generic_products';
import { isDefined, isOneOf } from './array_helpers';
import { Replace } from '../models/type_helpers.interface';
import { reusedContentProductId } from '../templates/categories/processor.model';
import { isProjectFolder } from './project-folder.helpers';
import { OneOfFactoryElements } from '../models/factory-element.interface';

/**
 * Get element names as a sentence
 * @example getElementNames(version, 'element1', 'element2') => 'element1 and element2'
 * @param version
 * @param elementsOrIds
 * @returns
 */
export const getElementNames = (
  version: IBuildingVersion,
  ...elementsOrIds: (OneOfElements | string)[]
): string => {
  const productLookup = version.products;
  const elements = elementsOrIds.map((elementOrId) => {
    if (typeof elementOrId === 'string') {
      return getElementById(version, elementOrId);
    }
    return elementOrId;
  });

  const isElementVersions = elements.every(isElementVersionElement);

  // When removing all element versions, just show the element name of the active element version (the element in the list)
  if (isElementVersions && elements.length >= 2) {
    return getElementName(elements.find(isActiveElementVersion), productLookup);
  }

  const names: string[] = elements.map((e) => getElementName(e, productLookup));

  return makeSentence(...names);
};

/**
 * Get name of an element. If the element is a productElement, the name of the product is returned.
 * @param element
 * @param productLookup Typically version.products
 * @param defaultName If no name exist, use this
 * @returns
 */
export const getElementName = <
  T extends OneOfListElements | OneOfFactoryElements,
>(
  element: T | undefined,
  productLookup: ProductRecord = genericProductsLookup,
  defaultName = 'Unknown',
): string => {
  if (!element) {
    return defaultName;
  }

  if (isProductElement(element) || isProductListItem(element)) {
    return productLookup[element.product_id]?.name ?? defaultName;
  }
  if (isProductListCategoryGroup(element)) {
    return getElementCategoryById(element.id).name;
  }
  if (isProductListGroup(element)) {
    const productId = element.product_id ?? element.id;
    return productLookup[productId]?.name ?? defaultName;
  }
  if (isElement(element)) {
    const mainCategory = getMainCategory(element.category_id);

    return mainCategory
      ? mainCategory.name
      : ((element.name || element.fallbackName) ?? defaultName);
  }
  return 'name' in element && element.name ? element.name : defaultName;
};

/**
 * If the element is generated by the element category and should not be editable
 * @param element
 * @returns
 */
export const isGenerated = (element: OneOfListElements): boolean =>
  isOneOfChildElements(element) && !!element.generated;

/**
 * If the element is generated by the element category and should not be editable.
 * Note: Older fn, use isGenerated instead if you not specifically need to check for product elements
 * @param element
 * @returns
 */
export const isGeneratedProductElement = (
  element: OneOfListElements | undefined,
): element is Replace<IProductElement, { generated: true }> =>
  isProductElement(element) && !!element.generated;

/**
 *
 * @param element
 * @returns
 */
export const shouldHideProductElement = (
  element: OneOfListElements | undefined,
): boolean => {
  // TODO: Use isGeneratedProductElement when migration has been done on isGeneratedProductElement
  if (isProductElement(element)) {
    const { count, hide_product_without_count, product_id } = element;

    if (
      hide_product_without_count ??
      // 2024-11-15: Older reused content elements didn't have hideProductWithoutCount so make sure to hide reussed anyways
      product_id === reusedContentProductId
    ) {
      return !count.resolved;
    }
  }
  return false;
};

export const isMaintenanceElement = (
  element: OneOfListElements | undefined,
): element is Replace<
  IElement,
  { category_id: ElementCategoryID.Maintenance }
> =>
  isElement(element) && element.category_id === ElementCategoryID.Maintenance;

/**
 * If the children is controlled by the element category and should not be editable
 * @param element
 * @returns
 */
export const isElementWithGeneratedChildren = (
  element: OneOfListElements | undefined,
): boolean => !!getElementCategory(element)?.getChildElements;

export const getElementCategoryId = (
  element: OneOfElements | OneOfFactoryElements | undefined,
): ElementCategoryID | undefined =>
  element && ElementPropertySource.Category in element
    ? element[ElementPropertySource.Category]
    : undefined;

export const isEqualElements = (
  a: OneOfChildElements,
  b: OneOfChildElements,
): boolean => {
  if (a?.kind !== b?.kind) {
    return false;
  }

  // ProductElement contains all information needed
  if (isProductElement(a) && isProductElement(b)) {
    return (
      isEqualExpressionValues(a.count, b.count) &&
      a.product_id === b.product_id &&
      a.unit === b.unit &&
      a.generic_product_id === b.generic_product_id
    );
  }
  if (isElement(a) && isElement(b)) {
    if (
      a.selectedQuantity !== b.selectedQuantity ||
      a.name !== b.name ||
      a.category_id !== b.category_id ||
      a.elements?.length !== b.elements?.length ||
      a.isDeactivated !== b.isDeactivated
    ) {
      return false;
    }

    const propsA = getElementAndQuantityProperties(a).filter(hasCount);
    const propsB = getElementAndQuantityProperties(b).filter(hasCount);

    if (!isElementPropertyListEqual(propsA, propsB)) {
      return false;
    }

    const childrenA = a.elements.filter(shouldCompareGeneratedProductElement);
    const childrenB = b.elements.filter(shouldCompareGeneratedProductElement);

    // Handle deeper structures
    for (let i = 0; i < childrenA.length; i++) {
      const childA = childrenA[i];
      const childB = childrenB[i];

      if (!childA || !childB || !isEqualElements(childA, childB)) {
        return false;
      }
    }

    return true;
  }
  return false;
};

const shouldCompareGeneratedProductElement = (element: OneOfChildElements) =>
  !isGeneratedProductElement(element) || 'generic_product_id' in element;

/**
 * Test if the element is deactivated. Hidden elements and inactive element versions are also considered deactivated
 * @param element
 * @param countInactiveVersionsAsDeactivated If inactive element versions should be considered deactivated
 * @returns
 */
export const isDeactivated = (
  element: OneOfListElements | undefined,
  countInactiveVersionsAsDeactivated = true,
): boolean => {
  if (isElement(element)) {
    if (element.isDeactivated === true) {
      return true;
    }
    // If the element is an inactive element version, it should be considered deactivated if the flag is set
    if (
      countInactiveVersionsAsDeactivated &&
      isInactiveElementVersion(element)
    ) {
      return true;
    }
  }

  return false;
};

/**
 * If the element is invisible to the user
 * @param element
 * @returns
 */
export const isHidden = (element: OneOfElements | undefined): boolean =>
  isElement(element) && !!element.isHidden;

export const elementHasChild = (element: IElement): boolean =>
  element.elements?.length > 0;

export const replaceProductInElement = (
  element: IProductElement,
  newProduct: IProduct,
): IProductElement => {
  // If the element is generated, it should keep a reference to the generic/Boverket product
  const generic_product_id =
    element.generated && element.generic_product_id !== newProduct.id
      ? (element.generic_product_id ?? element.product_id)
      : undefined;

  // Never used since generated is always true now
  const unitIsUnchanged = getSelectableUnitsInConversionFactors(
    newProduct.conversion_factors,
  ).includes(element.unit as SelectableQuantityUnit);

  const keepSelectedUnit = element.generated || unitIsUnchanged;
  const unit = keepSelectedUnit ? element.unit : newProduct.unit;

  return {
    ...element,
    product_id: newProduct.id,
    generic_product_id,
    unit,
  };
};

export const getRootElements = (version: IBuildingVersion): IElement[] => {
  return getChildElementsOfVersions(version).map((element) => {
    if (element.category_id && !element.fallbackName) {
      const category = getElementCategoryById(element.category_id);
      return { ...element, fallbackName: category.name };
    }
    return element;
  });
};

/**
 * Require the element to be of a specific kind (Element, Version, Product)
 * @param element
 * @param kinds Pass in multiple kinds to allow for multiple kinds
 * @returns
 */
export const requiredKind = <K extends keyof ElementKindMap>(
  element: unknown,
  ...kinds: K[]
): ElementKindMap[K] => {
  const elementKind = getElementKind(element);

  if (!kinds.length) {
    throw new Error('No kinds provided');
  }

  if (!isOneOf(kinds, elementKind)) {
    throw new Error(
      `Element kind mismatch. Expected ${kinds.join(' or ')} but got ${elementKind}`,
    );
  }
  return element as ElementKindMap[K];
};

/**
 * Get all ids in element including children and their properties/quantities
 * @param searchIn - The root element to search in
 * @returns - An array of all ids
 */
export const getElementAndPropertyIds = (
  searchIn: OneOfSearchableElements,
): string[] =>
  flattenElements(searchIn)
    .flatMap((el) => [
      el.id,
      // getElementVersionId(el),
      ...getElementAndQuantityProperties(el).map((p) => p.id),
    ])
    .filter(isDefined);

/**
 * Use this to also search by name (and other properties)
 * @param element
 * @param compareTo
 */
export const isElementMatch = <T extends OneOfElements>(
  element: T,
  idOrName: string | undefined | number,
): boolean => {
  if (idOrName === undefined) {
    return false;
  }
  if (element.id === idOrName) {
    return true;
  }
  return isNameMatch(getElementName(element), idOrName.toString());
};

/**
 * Return a function that can be used to check if an element matches a static id or name
 * @param idOrName
 * @returns
 */
export const getIsElementMatchFn =
  <T extends OneOfElements>(idOrName: string | undefined | number) =>
  (element: T) =>
    isElementMatch(element, idOrName);

/**
 * If the element can be expanded in ElementList or not
 * @param element
 */
export const isExpandableElement = (element: OneOfListElements): boolean =>
  (isElement(element) || isProjectFolder(element)) && hasChildren(element);
