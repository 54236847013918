import { hasTruthyProperties } from '../helpers/object_helpers';
import {
  getProductIdsInRecipes,
  isNodonRecipeID,
} from '../helpers/recipe_helpers';
import { flattenElements } from '../helpers/recursive_element_helpers';
import {
  IProduct,
  ProductID,
  ProductRecord,
} from '../models/product.interface';
import { Recipe } from '../models/recipe.interface';
import { throwValidationErrors, ValidationTypes } from './validation.helpers';
import { isValidElements } from './project.validation';
import { Project } from '../models/project.interface';
import { TMP_PROJECT_ID } from '../constants';
import {
  getProjectProductsRecord,
  toProductIds,
} from '../helpers/product_helpers';
import { genericProductsLookup } from '../generic_products';
import {
  isElementSelectProperty,
  isElementSwitchProperty,
} from '../helpers/element_property_helpers';
import { getElementCategoryById } from '../templates/categories';

export const validateRecipe = (
  recipe: Recipe,
  productRecord?: ProductRecord,
  onError?: (error: string) => void,
): Recipe => {
  throwValidationErrors(
    isValidRecipe(recipe, productRecord),
    'RecipeValidationError',
    onError,
  );
  return recipe;
};

// TODO: Make sure that ALL products are available when validating recipes
export const isValidRecipe = (
  recipe: Recipe,
  productRecord:
    | ProductRecord
    | IProduct[]
    | ProductID[] = genericProductsLookup,
): ValidationTypes => {
  const { category_id, properties, id } = recipe;

  if (!getElementCategoryById(category_id, false)) {
    return `Recipe refers to missing category: "${category_id}"`;
  }
  if (!properties) {
    return 'Recipe properties are missing';
  }
  if (!recipe.elements) {
    return 'Recipe elements are missing';
  }
  if (isNodonRecipeID(id)) {
    return `Cannot do changes to a Nodon Recipe`;
  }
  if (properties.some(isElementSelectProperty)) {
    return `Can't save a recipe with a select property`;
  }
  if (properties.some(isElementSwitchProperty)) {
    return `Can't save a recipe with a switch property`;
  }

  // Make sure old recipe format is not allowed to be saved
  if (!Array.isArray(recipe.elements)) {
    return 'Elements must be an array';
  }
  if (!hasTruthyProperties(recipe, 'id', 'name')) {
    return 'Elements is missing name or id';
  }
  const prodIdsInRecipe = getProductIdsInRecipes(recipe);
  const availableProdIds = toProductIds(productRecord);
  const missing = prodIdsInRecipe.filter(
    (id) => !availableProdIds.includes(id),
  );

  if (missing.length > 0) {
    return `Missing product ids in recipe "${recipe.name}. Have you provided all products available in organization "${recipe.organizations.join(', ')}"?`;
  }
  const elements = flattenElements(...recipe.elements);

  // TODO: Check that recipe doesn't have references to recipes in other organizations (other than template organization)

  if (!elements.length) {
    return `Recipe "${recipe.name}" has no elements`;
  }

  // Check that all elements in the recipe are valid
  const validElements = isValidElements(elements);
  if (validElements !== true) {
    return validElements;
  }

  return true;
};

export const validateRecipeProducts = (
  project: Project,
  usedRecipes: Recipe[],
): Project => {
  throwValidationErrors(isValidRecipeProducts(project, usedRecipes));
  return project;
};

/**
 * Make sure all products used in recipes are defined in the project.
 * @param project
 * @param usedRecipes
 * @returns
 */
export const isValidRecipeProducts = (
  project: Project,
  usedRecipes: Recipe[],
): ValidationTypes => {
  const recipeProductIds = getProductIdsInRecipes(...usedRecipes);
  const productRecord = getProjectProductsRecord(project);
  const missing = recipeProductIds.filter((id) => !productRecord[id]);

  // Don't check for missing products in tmp project (used on import/copy+paste)
  if (project.owner !== TMP_PROJECT_ID && missing.length > 0) {
    return `Missing product ids in recipe ${missing.join(', ')}`;
  }
  return true;
};
