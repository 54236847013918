import {
  Results,
  ResultsRecord,
  ConversionFactors,
  ProductOrConversionFactors,
  QuantityUnit,
  ConversionFactorGroupKey,
  ConversionFactorUnit,
} from '../models/unit.interface';
import {
  ElementKind,
  IBuildingVersion,
  IElement,
  IElementID,
  IProductElement,
  OneOfElements,
  Project,
  ProjectMetadata,
} from '../models/project.interface';
import {
  ElementPath,
  filterElements,
  flattenElements,
  forEachElement,
  getAllBuildingVersions,
  getChildElements,
  isElement,
  isParentElement,
  isProductElement,
} from './recursive_element_helpers';
import { IProduct } from '../models/product.interface';
import {
  convertConversionFactors,
  multiplyConversionFactors,
} from './conversion_helpers';
import { cloneDeep, mapValues } from 'lodash';
import { cacheFactory, required } from './function_helpers';
import { IRequiredStoreyWithElevation } from './storeys_helpers';
import { CALCULATIONS } from '../../client/src/calculations/calculations.constants';
import { isNumberObjectsCloseTo, roundToDecimals } from './math_helpers';
import { MainCategoryVariables } from './expression_variables_helpers';
import { ElementPropertyName } from '../models/element_property.interface';
import { inputStringToNumber } from './string_helpers';
import { isDeactivated, requiredKind } from './element_helpers';
import {
  Co2eInputUnits,
  compressConversionFactors,
  getConversionFactorValue,
  mergeConversionFactors,
  sumConversionFactors,
  SupportedConversionUnits,
} from './conversion-factors.helpers';
import { getBuildingWidth } from './coordinates.helpers';
import { ItemOrItemId } from '../models/type_helpers.interface';
import { IProposal } from '../models/proposals.interface';
import {
  getActiveProposal,
  getElementsActiveInProposal,
  getProposalById,
} from './proposal.helpers';
import { getId, omit, omitUndefined } from './object_helpers';
import {
  getElementVersionId,
  isActiveElementVersion,
} from './element-version.helpers';
import { getCount, getResolvedCountInPath } from './element_property_helpers';
import { getBuildingLifetime } from './project_helpers';
import { getElementCategory } from './element_category_helpers';
import { IElementCategoryMapResultsContext } from '../models/element_categories.interface';
import { ProductRecordOwners } from './product_helpers';
import { getProductElementConversionFactors } from './product-element.helpers';

type MapResultsContext = Omit<
  IElementCategoryMapResultsContext,
  'categoryElement'
>;

const isProductConversionFactors = (
  product?: ProductOrConversionFactors,
): product is Pick<IProduct, 'conversion_factors'> =>
  !!product && 'conversion_factors' in product;

/**
 * Get results and fallback to empty results object if not found
 * @param record
 * @param id
 * @returns
 */
export const getResultsById = (
  record: ResultsRecord,
  id: string | undefined,
): Results => (id && record?.[id]) || {};

/**
 * Get a specific quantity from a ConversionFactorsRecord and fallback to 0 if not found
 * @param record
 * @param id
 * @param unit
 * @returns
 */
export const getValueFromResultsRecord = (
  record: ResultsRecord,
  id: IElementID | undefined,
  ...units: (ConversionFactorUnit | ConversionFactorGroupKey)[]
): number => {
  return getConversionFactorValue(getResultsById(record, id), ...units);
};

/**
 * Get conversion factors from a product or a conversion factors object
 * @param product
 * @returns
 */
export const getConversionFactors = (
  productOrFactors?: ProductOrConversionFactors,
): Results => {
  // If no product or conversion factors are provided, return empty conversion factors
  if (!productOrFactors) {
    return {};
  }

  return isProductConversionFactors(productOrFactors)
    ? productOrFactors.conversion_factors
    : productOrFactors;
};

/**
 * Get the relative conversion factors for a product.
 * Need to be multiplied with how many units of the product is used to get Results.
 * @param version Version should not be used when when calc
 * @param product
 * @returns
 */
export function getProductConversionFactors(
  product: IProduct | undefined,
  perUnit?: SupportedConversionUnits,
): Results | Partial<Results> {
  // TODO: This happens but should it be allowed?
  if (!product) {
    return {};
  }

  const conversionFactors = getConversionFactors(product);

  // Change the scale of conversion factors relative to another unit
  if (perUnit && perUnit !== product.unit) {
    return convertConversionFactors(conversionFactors, perUnit);
  }

  return conversionFactors;
}

/**
 * Get the combined conversion factors for an epd with a generic product as base (both optional).
 * @param epd
 * @param genericProduct
 * @param perUnit
 * @returns
 */
export const getCombinedConversionFactors = (
  epd: IProduct | undefined,
  genericProduct?: IProduct,
  perUnit?: SupportedConversionUnits,
) => {
  const epdFactors = getProductConversionFactors(epd);
  const genericFactors = getProductConversionFactors(genericProduct);

  return convertConversionFactors(
    mergeConversionFactors(genericFactors, epdFactors),
    perUnit,
  );
};

/** Value used by SCB, calculating 40km on a smaller truck. */
const LOCAL_TRUCK_EMISSION = 0.0001125;
const LONG_WAY_TRUCK_EMISSION = 0.000075;
const LOCAL_TRANSPORT_KM = 40;
const SMALL_TRUCK_EMISSION = 40 * LOCAL_TRUCK_EMISSION;

/**
 * Get A4 in KM for a product
 * @param product
 * @returns
 */
export const getProductTransportValue = (
  productOrConversionFactors: ProductOrConversionFactors,
): number => {
  const { co2e_A4, kg } = getConversionFactors(productOrConversionFactors);

  // No transport value if we can't translate unit to kg
  return kg ? a4ToKm(co2e_A4, kg) : 0;
};

/**
 * Get a factor between 0 and 1 that represents the amount of waste generated by the product.
 * A5 = (A1toA3 + A4) * waste_factor
 * @param product
 * @returns
 */
export const getProductWasteFactor = (factors: ConversionFactors): number => {
  const {
    'co2e_A1-A3': a1_a3 = 0,
    co2e_A4: a4 = 0,
    co2e_A5: a5 = 0,
  } = getConversionFactors(factors);
  const denominator = a1_a3 + a4;

  // Avoid division by zero (resulting in NaN)
  return denominator !== 0 ? a5 / denominator : 0;
};

/**
 * Convert a user input value to a conversion factor value.
 * Handles the cases for A4 and A5 in which the user input km or a percentage.
 * @param inputValue
 * @param factors
 * @param factor
 * @returns
 */
export const inputToConversionFactorValue = (
  inputValue: string | number | undefined | null,
  factors: ConversionFactors,
  factor: QuantityUnit,
  fallbacks?: ConversionFactors,
): number => {
  if (!inputValue) {
    return 0;
  }

  switch (factor) {
    case 'co2e_A4':
      return kmToA4(Number(inputValue), getConversionFactors(factors).kg ?? 0);
    case 'co2e_A5':
      return getA5FromWasteFactorPercentage(factors, inputValue, fallbacks);
    default:
      return inputStringToNumber(inputValue);
  }
};

/**
 * Get conversion factors from user input values.
 * co2e_A4 in km is converted to a kgCO2e/[unit] value
 * co2e_A5 in a percentage is converted to a a kgCO2e/[unit] value
 * @param conversion_factors
 * @returns
 */
export const fromTransportWasteConversionFactors = (
  conversion_factors: ConversionFactors,
  fallbacks?: ConversionFactors,
): ConversionFactors => {
  const factors = omitUndefined(conversion_factors);

  const co2e_A4 =
    factors.co2e_A4 !== undefined
      ? inputToConversionFactorValue(factors.co2e_A4, factors, 'co2e_A4')
      : undefined;

  // A4 must be set before A5 since A5 depends on "transport"
  const co2e_A5 =
    conversion_factors.co2e_A5 !== undefined
      ? inputToConversionFactorValue(
          factors.co2e_A5,
          { ...factors, co2e_A4 },
          'co2e_A5',
          fallbacks,
        )
      : undefined;

  return { ...factors, co2e_A4, co2e_A5 };
};

/**
 * Get conversion factors where A4 and A5 are converted to user input values.
 * co2e_A4 is converted to km (transport)
 * co2e_A5 is converted to a percentage (waste)
 * @param conversion_factors
 * @returns
 */
export const toTransportWasteConversionFactors = (
  conversion_factors: ConversionFactors,
): ConversionFactors => {
  const co2e_A4 =
    conversion_factors.co2e_A4 !== undefined
      ? getProductConversionFactorInputValue(conversion_factors, 'co2e_A4')
      : undefined;

  const co2e_A5 =
    conversion_factors.co2e_A5 !== undefined
      ? getProductConversionFactorInputValue(conversion_factors, 'co2e_A5')
      : undefined;

  return { ...conversion_factors, co2e_A4, co2e_A5 };
};

export const getConversionFactorUnit = (
  factor: QuantityUnit | Co2eInputUnits,
  product: IProduct,
): string => {
  switch (factor) {
    case 'co2e_A1-A3':
      return 'kgCO2e';
    case 'co2e_A4':
    case 'co2e_transport':
      return 'km';
    case 'co2e_A5':
    case 'co2e_waste':
    case 'co2e_waste_percent':
      return '%';
    case 'sek_A1-A3':
      return `kr/${product.unit}`;
    default:
      return 'kg';
  }
};

/**
 *
 * @param product
 * @param wasteFactorInput A percentage between 0 and 100 OR 100 and 200;
 * @returns
 */
export const getA5FromWasteFactorPercentage = (
  factors: ConversionFactors,
  wasteFactorInput: number | string,
  fallbacks?: ConversionFactors,
): number => {
  let wasteFactor = inputStringToNumber(wasteFactorInput) / 100;

  // Boverket uses waste factor as 1.12 for 12% waste
  if (wasteFactor > 1) {
    wasteFactor -= 1;
  }

  const { 'co2e_A1-A3': a1_a3, co2e_A4: a4 } = {
    ...fallbacks,
    ...omitUndefined(factors),
  };

  if (wasteFactor < 0 || wasteFactor > 1) {
    console.error('Waste factor must be between 0 and 100%');
  }

  if (a1_a3 && a4) return (a1_a3 + a4) * wasteFactor;
  if (a1_a3) return a1_a3 * wasteFactor;
  if (a4) return a4 * wasteFactor;
  return wasteFactor;
};

/**
 * Get the editable value for a product. For transport (A4) it will be in km, for waste (A5) it will be a factor between 0 and 100%
 * @param product
 * @param factor
 */
export const getProductConversionFactorInputValue = (
  factors: ConversionFactors,
  factor: keyof Results,
): number => {
  const conversionFactors = getConversionFactors(factors);

  if (factor === 'co2e_A4') {
    return getProductTransportValue(conversionFactors);
  }
  if (factor === 'co2e_A5') {
    return roundToDecimals(getProductWasteFactor(conversionFactors) * 100);
  }
  return conversionFactors[factor] ?? 0;
};

/**
 * Get A4 values (in kg co2e / [unit])
 * Page 146: https://www.boverket.se/contentassets/5c704bbb2b2f4bd1a31beecf355efaa4/referensvarden-for-klimatpaverkan-vid-uppforande-av-byggnader_kth-2021.pdf
 */
export const kmToA4 = (km: number, kgPerUnit = 1): number => {
  return (
    (km < LOCAL_TRANSPORT_KM
      ? km * LOCAL_TRUCK_EMISSION
      : SMALL_TRUCK_EMISSION +
        (km - LOCAL_TRANSPORT_KM) * LONG_WAY_TRUCK_EMISSION) * kgPerUnit
  );
};

/** Returns the distance traveled for a given kgCO2e. */
export const a4ToKm = (a4: number | undefined, kgPerUnit = 1): number => {
  if (!a4 || !kgPerUnit) {
    return 0;
  }

  // A4 per other unit like m3
  const a4PerUnit = a4 / kgPerUnit;

  if (a4PerUnit > SMALL_TRUCK_EMISSION) {
    return Math.round(
      LOCAL_TRANSPORT_KM +
        (a4PerUnit - SMALL_TRUCK_EMISSION) / LONG_WAY_TRUCK_EMISSION,
    );
  }
  return Math.round(a4PerUnit / LOCAL_TRUCK_EMISSION);
};

/**
 * Get conversion factors, including global warming potential, for a ProductElement.
 * Note that this values are relative to how much the product is used (parentElement.count).
 * @param version Version to try to grab products from.
 * @param element
 * @param product Calculating a recipe will require the product to be passed in.
 * @returns
 */
const sumProductElementConversionFactors = (
  productRecordOwner: ProductRecordOwners,
  element: IProductElement,
  product?: IProduct,
): Results => {
  const conversionFactors = getProductElementConversionFactors(
    productRecordOwner,
    element,
    product,
  );
  const productCount = getCount(element, true).resolved;

  // Sum is the ConversionFactors * productCount
  return multiplyConversionFactors(conversionFactors, productCount);
};

/**
 * Get all results
 * @param version
 * @returns
 */
export const generateProductElementResultsRecord = (
  project: Project,
  version: IBuildingVersion,
) => {
  // Cache this calculation by version id and version
  return cacheFactory(
    () => {
      const record: ResultsRecord = {};

      forEachElement(version, (element, path) => {
        if (isProductElement(element)) {
          record[element.id] = mapResults(
            generateElementConversionFactorSum(path, element),
            { project, path, productElement: element },
          );
        }
      });

      return record;
    },
    `getProductElementResultsRecord[${version.id}]`,
    [version],
  );
};

/**
 * Add lifetime emissions to B4
 * @param project
 * @param path
 * @param results
 * @returns
 */
const mapResultsByLifetime = (
  results: Results,
  { project, path }: MapResultsContext,
): Results => {
  const lifetime = getResolvedCountInPath(
    path,
    ElementPropertyName.Lifetime,
    0,
  );
  const buildingLifetime = getBuildingLifetime(project);
  if (!lifetime || !buildingLifetime) {
    return results;
  }
  const lifecycles = Math.max(buildingLifetime / lifetime - 1, 0);
  if (lifecycles) {
    return {
      ...results,
      co2e_B4: getConversionFactorValue(results, 'co2e_A') * lifecycles,
      sek_B4: getConversionFactorValue(results, 'sek_A') * lifecycles,
    };
  }
  return results;
};

/**
 * Allow categories to adjust the results before they are summed up
 * @param results
 * @param context
 * @returns
 */
const mapResultsByElementCategories = (
  results: Results,
  context: Omit<IElementCategoryMapResultsContext, 'categoryElement'>,
): Results => {
  // Map results from bottom to top
  return context.path.toReversed().reduce((acc, el) => {
    const mapFn = getElementCategory(el)?.mapResults;
    return mapFn
      ? mapFn(acc, {
          ...context,
          categoryElement: requiredKind(el, ElementKind.Element),
        })
      : acc;
  }, results);
};

/**
 * The mappers are executed in the order they are defined
 */
const RESULT_MAPPERS: ((
  results: Results,
  context: MapResultsContext,
) => Results)[] = [mapResultsByElementCategories, mapResultsByLifetime];

/**
 * Adjust results based on the mappers methods in the order they are defined.
 * @param results
 * @param context
 * @returns
 */
const mapResults = (results: Results, context: MapResultsContext): Results =>
  compressConversionFactors(
    RESULT_MAPPERS.reduce((acc, mapper) => mapper(acc, context), results),
  );

/**
 * Generate a new record of all results for each element in version.
 * Only use for enricher or fallback since it's heavy to calculate.
 *  { [id]: results, ... }
 * @param version
 * @param allowedElements Defaults to all elements in version. But could be a subset of elements to make calculations on proposals etc.
 * @param additionalCacheKey Proposals might have different element lists, provide a key to cache the results separately.
 */
export const generateResultsRecord = (
  project: Project,
  version: IBuildingVersion,
  allowedElements?: OneOfElements[],
  additionalCacheKey: string = '',
): ResultsRecord => {
  // Cache this calculation by version id and version
  return cacheFactory(
    () => {
      const elements = allowedElements ?? flattenElements(version);

      // These are the only elements allowed to be included in the sum
      const elementsIncludedInSum = removeDuplicateElementVersions(elements);

      // Make sure we can edit the record
      const record: ResultsRecord = cloneDeep(
        generateProductElementResultsRecord(project, version),
      );

      // No need to calculate product elements again
      const parentElements = elements.filter(isParentElement);

      // Sum all productElements in each element
      parentElements.forEach((element) => {
        // All children that are active as a flat list (will also remove all children of deactivated elements)
        const children = filterElements(
          getChildElements(element),
          (child) => {
            // Exclude deactivated elements
            if (isDeactivated(child, false)) {
              return false;
            }
            // Always include product elements (as long as their parents are included)
            if (isProductElement(child)) {
              return true;
            }

            // Don't allow two of the same ElementVersion to be included
            // Only include other types of elements if they are provided in the elements array
            return required(elementsIncludedInSum).includes(child);
          },
          false,
        );

        // Element results is the sum of all products in it
        const productFactors = children
          .filter(isProductElement)
          .map((p) => record[p.id]);

        record[element.id] = sumConversionFactors(...productFactors);
      });

      return record;
    },
    `generateResultsRecord[${version.id}][${additionalCacheKey}]`,
    [version],
  );
};

/**
 * Make sure two versions of the same element are not included the same time
 * since that would make calculations wrong
 * @param elements
 * @returns
 */
const removeDuplicateElementVersions = (elements: OneOfElements[]) => {
  return elements.filter((el) => {
    const versionId = getElementVersionId(el);

    // Always include activeVersion OR element without version
    if (!versionId || isActiveElementVersion(el)) {
      return true;
    }

    const versions = elements.filter(
      (e) => getElementVersionId(e) === versionId,
    );

    // Include if only one version
    if (versions.length === 1) {
      return true;
    }

    // Exclude if another version is active
    if (versions.some(isActiveElementVersion)) {
      return false;
    }

    // Else just include the first one
    return versions[0] === el;
  });
};

/**
 * Generate a record of all results for each element in version.
 * Only use for enricher or fallback since it's heavy to calculate.
 *  { [id]: results, ... }
 * @param version
 */
export const generateVersionResultsRecord = (
  project: Project,
  version: IBuildingVersion,
): ResultsRecord => {
  // No proposals, return record of all elements
  if (!version.proposals?.length) {
    return generateResultsRecord(project, version);
  }

  // Cache the rest to get same result object for same version
  return cacheFactory(
    () => {
      const activeProposal = required(getActiveProposal(version));
      const versionResultsRecord = generateResultsRecord(project, version);
      const proposalResultsRecord =
        activeProposal.resultsRecord ??
        generateProposalResultsRecord(project, version, activeProposal);

      return {
        ...versionResultsRecord,
        ...proposalResultsRecord,
      };
    },
    `generateVersionResultsRecord[${version.id}]`,
    [version],
  );
};

/**
 * Generate a record of all results for each element in proposal.
 * Only use for enricher or fallback since it's heavy to calculate.
 * @param version
 * @param proposalOrId
 * @returns
 */
export const generateProposalResultsRecord = (
  project: Project,
  version: IBuildingVersion,
  proposalOrId: ItemOrItemId<IProposal>,
): ResultsRecord => {
  const proposalId = getId(proposalOrId);
  return cacheFactory(
    () => {
      const elements = getElementsActiveInProposal(version, proposalId);
      return {
        ...generateResultsRecord(project, version), // Use version record as base
        ...generateResultsRecord(project, version, elements, proposalId), // Override with proposal specific results
      };
    },
    `generateProposalResultsRecord[${version.id}][${proposalId}]`,
    [version, proposalId],
  );
};

/**
 * Use already calculated conversion factors from elements if they exist, else calculate them.
 * @param version
 * @returns
 */
export const getResultsRecordFromElementResults = (
  project: Project,
  version: IBuildingVersion,
): ResultsRecord => {
  return cacheFactory(
    () => {
      if (version.results) {
        return flattenElements(version).reduce((acc, el) => {
          if (!el.results) {
            throw new Error('Element results missing');
          }
          acc[el.id] = el.results;
          return acc;
        }, {} as ResultsRecord);
      }
      // Older versions don't have results, so we calculate them
      return generateVersionResultsRecord(project, version);
    },
    `getResultsRecordFromElementResults[${version.id}]`,
    [version],
  );
};

export const getProjectResultsRecord = (project: Project): ResultsRecord => {
  return cacheFactory(
    () => {
      const versions = getAllBuildingVersions(project);
      const factors = versions.map((version) =>
        getResultsRecordFromElementResults(project, version),
      );
      return factors.reduce((acc, curr) => ({ ...acc, ...curr }), {});
    },
    `getProjectResultsRecord[${project.id}]`,
    [project],
  );
};

/**
 * Get a record of all results for each element in a proposal.
 * @param element
 * @param inProposal
 * @returns
 */
export const getResultsRecord = (
  project: Project,
  version: IBuildingVersion,
  inProposal?: ItemOrItemId<IProposal>,
): ResultsRecord => {
  // If no proposal is provided, the active proposal record should be the same as the version record
  inProposal = inProposal ?? getActiveProposal(version);

  // Get from proposal if provided
  if (inProposal) {
    const proposal = getProposalById(version, inProposal);
    const record = proposal.resultsRecord ?? {};

    // Some duplicated proposal have an old resultsRecord so check that this version is in the proposal before using it
    return record[version.id]
      ? record
      : generateProposalResultsRecord(project, version, proposal);
  }

  // Iterate over all elements in version to create a record
  return getResultsRecordFromElementResults(project, version);
};

/**
 * Get results for a specific element.
 * Won't recalculate the results if they already exist.
 * @param version
 * @param element
 * @param inProposal Pass the element co2, cost when used in a certain proposal
 * @returns
 */
export const getElementResults = (
  project: Project,
  version: IBuildingVersion,
  element: OneOfElements | undefined,
  inProposal?: ItemOrItemId<IProposal>,
) =>
  (element && getResultsRecord(project, version, inProposal)[element.id]) ?? {};

/**
 * Get latest conversion factors totals for a specific element
 * IMPORTANT: Only use this if you need to recalculate the conversion factors for a specific element only.
 * All other cases use getResultsRecord above
 * @param version
 * @returns
 */
const sumElementConversionFactors = (
  version: IBuildingVersion | ElementPath,
  element: IElement,
): Results => {
  // We use this to calculate possible recipes so cache it by element id + recipe id
  return cacheFactory(
    (): Results => {
      if (isDeactivated(element)) {
        return {};
      }
      const factors: Results[] = [];

      forEachElement(element, (el, path) => {
        // Only sum product elements with no inactive parents
        if (isProductElement(el) && !path.some((e) => isDeactivated(e))) {
          factors.push(sumProductElementConversionFactors(version, el));
        }
      });

      return sumConversionFactors(...factors);
    },
    `sumElementConversionFactors[${element.id}, ${element.recipe_id}]`,
    [element], // Only element is needed to cache this correctly
    false, // TODO: Make sure caching is GOOOOD
  );
};

/**
 * Get summary of conversion factors for a specific element or product element.
 * @param versionOrPath The version OR path (with version as first element) need to calculate counts (and for products)
 * @param element Which element to get the conversion factors sum for
 * @param product Optional product. Needed for recipes
 * @returns A record containing how much of each unit the elemenet contains (kg, m³, co2e_A1-A3 etc)
 */
export const generateElementConversionFactorSum = (
  versionOrPath: IBuildingVersion | ElementPath,
  element: IElement | IProductElement, // TODO: Make this support versions
  product?: IProduct, // Needed for recipes?
): Results => {
  if (isElement(element)) {
    return sumElementConversionFactors(versionOrPath, element);
  } else if (isProductElement(element)) {
    return sumProductElementConversionFactors(versionOrPath, element, product);
  }
  return {};
};

/**
 * Get summary of all conversion factors in an element or product element in a specific unit.
 * Return 0 if the unit is not found.
 * @param version
 * @param element
 * @param unit
 * @returns
 */
export const getElementSumInUnit = (
  version: IBuildingVersion,
  element: IElement,
  unit: SupportedConversionUnits,
): number =>
  getConversionFactorValue(
    generateElementConversionFactorSum(version, element),
    unit,
  );

/**
 * Get mass of element.
 * Which is the sum of all productElement mass in the element.
 * @param version
 * @param element
 * @returns
 */
export const getElementMass = (version: IBuildingVersion, element: IElement) =>
  getElementSumInUnit(version, element, 'kg');

/**
 * Get total amount of CO2e of an element.
 * @param version
 * @param element
 * @returns
 */
export const getElementCO2e = (version: IBuildingVersion, element: IElement) =>
  getElementSumInUnit(version, element, 'co2e');

/**
 * Get volume of element.
 * Which is the sum of all productElement volumes in the element.
 * @param version
 * @param element
 * @returns
 */
export const getElementVolume = (
  version: IBuildingVersion,
  element: IElement,
) => getElementSumInUnit(version, element, 'm³');

export const getCO2eRecord = (
  factorRecord: ResultsRecord,
  elementQuantity = 1,
): Record<IElementID, number> => {
  return mapValues(
    factorRecord,
    (factors) => getConversionFactorValue(factors, 'co2e') * elementQuantity,
  );
};
interface BuildingVariables {
  total_height: number;
  total_height_above_ground: number;
  total_height_below_ground: number;
  gfa_above_ground: number;
  gfa_below_ground: number;
  external_gwa: number;
  external_gwa_above_ground: number;
  external_gwa_below_ground: number;
  gross_volume: number;
  gross_volume_above_ground: number;
  gross_volume_below_ground: number;
  gfa_ground_floor_slabs: number;
  gfa_cantilevered_floor_slabs: number;
  gfa_terrace_slabs: number;
  gfa_sky: number;
  gfa_activities: number;
}

/**
 * Calculate the width under a saddle roof where the room height is minHeight or more.
 * @param building_width
 * @param minHeight
 * @param roofPitch
 * @returns
 */
const getWidthOfMinimumRoomHeight = (
  building_width: number,
  innerHeight: number,
  roofPitch: number,
): number => {
  const minHeight = 1.9 - innerHeight;

  if (!roofPitch || minHeight <= 0) {
    return building_width;
  }

  // +60cm after the point where height is 1.9m height is counted as width
  const minWidth =
    0.6 * 2 +
    building_width -
    (2 * minHeight) / Math.tan((roofPitch * Math.PI) / 180);

  // width can't be larger than building width.
  return Math.min(building_width, minWidth);
};

/**
 * Get the building length based on the area and span
 * Note that this length is an estimate of length if we recalculate the shape to a rectangle.
 * @param footprintArea
 * @param buildingWidth
 */
export const getBuildingLengthEstimate = (
  footprintArea: number,
  buildingWidth: number,
): number => (buildingWidth ? footprintArea / buildingWidth : 0);

/**
 * Get available GFA for Activities on a Storey.
 * @param gfa_sky The of this storey that is not covered by a roof
 * @param gfa The total gfa of this storey
 * @param building_width
 * @param innerHeight Height to the point where the roof starts
 * @param roof_pitch Angle in degrees
 * @returns
 */
const getActivitiesGFA = (
  gfa_sky: number,
  gfa: number,
  building_width: number,
  innerHeight: number,
  roof_pitch = 20,
) => {
  const length = getBuildingLengthEstimate(gfa, building_width);
  const lengthWithRoof = length * (gfa_sky / gfa);
  const lengthWithoutRoof = length - lengthWithRoof;

  const areaWithoutRoof = lengthWithoutRoof * building_width;
  const areaWithRoof =
    lengthWithRoof *
    getWidthOfMinimumRoomHeight(building_width, innerHeight, roof_pitch);

  return roundToDecimals(areaWithoutRoof + areaWithRoof);
};

export const getBuildingVariables = (
  storeys: IRequiredStoreyWithElevation[],
  { gfa, perimeter, inner_height, elevation = 0 }: IRequiredStoreyWithElevation,
  index: number,
  meta: ProjectMetadata,
  mainCategoryVariables: MainCategoryVariables = {},
): BuildingVariables => {
  const total_height = CALCULATIONS.total_height.calculate({
    inner_height,
  });
  const total_height_above_ground =
    CALCULATIONS.total_height_above_ground.calculate({
      elevation,
      total_height,
    });
  const total_height_below_ground =
    CALCULATIONS.total_height_below_ground.calculate({
      total_height,
      total_height_above_ground,
    });
  const gfa_above_ground = CALCULATIONS.gfa_above_ground.calculate({
    total_height_below_ground,
    gfa,
  });
  const gfa_below_ground = CALCULATIONS.gfa_below_ground.calculate({
    total_height_below_ground,
    gfa,
  });
  const external_gwa = CALCULATIONS.external_gwa.calculate({
    perimeter,
    total_height,
  });
  const external_gwa_above_ground =
    CALCULATIONS.external_gwa_above_ground.calculate({
      perimeter,
      total_height_above_ground,
    });
  const external_gwa_below_ground =
    CALCULATIONS.external_gwa_below_ground.calculate({
      perimeter,
      total_height_below_ground,
    });
  const gross_volume = CALCULATIONS.gross_volume.calculate({
    gfa,
    total_height,
  });
  const gross_volume_above_ground =
    CALCULATIONS.gross_volume_above_ground.calculate({
      gfa,
      total_height_above_ground,
    });
  const gross_volume_below_ground =
    CALCULATIONS.gross_volume_below_ground.calculate({
      gfa,
      total_height_below_ground,
    });

  let extraBelow = gfa;
  let extraAbove = gfa;
  let gfa_ground_floor_slabs = CALCULATIONS.gfa_ground_floor_slabs.calculate();
  let gfa_cantilevered_floor_slabs =
    CALCULATIONS.gfa_cantilevered_floor_slabs.calculate();
  let gfa_terrace_slabs = CALCULATIONS.gfa_terrace_slabs.calculate();
  let gfa_sky = CALCULATIONS.gfa_sky.calculate();

  const prevStorey = storeys[index - 1];
  const nextStorey = storeys[index + 1];

  if (prevStorey?.gfa) {
    extraBelow = gfa - prevStorey.gfa;
  }
  if (nextStorey?.gfa) {
    extraAbove = gfa - nextStorey.gfa;
  }

  if (extraBelow > 0) {
    if (elevation <= 0) {
      gfa_ground_floor_slabs = extraBelow;
    } else {
      gfa_cantilevered_floor_slabs = extraBelow;
    }
  }
  if (extraAbove > 0) {
    if (elevation + inner_height <= 0) {
      gfa_terrace_slabs = extraAbove;
    } else {
      gfa_sky = extraAbove;
    }
  }

  const roof_pitch = mainCategoryVariables[ElementPropertyName.RoofPitch];
  const building_width = getBuildingWidth(meta.building_footprint.coordinates);
  const gfa_activities = getActivitiesGFA(
    gfa_sky,
    gfa,
    building_width,
    inner_height,
    typeof roof_pitch === 'number' ? roof_pitch : undefined,
  );

  return {
    total_height,
    total_height_above_ground,
    total_height_below_ground,
    gfa_above_ground,
    gfa_below_ground,
    external_gwa,
    external_gwa_above_ground,
    external_gwa_below_ground,
    gross_volume,
    gross_volume_above_ground,
    gross_volume_below_ground,
    gfa_ground_floor_slabs,
    gfa_cantilevered_floor_slabs,
    gfa_terrace_slabs,
    gfa_sky,
    gfa_activities,
  };
};

/**
 * Test if two results objects are equal.
 * @param a
 * @param b
 * @param excludedKeys Properties to exclude from the comparison
 * @returns
 */
export const isEqualResults = (
  a: Results | undefined,
  b: Results | undefined,
  ...excludedKeys: (keyof Results)[]
): boolean => {
  if (a === b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return isNumberObjectsCloseTo(
    omit(a, ...excludedKeys),
    omit(b, ...excludedKeys),
  );
};

/**
 * Get results divided by a factor (like if you want results per kg)
 * @param results
 * @param denominator
 * @returns
 */
export const getResultsByFactor = <
  T extends Results | ConversionFactors | number,
>(
  results: T | undefined,
  denominator = 1,
): T => {
  const factor = denominator > 0 ? 1 / denominator : 0;

  if (typeof results === 'number') {
    return (results * factor) as T;
  }
  return multiplyConversionFactors(results, factor);
};
