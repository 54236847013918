import React, { FC, useCallback, useMemo, useState } from 'react';
import { ListItemButton, Box, Tooltip } from '@mui/material';
import { mouseEventHandler } from '../../../../../shared/helpers/event_helpers';
import {
  MainCategoryId,
  ElementCategoryConversionFactorRecord,
} from '../../../../../shared/models/element_categories.interface';
import BarChart from '../../charts/BarChart';
import { useUIState } from '../../../store/ui';
import {
  getElementCategory,
  getSumOfCategoryConversionFactors,
} from '../../../../../shared/helpers/element_category_helpers';
import { isMainCategoryMatch } from '../../../../../shared/templates/categories';
import Droppable from '../../drag-and-drop/Droppable';
import { useMoveElements } from '../../../hooks/move-elements.hook';
import { useMainCategoryElementById } from '../../../hooks/element-category.hook';
import { useBarChartTooltip } from '../../../hooks/chart.hook';
import { BarChartTooltipContext } from '../../../../../shared/models/chart.interface';
import { makeStyles } from 'tss-react/mui';
import MainCategoryItemTooltipContent from './MainCategoryItemTooltipContent';
import { useMainCategoryElementNavigation } from '../../../hooks/router.hooks';
import { BAR_CHART_HEIGHT } from '../../../../../shared/constants';
import { mainCategoryIcons } from '../../Icon';
import { BarChartTooltipContainerStyle } from '../../../style/constants';
import { useProjectCostIsDisabled } from '../../../store/project';
import { getConversionFactorValue } from '../../../../../shared/helpers/conversion-factors.helpers';

interface MainCategoryItemProps {
  categoryId: MainCategoryId;
  categoryConversionFactors: ElementCategoryConversionFactorRecord;
  maxCO2: number;
  maxCost: number;
}

const MainCategoryItem: FC<MainCategoryItemProps> = ({
  categoryId,
  categoryConversionFactors,
  maxCO2,
  maxCost,
}) => {
  const { classes } = useStyles();
  const disableCost = useProjectCostIsDisabled();

  const { selectedElementCategoryId, setShowProjectDetailsEditor } = useUIState(
    'selectedElementCategoryId',
    'setShowProjectDetailsEditor',
  );

  const { moveElementsTo } = useMoveElements();
  const renderChartTooltip = useBarChartTooltip();
  const getMainCategoryElement = useMainCategoryElementById();
  const mainCategoryNavigation = useMainCategoryElementNavigation(false);

  const [showMainCategoryTooltip, setShowMainCategoryTooltip] = useState(false);

  const disableTooltip =
    !!selectedElementCategoryId &&
    !isMainCategoryMatch(categoryId, selectedElementCategoryId);

  const hasEmissions = Object.entries(categoryConversionFactors).some(
    ([, categoryFactors]) =>
      getConversionFactorValue(categoryFactors, 'co2e') > 0,
  );
  const categoryName = getElementCategory(categoryId)?.name ?? '';

  const totalCO2e = useMemo(
    () => getSumOfCategoryConversionFactors(categoryConversionFactors, 'co2e'),
    [categoryConversionFactors],
  );

  const totalCost = useMemo(
    () => getSumOfCategoryConversionFactors(categoryConversionFactors, 'sek'),
    [categoryConversionFactors],
  );

  const handleClick = useCallback(() => {
    mainCategoryNavigation(categoryId);
    setShowProjectDetailsEditor(false);
  }, [mainCategoryNavigation, categoryId, setShowProjectDetailsEditor]);

  const handleDrop = useCallback(
    async (dragId: string) => {
      const mainCategoryElement = getMainCategoryElement(categoryId);

      if (mainCategoryElement) {
        await moveElementsTo(mainCategoryElement.id, 'inside', dragId);
        mainCategoryNavigation(categoryId);
      }
    },
    [
      getMainCategoryElement,
      categoryId,
      moveElementsTo,
      mainCategoryNavigation,
    ],
  );

  const handleRenderChartTooltip = useCallback(
    (context: BarChartTooltipContext) => {
      if (renderChartTooltip && !disableTooltip) {
        renderChartTooltip(context, { offsetBottom: 36 });
        setShowMainCategoryTooltip(false);
      }
    },
    [disableTooltip, renderChartTooltip],
  );

  const handleOpenLocalTooltip = useCallback(() => {
    setShowMainCategoryTooltip(!disableTooltip);
  }, [disableTooltip]);

  const handleCloseLocalTooltip = useCallback(() => {
    setShowMainCategoryTooltip(false);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        maxWidth: 300,
        padding: 1,
        borderRadius: 4,
      }}
    >
      <Droppable allowDropBelow allowDropInside onDrop={handleDrop}>
        <Tooltip
          disableInteractive
          open={showMainCategoryTooltip}
          placement="bottom"
          onOpen={handleOpenLocalTooltip}
          onClose={handleCloseLocalTooltip}
          classes={{ tooltip: classes.tooltip }}
          title={
            <MainCategoryItemTooltipContent
              co2e={totalCO2e}
              cost={totalCost}
              name={categoryName}
            />
          }
        >
          <ListItemButton
            onClick={mouseEventHandler(handleClick)}
            selected={
              !!selectedElementCategoryId &&
              isMainCategoryMatch(categoryId, selectedElementCategoryId)
            }
            sx={{
              justifyContent: 'center',
              height: '100%',
              borderRadius: '4px',
              padding: 3,
            }}
          >
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                transition: '250ms',
                opacity:
                  selectedElementCategoryId &&
                  !isMainCategoryMatch(categoryId, selectedElementCategoryId)
                    ? 0.3
                    : 1,
              }}
            >
              {hasEmissions && maxCO2 > 0 && (
                <Box display="flex" mb={2} position="relative">
                  <Box height={BAR_CHART_HEIGHT} width={100}>
                    <BarChart
                      data={categoryConversionFactors}
                      maxValue={maxCO2}
                      renderTooltip={handleRenderChartTooltip}
                    />
                  </Box>
                  {!disableCost && totalCost > 0 && (
                    <Box height={BAR_CHART_HEIGHT} width={16} ml={-5}>
                      <BarChart
                        data={totalCost}
                        maxValue={maxCost}
                        barPercentage={0.1}
                      />
                    </Box>
                  )}
                </Box>
              )}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-end"
                height={42}
              >
                {mainCategoryIcons[categoryId]}
                {categoryName}
              </Box>
            </Box>
          </ListItemButton>
        </Tooltip>
      </Droppable>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  tooltip: {
    ...BarChartTooltipContainerStyle,
    padding: '6px',
  },
}));

export default MainCategoryItem;
