import { IElement } from './project.interface';
import { PartialRecord } from './type_helpers.interface';
import { ResultsRecord } from './unit.interface';

export const DEFAULT_PROPOSAL_NAME = 'Proposal 1';

export interface IProposal {
  id: string;
  name: string;
  active?: boolean;
  selections: PartialRecord<
    NonNullable<IElement['versionId']>,
    IElement['id'] | undefined
  >;
  resultsRecord: ResultsRecord;
}
