import { isObject, size, upperFirst } from 'lodash';
import {
  ElementPropertyResolvedCount,
  ElementPropertyType,
  IElementProperty,
  IFactoryProperty,
} from '../models/element_property.interface';
import {
  elementQuantityNames,
  ElementQuantityExpressionRecord,
  elementQuantityUnitRecord,
  FactoryQuantityRecord,
  IElementQuantityExpressionProperty,
  IFactoryQuantityExpressionProperty,
  ElementQuantityRecord,
  IElementQuantityProperty,
  IFactoryQuantityProperty,
  IElementQuantitySwitchProperty,
  IElementQuantitySelectProperty,
  elementQuantitySwitchNames,
  ElementQuantityName,
  ElementQuantitySwitchName,
  ElementQuantitySelectName,
  elementQuantitySelectNames,
  ElementQuantityExpressionName,
  ElementQuantityTypeByName,
  FactoryQuantityTypeByName,
  elementQuantityExpressionNames,
  sortedElementQuantityNames,
  DEFAULT_QUANTITY_EXPRESSION,
  DEFAULT_QUANTITY_EXPRESSIONS,
  elementQuantityRepeatingChildNames,
  RepeatingItemQuantityName,
  ElementPropertyOrQuantityProperty,
  ElementOrQuantityRecord,
} from '../models/element_quantities.interface';
import {
  IBuildingVersion,
  IElement,
  OneOfElements,
  OneOfParentElements,
  Project,
} from '../models/project.interface';
import { ItemOrItemId } from '../models/type_helpers.interface';
import { createRecordByKey, getItemByIdOrName, isOneOf } from './array_helpers';
import { createElementProperty } from './element_property_factory_helpers';
import {
  getCount,
  getElementPropertyResolvedCount,
  PropertyChange,
  requirePropertyOfType,
  setElementProperties,
} from './element_property_helpers';
import {
  createExpression,
  isExpressionValueFactoryType,
  resolveFactoryCount,
} from './expression_factory_helpers';
import {
  applyChanges,
  filterObject,
  getId,
  hasDefinedProperties,
  isNonArrayObject,
  isPrimitive,
  mapFilterRecord,
  omitUndefined,
} from './object_helpers';
import { updateElements } from './project_helpers';
import { validateElementProperties } from '../validation/element-property.validation';
import {
  getElementById,
  isElement,
  isOneOfPropertyElements,
} from './recursive_element_helpers';
import { getTimestamp } from './date.helpers';
import { ElementCategoryID } from '../models/element_categories.interface';
import { FactoryCountExpression } from '../models/factory-element.interface';
import { getElementCategory } from './element_category_helpers';
import { Orientation } from '../models/orientation.interface';
import { getElementCategoryId } from './element_helpers';
import { createId } from './id.helpers';

const EMPTY_QUANTITY_RECORD: Readonly<ElementQuantityRecord> = Object.freeze(
  {},
);
const REPEATING_DEFAULTS: Record<
  RepeatingItemQuantityName,
  ElementPropertyResolvedCount<
    ElementQuantityTypeByName<RepeatingItemQuantityName>['type']
  >
> = {
  repeating_item_spacing: 0,
  repeating_item_thickness: 0,
  repeating_item_count: 0,
  repeating_direction: 'horizontal',
};

/**
 * Check if name is a valid element quantity name
 * @param name
 * @returns
 */
export const isElementQuantityName = (
  name: string | undefined,
): name is ElementQuantityName => isOneOf(elementQuantityNames, name);

export const isElementQuantityRepeatingChildName = (
  name: string | undefined,
): name is (typeof elementQuantityRepeatingChildNames)[number] => {
  return isOneOf(elementQuantityRepeatingChildNames, name);
};

const isElementQuantitySwitchName = (
  name: unknown,
): name is ElementQuantitySwitchName =>
  isOneOf(elementQuantitySwitchNames, name);

const isElementQuantitySelectName = (
  name: unknown,
): name is ElementQuantitySelectName =>
  isOneOf(elementQuantitySelectNames, name);

const isElementQuantityExpressionName = (
  name: unknown,
): name is ElementQuantityExpressionName =>
  isOneOf(elementQuantityExpressionNames, name);

export const isElementQuantityExpressionProperty = (
  property?: ElementPropertyOrQuantityProperty,
): property is IElementQuantityExpressionProperty =>
  !!property &&
  isElementQuantityExpressionName(property.name) &&
  !!getCount(property);

export const isElementQuantitySwitchProperty = (
  property?: ElementPropertyOrQuantityProperty,
): property is IElementQuantitySwitchProperty =>
  !!property &&
  isElementQuantitySwitchName(property.name) &&
  typeof getCount(property) === 'boolean';

export const isElementQuantitySelectProperty = (
  property?: ElementPropertyOrQuantityProperty,
): property is IElementQuantitySelectProperty =>
  isNonArrayObject(property) &&
  isElementQuantitySelectName(property.name) &&
  isElementQuantityPropertyOptions(
    (property as IElementQuantitySelectProperty).options,
  );

const isElementQuantityPropertyOptions = (
  options: unknown,
): options is IElementQuantitySelectProperty['options'] =>
  Array.isArray(options) || typeof options === 'string';

/**
 * Test if a property is a quantity property.
 * Any of the three types
 * @param property
 * @returns
 */
export const isElementQuantityProperty = (
  property?: ElementPropertyOrQuantityProperty,
): property is IElementQuantityProperty =>
  isElementQuantityExpressionProperty(property) ||
  isElementQuantitySwitchProperty(property) ||
  isElementQuantitySelectProperty(property);

const isFactoryQuantityExpressionProperty = (
  property: unknown,
): property is IFactoryQuantityExpressionProperty =>
  isObject(property) &&
  'name' in property &&
  isElementQuantityExpressionName(property.name);

export const isElementQuantityRecord = (
  record: unknown,
): record is ElementQuantityRecord => {
  if (!isObject(record)) {
    return false;
  }
  return Object.values(omitUndefined(record)).some(isElementQuantityProperty);
};

export const getSelectedElementQuantityProperty = (
  element: IElement,
): IElementQuantityExpressionProperty | undefined => {
  const quantity = getElementQuantityRecord(element);
  const selectedQuantity = getSelectedQuantityName(element);
  return selectedQuantity
    ? requirePropertyOfType(
        quantity[selectedQuantity],
        ElementPropertyType.Expression,
      )
    : undefined;
};

/**
 * Get a property type by quantity name in a typesafe way.
 * @param name
 * @returns
 */
const getPropertyTypeByName = <T extends ElementQuantityName>(
  name: T,
): Required<ElementQuantityTypeByName<T>>['type'] => {
  if (isElementQuantitySwitchName(name)) {
    return ElementPropertyType.Switch;
  }
  if (isElementQuantitySelectName(name)) {
    return ElementPropertyType.Select;
  }
  if (isElementQuantityExpressionName(name)) {
    return ElementPropertyType.Expression;
  }
  throw new Error(`Invalid element quantity name: ${String(name)}`);
};

/**
 * Make a record of ElementQuantityProperties from an array of IElementQuantityProperty
 * @param properties
 * @returns
 */
export const toElementQuantityRecord = (
  ...properties: IElementQuantityProperty[]
): ElementQuantityRecord => createRecordByKey(properties, 'name');

const getQuantityExpressionDefaults = (
  defaults: IFactoryQuantityExpressionProperty,
) => ({
  min: 0, // No negative quantities for now
  fallbackCount: createExpression(
    getDefaultFallbackExpressionByName(defaults.name),
  ), // Allways provide a fallback count
  ...omitUndefined(defaults),
  unit: defaults.unit ?? elementQuantityUnitRecord[defaults.name],
});

/**
 * Create a new element quantity property from a set of defaults.
 * Should automatically be able to map to the correct type
 * @param defaults
 * @param regenerateId If true it will regerate id for all elements even if id already exist
 * @returns
 */
export const createElementQuantityProperty = <
  T extends IFactoryQuantityProperty,
  K extends T['name'],
>(
  defaults: T,
  regenerateId: boolean = false,
): ElementQuantityTypeByName<K> => {
  const name = defaults.name;
  const type = getPropertyTypeByName(name);

  if (defaults.type && defaults.type !== type) {
    throw new Error(
      `Invalid property type for ${name}, expected ${type} but got ${defaults.type}`,
    );
  }

  if (isFactoryQuantityExpressionProperty(defaults)) {
    defaults = getQuantityExpressionDefaults(defaults) as T;
  }

  const property = createElementProperty({
    ...omitUndefined(defaults),
    type,
    updated_at: getTimestamp(),
    id: createId(defaults, regenerateId),
  } as unknown as IFactoryProperty);
  if (isElementQuantityProperty(property)) {
    return property as ElementQuantityTypeByName<K>;
  }
  throw new Error('Could not create element quantity property');
};

/**
 * Create an entire ElementQuantityRecord from a FactoryQuantityRecord or array of IFactoryQuantityProperty
 * @param defaults
 * @returns
 */
export const createElementQuantityRecord = (
  defaults: FactoryQuantityRecord | IFactoryQuantityProperty[] = [],
  regenerateId?: boolean,
): ElementQuantityRecord => {
  const factoryRecord = toFactoryQuantityRecord(defaults);

  return toElementQuantityRecord(
    ...Object.values(factoryRecord).map((value: IFactoryQuantityProperty) =>
      createElementQuantityProperty(value, regenerateId),
    ),
  );
};

/**
 * Create an entire ElementQuantityRecord from a FactoryQuantityRecord
 * @param defaults
 * @returns
 */
const toFactoryQuantityRecord = (
  defaults: FactoryQuantityRecord | IFactoryQuantityProperty[] = {},
): FactoryQuantityRecord => {
  const arrayWithNames = Object.entries(defaults).map(([key, value]) => {
    const name = isElementQuantityName(key)
      ? key
      : 'name' in value && value.name;
    if (!isElementQuantityName(name)) {
      throw new Error(`Invalid element quantity name: ${String(name)}`);
    }
    return toFactoryQuantityProperty(value, name);
  });

  return createRecordByKey(arrayWithNames, 'name');
};

const toFactoryQuantityProperty = <
  T extends FactoryQuantityRecord[K],
  K extends ElementQuantityName,
>(
  value: T,
  name: K,
): FactoryQuantityTypeByName<K> => {
  // Create a QuantityProperty with name and count
  const property: FactoryQuantityTypeByName<K> = {
    ...(isPrimitive(value) || isExpressionValueFactoryType(value)
      ? { count: value as FactoryQuantityTypeByName<K>['count'] } // Value is a Factory count
      : (value as Partial<FactoryQuantityTypeByName<K>>)), // Value is already a QuantityProperty
    name,
  } as FactoryQuantityTypeByName<K>;

  // For expressions we need to create a count property as an ExpressionValue
  return isElementQuantityExpressionName(name)
    ? resolveFactoryCount({
        fallbackCount: createExpression(
          getDefaultFallbackExpressionByName(name),
        ),
        ...property,
      } as {
        count?: FactoryCountExpression | undefined;
        fallbackCount?: FactoryCountExpression | undefined;
      })
    : property;
};

/**
 * Get all quantity properties for an element as an array.
 * @param elementOrQuantity
 * @returns
 */
export const getElementQuantityProperties = (
  elementOrQuantity?: ElementOrQuantityRecord,
): IElementQuantityProperty[] =>
  Object.values(getElementQuantityExpressionRecord(elementOrQuantity)).sort(
    sortQuantityPropertiesFn,
  );

const quantityNamesPrio: ElementQuantityName[] = [
  'mass',
  'volume',
  'area_side',
  'area_top',
  'width',
  'height',
  'length',
  'time',
  'energy',
  'density',
] as const;

/**
 * Get the default selected quantity name for an element.
 * @param element
 * @param categoryId
 * @returns
 */
export const getDefaultSelectedQuantityName = (
  element: IElement,
  categoryId: ElementCategoryID | undefined = getElementCategoryId(element),
): ElementQuantityName | undefined => {
  const quantity = getElementQuantityRecord(element);
  if (size(quantity) === 0) {
    return;
  }
  const quantityName =
    element.selectedQuantity ?? getSelectedQuantityNameByCategoryId(categoryId);

  // If quantity exist and is an expression property, return it
  if (
    quantityName &&
    isElementQuantityExpressionProperty(quantity[quantityName])
  ) {
    return quantityName;
  }

  // Return the first quantity with a count or else fallback count
  return (
    quantityNamesPrio.find((name) => quantity[name]?.count) ??
    quantityNamesPrio.find((name) => quantity[name]?.fallbackCount)
  );
};

/**
 * Get the selected quantity name for an element.
 * Will automatically fallback if undefined and quantities are available.
 * @param element
 * @returns
 */
export const getSelectedQuantityName = (
  element: IElement,
): ElementQuantityName | undefined => {
  const { selectedQuantity } = element;
  return selectedQuantity ?? getDefaultSelectedQuantityName(element);
};

/**
 * Select or clear element quantity
 * @param element
 * @param name New selected quantity name. If undefined, clear selected quantity
 * @returns
 */
export const selectElementQuantity = (
  element: IElement,
  name: 'auto' | ElementQuantityName | undefined,
): IElement => {
  const { quantity, selectedQuantity } = element;

  // If no quantity, clear selected quantity
  if (!quantity || !size(quantity) || !name) {
    return selectedQuantity
      ? { ...element, selectedQuantity: undefined }
      : element;
  }

  // If selected quantity does not exist, use default quantity name
  if (name === 'auto' || !isElementQuantityExpressionProperty(quantity[name])) {
    name = getDefaultSelectedQuantityName(element);
  }

  // If selected quantity is different, update it
  return name !== selectedQuantity
    ? { ...element, selectedQuantity: name }
    : element;
};

/**
 * Get the default selected quantity name for a category.
 * TODO: Maybe nicer to add to element category
 * @param categoryId
 * @returns
 */
export const getSelectedQuantityNameByCategoryId = (
  categoryId: ElementCategoryID | undefined,
): ElementQuantityName | undefined => {
  switch (categoryId) {
    case ElementCategoryID.Wall:
    case ElementCategoryID.WindowsDoors:
      return 'area_side';
    case ElementCategoryID.Slab:
    case ElementCategoryID.Other:
      return 'area_top';
    case ElementCategoryID.Column:
      return 'height';
    case ElementCategoryID.Beam:
      return 'length';
    case ElementCategoryID.Concrete:
    case ElementCategoryID.Insulation:
    case ElementCategoryID.Wood:
    case ElementCategoryID.Ceramics:
    case ElementCategoryID.Gypsum:
    case ElementCategoryID.Metal:
    case ElementCategoryID.OtherProduct:
      return 'mass';
    case ElementCategoryID.Labour:
      return 'time';
    case ElementCategoryID.Energy:
      return 'energy';
  }
};

export const getAllElementProperties = (
  properties: IElementProperty[],
  defaultProperty?: IElementQuantityExpressionProperty,
): IElementProperty[] => {
  if (defaultProperty) {
    return [defaultProperty, ...properties];
  }
  return properties;
};

/**
 * Get element quantity record from element or quantity record
 * @param elementOrQuantity
 * @returns
 */
export const getElementQuantityRecord = (
  elementOrQuantity: ElementOrQuantityRecord = {},
): ElementQuantityRecord => {
  if (isElementQuantityRecord(elementOrQuantity)) {
    return elementOrQuantity;
  }
  const quantity =
    'quantity' in elementOrQuantity
      ? elementOrQuantity.quantity
      : EMPTY_QUANTITY_RECORD;

  return isElementQuantityRecord(quantity) ? quantity : EMPTY_QUANTITY_RECORD;
};

/**
 * Get element quantity record from element or quantity record
 * @param elementOrQuantity
 * @returns
 */
export const getElementQuantityExpressionRecord = (
  elementOrQuantity: ElementOrQuantityRecord = {},
): ElementQuantityExpressionRecord =>
  filterObject(
    getElementQuantityRecord(elementOrQuantity),
    isElementQuantityProperty,
  );

export const getElementQuantityByName = <T extends ElementQuantityName>(
  elementOrQuantity: ElementOrQuantityRecord,
  name: T,
): ElementQuantityTypeByName<T> | undefined =>
  getElementQuantityRecord(elementOrQuantity)[name] as
    | ElementQuantityTypeByName<T>
    | undefined;

export const getElementQuantityCountByName = <T extends ElementQuantityName>(
  elementOrQuantity: ElementOrQuantityRecord,
  name: T,
): ElementQuantityTypeByName<T>['count'] | undefined => {
  const quantity = getElementQuantityByName(elementOrQuantity, name);
  return quantity ? getCount(quantity) : undefined;
};

export const getElementQuantityResolvedCountByName = <
  T extends ElementQuantityName,
  D extends
    | ElementPropertyResolvedCount<ElementQuantityTypeByName<T>['type']>
    | undefined,
  V = ElementPropertyResolvedCount<ElementQuantityTypeByName<T>['type']>,
  R = D extends undefined ? V | undefined : V,
>(
  elementOrQuantity: ElementOrQuantityRecord,
  name: T,
  defaultValue?: D,
): R => {
  const quantity = getElementQuantityByName(elementOrQuantity, name);
  return (
    quantity
      ? getElementPropertyResolvedCount(quantity, defaultValue)
      : defaultValue
  ) as R;
};

export const getRepeatingResolvedCountByName = <
  T extends RepeatingItemQuantityName,
  R extends ElementPropertyResolvedCount<ElementQuantityTypeByName<T>['type']>,
>(
  elementOrQuantity: ElementOrQuantityRecord,
  name: T,
): R => {
  const isRepeating = getElementQuantityResolvedCountByName(
    elementOrQuantity,
    'repeating_items',
  );
  if (isRepeating) {
    return (
      getElementQuantityResolvedCountByName(elementOrQuantity, name) ??
      REPEATING_DEFAULTS[name]
    );
  }

  return REPEATING_DEFAULTS[name] as R;
};

/**
 * Between 0 to 1 how much space an element occupies taking account of repeating items and fill.
 * Illustation below with spacing = 4, width = 1, fill = 1 gives a fill grade of 0.2
 * []----[]----[]----[]----[]----[]----[]
 * @param element
 */
export const getElementFillGrade = (element: IElement): number => {
  const spacing = getRepeatingResolvedCountByName(
    element,
    'repeating_item_spacing',
  );
  const width = getRepeatingResolvedCountByName(
    element,
    'repeating_item_thickness',
  );
  const fill = getElementQuantityResolvedCountByName(element, 'fill') ?? 1;
  return (!spacing || !width ? 1 : width / (width + spacing)) * fill;
};

export const getElementQuantityFormattedName = (key: string): string => {
  const names: Partial<Record<ElementQuantityName, string>> = {
    area_side: 'Side area',
    area_top: 'Top area',
    area_section: 'Section area',
    density_areal_side: 'Areal density',
    repeating_item_spacing: 'Item spacing',
    repeating_item_thickness: 'Item thickness',
    repeating_item_count: 'Item count',
    repeating_items: 'Repeating items',
    repeating_direction: 'Repeating direction',
    time: 'Time of work',
  };
  return (isElementQuantityName(key) && names[key]) || upperFirst(key);
};

/**
 * Update element quantity with one or more changes.
 * Will add any missing property
 * @param element
 * @param elementChanges
 * @param root Used to find element parent
 * @returns
 */
export const setElementQuantityProperties = (
  element: IElement,
  changes: FactoryQuantityRecord | IFactoryQuantityProperty[],
): IElement => {
  const quantity = getElementQuantityRecord(element);
  const updatedElement = applyChanges(element, {
    quantity: setQuantityRecord(quantity, changes),
  });
  if (updatedElement !== element) {
    // Validate on change only
    validateElementProperties(getElementQuantityProperties(updatedElement));
  }

  return updatedElement;
};

const setQuantityRecord = (
  quantityRecord: ElementQuantityRecord,
  changes: FactoryQuantityRecord | IFactoryQuantityProperty[],
): ElementQuantityRecord => {
  const changeRecord = toFactoryQuantityRecord(changes);
  const changesArray = (
    Object.values(changeRecord) as IFactoryQuantityProperty[]
  ).map((value) => {
    if (!value.name) {
      throw new Error(`Invalid element quantity property, no name provided`);
    }
    const current = quantityRecord[value.name];
    return !current ? createElementQuantityProperty(value) : value;
  });

  const updatedRecord = applyChanges(
    quantityRecord,
    createRecordByKey(changesArray, 'name'),
  );

  // Apply updated_at to all changed properties
  return applyUpdatedAtDatesToQuantityRecord(updatedRecord, quantityRecord);
};

/**
 * Detect what have changed and apply new updated_at dates to those properties
 * @param updatedRecord
 * @param originalRecord
 * @returns
 */
const applyUpdatedAtDatesToQuantityRecord = (
  updatedRecord: ElementQuantityRecord,
  originalRecord: ElementQuantityRecord,
): ElementQuantityRecord => {
  // Don't modify if nothing have changed
  if (updatedRecord === originalRecord) {
    return originalRecord;
  }
  return mapFilterRecord(updatedRecord, (value) => {
    if (
      value &&
      (value.count as { expression: unknown })?.expression !==
        (originalRecord[value.name]?.count as { expression: unknown })
          ?.expression
    ) {
      return { ...value, updated_at: getTimestamp() };
    }
    return value;
  }) as ElementQuantityRecord;
};

/**
 * Update an element's quantity with one or more changes
 * and apply the element changes to the root.
 * Will add any missing property
 * @param root
 * @param elementOrId
 * @param quantityChanges
 * @returns
 */
export const updateElementQuantityPropertiesInRoot = <
  R extends Project | IBuildingVersion,
>(
  root: R,
  elementOrId: ItemOrItemId<OneOfElements>,
  quantityChanges: FactoryQuantityRecord | IFactoryQuantityProperty[],
): R => {
  const elementId = getId(elementOrId);

  // Make sure to fetch the latest instance to not overwrite any changes previously made
  const element = getElementById(root, elementId);

  if (!isElement(element)) {
    throw new Error(`Element not found with id ${elementId}`);
  }
  const updatedElement = setElementQuantityProperties(element, quantityChanges);

  // Update element if properties have changed else return project unmodified
  if (updatedElement !== element) {
    return updateElements(root, updatedElement);
  }
  return root;
};

/**
 * Update an element's quantity and properties and apply the changes to the root (project or building version).
 * Good if you want to update both properties and quantity at the same time
 * @param root
 * @param element
 * @param changes
 * @returns
 */
export const updateElementQuantityAndPropertiesInRoot = <
  R extends Project | IBuildingVersion,
>(
  root: R,
  element: OneOfParentElements,
  ...changes: PropertyChange[]
): R => {
  if (!isOneOfPropertyElements(element) || changes.length === 0) {
    return root;
  }
  const quantities = Object.values(getElementQuantityProperties(element));

  // Changes to quantity properties
  const quantityChanges = changes.filter(
    (change) => !!getItemByIdOrName(quantities, change, false),
  );

  // Changes to properties are the rest
  const propertyChanges = changes.filter(
    (change) => !quantityChanges.includes(change),
  );

  // Only IElement have quantity properties currently
  if (isElement(element)) {
    root = updateElementQuantityPropertiesInRoot(
      root,
      element,
      quantityChanges as IFactoryQuantityProperty[],
    );
  }
  return setElementProperties(root, element, ...propertyChanges);
};

export const hasQuantityProperties = (element: OneOfElements): boolean =>
  getElementQuantityProperties(element).length > 0;

const sortQuantityPropertiesFn = (
  p1: IElementQuantityExpressionProperty,
  p2: IElementQuantityExpressionProperty,
) =>
  sortedElementQuantityNames.indexOf(p1.name) -
  sortedElementQuantityNames.indexOf(p2.name);

export const getDefaultFallbackExpressionByName = (name?: string): string =>
  DEFAULT_QUANTITY_EXPRESSIONS[name as ElementQuantityExpressionName] ??
  DEFAULT_QUANTITY_EXPRESSION;

export const getDefinedQuantities = (
  quantity: ElementQuantityRecord | undefined,
): Record<string, IElementQuantityExpressionProperty> => {
  const record = getElementQuantityExpressionRecord(quantity);

  if (!hasDefinedProperties(record)) {
    throw new Error('Quantities are missing');
  }
  return record;
};

export type ThicknessUnit = Extract<
  ElementQuantityExpressionName,
  'height' | 'width'
>;

/**
 * Get in which unit a "thickness" should be measured. For walls thickness in width, for floors/slabs it's height.
 * @param element
 * @returns
 */
export const getThicknessUnit = (element: IElement): ThicknessUnit => {
  const category = getElementCategory(element);
  if (category?.orientation === Orientation.Horizonal) {
    return 'height';
  }
  if (category?.orientation === Orientation.Vertical) {
    return 'width';
  }

  return getElementQuantityResolvedCountByName(element, 'height', 0) >
    getElementQuantityResolvedCountByName(element, 'width', 0)
    ? 'height'
    : 'width';
};

export type AreaUnit = Extract<
  ElementQuantityExpressionName,
  'area_side' | 'area_top'
>;

/**
 * Get in which unit an "area" should be measured. For walls area_side, for floors/slabs it's area_top.
 * @param element
 * @returns
 */
export const getAreaUnit = (element: IElement): AreaUnit => {
  const category = getElementCategory(element);
  if (category?.orientation === Orientation.Horizonal) {
    return 'area_top';
  }
  if (category?.orientation === Orientation.Vertical) {
    return 'area_side';
  }

  return getElementQuantityResolvedCountByName(element, 'area_side', 0) >
    getElementQuantityResolvedCountByName(element, 'area_top', 0)
    ? 'area_side'
    : 'area_top';
};
