import React from 'react';
import { Box } from '@mui/material';
import { useProposals } from '../hooks/proposals.hook';
import ProposalListItem from './ElementList/ListItems/ProposalListItem/ProposalListItem';

const Proposals: React.FC = () => {
  const proposals = useProposals();

  return (
    <Box>
      {proposals.map((proposal) => (
        <ProposalListItem proposal={proposal} key={proposal.id} />
      ))}
    </Box>
  );
};

export default Proposals;
