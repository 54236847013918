import { getCategoryPropertyValueRecord } from '../../../helpers/element_category_helpers';
import { omit } from '../../../helpers/object_helpers';
import { isEqualCategoryPropertyValueRecord } from '../../../helpers/recipe_helpers';
import {
  IElementCategory,
  ElementCategoryID,
  IElementCategoryQuantityPropertiesFn,
} from '../../../models/element_categories.interface';
import { DEFAULT_QUANTITY_PROPERTIES } from '../../../models/element_quantities.interface';
import { Orientation } from '../../../models/orientation.interface';
import { wallProperties } from './wall.model';

const recipeFilter: IElementCategory['recipeFilter'] = (recipe, element) => {
  const category_property_value_record =
    getCategoryPropertyValueRecord(element);

  // Recipe category_property_value_record must be an EXACT to the element to appear
  return isEqualCategoryPropertyValueRecord(
    category_property_value_record,
    recipe.category_property_value_record,
  );
};

const getQuantityProperties: IElementCategoryQuantityPropertiesFn = () =>
  omit(DEFAULT_QUANTITY_PROPERTIES, 'density', 'mass', 'fill');

export const wall: IElementCategory = {
  id: ElementCategoryID.Wall,
  name: 'Wall',
  properties: wallProperties,
  defaultSelectedQuantity: 'area_side',
  orientation: Orientation.Vertical,
  recipeFilter,
  getQuantityProperties,
};
