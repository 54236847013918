import { sortBy } from 'lodash';
import { ICoordinate } from '../models/project.interface';
import { cacheFactory } from './function_helpers';
import { getMedianValue, roundToDecimals } from './math_helpers';

export type Point = [number, number];
export type Line = [Point, Point];

export const getPointOnLine = (line: Line, ratio: number): Point => {
  const [p1, p2] = line;
  const x1 = p1[0];
  const y1 = p1[1];
  const x2 = p2[0];
  const y2 = p2[1];

  const x = x1 + ratio * (x2 - x1);
  const y = y1 + ratio * (y2 - y1);

  return [x, y];
};

const createPerpendicularPoint = (
  p1: Point,
  p2: Point,
  distance = 1,
): Point => [
  p2[0] +
    (distance * (p1[1] - p2[1])) /
      Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)),
  p2[1] -
    (distance * (p1[0] - p2[0])) /
      Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)),
];

export const createPerpendicularLine = (
  line: Line,
  length = 1,
  anchorPosition = 1,
): Line => {
  let p1: Point;
  let p2: Point;
  // Use start point as anchor
  if (anchorPosition === 0) {
    [p1, p2] = reverseLine(line);
  } else {
    p1 = line[0];
    p2 = getPointOnLine(line, anchorPosition);
  }

  const perp1 = createPerpendicularPoint(p1, p2, length / 2);
  const perp2 = createPerpendicularPoint(p1, p2, -length / 2);
  return [perp1, perp2];
};

export const findIntervalIntersection = (
  interval1: [number, number],
  interval2: [number, number],
): [number, number] | undefined => {
  const [start1, end1] = interval1;
  const [start2, end2] = interval2;

  const start = Math.max(start1, start2);
  const end = Math.min(end1, end2);

  if (start <= end) {
    return [start, end];
  }
};

const getDistance = (p1: Point, p2: Point) => {
  const [x1, y1] = p1;
  const [x2, y2] = p2;

  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
};

/**
 * Get the metric distance [x, y] from a coordinate  to a base coordinate
 * @param coordinate
 * @param base
 * @returns
 */
const convertCoordinateToMetricOffset = (
  coordinate: ICoordinate,
  base: ICoordinate,
): Point => {
  const { lat, lng } = coordinate;
  const distanceX = getCoordinateDistance(coordinate, { lat, lng: base.lng });
  const directionX = lng > base.lng ? 1 : -1;

  const distanceY = getCoordinateDistance(coordinate, { lat: base.lat, lng });
  const directionY = lat > base.lat ? 1 : -1;

  return [distanceX * directionX, distanceY * directionY];
};

/**
 * Calculate the distance between two coordinates in meters.
 * @param coordinate1
 * @param coordinate2
 * @returns
 */
export function getCoordinateDistance(
  coordinate1: ICoordinate,
  coordinate2?: Partial<ICoordinate>,
) {
  // generally used geo measurement function
  const lat1 = coordinate1.lat;
  const lon1 = coordinate1.lng;
  const lat2 = coordinate2?.lat ?? 0;
  const lon2 = coordinate2?.lng ?? 0;
  const R = 6378.137; // Radius of earth in KM
  const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
  const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d * 1000; // meters
}

/**
 * Get the closest coordinate to a target coordinate.
 * @param target
 * @param items
 * @returns
 */
export const getClosestCoordinate = <T extends ICoordinate>(
  target: ICoordinate,
  items: T[],
): T => {
  const first = items[0];
  if (!first) {
    throw new Error('No items to compare');
  }

  return items.reduce((closest, item) => {
    return getCoordinateDistance(target, item) <
      getCoordinateDistance(target, closest)
      ? item
      : closest;
  }, first);
};

const isFootprintCoordinates = (
  coord: Point[] | ICoordinate[],
): coord is ICoordinate[] => {
  const first = coord[0];
  return first ? 'lat' in first : false;
};

const MEASURE_POINTS = 100;

const getMeasurePoints = (line: Line, totalLength: number): number[] => {
  const lineLength = getDistance(line[0], line[1]);
  const lineRatio = lineLength / totalLength;
  const numberOfMeasurePoints = Math.round(lineRatio * MEASURE_POINTS);
  const measurePoints: number[] = [];

  if (numberOfMeasurePoints > 1) {
    for (let i = 0; i < 1; i += 1 / numberOfMeasurePoints) {
      measurePoints.push(i);
    }
  } else {
    measurePoints.push(0);
  }
  measurePoints.push(1);
  return measurePoints;
};

export const getBuildingWidth = (
  pointsOrCoordinates: Point[] | ICoordinate[] = [],
): number => {
  if (pointsOrCoordinates.length === 0) {
    return 0;
  }

  const isFootprint = isFootprintCoordinates(pointsOrCoordinates);
  const base = isFootprint ? pointsOrCoordinates[0] : { lat: 0, lng: 0 };
  const points = (
    isFootprint && base
      ? pointsOrCoordinates.map((p) => convertCoordinateToMetricOffset(p, base))
      : pointsOrCoordinates
  ) as Point[];

  return cacheFactory(
    () => {
      const spans: number[] = [];

      const lines = points.reduce((acc, point, index) => {
        const nextPoint = points[(index + 1) % points.length];

        if (nextPoint) {
          acc.push([point, nextPoint]);
        }
        return acc;
      }, [] as Line[]);

      const totalLength = lines.reduce(
        (acc, line) => acc + getDistance(line[0], line[1]),
        0,
      );

      lines.forEach((line1, index1) => {
        const measurePoints = getMeasurePoints(line1, totalLength);
        const perpendicularLines = measurePoints.map((ratio) =>
          createPerpendicularLine(line1, 100000, ratio),
        );

        lines.forEach((line2, index2) => {
          if (index1 === index2) {
            return;
          }

          const intersectingLines: Line[] = perpendicularLines
            .map((perpendicularLine) => [
              findIntersectionPoint(perpendicularLine, line1),
              findIntersectionPoint(perpendicularLine, line2),
            ])
            .filter((line): line is Line => !!line[0] && !!line[1]);

          const distances = intersectingLines
            .map(([p1, p2]) => getDistance(p1, p2))
            .filter((distance) => distance > 0.005); // Filter out values like 7.290154618149881e-12 and 0

          if (distances.length) {
            spans.push(...distances);
          }
        });
      });

      // remove extreme values
      const filteredSpans = filterExtremeValues(sortBy(spans));

      // Return a value slighly lower than the median value
      return roundToDecimals(getMedianValue(filteredSpans, 0.4));
    },
    'getBuildingSpan',
    points.flat(),
  );
};

export const getSmallestPerpendicularSize = (
  points: Point[],
): [number, number] => {
  const [p1, p2, p3, p4] = points;

  if (!p1 || !p2 || !p3 || !p4) {
    return [0, 0];
  }

  const width = Math.min(
    getDistance(p1, p2),
    getDistance(p2, p3),
    getDistance(p3, p4),
    getDistance(p4, p1),
  );

  const height = Math.min(
    getDistanceToLine(p1, p2, p4),
    getDistanceToLine(p2, p3, p1),
    getDistanceToLine(p3, p4, p2),
    getDistanceToLine(p4, p1, p3),
  );

  return [width, height];
};

const getDistanceToLine = (p1: Point, p2: Point, p3: Point): number => {
  const [x1, y1] = p1;
  const [x2, y2] = p2;
  const [x3, y3] = p3;
  const numerator = Math.abs(
    (y2 - y1) * x3 - (x2 - x1) * y3 + x2 * y1 - y2 * x1,
  );
  const denominator = getDistance(p1, p2);
  return numerator / denominator;
};

const filterExtremeValues = (numbers: number[]): number[] => {
  const mean =
    numbers.reduce((sum, number) => sum + number, 0) / numbers.length;
  const variance =
    numbers.reduce((sum, number) => sum + (number - mean) ** 2, 0) /
    numbers.length;
  const standardDeviation = Math.sqrt(variance);
  const lowerBound = mean - 2 * standardDeviation;
  const upperBound = mean + 2 * standardDeviation;
  return numbers.filter(
    (number) => number >= lowerBound && number <= upperBound,
  );
};

export const findIntersectionPoint = (
  line1: Line,
  line2: Line,
): Point | undefined => {
  const [p1, p2] = line1;
  const [p3, p4] = line2;

  const x1 = p1[0];
  const y1 = p1[1];
  const x2 = p2[0];
  const y2 = p2[1];
  const x3 = p3[0];
  const y3 = p3[1];
  const x4 = p4[0];
  const y4 = p4[1];

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
  if (denominator === 0) {
    // The lines are parallel
    return undefined;
  }

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    // The intersection point is outside the line segments
    return undefined;
  }

  const x = x1 + ua * (x2 - x1);
  const y = y1 + ua * (y2 - y1);

  return [x, y];
};

const reverseLine = (line: Line): Line => [line[1], line[0]];
