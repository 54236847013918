import React, { useCallback, useMemo, useState } from 'react';
import {
  IElementPropertyOption,
  ElementSelectPropertyCountType,
} from '../../../../../shared/models/element_property.interface';
import SelectMenuItem from '../ListItems/SelectMenuItem';
import { NodonMenuProps, SelectListChildren } from '../menu.model';
import TextMenu from './TextMenu';
import { getListItemKey } from '../../../../../shared/helpers/utils.helpers';
import { ButtonProps } from '@mui/material';
import { NodonTheme } from '../../../style';

interface PropertyMenuProps extends NodonMenuProps {
  /**
   * The value that is currently selected
   */
  selectedValue: ElementSelectPropertyCountType;

  /**
   * The label should be showed for the selected value.
   * Will default to selectedValue if not defined
   */
  selectedLabel?: string;
  options: IElementPropertyOption[];
  isFallback?: boolean;
  onSelect: (value: ElementSelectPropertyCountType) => void;
}

const PropertyMenu: React.FC<PropertyMenuProps> = ({
  selectedValue,
  selectedLabel = selectedValue?.toString(),
  options,
  onSelect,
  isFallback = false,
  ...props
}) => {
  const isSmallMenuWidth = options.length < 20;
  const labelMaxLength = isSmallMenuWidth ? 25 : 40;

  const [anchor, setAnchor] = useState<Element>();
  const [disableDivider, setDisableDivider] = useState(false);

  const handleSelect = useCallback(
    (value: ElementSelectPropertyCountType) => {
      setAnchor(undefined);
      onSelect(value);
    },
    [onSelect],
  );

  const handleCloseMenu = useCallback(() => {
    setAnchor(undefined);
  }, [setAnchor]);

  const handleSearch = useCallback(
    (searchString: string) => {
      setDisableDivider(searchString.length > 0);
    },
    [setDisableDivider],
  );

  const renderOptions = useCallback<SelectListChildren<IElementPropertyOption>>(
    ({ label, value }, style, index) => (
      <SelectMenuItem
        key={getListItemKey(index, label)}
        style={style}
        label={label}
        value={value}
        disableDivider={disableDivider}
        selected={value === selectedValue}
        showTooltip={label.length > labelMaxLength}
        onClick={handleSelect}
      />
    ),
    [disableDivider, selectedValue, labelMaxLength, handleSelect],
  );

  const menuButtonProps = useMemo<ButtonProps>(() => {
    return {
      ...props.buttonProps,
      sx: {
        color: NodonTheme.typography[isFallback ? 'caption' : 'body2'].color,
        maxWidth: 175,
        ...props.buttonProps?.sx,
      },
    };
  }, [isFallback, props.buttonProps]);

  return (
    <TextMenu
      {...props}
      anchor={anchor}
      label={selectedLabel}
      items={options}
      menuWidth={isSmallMenuWidth ? 'small' : 'medium'}
      buttonProps={menuButtonProps}
      onOpen={setAnchor}
      onClose={handleCloseMenu}
      onSearch={handleSearch}
    >
      {renderOptions}
    </TextMenu>
  );
};

export default PropertyMenu;
