/**
 * Temporary ID used to add elements that should not be saved to the server
 */
export const TMP_ELEMENT_ID = 'tmp_element_id';

/**
 * Temporary ID for a mock project used when pasting a copied element
 */
export const TMP_PROJECT_ID = 'tmp_project_id';

export const SHARED_PROJECT = 'shared';

export const TEMPLATE_ORGANIZATION = 'template-projects';

export const GFA_QUOTA_UNLIMITED = 'unlimited';

export const ADMIN_DOMAIN_NAMES = ['nodon', 'climateworks'];

export const COMMENT_MAX_LENGTH = 2000;
export const NEW_COMMENT_ID_PREFIX = 'new-comment';

export const BAR_CHART_HEIGHT = 100;
export const BAR_CHART_ANIMATION_DELAY = 750;
export const DEFAULT_BAR_CHART_CO2_COLOR = '#d1d1d1';

export const MENU_ITEM_HEIGHT = 40;

export const NODON_PREFIX = `nodon_`;

export const NODON_OTHER_PRODUCT_ID = `${NODON_PREFIX}other_product`;
export const NODON_AIR_HANDLING_UNIT_ID = `${NODON_PREFIX}air_handling_unit`;
export const OTHER_PRODUCT_ID = `${NODON_PREFIX}other_product`;
export const OTHER_PRODUCT_M2_ID = `${NODON_PREFIX}other_product_m2`;
