import {
  create,
  all,
  SymbolNode,
  isSymbolNode,
  factory,
  MathNode,
} from 'mathjs';
import {
  firstDefined,
  firstNonZero,
  getParentValue,
  getProperty,
  isDefined,
  maxDefined,
} from './extentions';
import { uniq } from 'lodash';
import { isNumeric } from '../math_helpers';
import { cacheFactory } from '../function_helpers';
import { getSBEFLifetime } from './extentions/get-sbef-lifetime.helper';

function equal(a: any, b: any) {
  return a == b;
}

function unequal(a: any, b: any) {
  return a != b;
}

/**
 * Global math.js instance.
 * Use this instead of creating a new instance.
 */
export const mathJS = create(
  {
    ...all,
    // Override equal to support strings and booleans
    createEqual: factory('equal', [], () => equal),
    // Override unequal to support strings and booleans
    createUnequal: factory('unequal', [], () => unequal),
  },
  {
    predictable: true,
  },
);

const G = 9.81;

if (
  typeof mathJS.unit !== 'function' ||
  typeof mathJS.createUnit !== 'function'
) {
  throw new Error('mathjs dependencies are not installed');
}

mathJS.createUnit('N', `${1 / G} kg`, { override: true });
mathJS.createUnit('kN', `1000 N`);
mathJS.createUnit('pcs', '1');
mathJS.createUnit('percent', '0.01');
mathJS.createUnit('ppm', '0.000001');

const extensions = {
  firstDefined,
  firstNonZero,
  getParentValue,
  getProperty,
  isDefined,
  maxDefined,
  getSBEFLifetime,
} as const;

// Import extensions
mathJS.import(extensions);

/**
 * Gets all the numeric math.js functions and constants.
 * Returns them ending in an empty parenthesis ().
 */
export const getAllMathJSVariables = (): string[] => {
  const builtIn = Object.keys(mathJS).filter((key) => {
    try {
      const testExpression = `${key}(1)`;
      const res = mathJS.evaluate(testExpression) as number;

      return isNumeric(res) && typeof res === 'number' && Number.isFinite(res);
    } catch (error) {
      return false;
    }
  });

  return [...builtIn, ...Object.keys(extensions)].map((key) => `${key}()`);
};

/**
 * Prefixed variables are now deprecated but som expression might still have them.
 * sample: "@a + @b"
 * @param expression
 * @returns
 */
export const removeVariablePrefixes = (expression: string): string => {
  return expression.replace(/@/, '');
};

/**
 * Check if a variable name is valid.
 * @param name
 * @returns
 */
export const isValidVariableName = (name?: string): boolean => {
  return (
    !!name &&
    name.length < 30 &&
    /^[a-zåäö]+[a-zåäö0-9_]*$/gi.test(name) &&
    isAvailableMathJsName(name)
  );
};

export const isAvailableName = (name?: string): boolean => {
  const reservedNames = ['width', 'height', 'length'];

  return !!name && !reservedNames.includes(name);
};

/**
 * Get all user defined variables in an expression
 * @param expression
 * @param exclude
 * @returns
 */
export const getVariablesInExpression = (
  expression: string | undefined,
  exclude: string[] = [],
): string[] =>
  cacheFactory(
    () => {
      // Save some time if expression is empty or a number
      if (!expression || isNumeric(expression)) {
        return [];
      }

      // We previously used @ as variable prefix for a while. Remove this before parsing
      expression = removeVariablePrefixes(expression);

      const variables = getSymbolNodes(expression)
        .map((node) => node.name)
        .filter(isAvailableMathJsName);

      const unprefixedExclude = exclude.map(removeVariablePrefixes);

      return uniq(variables).filter((v) => !unprefixedExclude.includes(v));
    },
    `getVariablesInExpression[${expression ?? ''}]`,
    [],
  );

/**
 * Validate that a variable name is valid in math.js.
 * Also check that it's not already used as a function or constant like (PI, E, SQRT, etc.)
 * @param name
 * @returns
 */
const isAvailableMathJsName = (name: string): boolean =>
  cacheFactory(
    () => {
      try {
        // Ensure that Math.js recognize the name as ONE node ('a' not 'a-b')
        if (isSymbolNode(mathJS.parse(name))) {
          // Should crash since it shouldn't recognize the name
          const value = mathJS.evaluate(name);

          // Almost every letter is a unit so we need to allow to use unit names like kg, b, j, etc.
          if (mathJS.isUnit(value)) {
            return true;
          }
        }
      } catch (e) {
        if (e instanceof Error && e.message === `Undefined symbol ${name}`) {
          return true;
        }
      }

      return false;
    },
    `isAvailableMathJsName[${name}]`,
    [],
  );

const getMathJsNodes = (expression: string): MathNode[] => {
  const symbols: MathNode[] = [];

  try {
    mathJS.parse(removeVariablePrefixes(expression)).traverse((node) => {
      symbols.push(node);
    });
  } catch (e) {
    // Ignore
  }
  return symbols;
};

/**
 * Get all symbol nodes in an expression.
 * @param expression
 * @returns
 */
const getSymbolNodes = (expression: string): SymbolNode[] =>
  getMathJsNodes(expression).filter(isSymbolNode);
