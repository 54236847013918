import React, { FC, useMemo } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Control, FieldErrors, Controller } from 'react-hook-form';
import {
  ConversionFactors,
  ConversionFactorUnit,
} from '../../../../shared/models/unit.interface';
import { FormProduct } from './EditProductDialog';
import InputContainer from '../../components/InputContainer';
import {
  formatThousands,
  getDecimals,
  isNumeric,
  roundToDecimals,
} from '../../../../shared/helpers/math_helpers';
import { toTransportWasteConversionFactors } from '../../../../shared/helpers/results.helpers';

const INPUT_QUANTITY_NAMES: Partial<Record<ConversionFactorUnit, string>> = {
  'co2e_A1-A3': 'Global Warming Potential (A1-A3)',
  co2e_A4: 'Transport',
  co2e_A5: 'Spill',
  'sek_A1-A3': 'Cost (A1-A3)',
  kg: 'Mass',
  'm³': 'Volume',
  'm²': 'Area',
  m: 'Length',
  pcs: 'Pieces',
};

const INPUT_END_ADORNMENTS: Partial<Record<ConversionFactorUnit, string>> = {
  'co2e_A1-A3': 'kgCO2e',
  co2e_A4: 'km',
  co2e_A5: '%',
  'sek_A1-A3': 'SEK',
};

interface ConversionFactorInputProps {
  control: Control<FormProduct>;
  errors: Partial<FieldErrors<FormProduct>>;
  unit: ConversionFactorUnit;
  selectedUnit: ConversionFactorUnit;
  requiredUnits: ConversionFactorUnit[];
  fallbackConversionFactors?: ConversionFactors;
}

const EditProductDialogConversionFactorInput: FC<
  ConversionFactorInputProps
> = ({
  control,
  errors,
  unit,
  selectedUnit,
  requiredUnits,
  fallbackConversionFactors,
}) => {
  const title = `${INPUT_QUANTITY_NAMES[unit] ?? unit} ${
    unit !== 'co2e_A4' && unit !== 'co2e_A5' ? 'per ' + selectedUnit : ''
  }`;

  const errorMessage = errors.conversion_factors?.[unit]?.message;

  const placeholder = useMemo(() => {
    if (!fallbackConversionFactors) {
      return requiredUnits.includes(unit) ? '' : 'optional';
    }

    const factor = toTransportWasteConversionFactors(fallbackConversionFactors)[
      unit
    ];

    return factor ? formatThousands(factor) : '';
  }, [fallbackConversionFactors, requiredUnits, unit]);

  const inputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          {INPUT_END_ADORNMENTS[unit] ?? unit}
        </InputAdornment>
      ),
    }),
    [unit],
  );

  return (
    <InputContainer title={title} mt={3}>
      <Controller
        control={control}
        name={`conversion_factors.${unit}`}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              size="small"
              fullWidth
              value={toValue(field.value)}
              placeholder={placeholder}
              error={!!errorMessage}
              helperText={errorMessage}
              InputProps={inputProps}
            />
          );
        }}
      />
    </InputContainer>
  );
};

const toValue = (value: string | number | undefined): number | string => {
  if (value !== undefined && isNumeric(value)) {
    const numeric = +value;
    const decimals = getDecimals(numeric).toString();

    // Fix floating point errors like 0.1 * 0.2 = 0.020000000000000004 else return value without rounding (to keep input unmodified)
    return decimals.length > 10 ? roundToDecimals(+value, 10) : value;
  }
  return value ?? '';
};

export default EditProductDialogConversionFactorInput;
