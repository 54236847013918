import React, {
  ReactNode,
  FC,
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import Loading from '../components/Loading';
import axios from 'axios';
import { Typography } from '@mui/material';
import { version } from './config/version';
import { hasDefinedProperties } from '../../../shared/helpers/object_helpers';
import { ServerEnvironment } from '../../../shared/models/environment.interface';

interface IAuth0Config {
  domain: ServerEnvironment['AUTH0_DOMAIN'];
  clientId: ServerEnvironment['AUTH0_CLIENT_ID'];
  audience: ServerEnvironment['AUTH0_AUDIENCE'];
}

/**
 * Config for client only.
 * If changes are made remember to update the config in google cloud secret manager
 */
export interface IConfig {
  loading: boolean;
  loaded: boolean;
  error?: Error;
  auth0: IAuth0Config;
  /**
   * Url to API
   */
  apiUrl: string;
  googleMapsApiKey: ServerEnvironment['API_KEY'];
  environment: ServerEnvironment['ENVIRONMENT'];
  version: string;
}

/**
 * Default config that loads values from your .env locally.
 * Is overridden by config.json from google cloud secret manager on test/prod
 * You can also edit the public/config.json file to change config locally
 */
const DEFAULT_CONFIG: IConfig = {
  loading: true,
  loaded: false,
  apiUrl: 'http://localhost:50000',
  googleMapsApiKey: 'AIzaSyBHrsIYXZ68_7MANX-RJs31Ii8sTF35G1Y',
  auth0: {
    domain: 'nodon.eu.auth0.com',
    clientId: 'yTF7Jv9u1uXwQJ0q0WOpc5BkjVLHLAsW',
    audience: 'nodon-api-test',
  },
  version: version ?? '0.0.0',
  environment: 'localhost',
};

export interface IPartialConfig extends Partial<Omit<IConfig, 'auth0'>> {
  auth0?: Partial<IAuth0Config>;
}

export function mergeConfigs(a: IConfig, b: IPartialConfig): IConfig {
  return { ...a, ...b, auth0: { ...a.auth0, ...b.auth0 } };
}

export type Action =
  | { type: 'set config'; config: IPartialConfig }
  | { type: 'error'; error?: Error };

const reducer = (config: IConfig, action: Action): IConfig => {
  switch (action.type) {
    case 'set config': {
      return {
        ...mergeConfigs(config, action.config),
        loaded: true,
        loading: false,
      };
    }
    case 'error': {
      return { ...config, error: action.error, loading: false };
    }
    default: {
      console.error('invalid dispatch action: ', action);
      return config;
    }
  }
};

const ConfigStateContext = createContext<IConfig | undefined>(undefined);
const ConfigDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

interface IConfigProviderProps {
  children: ReactNode;
}

export const ConfigProvider: FC<IConfigProviderProps> = ({ children }) => {
  const [config, dispatch] = useReducer(reducer, DEFAULT_CONFIG);

  useEffect(() => {
    void dispatchConfig(dispatch);
  }, []);

  return (
    <ConfigStateContext.Provider value={config}>
      <ConfigDispatchContext.Provider value={dispatch}>
        {config.error && (
          <>
            <Typography variant="h6" color="error">
              Unable to load configuration
            </Typography>
            <Typography variant="subtitle1">{config.error.message}</Typography>
          </>
        )}
        {config.loading && <Loading />}
        {config.loaded && children}
      </ConfigDispatchContext.Provider>
    </ConfigStateContext.Provider>
  );
};

export const useConfig = (): [IConfig, Dispatch<Action>] => {
  const config = useContext(ConfigStateContext);
  const dispatch = useContext(ConfigDispatchContext);

  if (config === undefined || dispatch === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return [config, dispatch];
};

export const useAppVersion = (): string => {
  const [config] = useConfig();
  return config.version;
};

const vanillaAxios = axios.create();

const dispatchConfig = async (
  dispatch: Dispatch<Action>,
  configPath?: string,
): Promise<void> => {
  try {
    const config = await getConfig(configPath);
    dispatch({
      type: 'set config',
      config,
    });
  } catch (e: any) {
    dispatch({ type: 'error', error: e });
  }
};

const configRecord: Record<string, IConfig> = {};

/**
 * Get config from client-server. Fetch only once per path, then cache it.
 * Config is handled in google cloud secret manager (https://console.cloud.google.com/security/secret-manager)
 * And will automatically be added to build
 * @param path
 * @returns
 */
export const getConfig = async (
  path = '/config/config.json',
): Promise<IConfig> => {
  if (!configRecord[path]) {
    const { data } = await vanillaAxios.get<IPartialConfig>(path);

    // Merge fetched config with default config
    const config = mergeConfigs(DEFAULT_CONFIG, data);

    validateConfig(config);
    configRecord[path] = config;
  }

  return configRecord[path];
};

/**
 * Make sure config is valid, else throw error
 * @param config
 * @returns
 */
const validateConfig = (
  config: undefined | IPartialConfig,
): config is IConfig => {
  if (!hasDefinedProperties(config, 'apiUrl', 'auth0')) {
    throw new Error('Config is missing required fields');
  }
  return true;
};
