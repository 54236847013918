import {
  IElement,
  OneOfChildElements,
  OneOfElements,
  OneOfListElements,
} from '../models/project.interface';
import {
  IProductElementParentItem,
  ProductRecord,
} from '../models/product.interface';
import {
  getChildElements,
  isBuildingVersionElement,
  isElement,
} from './recursive_element_helpers';
import { getElementName } from './element_helpers';
import { cloneMoveAfter, cloneMoveBefore, sort } from './array_helpers';
import {
  SortOptions,
  ElementSortFn,
  IInsertSortPlacement,
  SortByAlternative,
} from '../models/sort.interface';
import { ResultsRecord } from '../models/unit.interface';
import { getConversionFactorValue } from './conversion-factors.helpers';

export const getElementSortFunction = <T extends OneOfListElements>({
  sortBy,
  productLookup,
  resultsRecord,
}: SortOptions): ElementSortFn<T> => {
  switch (sortBy) {
    case 'name': {
      return (a, b) => {
        return getElementName(a, productLookup).toLocaleLowerCase() >
          getElementName(b, productLookup).toLocaleLowerCase()
          ? 1
          : -1;
      };
    }
    case 'co2': {
      return (a, b) =>
        getConversionFactorValue(resultsRecord[b.id], 'co2e') -
        getConversionFactorValue(resultsRecord[a.id], 'co2e');
    }
    case 'cost': {
      return (a, b) =>
        getConversionFactorValue(resultsRecord[b.id], 'sek') -
        getConversionFactorValue(resultsRecord[a.id], 'sek');
    }
    case 'position': {
      // Function that doesn't change existing order
      return noSortFn;
    }
    default:
      throw new Error('Unknown element sort function requested.');
  }
};

const noSortFn = () => 0;

const getSortOptions = ({
  sortBy,
  productLookup,
  resultsRecord: quantitiesRecord,
}: SortOptions): SortOptions => {
  if (sortBy === 'name') {
    return { sortBy, productLookup };
  }
  if (sortBy === 'co2' || sortBy === 'cost') {
    return { sortBy, resultsRecord: quantitiesRecord };
  }
  return { sortBy };
};

export const sortElements = <T extends OneOfListElements = IElement>(
  elements: T[],
  options: SortOptions = { sortBy: 'position' },
): T[] => {
  // create copy of elements to avoid mutation of state and to keep the order of the original list
  const newList = [...elements];
  const sortOptions = getSortOptions(options);

  return sort<T>(newList, getElementSortFunction(sortOptions));
};

/**
 * Get all elements with children in a flat array.
 * Will be in the order of the element list
 */
export const flattenSortElements = <T extends OneOfElements>(
  sortBy: SortByAlternative,
  productLookup: ProductRecord,
  resultsRecord: ResultsRecord,
  ...elements: T[]
): (T | OneOfChildElements)[] => {
  const sortedElements = sortElements(elements, {
    sortBy,
    productLookup,
    resultsRecord,
  });

  const elementsWithChildren = sortedElements.map((element) => [
    element,
    ...flattenSortElements(
      sortBy,
      productLookup,
      resultsRecord,
      ...getChildElements(element),
    ),
  ]);

  return elementsWithChildren.flat();
};

export const sortInsert = <T>(
  placement: IInsertSortPlacement,
  elements: T[],
  adjacentElement?: T,
  ...elementsToInsert: T[]
) => {
  if (placement === 'first') {
    return [...elementsToInsert, ...elements];
  }
  if (placement === 'last') {
    return [...elements, ...elementsToInsert];
  }
  if (adjacentElement) {
    if (placement === 'before') {
      return cloneMoveBefore(elements, adjacentElement, ...elementsToInsert);
    }
    if (placement === 'after') {
      return cloneMoveAfter(elements, adjacentElement, ...elementsToInsert);
    }
  }
  return [...elementsToInsert, ...elements];
};

const getKindSortValue = <T extends OneOfListElements>(element: T): number => {
  if (isBuildingVersionElement(element)) {
    return 2;
  } else if (isElement(element)) {
    return 1;
  }
  return 0;
};

export const sortElementsByKind = <T extends OneOfListElements>(
  a: T,
  b: T,
  fallback?: ElementSortFn<T>,
) => {
  const byKind = getKindSortValue(b) - getKindSortValue(a);

  if (byKind === 0 && fallback) {
    return fallback(a, b);
  }
  return byKind;
};

export const sortProductsByCO2 = (
  a: IProductElementParentItem,
  b: IProductElementParentItem,
): number => {
  return b.co2eSum - a.co2eSum;
};
