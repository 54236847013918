import { getNodeValue } from './get-node-value.helper';

/**
 * Map value to numbers if they are defined, not NaN and not Infinite.
 * Other values will be undefined (to keep the array length the same)
 * @param args
 * @param math
 * @param scope
 * @returns
 */
export function mapDefinedToNumbers(
  args: any[],
  math: any,
  scope: any,
): (number | undefined)[] {
  return (args ?? []).map((node) => {
    const value = getNodeValue(node, math, scope);
    if (typeof value === 'number' && isFinite(value)) {
      return value;
    }
  });
}
