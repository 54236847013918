import React, { useMemo } from 'react';
import { Box, SxProps, Tooltip } from '@mui/material';
import { NodonTheme } from '../../style';
import { useProject } from '../../store/project';
import { formatValue } from '../../../../shared/helpers/math_helpers';
import { getWidthInPercent } from './bar-chart.helpers';

interface TargetIndicatorProps {
  maxValue: number;
}

const TargetIndicator: React.FC<TargetIndicatorProps> = ({ maxValue }) => {
  const { target } = useProject();

  const hoverAreaStyles = useMemo<SxProps>(
    () => ({
      zIndex: 0,
      position: 'absolute',
      height: '100%',
      transition: 'left 0.5s ease',
      padding: `0 ${HOVER_AREA_WIDTH}px`,
      left: `calc(${getWidthInPercent(getTarget(target, maxValue), maxValue)}% - ${HOVER_AREA_WIDTH}px)`,
    }),
    [target, maxValue],
  );
  const indicatorStyles = useMemo<SxProps>(
    () => ({
      height: '100%',
      borderLeft: `1px solid ${NodonTheme.palette.neutral.light}`,
    }),
    [],
  );

  return (
    !!target && (
      <Tooltip
        disableInteractive
        title={`Target: ${formatValue(target, 'kg/m²')}`}
      >
        <Box sx={hoverAreaStyles}>
          <Box sx={indicatorStyles} />
        </Box>
      </Tooltip>
    )
  );
};

const HOVER_AREA_WIDTH = 8;

const getTarget = (target: number | null | undefined, maxValue: number) => {
  if (!target) return 0;
  if (target > maxValue) return maxValue;
  return target;
};

export default TargetIndicator;
