import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ExpressionValue } from '../../../shared/models/project.interface';
import { SelectableQuantityUnit } from '../../../shared/models/unit.interface';
import { formatThousands } from '../../../shared/helpers/math_helpers';
import { getValueInSelectedUnit } from '../../../shared/helpers/conversion_helpers';

interface IExpressionTypographyInput {
  value: ExpressionValue | number | undefined;
  unit?: SelectableQuantityUnit | 'none';
  disabled?: boolean;
  isFallback?: boolean;
}

const ExpressionTypography: React.FC<IExpressionTypographyInput> = ({
  value,
  unit,
  disabled,
  isFallback,
}) => {
  const { classes, cx } = useStyles();
  const resolved = getValueInSelectedUnit(
    typeof value === 'number' ? value : (value?.resolved ?? 0),
    unit,
  );

  // Show "-" when fallback is 0 (empty)
  const formatted = isFallback && !resolved ? '-' : formatThousands(resolved);

  return (
    <Typography
      variant="body2"
      pr={2}
      className={cx(
        isFallback && classes.discrete,
        disabled && classes.disabled,
      )}
    >
      {formatted + ' '}
      <Typography variant="caption">{unit}</Typography>
    </Typography>
  );
};

const useStyles = makeStyles()(({ palette }) => ({
  discrete: {
    color: palette.text.disabled,
  },
  disabled: {
    color: palette.text.disabled,
  },
}));

export default ExpressionTypography;
