import React, { useMemo } from 'react';
import ResultsBarCharts from '../../../charts/ResultsBarCharts';
import { clamp } from 'lodash';
import {
  Results,
  ConversionFactorUnit,
} from '../../../../../../shared/models/unit.interface';
import {
  useProductListItemResultsDivisor,
  useProductListMaxResults,
} from '../../../../hooks/product-list.hook';
import {
  useProposalColor,
  useSelectedProposal,
} from '../../../../hooks/proposals.hook';
import { PRODUCT_CO2_BAR_HEIGHT } from '../../list.constants';
import {
  useCo2eChartData,
  useCostChartData,
} from '../../../../hooks/results.hook';
import { useProductListEmissionsPerMass as useShowProductListEmissionsPerMass } from '../../../../hooks/product-list.hook';
import { getConversionFactorValue } from '../../../../../../shared/helpers/conversion-factors.helpers';
import { ResultsTooltip } from '../../../ResultsTooltip';
import { getResultsByFactor } from '../../../../../../shared/helpers/results.helpers';

interface ProductListItemBarChartsProps {
  results?: Results;
  unit: ConversionFactorUnit;
}

const ProductListItemBarCharts: React.FC<ProductListItemBarChartsProps> = ({
  results = {},
  unit,
}) => {
  const getDivisor = useProductListItemResultsDivisor();

  const proposal = useSelectedProposal();
  const proposalColor = useProposalColor(proposal?.id ?? '');

  const isPerUnit = useShowProductListEmissionsPerMass();
  const divisor = getDivisor(unit, results);

  const maxResults = useProductListMaxResults();
  const maxResultsFactors = useProductListMaxResults(true);

  const resultsFactors = useMemo(
    () => getResultsByFactor(results, divisor),
    [results, divisor],
  );

  const co2ePerGFAResults = useCo2eChartData(resultsFactors);
  const costPerGFAResults = useCostChartData(resultsFactors);

  const co2eMax = getConversionFactorValue(maxResultsFactors, 'co2e');
  const costMax = getConversionFactorValue(maxResultsFactors, 'sek');

  const heightScale = getHeightScale(results, maxResults, unit);

  return (
    <ResultsTooltip results={resultsFactors} perUnit={isPerUnit ? unit : 'GFA'}>
      <ResultsBarCharts
        color={proposalColor}
        co2eHeight={
          isPerUnit ? heightScale * PRODUCT_CO2_BAR_HEIGHT : undefined
        }
        co2e={co2ePerGFAResults}
        cost={costPerGFAResults}
        co2eMax={co2eMax}
        costMax={costMax}
      />
    </ResultsTooltip>
  );
};

const getHeightScale = (
  results: Results,
  maxResults: Results,
  unit: ConversionFactorUnit,
) => {
  const unitValue = results[unit] ?? 1;
  const maxUnitValue = maxResults[unit] ?? 1;
  return clamp(unitValue / maxUnitValue, 0.08, 1);
};

export default ProductListItemBarCharts;
