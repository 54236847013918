import React, { Fragment, useCallback } from 'react';
import { Box, Divider, Tooltip } from '@mui/material';
import { createLocalStorageStore } from '../helpers/local-storage.helpers';
import {
  Lifecycle,
  lifecycles,
} from '../../../shared/models/lifecycles.interface';
import TextMenu from './menus/TextMenu/TextMenu';
import SelectMenuItem from './menus/ListItems/SelectMenuItem';
import { SelectListChildren } from './menus/menu.model';
import { getLifecycleLabel } from '../../../shared/helpers/lifecycles.helpers';

const { useStore, set } = createLocalStorageStore<Lifecycle[]>(
  'visible_lifecycles',
  [Lifecycle.A1A3, Lifecycle.A4, Lifecycle.A5], // Default to A1-A5
);
const ALL_LIFECYCLES = 'All';
type LifecycleItem = typeof ALL_LIFECYCLES | Lifecycle;
const ITEMS: LifecycleItem[] = [ALL_LIFECYCLES, ...lifecycles];

/**
 * Use the selected lifecycles from local storage
 */
export const useSelectedLifecycles = useStore;

export const SelectLifecycles: React.FC = () => {
  const selectedLifecycles = useSelectedLifecycles();
  const selectLifecycle = useSelectLifecycle();

  const isSelectedLifecycle = useCallback(
    (value: LifecycleItem) => {
      if (value === ALL_LIFECYCLES) {
        return selectedLifecycles.length === lifecycles.length;
      }
      return selectedLifecycles.includes(value);
    },
    [selectedLifecycles],
  );

  const renderChildren = useCallback<SelectListChildren<LifecycleItem>>(
    (item, style) => {
      return (
        <Fragment key={`lifecycle-container-${item}`}>
          <SelectMenuItem
            style={style}
            showCheckbox={true}
            selected={isSelectedLifecycle(item)}
            key={`lifecycle-${item}`}
            value={item}
            disableDivider={item !== ALL_LIFECYCLES}
            onClick={() => selectLifecycle(item)}
          />
          {item === ALL_LIFECYCLES && <Divider key="`lifecycle-divider" />}
        </Fragment>
      );
    },
    [isSelectedLifecycle, selectLifecycle],
  );

  return (
    <Tooltip title="Set which lifecycles should be included in the results">
      <Box>
        <TextMenu
          items={ITEMS}
          label={getLifecycleLabel(selectedLifecycles)}
          menuWidth="small"
        >
          {renderChildren}
        </TextMenu>
      </Box>
    </Tooltip>
  );
};

const useSelectLifecycle = (): ((value: LifecycleItem) => void) => {
  const selectedLifecycles = useSelectedLifecycles();
  return useCallback(
    (value: LifecycleItem) => {
      if (!ITEMS.includes(value)) {
        throw new Error(`Invalid lifecycle selection: ${value}`);
      }

      if (value === ALL_LIFECYCLES) {
        set(lifecycles.length === selectedLifecycles.length ? [] : lifecycles);
      } else if (selectedLifecycles.includes(value)) {
        set(selectedLifecycles.filter((item) => item !== value));
      } else {
        set([...selectedLifecycles, value]);
      }
    },
    [selectedLifecycles],
  );
};
