import {
  SBEFCode,
  SBEFCodeLanguage,
  sbefCodes,
  sbefCodesLookup,
  sbefLabelLookup,
  sbefLabels,
} from '../construction/sbef-codes';
import {
  ElementPropertyName,
  ElementSelectPropertyCountType,
  SBEFProperty,
} from '../models/element_property.interface';
import { OneOfElements, IElement } from '../models/project.interface';
import { Recipe } from '../models/recipe.interface';
import { isValidSelectPropertyCount } from '../validation';
import {
  getCount,
  getElementProperty,
  isElementSBEFProperty,
} from './element_property_helpers';
import { isRecipe } from './recipe_helpers';
import { isElement } from './recursive_element_helpers';

export const getSBEFCodeById = (id: string): SBEFCode | undefined => {
  return sbefCodesLookup[id.toString()];
};

export const getSBEFLifetimeById = (id: string): number | undefined => {
  return getSBEFCodeById(id)?.lifetime;
};

/**
 * Get the SBEF codes that should have a divider between them in the menu
 * @returns
 */
export const getSBEFDividerItems = () => {
  const items: string[] = [];

  for (let i = 0; i <= 10; i++) {
    if (i === 10) {
      i = 101;
    }

    const item = sbefLabelLookup[i];

    if (item) {
      items.push(item);
    }
  }
  return items;
};

/**
 * Get a record of SBEF codes grouped by the first digit
 * @param lang
 * @returns
 */
export const getSBEFGroups = (
  lang: SBEFCodeLanguage = 'en',
): Record<string, string[]> => {
  return sbefCodes.reduce<Record<string, string[]>>(
    (acc, code) => {
      const name = code.id + '. ' + code.description[lang];

      // Create the SBEF main categories (groups) for single digit codes
      if (code.id.length === 1) {
        acc[name] = [];
      }

      // If the id has more than two digits (eg '101'), it belongs to an unspecified category
      if (code.id.length > 2) {
        acc['other']?.push(name);
      }

      /* 
      Place the code in the correct group, eg:
      0. group label
        00. code label
        01. code label
        ...
      */
      if (code.id.length > 1) {
        const match = Object.keys(acc).find((key) => {
          const firstChar = code.id[0];
          return firstChar && key.startsWith(firstChar);
        });

        if (match) acc[match]?.push(name);
      }

      return acc;
    },
    { other: [] },
  );
};

/**
 * Get a valid SBEF code from a string (eg "04. Afterlaying" -> "04")
 * @param count
 * @param fallback
 * @returns
 */
export const getSBEFCode = (
  count: ElementSelectPropertyCountType,
  fallback = '-',
): string | undefined => {
  const string = typeof count === 'string' ? count : '';
  const code = string.split('.')[0];

  if (code && isNaN(Number(code))) {
    return fallback;
  }
  return code;
};

/**
 * Get a SBEF property from an element
 * @param element
 * @returns
 */
export const getSBEFProperty = (
  element: IElement,
): SBEFProperty | undefined => {
  const property = getElementProperty(element, ElementPropertyName.SBEFCode);
  return isElementSBEFProperty(property) ? property : undefined;
};

/**
 * Get a the code part (eg "04") of the SBEF property from an element
 * @param element
 * @returns
 */
export const getSBEFCodeFromElement = (element: OneOfElements): string => {
  if (isElement(element)) {
    const property = getSBEFProperty(element);

    if (property && property.count) {
      return getSBEFCode(property.count) ?? '';
    }
  }
  return '';
};

/**
 * Get a valid SBEF count value (code + description) from an element or recipe
 * @param element
 * @returns
 */
export const getSBEFCountValue = (element: OneOfElements | Recipe): string => {
  let count: ElementSelectPropertyCountType | undefined;

  if (isRecipe(element)) {
    count = element.category_property_value_record[
      ElementPropertyName.SBEFCode
    ] as ElementSelectPropertyCountType;
  }
  if (isElement(element)) {
    const property = getSBEFProperty(element);
    count = getCount(property, false);
  }
  if (count && isValidSelectPropertyCount(count)) {
    return count.toString();
  }
  return '';
};

/**
 * Get a valid SBEF option from a string (the value used when selecting an option in the SBEF dropdown)
 * @param option
 * @returns
 */
export const getSBEFOption = (code: string): string => {
  return sbefLabels.find((o) => o.startsWith(code + '.')) ?? 'none';
};
