import {
  ElementPropertyType,
  IFactorySelectProperty,
  IFactorySwitchProperty,
} from '../../../models/element_property.interface';
import { PropertiesOptions } from '../../../models/element_categories.interface';
import {
  ProductTree,
  ProductTreeCountMap,
  ProductTreeUnitMap,
} from '../processor.model';

const PRODUCT_FLOAT_GLASS = 'boverket_sv-SE_6000000122';
const PRODUCT_FIRE_RESISTENT = 'boverket_sv-SE_6000000179';
const PRODUCT_INSULATING_DOUBLE = 'boverket_sv-SE_6000000175';
const PRODUCT_INSULATING_TRIPPLE = 'boverket_sv-SE_6000000176';
const PRODUCT_SAFETY_LAMINATED = 'boverket_sv-SE_6000000177';
const PRODUCT_SAFETY_TOUGHENED = 'boverket_sv-SE_6000000178';

// Property Names

export enum WindowsDoorsPropertyName {
  WindowsDoorsCategory = 'category',
  Opening = 'opening',
  FrameMaterial = 'frame_material',
}

export enum WindowsDoorsSpecialPropertyName {
  Glazing = 'glazing',
  FireRated = 'fire_rated',
  WindowsDoorsType = 'type',
}

// Parent types

export enum WindowsDoorsCategory {
  Window = 'Window',
  DoorExternal = 'Door, external',
  DoorInternal = 'Door, internal',
  GlassGoods = 'Glass goods',
}

// Nested types

export enum Opening {
  SideHung = 'Side hung',
  Inward = 'Inward',
  FullyReversable = 'Fully reversable',
  Fixed = 'Fixed',
}

export enum FrameMaterial {
  Aluminum = 'Aluminum',
  WoodAluminum = 'Wood, aluminum',
  Wood = 'Wood',
  WoodLaminated = 'Wood (laminated)',
  SteelStainless = 'Steel (stainless)',
  SteelCarbon = 'Steel (carbon)',
}

export enum FireRated {
  False = 'false',
  True = 'true',
}

export enum Glazing {
  Full = 'Full',
  Semi = 'Semi',
  None = 'None',
}

export enum WindowsDoorsType {
  FloatGlass = 'Float glass',
  FireResistent = 'Fire-resistent',
  InsulatingDouble = 'Insulating, double',
  InsulatingTripple = 'Insulating, tripple',
  SafetyLaminated = 'Safety, laminated',
  SafetyToughened = 'Safety, toughened',
}

// productTree

export const windowsDoorsProductTree: ProductTree = {
  [WindowsDoorsCategory.Window]: {
    [Opening.SideHung]: {
      [FrameMaterial.WoodAluminum]: 'boverket_sv-SE_6000000103',
      [FrameMaterial.Wood]: 'boverket_sv-SE_6000000104',
    },
    [Opening.Inward]: {
      [FrameMaterial.WoodAluminum]: 'boverket_sv-SE_6000000106',
      [FrameMaterial.Wood]: 'boverket_sv-SE_6000000105',
    },
    [Opening.FullyReversable]: {
      [FrameMaterial.WoodAluminum]: 'boverket_sv-SE_6000000108',
      [FrameMaterial.Wood]: 'boverket_sv-SE_6000000107',
    },
    [Opening.Fixed]: {
      [FrameMaterial.WoodAluminum]: 'boverket_sv-SE_6000000110',
      [FrameMaterial.Wood]: 'boverket_sv-SE_6000000109',
    },
  },
  [WindowsDoorsCategory.DoorExternal]: {
    [Glazing.Full]: {
      [FrameMaterial.WoodAluminum]: 'boverket_sv-SE_6000000114',
      [FrameMaterial.Wood]: 'boverket_sv-SE_6000000113',
      [FrameMaterial.Aluminum]: 'boverket_sv-SE_6000000187',
    },
    [Glazing.Semi]: {
      [FrameMaterial.WoodAluminum]: 'boverket_sv-SE_6000000112',
      [FrameMaterial.Wood]: 'boverket_sv-SE_6000000111',
    },
    [Glazing.None]: {
      [FrameMaterial.Wood]: 'boverket_sv-SE_6000000118',
      [FrameMaterial.SteelStainless]: 'boverket_sv-SE_6000000116',
      [FrameMaterial.SteelCarbon]: 'boverket_sv-SE_6000000115',
    },
  },
  [WindowsDoorsCategory.DoorInternal]: {
    [FireRated.True]: {
      [FrameMaterial.Wood]: 'boverket_sv-SE_6000000119',
      [FrameMaterial.WoodLaminated]: 'boverket_sv-SE_6000000120',
      [FrameMaterial.SteelCarbon]: 'boverket_sv-SE_6000000117',
    },
    [FireRated.False]: {
      [FrameMaterial.Wood]: 'boverket_sv-SE_6000000121',
    },
  },
  [WindowsDoorsCategory.GlassGoods]: {
    [WindowsDoorsType.FloatGlass]: PRODUCT_FLOAT_GLASS,
    [WindowsDoorsType.FireResistent]: PRODUCT_FIRE_RESISTENT,
    [WindowsDoorsType.InsulatingDouble]: PRODUCT_INSULATING_DOUBLE,
    [WindowsDoorsType.InsulatingTripple]: PRODUCT_INSULATING_TRIPPLE,
    [WindowsDoorsType.SafetyLaminated]: PRODUCT_SAFETY_LAMINATED,
    [WindowsDoorsType.SafetyToughened]: PRODUCT_SAFETY_TOUGHENED,
  },
};

// Properties
export const windowsDoorsCategoryProperty: IFactorySelectProperty = {
  name: WindowsDoorsPropertyName.WindowsDoorsCategory,
  type: ElementPropertyType.Select,
  count: WindowsDoorsCategory.Window,
  options: Object.values(WindowsDoorsCategory),
};

export const windowsDoorsOpeningProperty: IFactorySelectProperty = {
  name: WindowsDoorsPropertyName.Opening,
  type: ElementPropertyType.Select,
  count: Opening.Fixed,
  options: Object.values(Opening),
};

export const windowsDoorsFrameMaterialProperty: IFactorySelectProperty = {
  name: WindowsDoorsPropertyName.FrameMaterial,
  type: ElementPropertyType.Select,
  count: FrameMaterial.Aluminum,
  options: Object.values(FrameMaterial),
};

// Alternative properties

export const windowsDoorsGlazingProperty: IFactorySelectProperty = {
  name: WindowsDoorsSpecialPropertyName.Glazing,
  type: ElementPropertyType.Select,
  count: Glazing.Full,
  options: Object.values(Glazing),
};

export const windowsDoorsFireRatedProperty: IFactorySwitchProperty = {
  name: WindowsDoorsSpecialPropertyName.FireRated,
  type: ElementPropertyType.Switch,
};

export const windowsDoorsFrameTypeProperty: IFactorySelectProperty = {
  name: WindowsDoorsSpecialPropertyName.WindowsDoorsType,
  type: ElementPropertyType.Select,
  count: WindowsDoorsType.FireResistent,
  options: Object.values(WindowsDoorsType),
};

// Options

export const propertiesOptions: PropertiesOptions[] = [
  {
    [WindowsDoorsCategory.DoorExternal]: {
      propertyName: WindowsDoorsSpecialPropertyName.Glazing,
      property: windowsDoorsGlazingProperty,
    },
  },
  {
    [WindowsDoorsCategory.DoorInternal]: {
      propertyName: WindowsDoorsSpecialPropertyName.FireRated,
      property: windowsDoorsFireRatedProperty,
    },
  },
  {
    [WindowsDoorsCategory.GlassGoods]: {
      propertyName: WindowsDoorsSpecialPropertyName.WindowsDoorsType,
      property: windowsDoorsFrameTypeProperty,
    },
  },
];

export const windowsDoorsUnit = 'kg/m²';
export const windowsDoorsCount = 'area_side';

export const windowsDoorsProductCountMap: ProductTreeCountMap = {
  mass: '*',
};

export const windowsDoorsProductUnitMap: ProductTreeUnitMap = {
  kg: '*',
};
