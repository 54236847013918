import React from 'react';
import NodonSwitch from './NodonSwitch';
import {
  setProductListEmissionsPerMass,
  useProductListEmissionsPerMass,
} from '../../hooks/product-list.hook';

const ProductListEmissionsPerMassSwitch = () => {
  const emissionsPerMass = useProductListEmissionsPerMass();

  return (
    <NodonSwitch
      label="Per kg product"
      checked={emissionsPerMass}
      onChange={setProductListEmissionsPerMass}
    />
  );
};

export default ProductListEmissionsPerMassSwitch;
