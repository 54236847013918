import { uniqBy } from 'lodash';
import { findDuplicates } from '../helpers/array_helpers';
import {
  getElementPropertySource,
  getElementSourceId,
  getElementPropertySourceId,
  getCount,
  isElementExpressionProperty,
  isElementSelectProperty,
  isElementSwitchProperty,
  IPropertiesOrPropertyParent,
  getElementProperties,
  getElementPropertiesByRecipeSource,
  getElementPropertiesByCategorySource,
  isSelectPropertyOptionValue,
  getSelectPropertyOptions,
} from '../helpers/element_property_helpers';
import {
  isElementQuantityExpressionProperty,
  isElementQuantityProperty,
} from '../helpers/element_quantity_helpers';
import { isValidVariableName } from '../helpers/mathjs';
import { isAutoRecipeId } from '../helpers/recipe_helpers';
import { isCountOfType } from '../helpers/count.helpers';
import { ElementCategoryID } from '../models/element_categories.interface';
import {
  IElementProperty,
  ElementPropertySource,
  IElementExpressionProperty,
  IElementSelectProperty,
  IElementSwitchProperty,
  ElementPropertyType,
} from '../models/element_property.interface';
import { OneOfElements } from '../models/project.interface';
import { ValidationTypes, throwValidationErrors } from './validation.helpers';
import { isElement } from '../helpers/recursive_element_helpers';
import { ProjectValidationErrors } from './project.validation';
import { getElementName } from '../helpers/element_helpers';
import { getElementCategoryById } from '../templates/categories';
import { isSelectableQuantityUnit } from '../helpers/unit_helpers';
import { selectableQuantityUnits } from '../models/unit.interface';

const isValidPropertySource = (
  element: OneOfElements,
  property: IElementProperty,
): ValidationTypes => {
  const source = getElementPropertySource(property);

  // Undefined source is valid
  if (!source) {
    return true;
  }

  const sourceId = getElementSourceId(element, source);
  const propertySourceId = getElementPropertySourceId(property);

  if (source === ElementPropertySource.Category) {
    const category = getElementCategoryById(
      sourceId as ElementCategoryID,
      false,
    );
    if (!category) {
      return `Category "${sourceId}" not found`;
    }
  }

  // category_id and recipe_id must match on element and property
  if (sourceId !== propertySourceId) {
    console.info(
      `Invalid ${source} id ${propertySourceId + ''} on ${getElementName(
        element,
      )} - ${property.name}`,
    );
    return `Invalid ${source} id ${propertySourceId + ''}`;
  }
  return true;
};

export const validatePropertySource = <T extends IElementProperty>(
  element: OneOfElements,
  property: T,
): T => {
  throwValidationErrors(isValidPropertySource(element, property));
  return property;
};

const isValidExpressionProperty = (
  property: IElementExpressionProperty,
): ValidationTypes => {
  const { min, max } = property;
  const count = getCount(property, false);

  // Must be an Expression
  if (!isCountOfType(count, ElementPropertyType.Expression)) {
    return 'Property of type expression has no count or fallbackCount';
  }

  // Min value
  if (typeof min === 'number' && count.resolved < min) {
    return `Property value below min`;
  }

  // Max value
  if (typeof max === 'number' && count.resolved > max) {
    return `Property value above max`;
  }

  return true;
};

/**
 *
 * @param count
 * @param allowUndefined
 * @returns
 */
export const isValidSelectPropertyCount = (
  count: unknown,
  allowUndefined?: boolean,
): count is IElementSelectProperty['count'] => {
  if (allowUndefined && count === undefined) {
    return true;
  }
  return isCountOfType(count, ElementPropertyType.Select);
};

export const isValidSelectProperty = (
  property: IElementSelectProperty,
): ValidationTypes => {
  const { inheritFallback, fallbackCount, count } = property;

  if (!getSelectPropertyOptions(property).length) {
    return ProjectValidationErrors.PROPERTY.SELECT.NO_OPTIONS;
  }

  if (!inheritFallback && count === undefined && fallbackCount === undefined) {
    return ProjectValidationErrors.PROPERTY.SELECT.NO_COUNT_OR_FALLBACK_COUNT;
  }
  // Count is allowed to be undefined if inheritFallback is true
  if (!isValidSelectPropertyCount(count, inheritFallback)) {
    return ProjectValidationErrors.PROPERTY.SELECT.INVALID_COUNT;
  }
  // Fallback count is allowed to be undefined if count is set
  if (!isValidSelectPropertyCount(fallbackCount, true)) {
    return ProjectValidationErrors.PROPERTY.SELECT.INVALID_COUNT;
  }
  if (count && !isSelectPropertyOptionValue(property, count)) {
    console.warn(
      `Count: "${String(count)}" of property "${property.name}" is not part of options ${getSelectPropertyOptions(
        property,
      )
        .map((o) => `"${o.value}"`)
        .join(', ')}`,
    );
    return ProjectValidationErrors.PROPERTY.SELECT.COUNT_NOT_IN_OPTIONS;
  }
  if (fallbackCount && !isSelectPropertyOptionValue(property, fallbackCount)) {
    console.warn(
      `Fallback count: "${String(fallbackCount)}" of property "${property.name}" is not part of options ${getSelectPropertyOptions(
        property,
      )
        .map((o) => `"${o.value}"`)
        .join(', ')}`,
    );
    return ProjectValidationErrors.PROPERTY.SELECT.COUNT_NOT_IN_OPTIONS;
  }
  return true;
};

export const isValidSwitchPropertyCount = (
  count: unknown,
  inheritFallback?: boolean,
): count is IElementSwitchProperty['count'] => {
  if (inheritFallback && count === undefined) {
    return true;
  }
  return isCountOfType(count, ElementPropertyType.Switch);
};

const isValidSwitchProperty = (
  property: IElementSwitchProperty,
): ValidationTypes => {
  const { count, inheritFallback } = property;
  if (!isValidSwitchPropertyCount(count, inheritFallback)) {
    return 'Invalid count for type switch';
  }

  return true;
};

export const isValidProperty = (
  property: IElementProperty | undefined,
): ValidationTypes => {
  if (!property) {
    return 'Property is undefined';
  }
  if (typeof property.id !== 'string' || !property.id) {
    return 'Property has no id';
  }
  if (property.recipe_id && property.category_id) {
    return ProjectValidationErrors.INVALID_PROPERTY_SOURCE;
  }
  if (isAutoRecipeId(property.recipe_id)) {
    return 'Property cannot have auto recipe id';
  }
  if (!isValidVariableName(property.name)) {
    return ProjectValidationErrors.INVALID_PROPERTY_NAME;
  }

  // Expression
  if (isElementExpressionProperty(property)) {
    return isValidExpressionProperty(property);
  }
  // Select
  else if (isElementSelectProperty(property)) {
    return isValidSelectProperty(property);
  }
  // Switch
  else if (isElementSwitchProperty(property)) {
    return isValidSwitchProperty(property);
  }
  // Invalid property type
  else {
    return 'Invalid property type';
  }
};

export const validateElementProperty = <T extends IElementProperty>(
  property: T,
): T => {
  throwValidationErrors(isValidProperty(property));
  return property;
};

export const validateElementProperties = (
  properties?: IElementProperty[],
): IElementProperty[] => {
  throwValidationErrors(isValidElementProperties(properties));
  return properties || [];
};

export const isValidElementProperties = (
  elementOrProps?: IPropertiesOrPropertyParent,
): ValidationTypes => {
  if (!elementOrProps) {
    return true;
  }

  const properties = getElementProperties(elementOrProps);

  // All properties must be valid
  for (const property of properties) {
    // Test that property is valid
    const propertyValid = isValidProperty(property);
    if (propertyValid !== true) {
      return propertyValid;
    }

    // Test that sources match if element
    const sourceValid =
      !isElement(elementOrProps) ||
      isValidPropertySource(elementOrProps, property);

    if (sourceValid !== true) {
      return sourceValid;
    }
  }

  const duplicateIds = findDuplicates(properties.map(({ id }) => id));

  // Can't have duplicate ids
  if (duplicateIds.length) {
    return 'Duplicate property ids';
  }

  const recipeProperties = getElementPropertiesByRecipeSource(elementOrProps);
  const categoryProperties =
    getElementPropertiesByCategorySource(elementOrProps);

  // Names must be unique within recipe
  if (uniqBy(recipeProperties, 'name').length !== recipeProperties.length) {
    return 'Duplicate property name in recipe properties';
  }
  // Names must be unique within category
  if (uniqBy(categoryProperties, 'name').length !== categoryProperties.length) {
    return 'Duplicate property name in category properties';
  }

  return true;
};

export const isValidElementQuantityProperties = (
  element: OneOfElements,
): ValidationTypes => {
  // Only elements have quantities
  if (!isElement(element)) {
    return true;
  }

  const { quantity, selectedQuantity } = element;

  // Selected quantity must be part of quantity
  if (selectedQuantity && !quantity?.[selectedQuantity]) {
    return `Selected quantity "${selectedQuantity}" is not part of quantity`;
  }

  // Quantity is not required
  if (!quantity) {
    return true;
  }
  // Sometimes we use an array of quantities. But make sure we never save it like that
  if (Array.isArray(quantity)) {
    return 'Quantity cannot be an array';
  }

  const properties = Object.values(quantity);
  for (const property of properties) {
    if (!isElementQuantityProperty(property)) {
      return 'Invalid quantity property';
    }
    if (
      isElementQuantityExpressionProperty(property) &&
      !isSelectableQuantityUnit(property.unit)
    ) {
      return `Invalid quantity property unit "${String(property.unit)}, expected one of ${selectableQuantityUnits.join(', ')}"`;
    }
  }
  return true;
};
