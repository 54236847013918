import { useCallback, useMemo } from 'react';
import {
  getSelectedVersion,
  useSelectedVersion,
  useSelectedVersionId,
} from '../store/ui';
import { IProposal } from '../../../shared/models/proposals.interface';
import {
  getProject,
  useProject,
  useUpdateElements,
} from '../store/project/project.hook';
import {
  selectElementByProposals,
  addProposal,
  deleteProposal,
  getAvailableProposals,
  getProposalColor,
  getProposalsInVersion,
  getProposalsUsingElement,
  removeElementFromProposals,
  selectProposal,
  updateElementActiveVersionStates,
  getElementsActiveInProposal,
  updateProposals,
} from '../../../shared/helpers/proposal.helpers';
import {
  ArrayOrSingle,
  ItemOrItemId,
  SemiPartial,
} from '../../../shared/models/type_helpers.interface';
import {
  IBuildingVersion,
  IElement,
  OneOfElementListElements,
  OneOfParentElements,
  Project,
} from '../../../shared/models/project.interface';
import {
  getId,
  isEmptyObject,
  mapFilterRecord,
} from '../../../shared/helpers/object_helpers';
import { getElementVersionId } from '../../../shared/helpers/element-version.helpers';
import { useIsConfirmed } from './confirm.hook';
import { required } from '../../../shared/helpers/function_helpers';
import {
  createRecordByKey,
  EMPTY_ARRAY,
  isEmptyArray,
  isOneOf,
} from '../../../shared/helpers/array_helpers';
import { useElementPath } from './useElement';
import {
  ConversionFactorGroupKey,
  Results,
  ResultsRecord,
} from '../../../shared/models/unit.interface';
import {
  useElementMaxResults,
  useFilterResultsRecordBySelectedLifecycles,
  useResultsPerGFA,
  useVersionResultRecord,
} from './results.hook';
import {
  clamp,
  getMaxValuesInArray,
} from '../../../shared/helpers/math_helpers';
import { getResultsRecord } from '../../../shared/helpers/results.helpers';
import { isMainCategoryElement } from '../../../shared/templates/categories';
import { isBuildingVersionElement } from '../../../shared/helpers/recursive_element_helpers';
import { getConversionFactorValue } from '../../../shared/helpers/conversion-factors.helpers';
import { createLocalStorageRecordStore } from '../helpers/local-storage.helpers';
import {
  getCo2eChartDataFromResults,
  getCostChartDataFromResults,
} from '../components/charts/bar-chart.helpers';
import { useObjectMemo } from './object.hook';

export const {
  setItem: setHiddenProposal,
  useStoreItem: useHiddenProposal,
  useStoreRecord: useHiddenProposals,
} = createLocalStorageRecordStore<IProposal['id'], boolean>('hidden_proposals');

export const useProposals = () => {
  const version = useSelectedVersion();
  return useMemo(() => getProposalsInVersion(version), [version]);
};

/**
 * Get which proposals that is available for a specific element.
 * If a parent element is not selected by a proposal that proposal
 * will not be available for any children deeper in the hierarchy
 * @param element
 * @returns List of available proposals for the element
 */
export const useAvailableProposals = (element: OneOfElementListElements) => {
  const proposals = useProposals();
  const path = useElementPath(element);

  return useMemo(
    () => getAvailableProposals(proposals, path),
    [path, proposals],
  );
};

/**
 * Create a new proposal. Pass existing proposal to duplicate
 * but will duplicate the selected if no proposal is passed
 * @returns
 */
export const useAddProposal = () => {
  const updateVersion = useUpdateElements();

  return useCallback(
    async (proposal: Partial<IProposal> | undefined = getActiveProposal()) => {
      const version = getSelectedVersion(true);
      await updateVersion(addProposal(version, proposal));
    },
    [updateVersion],
  );
};

export const useDeleteProposal = () => {
  const updateVersion = useUpdateElements();
  const confirm = useIsConfirmed();
  const selectProposal = useSelectProposal();

  return useCallback(
    async (proposalOrId: ItemOrItemId<IProposal>) => {
      const version = getSelectedVersion(true);
      const id = getId(proposalOrId);
      const proposal = required(getProposals().find((p) => p.id === id));
      if (await confirm(`Are you sure you want to delete ${proposal?.name}?`)) {
        await updateVersion(deleteProposal(version, proposalOrId));
        const remainingProposalIds = getProposals().map(getId);
        const selectedId = getSelectedProposalId();
        const firstProposal = remainingProposalIds[0];

        // If no version is selected, select the first proposal
        if (firstProposal && !isOneOf(remainingProposalIds, selectedId)) {
          selectProposal(firstProposal);
        }
      }
    },
    [confirm, selectProposal, updateVersion],
  );
};

export const useUpdateProposal = () => {
  const updateVersion = useUpdateElements();

  return useCallback(
    async (proposal: IProposal) => {
      const version = getSelectedVersion(true);
      await updateVersion({ ...version, proposals: [proposal] });
    },
    [updateVersion],
  );
};

export const useSelectedProposalId = (): IProposal['id'] | undefined => {
  const proposals = useProposals();
  return proposals.find((proposal) => proposal.active)?.id;
};

export const useSelectedProposal = (): IProposal | undefined => {
  const proposals = useProposals();
  const id = useSelectedProposalId();
  return proposals.find((proposal) => proposal.id === id);
};

export const useIsSelectedProposal = (
  proposalOrId: ItemOrItemId<IProposal>,
): boolean => {
  return getId(proposalOrId) === useSelectedProposalId();
};

export const useSelectProposal = () => {
  const updateElements = useUpdateElements();

  return useCallback(
    async (proposalOrId: ItemOrItemId<IProposal>) => {
      const version = getSelectedVersion(true);
      const id = getId(proposalOrId);

      const updatedVersion = selectProposal(version, id);

      // Version was already selected
      if (updatedVersion === version) {
        return;
      }

      await updateElements<OneOfParentElements>(
        updateElementActiveVersionStates(updatedVersion),
      );
    },
    [updateElements],
  );
};

export const useProposalsUsingElement = (element: OneOfElementListElements) => {
  const proposals = useAvailableProposals(element);
  const path = useElementPath(element);

  // Find the closest element with a version id
  const closestVersionElement = useMemo(
    () => [...path, element].reverse().find(getElementVersionId) ?? element,
    [path, element],
  );

  return useMemo(() => {
    return getProposalsUsingElement(proposals, path, closestVersionElement);
  }, [closestVersionElement, path, proposals]);
};

export const useIsUsedByAnyProposal = (element: IElement): boolean => {
  return useProposalsUsingElement(element).length > 0;
};

export const useIsElementSelectedInProposal = (
  element: IElement,
  proposal: IProposal,
): boolean => useProposalsUsingElement(element).includes(proposal);

/**
 * Get proposals from selected version without causing re-renders
 * @returns
 */
export const getProposals = () => {
  return getSelectedVersion(true).proposals ?? (EMPTY_ARRAY as IProposal[]);
};

export const getSelectedProposalId = () => {
  return getProposals().find((p) => p.active)?.id;
};

export const getActiveProposal = (): IProposal | undefined => {
  const proposals = getProposals();
  return proposals.find((proposal) => proposal.active);
};

/**
 * Add element in proposal. By default it will add element in selected proposal
 * but you can pass proposal to add element in that proposal
 * @returns
 */
export const useSelectElementByProposals = () => {
  const updateVersion = useUpdateElements();

  return useCallback(
    async (
      element: IElement,
      proposalsOrId:
        | ArrayOrSingle<ItemOrItemId<IProposal>>
        | undefined = getActiveProposal(),
    ): Promise<Project> => {
      const version = getSelectedVersion();
      if (!proposalsOrId || isEmptyArray(proposalsOrId)) {
        throw new Error('No proposal selected');
      }
      if (version) {
        const updatedVersion = selectElementByProposals(
          version,
          proposalsOrId,
          element,
        );
        await updateVersion(updatedVersion);
      }
      return getProject();
    },
    [updateVersion],
  );
};

/**
 * Remove element from proposal. By default it will remove element from selected proposal
 * but you can pass proposal to remove element from that proposal
 * @returns
 */
export const useRemoveElementFromProposals = () => {
  const updateVersion = useUpdateElements();

  return useCallback(
    async (
      element: IElement,
      proposalOrIds:
        | ArrayOrSingle<ItemOrItemId<IProposal>>
        | undefined = getActiveProposal(),
    ) => {
      const version = getSelectedVersion();

      if (!proposalOrIds || isEmptyArray(proposalOrIds)) {
        throw new Error('No proposal selected');
      }
      if (version) {
        const updatedVersion = removeElementFromProposals(
          version,
          proposalOrIds,
          element,
        );
        await updateVersion(updatedVersion);
      }
      return getProject();
    },
    [updateVersion],
  );
};

export const useGetProposalColor = () => {
  const proposals = useProposals();

  return useCallback(
    (proposalOrId: ItemOrItemId<IProposal>) => {
      return getProposalColor(proposals, proposalOrId);
    },
    [proposals],
  );
};

export const useProposalColor = (proposalOrId: ItemOrItemId<IProposal>) => {
  const getProposalColor = useGetProposalColor();
  return useMemo(
    () => getProposalColor(proposalOrId),
    [getProposalColor, proposalOrId],
  );
};

export const useProposalResultsRecord = (
  proposalOrId: ItemOrItemId<IProposal> | undefined,
): ResultsRecord => {
  const proposals = useProposals();
  const id = proposalOrId ? getId(proposalOrId) : undefined;
  const proposalRecord = proposals.find((p) => p.id === id)?.resultsRecord;
  // Fallback for older projects
  const versionRecord = useVersionResultRecord();

  const lifecycleFilter = useFilterResultsRecordBySelectedLifecycles();

  return useMemo(
    () =>
      proposalRecord && !isEmptyObject(proposalRecord)
        ? lifecycleFilter(proposalRecord)
        : versionRecord, // already filtered

    [proposalRecord, versionRecord, lifecycleFilter],
  );
};

/**
 * Get the results of all values for a specific proposal.
 * I.E. How much would a version emit/cost for a specific proposal
 * @param proposalOrId
 * @returns
 */
export const useProposalResults = (proposalOrId: ItemOrItemId<IProposal>) => {
  const id = useSelectedVersionId();
  const record = useProposalResultsRecord(proposalOrId) as
    | ResultsRecord
    | undefined;

  return useMemo(() => {
    if (!id || !record || !record[id]) {
      return {};
    }
    return record[id];
  }, [id, record]);
};

/**
 * Get all proposal results records
 * @returns
 */
export const useAllProposalResultRecords = (): Record<
  IProposal['id'],
  ResultsRecord
> => {
  const lifecycleFilter = useFilterResultsRecordBySelectedLifecycles();
  const version = required(useSelectedVersion());
  const proposals = useProposals();
  const resultsRecord = useMemo(
    () =>
      createRecordByKey(proposals, 'id', (p) =>
        lifecycleFilter(getResultsRecord(getProject(), version, p)),
      ),
    [proposals, version, lifecycleFilter],
  );

  return resultsRecord;
};

export const useElementResultsOfAllProposals = (
  elementOrId: ItemOrItemId<OneOfElementListElements>,
) => {
  const id = getId(elementOrId);
  const records = useAllProposalResultRecords();
  return useMemo(() => mapFilterRecord(records, (r) => r[id]), [id, records]);
};

/**
 * Get all max values
 * @returns
 */
export const useProposalsMaxResults = (): Results => {
  const versionId = useSelectedVersionId();
  const versionRecord = useVersionResultRecord();
  const allProposalResultRecords = useAllProposalResultRecords();

  return useMemo(() => {
    const resultsArray = [
      {},
      ...Object.values(allProposalResultRecords).map((record) =>
        versionId ? record[versionId] : undefined,
      ),
      versionId ? versionRecord?.[versionId] : undefined, // As fallback
    ];
    return getMaxValuesInArray(resultsArray);
  }, [allProposalResultRecords, versionId, versionRecord]);
};

export const useProposalsBarChartValues = (id: IProposal['id']) => {
  const { target } = useProject();
  const results = useProposalResults(id);
  const resultsMax = useProposalsMaxResults();

  const resultsPerGFA = useResultsPerGFA(results);
  const resultsPerGFAmax = useResultsPerGFA(resultsMax);

  const co2ePerGFAResults = useMemo(
    () => getCo2eChartDataFromResults(resultsPerGFA),
    [resultsPerGFA],
  );
  const costPerGFAResults = useMemo(
    () => getCostChartDataFromResults(resultsPerGFA),
    [resultsPerGFA],
  );

  const co2ePerGFA = getConversionFactorValue(resultsPerGFA, 'co2e');
  const costPerGFA = getConversionFactorValue(resultsPerGFA, 'sek');

  const costPerGfaMax = getConversionFactorValue(resultsPerGFAmax, 'sek');
  const proposalsCO2ePerGfaMax = getConversionFactorValue(
    resultsPerGFAmax,
    'co2e',
  );
  const co2ePerGfaMax = target
    ? Math.max(target * 1.1, proposalsCO2ePerGfaMax)
    : proposalsCO2ePerGfaMax;

  return useObjectMemo({
    resultsPerGFA,
    co2ePerGFAResults,
    costPerGFAResults,
    co2ePerGFA,
    costPerGFA,
    costPerGfaMax,
    co2ePerGfaMax,
  });
};

/**
 * Get ratio between proposal results and the largest result value found in elements
 * Use this to scale co2/cost bar to the correct height
 * @param byProperty
 */
export const useProposalElementScale = (
  byProperty: keyof Results | ConversionFactorGroupKey = 'co2e',
  min = 1,
  max = 5,
): number => {
  const proposalResults = useProposalsMaxResults();
  const elementResults = useElementMaxResults();

  const proposalValue = getConversionFactorValue(proposalResults, byProperty);
  const elementValue = getConversionFactorValue(elementResults, byProperty);

  // Scale is at least 1
  return clamp(proposalValue / (elementValue || 1), min, max);
};

export const useElementsSelectedByProposals = (ids: IProposal['id'][]) => {
  const version = useSelectedVersion();
  return useMemo(() => {
    return version
      ? ids
          .flatMap((id) => getElementsActiveInProposal(version, id, false))
          .filter(
            (element) =>
              !isMainCategoryElement(element) &&
              !isBuildingVersionElement(element),
          )
      : [];
  }, [ids, version]);
};

export const useUpdateProposals = () => {
  const updateElements = useUpdateElements();

  return useCallback(
    (version: IBuildingVersion, ...updates: SemiPartial<IProposal, 'id'>[]) => {
      return updateElements(updateProposals(version, ...updates));
    },
    [updateElements],
  );
};
