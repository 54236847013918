import { createWithEqualityFn } from 'zustand/traditional';
import {
  IProjectInfo,
  Project,
  ProjectID,
} from '../../../../shared/models/project.interface';
import axios, { AxiosRequestConfig, AxiosResponseHeaders } from 'axios';
import { DateTime } from 'luxon';
import { devtools } from 'zustand/middleware';
import {
  createProject,
  getBackwardsCompatibleProject,
} from '../../../../shared/helpers/project_factory_helpers';
import { StoreErrorMessage } from '../store_errors';
import { getSelectedOrganization } from '../organization/organization.hook';
import {
  IProjectState,
  IUpdateProjectOptions,
  ProjectsLookup,
} from './project-state.model';
import { validateProject } from '../../../../shared/validation/project.validation';
import { reloadApp, UpdateClientAction, updateResourceLocally } from '../utils';
import { projectToProjectInfo } from '../../../../shared/helpers/project_helpers';
import { useFolderStateStore } from '../folder/folder.store';
import { shallow } from 'zustand/shallow';
import { getTimestamp } from '../../../../shared/helpers/date.helpers';
import { getConfig } from '../../providers/ConfigProvider';
import { getHmrStoreState, initHmrStore } from '../../helpers/vite.helpers';
import { IProjectFolder } from '../../../../shared/models/folder.interface';
import {
  isProjectFolder,
  isProjectInfo,
} from '../../../../shared/helpers/project-folder.helpers';
import { isEqualIds } from '../../../../shared/helpers/utils.helpers';
import { required } from '../../../../shared/helpers/function_helpers';

const projectReviver = (key: string, value: any): unknown => {
  if (
    value !== null &&
    ['updated_at', 'created_at', 'deleted_at'].includes(key)
  ) {
    return DateTime.fromISO(value).toString();
  }
  return value;
};

const STORE_NAME = 'project';

const REQUEST_CONFIG: AxiosRequestConfig = {
  transformResponse: (
    data: any,
    headers: AxiosResponseHeaders,
    status?: number,
  ): any => {
    return status && status < 400
      ? (JSON.parse(data, projectReviver) as Record<ProjectID, Project>)
      : undefined;
  },
} as const;

const DEFAULT_PROJECT: Project = {
  id: 0,
  owner: '',
  organizations: [],
  sharing_key: '',
  name: '',
  buildings: [],
  template: false,
  active_version_id: '',
  created_at: DateTime.fromISO('2023-01-01').toString(),
  updated_at: DateTime.fromISO('2023-01-01').toString(),
} as const;

/**
 * Store to hold project related values.
 * Avoid using directly. Use the useProjectStore hook instead.
 */
export const useProjectStateStore = createWithEqualityFn<IProjectState>()(
  devtools(
    (set, get) => ({
      isDeleting: false as boolean,
      isCreating: false as boolean,
      isLoading: false as boolean,
      projectsFetched: false as boolean,
      projectsFetching: false as boolean,
      projectsLookup: {},
      project: DEFAULT_PROJECT,
      // HMR sometimes causes multiple reloads of this file. Keep store state between reloads
      ...getHmrStoreState(STORE_NAME),

      fetchPublicProject: async (sharing_key) => {
        const organization = getSelectedOrganization(true);

        set(() => ({ isLoading: true }));

        try {
          const config = await getConfig();
          const { data } = await axios.get<Project>(
            `projects/shared/${sharing_key}`,
            {
              headers: {
                AppVersion: config.version,
                Organization: organization,
              },
            },
          );

          set(() => ({
            project: data,
            isLoading: false,
            projectsLookup: {
              [data.id]: projectToProjectInfo(data),
            } as ProjectsLookup,
          }));

          return data;
        } catch (err: any) {
          set(() => ({ isLoading: false }));
          throw Promise.reject(err);
        }
      },

      fetchProjects: async () => {
        set(() => ({
          projectsFetching: true,
          projectsFetched: false,
          error: undefined,
        }));

        try {
          const { data } = await axios.get<ProjectsLookup>(
            `projects`,
            REQUEST_CONFIG,
          );
          set(() => ({
            projectsFetching: false,
            projectsFetched: true,
            error: undefined,
            projectsLookup: data,
          }));
        } catch (err) {
          set(() => ({
            projectsFetching: false,
            projectsFetched: false,
            error: err as Error,
          }));
        }
      },

      fetchProject: async (id) => {
        set(() => ({ isLoading: true }));

        try {
          const project = await fetchProject(id);

          set(() => ({
            project,
            isLoading: false,
          }));

          return project;
        } catch (err) {
          set(() => ({ isLoading: false }));
          throw err;
        }
      },

      createProject: async (projectPartial, duplicate = false) => {
        const { isCreating, updateProjectLocally } = get();

        if (isCreating) {
          throw new Error('Already creating project');
        }

        set(() => ({ isCreating: true }));

        try {
          const payload = duplicate
            ? validateProject(
                createProject(
                  required(
                    projectPartial,
                    'Cannot duplicate project without providing a project',
                  ),
                ),
              )
            : projectPartial;

          const { data } = await axios.post<Project>(
            `projects${duplicate ? '/duplicate' : ''}`,
            payload,
            REQUEST_CONFIG,
          );

          updateProjectLocally({
            action: UpdateClientAction.Add,
            itemOrId: data,
          });

          return data;
        } catch (err) {
          set(() => ({ isCreating: false }));
          throw err;
        }
      },

      createTemplate: async (projectId) => {
        const { fetchProject } = get();
        const fetchedProject = await fetchProject(projectId);

        if (!fetchedProject) {
          throw new Error('Project not found.');
        }

        if (!fetchedProject.organizations?.length) {
          throw new Error(StoreErrorMessage.NotBeloningToOrganization);
        }

        const templateProject: Project = {
          ...fetchedProject,
          locked: false,
          archived: false,
        };

        validateProject(templateProject);

        await axios.post<Project>(
          `projects/template`,
          templateProject,
          REQUEST_CONFIG,
        );
      },

      deleteProject: async (id) => {
        const { updateProjectLocally } = get();

        set(() => ({ isDeleting: true }));

        try {
          await axios.delete(`/projects/${id}`);

          updateProjectLocally({
            action: UpdateClientAction.Remove,
            itemOrId: String(id),
            options: { isDeleting: false },
          });
        } catch (err: any) {
          set(() => ({ isDeleting: false }));
          return Promise.reject(err);
        }
      },

      updateProject: async (
        updatedProject: Project,
        options: IUpdateProjectOptions = {},
      ): Promise<Project> => {
        const { project, updateProjectLocally } = get();

        // Set project in store without waiting for server response
        updatedProject = updateProjectLocally({
          action: UpdateClientAction.Update,
          itemOrId: updatedProject,
          options: { isLoading: !options.skipServerUpdate },
        });

        // Don't send to server if nothing has changed
        if (project === updatedProject) {
          return project;
        }

        // Don't send to server if only local update is requested
        if (options.skipServerUpdate) {
          return updatedProject;
        }

        try {
          await axios.put<Project>(
            `projects`,
            { project: updatedProject },
            REQUEST_CONFIG,
          );

          set(() => ({ isLoading: false }));

          return updatedProject;
        } catch (err: any) {
          // Revert changes if server update failed
          updateProjectLocally({
            action: UpdateClientAction.Update,
            itemOrId: project,
            options: { isLoading: false },
          });
          reloadApp(err.response?.status);
          return Promise.reject(err);
        }
      },

      // TODO: Simpler if it was a Partial with a required id
      updateProjectDetails: async ({ projectId, data }) => {
        const { updateProjectLocally } = get();

        set(() => ({ isLoading: true }));

        const { data: updatedProject } = await axios.put<Project>(
          `projects/details`,
          { projectId, data },
          REQUEST_CONFIG,
        );

        updateProjectLocally({
          action: UpdateClientAction.Update,
          itemOrId: updatedProject,
          options: { isLoading: false },
        });

        return updatedProject;
      },

      updateProjectAndFolderLocations: async (items) => {
        const { project } = get();

        const projectInfos = items.filter(isProjectInfo);
        const folders = items.filter(isProjectFolder);

        set(() => ({ isLoading: true }));

        const { data } = await axios.put<{
          projects: Project[];
          folders: IProjectFolder[];
        }>(`/projects/locations`, { projectInfos, folders }, REQUEST_CONFIG);

        const changedProject = data.projects.find(({ id }) =>
          isEqualIds(id, project),
        );

        // TODO: refactor to use updateProjectLocally, if possible
        set(({ projectsLookup }) => {
          return {
            isLoading: false,
            project: changedProject
              ? {
                  ...project,
                  parent_id: changedProject.parent_id,
                  location: changedProject.location,
                }
              : project,
            projectsLookup: {
              ...projectsLookup,
              ...data.projects.reduce(
                (acc, updatedProject) => ({
                  ...acc,
                  [updatedProject.id]: projectToProjectInfo(updatedProject),
                }),
                {} as ProjectsLookup,
              ),
            },
          };
        });

        const folderState = useFolderStateStore.getState();
        folderState.updateFoldersLocally(data.folders);
      },

      updateProjectLocally: ({
        action,
        itemOrId,
        options: {
          isCreating = false,
          isDeleting = false,
          isLoading = false,
        } = {},
      }): Project => {
        const { project } = get();
        let updatedProject = project;
        let { projectsLookup } = get();

        if (
          action === UpdateClientAction.Add ||
          action === UpdateClientAction.Update
        ) {
          // Don't send to server if not changed
          if (project === itemOrId) {
            return project;
          }

          const { lookup } = updateResourceLocally({
            action,
            itemOrId: projectToProjectInfo(itemOrId),
            lookup: projectsLookup,
          });

          updatedProject = isEqualIds(project, itemOrId) // Only update if project is the one in use
            ? { ...itemOrId, updated_at: getTimestamp() } // Make sure to update the updated_at timestamp
            : project;
          projectsLookup = lookup ?? projectsLookup;
        }

        if (action === UpdateClientAction.Remove) {
          const { lookup } = updateResourceLocally<IProjectInfo>({
            action: action,
            itemOrId,
            lookup: projectsLookup,
          });

          projectsLookup = lookup ?? projectsLookup;
        }

        // Crash if new errors are introduced
        validateProject(updatedProject);

        set(() => ({
          isCreating,
          isDeleting,
          isLoading,
          projectsLookup,
          project: updatedProject,
        }));

        return updatedProject;
      },
    }),
    { name: STORE_NAME },
  ),
  shallow,
);

/**
 * Fetch project from server. Will not cause state change in project store.
 * @param id
 * @returns
 */
export const fetchProject = async (id: number) => {
  const { data } = await axios.get<Project>(`projects/${id}`, REQUEST_CONFIG);

  // TODO: Needed? Should maybe run migration on server instead
  return getBackwardsCompatibleProject(data);
};

// In HMR mode we need to keep the store state between reloads
initHmrStore(STORE_NAME, useProjectStateStore);
