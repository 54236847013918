import {
  ElementPropertyType,
  IFactorySelectProperty,
} from '../../../models/element_property.interface';
import { ProductTree } from '../processor.model';

// Property Names

export enum LabourPropertyName {
  Role = 'role',
}

export enum Role {
  Labourer = 'Labourer',
  Carpenter = 'Carpenter',
  Bricklayer = 'Bricklayer',
  ConcreteFinisher = 'Concrete Finisher',
  Electrician = 'Electrician',
  Plumber = 'Plumber',
  HVACEngineer = 'HVAC Engineer',
  Roofer = 'Roofer',
  PainterAndDecorator = 'Painter and Decorator',
  HeavyEquipmentOperator = 'Heavy Equipment Operator',
  Tiler = 'Tiler',
  Welder = 'Welder',
  Dryliner = 'Dryliner',
  InsulationInstaller = 'Insulation Installer',
  DemolitionWorker = 'Demolition Worker',
  Scaffolder = 'Scaffolder',
  Surveyor = 'Surveyor',
  AsphaltPaver = 'Asphalt Paver',
  Ironworker = 'Ironworker',
  ExcavatorOperator = 'Excavator Operator',
}

// Labour product id's are created here only
export const labourProductTree: ProductTree = {
  [Role.Labourer]: 'nodon_sv-SE_0000000000',
  [Role.Carpenter]: 'nodon_sv-SE_0000000001',
  [Role.Bricklayer]: 'nodon_sv-SE_0000000002',
  [Role.ConcreteFinisher]: 'nodon_sv-SE_0000000003',
  [Role.Electrician]: 'nodon_sv-SE_0000000004',
  [Role.Plumber]: 'nodon_sv-SE_0000000005',
  [Role.HVACEngineer]: 'nodon_sv-SE_0000000006',
  [Role.Roofer]: 'nodon_sv-SE_0000000007',
  [Role.PainterAndDecorator]: 'nodon_sv-SE_0000000008',
  [Role.HeavyEquipmentOperator]: 'nodon_sv-SE_0000000009',
  [Role.Tiler]: 'nodon_sv-SE_0000000010',
  [Role.Welder]: 'nodon_sv-SE_0000000011',
  [Role.Dryliner]: 'nodon_sv-SE_0000000012',
  [Role.InsulationInstaller]: 'nodon_sv-SE_0000000013',
  [Role.DemolitionWorker]: 'nodon_sv-SE_0000000014',
  [Role.Scaffolder]: 'nodon_sv-SE_0000000015',
  [Role.Surveyor]: 'nodon_sv-SE_0000000016',
  [Role.AsphaltPaver]: 'nodon_sv-SE_0000000017',
  [Role.Ironworker]: 'nodon_sv-SE_0000000018',
  [Role.ExcavatorOperator]: 'nodon_sv-SE_0000000019',
}; // TODO: Should not share id suffix with boverket products to avoid conflict with climate improved products...
// Also region prefix should be gone since it's not swedish

export const labourProduct = {
  [Role.Labourer]: {
    name: 'Labourer',
    description:
      'A general worker responsible for various tasks, including moving materials, digging, and cleaning',
    'sek_A1-A3': 350,
  },
  [Role.Carpenter]: {
    name: 'Carpenter',
    description:
      'Specializes in woodworking, framing, and structural components',
    'sek_A1-A3': 490,
  },
  [Role.Bricklayer]: {
    name: 'Bricklayer',
    description:
      'Works with bricks, blocks, or stone to construct walls and structures',
    'sek_A1-A3': 490,
  },
  [Role.ConcreteFinisher]: {
    name: 'Concrete Finisher',
    description:
      'Specializes in working with concrete, pouring, and finishing surfaces',
    'sek_A1-A3': 440,
  },
  [Role.Electrician]: {
    name: 'Electrician',
    description: 'Installs and maintains electrical systems and wiring',
    'sek_A1-A3': 560,
  },
  [Role.Plumber]: {
    name: 'Plumber',
    description: 'Installs and maintains plumbing systems and fixtures',
    'sek_A1-A3': 560,
  },
  [Role.HVACEngineer]: {
    name: 'HVAC Engineer',
    description: 'Handles heating, ventilation, and air conditioning systems',
    'sek_A1-A3': 490,
  },
  [Role.Roofer]: {
    name: 'Roofer',
    description: 'Installs and repairs roofing materials',
    'sek_A1-A3': 440,
  },
  [Role.PainterAndDecorator]: {
    name: 'Painter and Decorator',
    description: 'Applies paint and finishes to surfaces',
    'sek_A1-A3': 440,
  },
  [Role.HeavyEquipmentOperator]: {
    name: 'Heavy Equipment Operator',
    description: 'Operates heavy machinery, such as bulldozers and cranes',
    'sek_A1-A3': 490,
  },
  [Role.Tiler]: {
    name: 'Tiler',
    description:
      'Specializes in the installation of ceramic, porcelain, and other types of tiles',
    'sek_A1-A3': 490,
  },
  [Role.Welder]: {
    name: 'Welder',
    description: 'Joins metal components through welding processes',
    'sek_A1-A3': 560,
  },
  [Role.Dryliner]: {
    name: 'Dryliner',
    description: 'Installs and finishes drywall and plasterboard',
    'sek_A1-A3': 440,
  },
  [Role.InsulationInstaller]: {
    name: 'Insulation Installer',
    description:
      'Installs insulation materials for temperature and energy efficiency',
    'sek_A1-A3': 440,
  },
  [Role.DemolitionWorker]: {
    name: 'Demolition Worker',
    description: 'Responsible for safely dismantling existing structures',
    'sek_A1-A3': 350,
  },
  [Role.Scaffolder]: {
    name: 'Scaffolder',
    description: 'Erects and maintains scaffolding for construction access',
    'sek_A1-A3': 490,
  },
  [Role.Surveyor]: {
    name: 'Surveyor',
    description: 'Measures and marks the land for construction purposes',
    'sek_A1-A3': 560,
  },
  [Role.AsphaltPaver]: {
    name: 'Asphalt Paver',
    description: 'Lays and maintains asphalt surfaces',
    'sek_A1-A3': 440,
  },
  [Role.Ironworker]: {
    name: 'Ironworker',
    description: 'Installs and reinforces steel structures',
    'sek_A1-A3': 490,
  },
  [Role.ExcavatorOperator]: {
    name: 'Excavator Operator',
    description: 'Operates heavy equipment for digging and excavation',
    'sek_A1-A3': 490,
  },
} as const;

// Properties
export const boardTypeProperty: IFactorySelectProperty = {
  name: LabourPropertyName.Role,
  type: ElementPropertyType.Select,
  count: Role.Labourer,
  options: Object.values(Role),
};
