import { useEffect, useMemo } from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';
import { isDefined } from '../../../shared/helpers/array_helpers';
import { useObjectMemoDeep } from './object.hook';
import { omit } from '../../../shared/helpers/object_helpers';
import { useDebounce } from './hooks';

interface IUseSearchProps<T> extends IFuseOptions<T> {
  /**
   * Pass number to debounce the string search.
   */
  debounce?: number;
}

export const useSearch = <T>(
  items: T[] = [],
  searchString: string = '',
  options: IUseSearchProps<T> = {},
) => {
  const fuseOptions = useObjectMemoDeep(omit(options, 'debounce'));
  const debounce = options.debounce ?? 0;

  const debouncedString = useDebounce(searchString, debounce);

  // Only create a new fuse instance if the options have changed
  const fuse = useMemo(
    () =>
      new Fuse([] as T[], {
        includeScore: true,
        shouldSort: false,
        minMatchCharLength: 1,
        threshold: 0.2,
        distance: 1000, // Distance * threshold is the amount of characters searched
        keys: ['id', 'name', 'value', 'label'],
        ...fuseOptions,
      }),
    [fuseOptions],
  );

  // Make sure fuse have the latest items
  useEffect(() => {
    fuse.setCollection(items);
  }, [items, fuse]);

  const filteredItems = useMemo(
    () =>
      debouncedString
        ? fuse
            .search(debouncedString)
            .map((foundItem) => items[foundItem.refIndex])
            .filter(isDefined)
        : items,
    [fuse, items, debouncedString],
  );

  // Only return new array if the filtered items have changed
  return useObjectMemoDeep(filteredItems);
};
