import {
  Results,
  ConversionFactors,
  PartialConversionFactorQuantityRecord,
  co2UnitsA,
  co2UnitsB,
  unitsA,
  unitsB,
  ConversionFactorGroupKey,
  co2Units,
  costUnits,
  costUnitsA,
  costUnitsB,
  ProductOrConversionFactors,
  conversionFactorGroupKeys,
  ConversionFactorUnit,
  QuantityUnit,
} from '../models/unit.interface';
import {
  omit,
  pick,
  getCommonKeys,
  getKeys,
  omitUndefined,
  omitValues,
} from './object_helpers';
import { ArrayOrRecord, PartialRecord } from '../models/type_helpers.interface';
import { findMap, isDefined, isOneOf } from './array_helpers';
import { IProduct } from '../models/product.interface';
import {
  canConvertConversionFactors,
  ConversionUnit,
  convert,
  convertConversionFactors,
  getCorrespondingConversionFactorUnit,
  getConvertableUnitFromConversionFactors,
  getPreferredConversionUnit,
  isConversionUnit,
} from './conversion_helpers';
import { required } from './function_helpers';
import {
  getMean,
  isCloseTo,
  removeNumberFormatting,
  sum,
  toNumber,
} from './math_helpers';
import { uniq } from 'lodash';
import {
  isCO2eUnit,
  isConversionFactorQuantityUnit,
  isConversionFactorUnit,
  isCostUnit,
  isQuantityUnit,
} from './unit_helpers';
import {
  getConversionFactors,
  getProductTransportValue,
  getProductWasteFactor,
  kmToA4,
  getA5FromWasteFactorPercentage,
} from './results.helpers';
import {
  validateConversionFactors,
  validateConversionFactorUnit,
} from '../validation/conversion-factor.validation';

const DEFAULT_CO2E_A4 = 0.042;
export const DEFAULT_CONVERSION_FACTORS = Object.freeze({
  kg: 1,
  co2e_A4: DEFAULT_CO2E_A4,
});

const co2eInputUnits = [
  'co2e_transport', // transport in km
  'co2e_waste', // waste between 0 and 1
  'co2e_waste_percent', // waste between 0 and 100
] as const;

/**
 * User input transport in km and waste in %
 */
export type Co2eInputUnits = (typeof co2eInputUnits)[number];

export type SupportedConversionUnits =
  | ConversionFactorUnit
  | QuantityUnit
  | ConversionFactorGroupKey
  | ConversionUnit
  | Co2eInputUnits;

/**
 * Get a conversion factor value OR a sum of multiple conversion factor values
 * @param factors
 * @param units
 * @returns
 */
export const getConversionFactorValue = (
  productOrConversionFactors?: Results | ProductOrConversionFactors,
  ...units: SupportedConversionUnits[]
): number => {
  const factors = getConversionFactors(productOrConversionFactors);
  const groupKeys = units.filter(isConversionFactorGroupKey);
  const otherKeys: Exclude<
    SupportedConversionUnits,
    ConversionFactorGroupKey
  >[] = units.filter((u) => !isConversionFactorGroupKey(u));
  const keys = [
    ...getConversionFactorKeysFromGroups(...groupKeys),
    ...otherKeys,
  ];
  const values = uniq(keys).map((k) => {
    if (k === 'none' || !k) {
      return 0;
    }
    // If unit exist in factors, return the value
    if (hasConversionFactor(factors, k)) {
      return factors[k as keyof typeof factors];
    }

    if (k === 'co2e_transport') {
      return getProductTransportValue(factors);
    }
    if (k === 'co2e_waste' || k === 'co2e_waste_percent') {
      return (
        getProductWasteFactor(factors) * (k === 'co2e_waste_percent' ? 100 : 1)
      );
    }

    // if a unit like mm is requested we need to convert from m
    const fromUnit = getConvertableUnitFromConversionFactors(factors, k);
    if (fromUnit && factors[fromUnit]) {
      return convert(factors[fromUnit], fromUnit, k);
    }
    return 0;
  });
  return sum(values);
};

/**
 * Check if conversion factors have a undefined value for a given unit
 * @param factors
 * @param unit
 * @returns
 */
export const hasConversionFactor = (
  factors: ConversionFactors,
  unit: unknown,
): boolean => isConversionFactorUnit(unit) && typeof factors[unit] === 'number';

/**
 * Create conversion factors from a number or a partial conversion factors object.
 * @param factors
 * @param applyDefaults Apply default values when missing A4, kg etc. Should not be done for EPDs (custom)
 * @returns
 */
export const createConversionFactors = (
  factors: Partial<ConversionFactors> | number = 0,
): Results => {
  // If a number is provided, it's assumed to be the co2e_A1-A3 value
  if (typeof factors === 'number') {
    return compressConversionFactors({
      ...DEFAULT_CONVERSION_FACTORS,
      'co2e_A1-A3': factors,
    });
  }
  return compressConversionFactors(factors);
};

/**
 * Remove any keys that have a value of 0 or undefined.
 * Will not modify the original object if nothing is removed.
 * @param factors
 */
export const compressConversionFactors = <
  T extends Partial<ConversionFactors> | Results,
>(
  factors: T,
): T => {
  return omitValues(factors, 0, undefined, null) as T;
};

const isConversionFactorGroupKey = (
  key: unknown,
): key is ConversionFactorGroupKey =>
  conversionFactorGroupKeys.includes(key as ConversionFactorGroupKey);

/**
 * If we quickly want to get a set of conversion factors, we can call for groups of keys
 * @param groups One or more groups of keys
 * @returns
 */
export const getConversionFactorKeysFromGroups = (
  ...groups: (ConversionFactorGroupKey | ConversionFactorUnit)[]
): ConversionFactorUnit[] => {
  return uniq(
    groups.flatMap((group) => {
      switch (group) {
        case 'A':
          return unitsA;
        case 'B':
          return unitsB;
        case 'co2e':
          return co2Units;
        case 'sek':
          return costUnits;
        case 'co2e_A':
          return co2UnitsA;
        case 'co2e_B':
          return co2UnitsB;
        case 'sek_A':
          return costUnitsA;
        case 'sek_B':
          return costUnitsB;
        default:
          return isConversionFactorUnit(group) ? [group] : [];
      }
    }),
  );
};

/**
 * Remove properties from conversion factors. Can also remove entire lifecycle phases (A or B).
 * Will not modify the original object if nothing is removed.
 * @param factors
 * @param keys
 * @returns
 */
export const removeConversionFactorsByKey = (
  factors: Partial<ConversionFactors>,
  ...keys: (ConversionFactorGroupKey | ConversionFactorUnit)[]
): Partial<ConversionFactors> => {
  const keysToClear = getConversionFactorKeysFromGroups(...keys);
  return compressConversionFactors(omit(factors, ...keysToClear));
};

/**
 * Merge conversion factors, scaling the factors to a common unit if possible.
 * @param base Default values that can be overriden. Will be scaled to match the overrides unit.
 * @param overrides Will override base values. Note that base values will scale to match the overrides unit.
 * @returns
 */
export const mergeConversionFactors = (
  base: ConversionFactors,
  overrides?: Partial<ConversionFactors>,
): ConversionFactors => {
  if (!overrides) {
    return base;
  }
  overrides = compressConversionFactors(overrides);
  return {
    ...convertConversionFactors(base, overrides),
    ...overrides,
  };
};

/**
 * Get the fallback conversion factors to use if the user input is not complete.
 * @param genericFactors
 * @param userInputFactors
 * @param selectedUnit
 * @returns
 */
export const getFallbackConversionFactors = (
  genericFactors: ConversionFactors | undefined,
  userInputFactors: ConversionFactors,
  selectedUnit: ConversionFactorUnit,
): ConversionFactors => {
  const fallback = omitUndefined(genericFactors ?? {});
  const converted = canConvertConversionFactors(fallback, selectedUnit)
    ? convertConversionFactors(fallback, selectedUnit)
    : fallback;

  return converted;
};

/**
 * Get summed conversion factors from a record of ConversionFactors
 * @param record
 * @returns
 */
export const sumConversionFactorRecord = (
  record: ArrayOrRecord<ConversionFactors>,
): ReturnType<typeof sumConversionFactors> =>
  sumConversionFactors(...Object.values(record));

/**
 * Sum all CO2e factors and return the total.
 * @param factors
 * @returns
 */
export const sumConversionFactors = <T extends ConversionFactors | Results>(
  ...factors: (T | undefined)[]
): T => {
  const conversionFactors = {} as T;
  factors.filter(isDefined).forEach((f) => {
    getKeys(f).forEach((key) => {
      conversionFactors[key] = (toNumber(conversionFactors[key], 0) +
        toNumber(f[key], 0)) as T[keyof T];
    });
  });
  return conversionFactors;
};

export const getEPDConversionFactor = (
  { id, generic_id }: IProduct,
  factors: PartialConversionFactorQuantityRecord,
  key: SupportedConversionUnits,
): number => {
  const epdFactors = factors[id];
  const fallbacks = generic_id ? factors[generic_id] : undefined;

  return (
    getConversionFactorValue(epdFactors, key) ||
    getConversionFactorValue(fallbacks, key)
  );
};

export const getConversionFactorsMean = (
  ...factors: ConversionFactors[]
): ConversionFactors => {
  const conversionUnit = required(
    getPreferredConversionUnit(...factors),
    "Can't get average of conversion factors with no common keys",
  );
  const commonKeys = getCommonKeys(...factors);

  // Convert to the common unit and only keep the common keys to not give faulty means
  const converted = factors.map((f) =>
    pick(convertConversionFactors(f, conversionUnit), ...commonKeys),
  );

  return getMean(...converted);
};

/**
 * Get the unit with value 1 from the conversion factors which should be the selected unit
 * @param conversionFactors
 * @param allowQuantityUnits Allow older units that doesn't exist in conversion_factors today
 * @returns
 */
export const getSelectedUnitFromConversionFactors = <
  T extends boolean = false,
  R = T extends true ? QuantityUnit : ConversionFactorUnit,
>(
  conversionFactors: ConversionFactors,
  allowQuantityUnits: T = false as T,
): R => {
  const allowed = allowQuantityUnits
    ? isQuantityUnit
    : isConversionFactorQuantityUnit;
  const unit = findMap(Object.entries(conversionFactors), ([key, value]) => {
    if (
      isCloseTo(value, 1) && // Some factors are close to 1 but not exactly 1
      !isCO2eUnit(key) &&
      !isCostUnit(key) &&
      allowed(key)
    ) {
      return key as ConversionFactorUnit;
    }
  });

  if (unit) {
    return unit as R;
  }
  console.info('conversionFactors', conversionFactors);
  throw new Error(`No unit with value 1 found in conversion factors`);
};

/**
 * Migrate older conversion to new stricter format which doesn't
 * allow redundant units and only one unit per quantity type (volume, length, etc)
 * @param conversionFactors
 * @returns
 */
export const migrateConversionFactors = (
  conversionFactors: PartialRecord<string, number>,
): ConversionFactors => {
  const keys = getKeys(conversionFactors);

  // Do not migrate if all keys are valid conversion factor units
  if (keys.every(isConversionFactorUnit)) {
    return conversionFactors;
  }

  const currentUnit = getSelectedUnitFromConversionFactors(
    conversionFactors,
    true,
  );

  const migratedFactors = compressConversionFactors(
    keys.reduce((acc, key) => {
      const value = conversionFactors[key];
      // Keep the existing unit if it's already a conversion factor unit
      if (isConversionFactorUnit(key)) {
        acc[key] = value;
      }
      // Convert the value to a ConversionFactorUnit if we can (else throw the value)
      else if (isConversionUnit(key)) {
        const unit = getCorrespondingConversionFactorUnit(key);
        // Ignore older values like co2e_total
        if (value && isQuantityUnit(key)) {
          acc[unit] = convert(value, key, unit);
        }
      }

      return acc;
    }, {} as ConversionFactors),
  );

  if (!isConversionFactorQuantityUnit(currentUnit)) {
    return convertConversionFactors(
      migratedFactors,
      getCorrespondingConversionFactorUnit(currentUnit, true),
    );
  }
  return migratedFactors;
};

export const getUnitFromConversionFactors = (
  conversionFactors: ConversionFactors,
): ConversionFactorUnit | undefined => {
  for (const [key, value] of Object.entries(conversionFactors)) {
    if (isConversionFactorQuantityUnit(key) && value === 1) {
      return key;
    }
  }
};

interface IConversionFactorContainer {
  /**
   * Override
   */
  epd?: ConversionFactors;

  /**
   * The generic conversion factors (typically from boverket).
   * Used together with EPD to get a combined set of conversion factors
   */
  generic: ConversionFactors;

  /**
   * When clearing a value in the generic conversion factors,
   * originalGeneric will be used to reset the value
   */
  originalGeneric?: ConversionFactors;
}

/**
 * Set a conversion factor value. If EPD is provided, it will be used instead of generic.
 * @param inputUnit The unit of the new value
 * @param inputValue A new conversion factor value or user input (%, km, blank etc)
 * @param container Diffent types of conversion factors that will be used to set the value
 * @returns the modified generic or epd
 */
export const setConversionFactorValue = (
  inputUnit: ConversionFactorUnit | Co2eInputUnits,
  inputValue: number | string | undefined,
  container: IConversionFactorContainer,
): Pick<Partial<IConversionFactorContainer>, 'epd' | 'generic'> => {
  const { unit, value } = transformCo2eInput(inputUnit, inputValue, container);
  const key = container.epd ? 'epd' : 'generic';
  const factors = required(container[key]);

  // If the value has changed return the modified conversion factors
  return factors[unit] !== value
    ? {
        [key]: validateConversionFactors(
          // Validate to make sure we don't remove critical factors (could be handled instead)
          compressConversionFactors({ ...factors, [unit]: value }),
          key === 'epd',
        ),
      }
    : {};
};

interface IConversionFactorProductContainer {
  epd?: IProduct;
  generic: IProduct;
  originalGeneric?: IProduct;
}

/**
 * Set a conversion factor value on a product. If EPD is provided, it will be used instead of generic.
 * @param inputUnit The unit of the new value
 * @param inputValue A new conversion factor value or user input (%, km, blank etc)
 * @param container Diffent types of products that will be used to set the value
 * @returns the modified product
 */
export const setConversionFactorValueOnProduct = (
  inputUnit: ConversionFactorUnit | Co2eInputUnits,
  inputValue: number | string | undefined,
  { epd, generic, originalGeneric }: IConversionFactorProductContainer,
): IProduct | undefined => {
  const { epd: epdFactors, generic: genericFactors } = setConversionFactorValue(
    inputUnit,
    inputValue,
    {
      epd: epd?.conversion_factors,
      generic: generic.conversion_factors,
      originalGeneric: originalGeneric?.conversion_factors,
    },
  );

  if (epdFactors) {
    return { ...required(epd), conversion_factors: epdFactors } as IProduct;
  }
  if (genericFactors) {
    return { ...generic, conversion_factors: genericFactors };
  }
};

/**
 * Convert transport and waste to A4 and A5
 * @param unit
 * @returns
 */
export const co2eInputUnitToConversionFactorUnit = (
  unit: ConversionFactorUnit | Co2eInputUnits,
): ConversionFactorUnit =>
  validateConversionFactorUnit(
    unit === 'co2e_transport'
      ? 'co2e_A4'
      : unit === 'co2e_waste' || unit === 'co2e_waste_percent'
        ? 'co2e_A5'
        : unit,
  );

/**
 * Transform user input into a value & unit that can be applied on a conversion factor.
 * @param inputUnit
 * @param inputValue
 * @param epd
 * @param generic
 * @returns
 */
export const transformCo2eInput = (
  inputUnit: ConversionFactorUnit | Co2eInputUnits,
  inputValue: number | string | undefined,
  { epd, generic, originalGeneric }: IConversionFactorContainer,
): { unit: ConversionFactorUnit; value: number | undefined } => {
  const unit = co2eInputUnitToConversionFactorUnit(inputUnit);
  const value =
    typeof inputValue === 'string'
      ? Number(removeNumberFormatting(inputValue.replace('%', '')))
      : inputValue;

  if (!value || !isFinite(value)) {
    // If user clears a generic value, return the original value
    const clearValue =
      originalGeneric && !epd
        ? convertConversionFactors(originalGeneric, generic)[unit]
        : undefined;
    return { unit, value: clearValue };
  }

  if (isOneOf(co2eInputUnits, inputUnit)) {
    const factors = mergeConversionFactors(generic, epd);
    if (inputUnit === 'co2e_transport') {
      return { unit, value: kmToA4(value, factors.kg) };
    }

    // Waste is a percentage of A1-A4
    return {
      unit,
      value: getA5FromWasteFactorPercentage(
        factors,
        value * (inputUnit === 'co2e_waste' ? 100 : 1),
      ),
    };
  }
  return { unit, value };
};
