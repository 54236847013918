import { useCallback, useMemo } from 'react';
import { uniq } from 'lodash';
import {
  ElementPath,
  forEachElement,
  isBuildingVersionElement,
  isElement,
  isProductElement,
  isTemporaryElement,
  validatePath,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  IProductListItem,
  IProductListGroup,
  IProductListCategoryGroup,
  OneOfProductListGroups,
} from '../../../shared/models/product.interface';
import {
  ElementKind,
  IBuildingVersion,
  IProductElement,
  OneOfParentElements,
} from '../../../shared/models/project.interface';
import {
  ConversionFactorUnit,
  Results,
} from '../../../shared/models/unit.interface';
import { required } from '../../../shared/helpers/function_helpers';
import { getElementCategoryId } from '../../../shared/helpers/element_helpers';
import { sumConversionFactors } from '../../../shared/helpers/conversion-factors.helpers';
import { isProductCategory } from '../../../shared/helpers/element_category_helpers';
import { ElementCategoryID } from '../../../shared/models/element_categories.interface';
import { PartialRecord } from '../../../shared/models/type_helpers.interface';
import {
  createRecordByKey,
  isDefined,
} from '../../../shared/helpers/array_helpers';
import { useSelectedVersion, useUIState } from '../store/ui/ui.hook';
import { getMaxValuesInArray } from '../../../shared/helpers/math_helpers';
import {
  getProductListElementId,
  PRODUCT_ID_SEPARATOR,
} from '../helpers/product-list.helpers';
import {
  getActiveProposal,
  isElementActiveInProposal,
} from '../../../shared/helpers/proposal.helpers';
import { useGroupByCategory } from '../components/switches/GroupByCategorySwitch';
import { useFilterResultsBySelectedLifecycles } from './results.hook';
import { createLocalStorageStore } from '../helpers/local-storage.helpers';
import { useProjectBuildingGFA } from '../store/project';
import { getResultsByFactor } from '../../../shared/helpers/results.helpers';

export const {
  useStore: useProductListEmissionsPerMass,
  set: setProductListEmissionsPerMass,
} = createLocalStorageStore('product_list_emissions_per_mass', false);

export const useProductListCategoryGroups = () => {
  const version = useSelectedVersion();
  return useMemo(() => {
    return version ? createProductListCategoryGroups(version) : [];
  }, [version]);
};

export const useProductListGroups = () => {
  const version = useSelectedVersion();
  return useMemo(() => {
    return version ? createProductListGroups(version) : [];
  }, [version]);
};

export const useSelectedProductListElement = () => {
  const { selectedProductListElementId } = useUIState(
    'selectedProductListElementId',
  );
  const productListCategoryGroups = useProductListCategoryGroups();
  const productListGroups = useProductListGroups();
  const groupByCategory = useGroupByCategory();

  const groups = groupByCategory
    ? productListCategoryGroups.flatMap((g) => g.elements)
    : productListGroups;

  const productItems = groups.flatMap((g) => g.elements);

  return useMemo(
    () =>
      [...groups, ...productItems].find(
        (g) => g.id === selectedProductListElementId,
      ),
    [groups, productItems, selectedProductListElementId],
  );
};

export const useProductListMaxResults = (factorized = false): Results => {
  const lifecycleFilter = useFilterResultsBySelectedLifecycles();
  const getResultsDivisor = useProductListItemResultsDivisor();

  const groupByCategory = useGroupByCategory();
  const categoryGroups = useProductListCategoryGroups();
  const groups = useProductListGroups();

  const results = useMemo(
    () =>
      (groupByCategory ? categoryGroups : groups).map(({ results, unit }) => {
        const divisor = factorized ? getResultsDivisor(unit, results) : 1;
        return getResultsByFactor(results, divisor);
      }),
    [groupByCategory, categoryGroups, groups, factorized, getResultsDivisor],
  );

  return useMemo(
    () => lifecycleFilter(getMaxValuesInArray(results)),
    [lifecycleFilter, results],
  );
};

export const useProductListItemResultsDivisor = () => {
  const isPerUnit = useProductListEmissionsPerMass();
  const gfa = useProjectBuildingGFA();

  return useCallback(
    (unit: ConversionFactorUnit, results: Results | undefined) => {
      if (!results) {
        return 1;
      }
      const unitDivisor = results[unit] ?? 1;
      return isPerUnit ? unitDivisor : gfa;
    },
    [isPerUnit, gfa],
  );
};

const createProductListCategoryGroups = (
  version: IBuildingVersion,
): IProductListCategoryGroup[] => {
  const categoryGroups: PartialRecord<
    ElementCategoryID,
    IProductListCategoryGroup
  > = {};

  const activeProposal = version ? getActiveProposal(version) : undefined;

  forEachElement(version, (element, path) => {
    const category_id = getElementCategoryId(element);
    if (
      isTemporaryElement(element) ||
      !isElement(element) ||
      !isProductCategory(category_id) ||
      !isElementActiveInProposal(activeProposal, path, element)
    ) {
      return;
    }

    // Create category group if not exists
    if (!categoryGroups[category_id]) {
      categoryGroups[category_id] = {
        kind: ElementKind.ProductCategory,
        id: category_id,
        unit: element.selectedQuantity === 'energy' ? 'kWh' : 'kg',
        elements: [],
        results: {},
      };
    }
    // Use the existing category group
    const categoryGroup = required(categoryGroups[category_id]);

    const productGroups = createProductListGroups(
      element,
      path,
      createRecordByKey(categoryGroup.elements, 'id'), // Reuse existing groups
    );

    categoryGroup.elements = productGroups;
  });

  return applyResults(Object.values(categoryGroups).filter(isDefined));
};

/**
 * @param root
 * @param path If not a version path need to be provided to be accurate
 */
const createProductListGroups = (
  root: OneOfParentElements,
  path: ElementPath = [],
  groups: PartialRecord<IProductElement['id'], IProductListGroup> = {},
): IProductListGroup[] => {
  const version = isBuildingVersionElement(root) ? root : undefined;
  const activeProposal = version ? getActiveProposal(version) : undefined;

  forEachElement(root.elements, (element, subPath) => {
    if (
      !isProductElement(element) ||
      !isElementActiveInProposal(activeProposal, subPath, element) ||
      subPath.find(isTemporaryElement)
    ) {
      return;
    }

    const { unit, generic_product_id, product_id, generated } = element;
    const id = getProductListElementId(product_id, generic_product_id);

    // Create group if not exists
    if (!groups[id]) {
      groups[id] = {
        id,
        generic_product_id,
        generated,
        unit,
        kind: ElementKind.ProductGroup,
        product_id: id.includes(PRODUCT_ID_SEPARATOR) ? product_id : undefined,
        results: {},
        elements: [],
      } as IProductListGroup;
    }
    const group = required(groups[id]);
    const fullPath = validatePath(uniq([...path, root, ...subPath]));
    group.elements.push(getProductListItem(element, fullPath));
  });

  // Sum results before returning
  return applyResults(Object.values(groups).filter(isDefined));
};

/**
 * Sum results of the child items in groups and apply it to the group
 * @param groups
 * @returns
 */
const applyResults = <T extends OneOfProductListGroups>(groups: T[]): T[] => {
  return groups.map((group) => ({
    ...group,
    results: sumConversionFactors(...group.elements.map((i) => i.results)),
  }));
};

const getProductListItem = (
  element: IProductElement,
  path: ElementPath,
): IProductListItem => {
  return {
    ...element,
    path,
    kind: ElementKind.ProductItem,
  };
};
