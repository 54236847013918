import React, { useCallback } from 'react';
import VisibilityButton from '../../Buttons/VisibilityButton';
import { IElement } from '../../../../../../shared/models/project.interface';
import { useUpdateElements } from '../../../../store/project';

interface ElementVisibilityToggleProps {
  element: IElement;
}

const ElementVisibilityToggle: React.FC<ElementVisibilityToggleProps> = ({
  element,
}) => {
  const updateElements = useUpdateElements();

  const handleClick = useCallback(() => {
    updateElements({
      ...element,
      isDeactivated: !element.isDeactivated,
    });
  }, [element, updateElements]);

  return (
    <VisibilityButton visible={!element.isDeactivated} onClick={handleClick} />
  );
};

export default ElementVisibilityToggle;
