import React, { useMemo } from 'react';
import { Divider, Box, Typography } from '@mui/material';
import {
  isProductCategory,
  isServiceCategory,
  isSystemCategory,
} from '../../../../../../shared/helpers/element_category_helpers';
import {
  ElementCategoryID,
  IElementCategory,
} from '../../../../../../shared/models/element_categories.interface';
import { getAvailableCategories } from '../../../../../../shared/templates/categories';
import { IElement } from '../../../../../../shared/models/project.interface';
import { useElementPath } from '../../../../hooks/useElement';
import { isMaintenanceElement } from '../../../../../../shared/helpers/element_helpers';
import ElementCategoryItem from './ElementCategoryItem';
import { ProjectValidationErrors } from '../../../../../../shared/validation/project.validation';

interface ICategoryGroup {
  name?: string;
  categories: IElementCategory[];
}

export const useElementCategoryItems = (
  element: IElement,
  filterCategories: Readonly<ElementCategoryID[]> = [],
): React.ReactNode[] => {
  const elementPath = useElementPath(element);

  const categories = useMemo(
    () =>
      getAvailableCategories(element.category_id).filter(
        (category) => !filterCategories.includes(category.id),
      ),
    [element.category_id, filterCategories],
  );

  const groups = getCategoryGroups(categories);

  return useMemo(() => {
    return groups.flatMap((group, index) => {
      return [
        !!group.name && (
          <Box key={'group-label-' + index} pl={2} pt={2} pb={2}>
            <Typography variant="menuTitle">{group.name}</Typography>
          </Box>
        ),
        ...group.categories.map((category) => {
          const disabled =
            elementPath.some(isMaintenanceElement) &&
            category.id === ElementCategoryID.Maintenance;

          return (
            <ElementCategoryItem
              key={category.id}
              value={category.id}
              id={category.id}
              disabled={disabled}
              tooltip={
                disabled
                  ? ProjectValidationErrors.ANCESTOR_HAS_MAINTENANCE_CATEGORY
                  : ''
              }
            >
              {category.name}
            </ElementCategoryItem>
          );
        }),
        index < groups.length - 1 && (
          <Divider
            key={'divider-systems-products-' + index}
            variant="fullWidth"
          />
        ),
      ];
    });
  }, [elementPath, groups]);
};

/**
 * Groups the categories into system, product, service and other.
 * @param categories
 * @returns
 */
const getCategoryGroups = (
  categories: IElementCategory[],
): ICategoryGroup[] => {
  const systemCategories: ICategoryGroup = {
    name: 'System',
    categories: categories.filter((category) => isSystemCategory(category.id)),
  };

  const productCategories: ICategoryGroup = {
    name: 'Product',
    categories: categories.filter((category) => isProductCategory(category.id)),
  };

  const serviceCategories: ICategoryGroup = {
    name: 'Service',
    categories: categories.filter((category) => isServiceCategory(category.id)),
  };

  const groupedCategories = [
    systemCategories,
    productCategories,
    serviceCategories,
  ];

  const otherCategories: ICategoryGroup = {
    name: '',
    categories: categories.filter(
      (cat) =>
        !groupedCategories.some((group) => group.categories.includes(cat)),
    ),
  };

  return [...groupedCategories, otherCategories].filter(
    (group) => group.categories.length > 0,
  );
};
