import { getCategoryPropertyValueRecord } from '../../../helpers/element_category_helpers';
import { omit } from '../../../helpers/object_helpers';
import { isEqualCategoryPropertyValueRecord } from '../../../helpers/recipe_helpers';
import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import { DEFAULT_QUANTITY_PROPERTIES } from '../../../models/element_quantities.interface';
import { Orientation } from '../../../models/orientation.interface';
import { slabProperties } from './slab.model';

const recipeFilter: IElementCategory['recipeFilter'] = (recipe, element) => {
  const category_property_value_record =
    getCategoryPropertyValueRecord(element);

  // Recipe category_property_value_record must be an EXACT to the element to appear
  return isEqualCategoryPropertyValueRecord(
    category_property_value_record,
    recipe.category_property_value_record,
  );
};

export const slab: IElementCategory = {
  id: ElementCategoryID.Slab,
  defaultSelectedQuantity: 'area_top',
  name: 'Slab',
  orientation: Orientation.Horizonal,
  properties: slabProperties,
  recipeFilter,
  getQuantityProperties: () =>
    omit(DEFAULT_QUANTITY_PROPERTIES, 'density', 'mass', 'fill'),
};
