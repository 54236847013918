import { isObject, last } from 'lodash';
import {
  ElementCategoryConversionFactorRecord,
  ElementCategoryID,
  IElementCategory,
  IElementCategoryPropertySelectionByProductIdFn,
  IElementCategoryWithCalculatedProperties,
  MainCategoryId,
  MainElementCategoryConversionFactorRecord,
  ProductCategoryId,
  ServiceCategoryId,
  SystemCategoryId,
  mainCategoryIds,
  productCategoryIds,
  serviceCategoryIds,
  systemCategoryIds,
} from '../models/element_categories.interface';
import {
  ElementPropertySource,
  IElementProperty,
  PropertyResolvedCountRecord,
} from '../models/element_property.interface';
import {
  ElementQuantityExpressionName,
  ElementQuantityExpressionRecord,
} from '../models/element_quantities.interface';
import {
  CreatedElement,
  ElementKind,
  ElementKindMap,
  IElement,
  IElementID,
  OneOfElements,
  OneOfListElements,
  OneOfParentElements,
} from '../models/project.interface';
import shallowEqual, {
  cloneRemoveItems,
  includesSome,
  isDefined,
  isOneOf,
  sortInOrderOf,
} from './array_helpers';
import {
  elementCategories,
  getElementCategoryById,
  isCategoryIdsWithGeneratedChildren,
  isMainCategory,
} from '../templates/categories';
import {
  createElementFromPartial,
  createElements,
  isFactoryElement,
  isFactoryProductElement,
  isOneOfParentFactoryElements,
} from './element_factory_helpers';
import { isDeactivated, isGenerated } from './element_helpers';
import {
  addMutualProperties,
  createElementProperty,
} from './element_property_factory_helpers';
import {
  applyElementPropertiesOfSource,
  getCount,
  getElementPropertiesByCategorySource,
  getElementPropertyResolvedCountRecord,
  hasCount,
  propertyChangeRecordToArray,
  setPropertyCountsFromRecord,
  setElementProperties,
  getElementProperties,
} from './element_property_helpers';
import {
  createElementQuantityRecord,
  getElementQuantityRecord,
  selectElementQuantity,
} from './element_quantity_helpers';
import { getKeys, isPartialEqual, omit } from './object_helpers';
import {
  getElementById,
  isBuildingVersionElement,
  isElement,
  isProductElement,
} from './recursive_element_helpers';
import { clearRecipe } from './recipe_helpers';
import { ResultsRecord } from '../models/unit.interface';
import {
  getConversionFactorValue,
  sumConversionFactors,
  SupportedConversionUnits,
} from './conversion-factors.helpers';
import { Replace } from '../models/type_helpers.interface';
import { enrichElementStructure } from './project_helpers';
import { RegenerateIds } from './id.helpers';
import {
  IFactoryElement,
  OneOfChildFactoryElements,
  OneOfFactoryElements,
} from '../models/factory-element.interface';
import { required } from './function_helpers';

export type UnknownCategoryId = ElementCategoryID | string | undefined;

type OneOfTypesWithCategoryId = OneOfListElements | IElementProperty;

const ElementCategoryIdValues = Object.values(
  ElementCategoryID as Record<string, string>,
);

export type PathRecord = Record<IElementID, OneOfParentElements[]>;

/**
 * Detect if an ID is a valid Element Category ID
 * @param str
 * @returns
 */
export const isElementCategoryID = (
  str: UnknownCategoryId,
): str is ElementCategoryID => {
  return !!str && ElementCategoryIdValues.includes(str);
};

/**
 * Set element category ID on an element and update properties belonging to the category
 * @param element
 * @param id
 * @returns
 */
export const applyElementCategory = (
  element: IElement,
  id: ElementCategoryID | string | undefined,
): IElement => {
  const prevId = element.category_id;
  const category_id = getElementCategoryId(id);

  // If category is the same as the original, return the element without modifying it
  if (prevId === category_id) {
    return element;
  }

  // Clear any recipe belonging to previous category
  element = clearRecipe(element);

  const category = getElementCategory(category_id);

  // Get quantities from category and keep existing values if they exist
  const quantity = getQuantityFromCategory(element, category ?? false);

  return selectElementQuantity(
    updateElementCategoryProperties({
      ...element,
      quantity,
      category_id,
      recipe_id: undefined,
    }),
    'auto',
  );
};

/**
 * Create an element with an applied category. Will also apply category to child elements recursively.
 * Can't be done directly in factory due to circular reference issues. TODO: Make this behavoiur default in createElementFromPartial
 * @param element
 * @returns
 */
export const createElementWithCategory = <
  T extends OneOfFactoryElements | Partial<IFactoryElement>,
  K extends T['kind'] extends keyof ElementKindMap
    ? T['kind']
    : ElementKind.Element,
>(
  element: T,
  regenerateIds?: RegenerateIds,
): CreatedElement<K> => {
  const factoryEl = {
    kind: ElementKind.Element, // Default kind
    ...element,
  } as OneOfFactoryElements;

  if (isOneOfParentFactoryElements(factoryEl)) {
    factoryEl.elements = factoryEl.elements?.map((child) =>
      createElementWithCategory(child, regenerateIds),
    );
  }

  // IElement should get category applied, the other elements should just be tre
  if (isFactoryElement(factoryEl)) {
    const category = getElementCategory(factoryEl);
    const fallbackName = category?.name;

    return applyElementCategory(
      createElementFromPartial(
        omit({ fallbackName, ...factoryEl }, 'category_id'),
        regenerateIds,
      ),
      category?.id,
    ) as CreatedElement<K>;
  }

  return createElementFromPartial(
    factoryEl,
    regenerateIds,
  ) as CreatedElement<K>;
};

/**
 * Get all counts on properties that's applied by the category
 * @param element
 * @sample
 * {
 *   "type": "concrete",
 *   "quality": "A",
 * }
 * @returns
 */
export const getCategoryPropertyValueRecord = (
  element: OneOfElements,
): PropertyResolvedCountRecord =>
  getElementPropertyResolvedCountRecord(
    getElementPropertiesByCategorySource(element),
  );

/**
 * Apply a IElementCategoryPropertyValueRecord to an element
 * @param element
 * @param category_id
 * @param record
 * @returns
 */
export const applyCategoryPropertyValueRecord = (
  element: IElement,
  category_id: ElementCategoryID | undefined,
  record: PropertyResolvedCountRecord | undefined,
): IElement => {
  let updatedElement = enrichElementStructure(
    applyElementCategory(element, category_id),
  );

  // Only need to apply properties if we have a category and a record
  if (category_id && Object.keys(record ?? {}).length) {
    // Somewhat ugly hack to make sure the properties are set
    // Since some properties are added depending on other properties we need to iterate a few times (in theory 2 should be enough).
    // In theory this could be executed until no changes are applied but to make sure we never cause infite loops we do it in for loop instead of a while(true).
    for (let i = 0; i < 3; i++) {
      if (record) {
        const updatedPropsElement = enrichElementStructure(
          setPropertyCountsFromRecord(updatedElement, record, true),
        );

        // If no changes were made we can break the loop and return the updated element
        if (updatedPropsElement === updatedElement) {
          return updatedElement;
        }
        updatedElement = updatedPropsElement;
      }
    }
  }
  return updatedElement;
};

/**
 * Apply latest properties from category to element based on current properties.
 * @param element Element to update, note that correct category_id must be set
 * @returns If element is changed it will return a new element else it will return the original element
 */
export const updateElementCategoryProperties = <T extends OneOfElements>(
  element: T,
): T => {
  if (!isElement(element)) {
    return element;
  }

  const elementWithProperties = applyElementPropertiesOfSource(
    element,
    ElementPropertySource.Category,
    getElementPropertiesFromCategory(element),
  );

  const elementWithUpdatedProperties = updateQuantityPropertiesFromCategory(
    elementWithProperties,
  );

  return applyCategoryChildElements(elementWithUpdatedProperties) as T;
};

/**
 * Apply latest properties from category to element
 * @param element Element to update, note that correct category_id must be set
 * @returns If element is changed it will return a new element else it will return the original element
 */
const updateQuantityPropertiesFromCategory = <T extends OneOfElements>(
  element: T,
): T => {
  if (!isElement(element)) {
    return element;
  }

  const category_id = getElementCategoryId(element);
  const quantity = getElementQuantityRecord(element);
  const newQuantity = getQuantityFromCategory(element);
  const newKeys = getKeys(newQuantity);
  const oldKeys = getKeys(quantity);

  // No new properties, remove all old properties connected to category
  if (!newQuantity) {
    const keysToRemove = Object.values(quantity)
      .filter((p) => !!p.category_id)
      .map((p) => p.name);

    return keysToRemove.length
      ? { ...element, quantity: omit(quantity, ...keysToRemove) }
      : element;
  }

  // Keys not belonging to the new category
  const keysFromOldCategory = Object.values(quantity)
    .filter(
      (p) =>
        getElementCategoryId(p) !== ElementCategoryID.None &&
        getElementCategoryId(p) !== category_id,
    )
    .map((p) => p.name);

  // No changes
  if (shallowEqual(newKeys, oldKeys) && [...keysFromOldCategory].length === 0) {
    return element;
  }

  const keysToRemove = oldKeys.filter(
    (k) => !newKeys.includes(k as ElementQuantityExpressionName),
  );

  const modifiedQuantity = {
    ...newQuantity,
    ...omit(quantity, ...keysToRemove, ...keysFromOldCategory),
  };

  return { ...element, quantity: modifiedQuantity } as T;
};

/**
 * Check if a category has getElementProperties function defined
 * @param category
 * @returns
 */
export const hasCalculatedCategoryProperties = (
  category?: IElementCategory,
): category is IElementCategoryWithCalculatedProperties =>
  !!category &&
  'getElementProperties' in category &&
  typeof category.getElementProperties === 'function';

/**
 * Get element properties from category. Use category set on element unless you provide an alternative id
 * @param element
 * @param id Alternative id if you want to get properties from a different category than the one in the element
 * @returns
 */
export const getElementPropertiesFromCategory = (
  element: OneOfElements,
  id?: ElementCategoryID | string,
): IElementProperty[] => {
  const category = getElementCategory(id ?? element);

  if (!category) {
    return [];
  }

  let properties = category?.properties || [];

  if (hasCalculatedCategoryProperties(category)) {
    properties = isElement(element)
      ? category.getElementProperties(element)
      : [];
  }

  // Add properties that should be generated by each category
  return addMutualProperties(properties, category).map((p) =>
    createElementProperty(p),
  );
};

/**
 * Get element category from element or category_id
 * @param elementOrId
 * @returns
 */
export const getElementCategory = (
  elementOrId?:
    | OneOfTypesWithCategoryId
    | UnknownCategoryId
    | OneOfFactoryElements
    | undefined,
): Readonly<IElementCategory> | undefined => {
  const id = getElementCategoryId(elementOrId);
  return id ? getElementCategoryById(id) : undefined;
};

export const getElementCategoryId = (
  elementOrId:
    | UnknownCategoryId
    | OneOfTypesWithCategoryId
    | OneOfFactoryElements
    | undefined,
): ElementCategoryID => {
  if (typeof elementOrId === 'string') {
    return isElementCategoryID(elementOrId)
      ? elementOrId
      : ElementCategoryID.None;
  } else if (
    isObject(elementOrId) &&
    ElementPropertySource.Category in elementOrId
  ) {
    return getElementCategoryId(elementOrId.category_id);
  }
  return ElementCategoryID.None;
};

/**
 * Get quantity properties from element category and keep existing values if they exist
 * @param element
 * @param overrideCategory Pass category if you wanna override the category of the element (when applying new one)
 * @param path The path of elements leading to the element
 * @returns
 */
const getQuantityFromCategory = (
  element: OneOfElements,
  overrideCategory?: IElementCategory | false,
): ElementQuantityExpressionRecord => {
  const category = overrideCategory ?? getElementCategory(element);
  if (category && isElement(element)) {
    const quantityPropertiesFn = category.getQuantityProperties;
    const quantity = getElementQuantityRecord(element);
    if (quantityPropertiesFn) {
      // Create a new record with updated fallbackCounts
      const record = createElementQuantityRecord(quantityPropertiesFn(element));

      Object.values(record).forEach((pr) => {
        // Apply the new category id
        pr.category_id = category.id;
        const existing = quantity[pr.name];

        // Keep count and fallbackCount if they have a value
        if (existing) {
          pr.count = hasCount(existing) ? existing.count : pr.count;
          pr.fallbackCount =
            (existing.fallbackCount as { expression: unknown })?.expression ===
            (pr.fallbackCount as { expression: unknown })?.expression
              ? existing.fallbackCount
              : pr.fallbackCount;
        }
      });

      return record;
    }
  }
  return {};
};

/**
 * Get quantity properties from element
 * @param element
 * @param overrideCategory Pass category if you wanna override the category of the element
 * @returns An array of child elements or undefined if category lacks a getChildElements function
 */
export const getCategoryChildElements = (
  element: OneOfElements,
  overrideCategory?: IElementCategory,
): OneOfChildFactoryElements[] | undefined => {
  if (isElement(element)) {
    const category = overrideCategory ?? getElementCategory(element);
    const getChildElementsFn = category?.getChildElements;

    if (getChildElementsFn) {
      const factoryElements = getChildElementsFn(element).map((e) => ({
        ...e,
        generated: true,
      }));

      return createElements(factoryElements, true).map((e, i) => {
        if (!isFactoryProductElement(e)) {
          throw new Error('Currently only supports product elements');
        }

        const existingElement = element.elements[i];
        const existingCount = isProductElement(existingElement)
          ? existingElement.count
          : undefined;

        // If a new count is created reuse the resolved count
        if (existingCount && e.count.resolved === 0) {
          const count = { ...existingCount, expression: e.count.expression };
          return {
            ...e,
            count,
            results: existingElement?.results,
          };
        }

        return e;
      });
    }
  }
};

/**
 * Apply child elements from category to element.
 * If no category chid elements are found, the original element is returned
 * @param element
 * @returns The modified element or the original element if no changes were made
 */
export const applyCategoryChildElements = (element: IElement): IElement => {
  const categoryChildren = getCategoryChildElements(element) ?? [];
  const currentProductElements = element.elements.filter(isProductElement);
  const currentElements = element.elements.filter(isElement);
  const categoryChildElements = categoryChildren.filter(isElement);
  const categoryChildProductElements =
    categoryChildren.filter(isProductElement);

  if (categoryChildren.length) {
    // Reuse existing product elements if found
    const productElements = categoryChildProductElements.map((child) => {
      const existing = currentProductElements.find(
        (el) =>
          el.product_id === child.product_id &&
          el.count.expression === child.count.expression,
      );
      const supplierProductElement = currentProductElements.find(
        (el) => el.generic_product_id === child.product_id,
      );
      const supplierProductElementWithReusedContentCount =
        supplierProductElement?.generic_product_id === child.product_id
          ? { ...supplierProductElement, count: child.count }
          : supplierProductElement;

      // Try to reuse existing elements or use a supplier product mapped to the element
      return existing ?? supplierProductElementWithReusedContentCount ?? child;
    });

    // Reuse existing elements if found
    const elements = categoryChildElements.map((child) => {
      const existing = currentElements.find(
        (el) =>
          el.category_id === child.category_id &&
          getCount(el)?.expression === getCount(child)?.expression,
      );
      return existing ?? child;
    });

    const newElements = [...elements, ...productElements];

    // Mutate if there are changes
    if (!shallowEqual(newElements, element.elements)) {
      return { ...element, elements: newElements };
    }
  }
  // Cleanup previously generated elements
  else {
    const elements = element.elements.filter((el) => !isGenerated(el));

    // If there are changes, return a new element
    if (elements.length !== element.elements.length) {
      return { ...element, elements };
    }
  }
  return element;
};

/**
 * Get the hierarchy of categories from a path filtering out any non-category ids
 * @param path
 * @returns
 */
export const getCategoryPath = (
  path: OneOfParentElements[] = [],
): ElementCategoryID[] => path.map(getElementCategoryId).filter((id) => !!id);

/**
 * Get the defined category id closest to the element
 * @param path
 * @returns
 */
export const getClosestElementCategoryId = (
  path: OneOfParentElements[],
): ElementCategoryID | undefined => last(getCategoryPath(path));

/**
 * Merge all main categories into a single record with only sub categories
 * @param mainRecord The main record or array of ElementCategoryConversionFactorRecord to merge
 */
export const mergeMainElementCategoryConversionFactorRecord = (
  mainRecord:
    | MainElementCategoryConversionFactorRecord
    | ElementCategoryConversionFactorRecord[],
): ElementCategoryConversionFactorRecord => {
  const record: ElementCategoryConversionFactorRecord = {};
  Object.values(mainRecord).forEach((subRecord) => {
    Object.entries(subRecord).forEach(([id, conversionFactor]) => {
      record[id as ElementCategoryID] = sumConversionFactors(
        conversionFactor,
        record[id as ElementCategoryID],
      );
    });
  });
  return record;
};

export const getMainElementCategoryConversionFactorRecord = (
  pathRecord: PathRecord,
  quantityRecord: ResultsRecord,
): MainElementCategoryConversionFactorRecord => {
  const record: MainElementCategoryConversionFactorRecord = {};
  Object.entries(pathRecord).forEach(([id, path]) => {
    // Ignore deactivated elements and their children
    if (path.some((e) => isDeactivated(e))) {
      return;
    }

    const version = path.find(isBuildingVersionElement);
    const oneOfElements = getElementById(version, id);

    const emptyPathElement =
      isElement(oneOfElements) && isMainCategory(oneOfElements.category_id)
        ? oneOfElements
        : undefined;

    const mainCategoryElement = path.filter(isElement).length
      ? path.find((el) => isMainCategory(getElementCategoryId(el)))
      : emptyPathElement;

    const subCategoryElement = path.find((el) =>
      isCategoryIdsWithGeneratedChildren(getElementCategoryId(el)),
    );

    const mainCategoryId =
      getElementCategoryId(mainCategoryElement) || ElementCategoryID.MAIN_OTHER;
    const subCategoryId =
      getElementCategoryId(subCategoryElement) || ElementCategoryID.Other;

    const mainRecord = record[mainCategoryId] ?? {};
    const summedConversionFactors = mainRecord[subCategoryId];

    record[mainCategoryId] = {
      ...mainRecord,
      [subCategoryId]: sumConversionFactors(
        summedConversionFactors,
        quantityRecord[id],
      ),
    };
  });

  return record;
};

export const getSubCategoryConversionFactors = (
  record: MainElementCategoryConversionFactorRecord,
): ElementCategoryConversionFactorRecord[] => {
  return Object.entries(record).map(([, factors]) => factors);
};

export const getSumOfCategoryConversionFactors = (
  record: ElementCategoryConversionFactorRecord,
  factor: SupportedConversionUnits,
): number => {
  return Object.entries(record).reduce((sum, [, factors]) => {
    return sum + getConversionFactorValue(factors, factor);
  }, 0);
};

export const isServiceCategory = (
  id?: ElementCategoryID | ServiceCategoryId | 'none',
): id is ServiceCategoryId => isOneOf(serviceCategoryIds, id);

export const isServiceCategoryElement = (
  element: unknown,
): element is IElement =>
  isElement(element) && isServiceCategory(getElementCategoryId(element));

export const isProductCategory = (
  id?: ElementCategoryID | ProductCategoryId | 'none',
): id is ProductCategoryId => isOneOf(productCategoryIds, id);

type IProductCategoryElement = Replace<
  IElement,
  { category_id: ProductCategoryId }
>;
export const isProductCategoryElement = (
  element: unknown,
): element is IProductCategoryElement =>
  isElement(element) && isProductCategory(getElementCategoryId(element));

export const isSystemCategory = (
  id?: ElementCategoryID | SystemCategoryId | 'none',
): id is SystemCategoryId =>
  !!id && systemCategoryIds.includes(id as SystemCategoryId);

type ISystemCategoryElement = Replace<
  IElement,
  { category_id: SystemCategoryId }
>;
export const isSystemCategoryElement = (
  element: unknown,
): element is ISystemCategoryElement =>
  isElement(element) && isSystemCategory(getElementCategoryId(element));

export const orderMainCategoryFactors = (
  record: MainElementCategoryConversionFactorRecord,
): [MainCategoryId, ElementCategoryConversionFactorRecord][] => {
  const conversionFactorsArray = Object.entries(record) as [
    MainCategoryId,
    ElementCategoryConversionFactorRecord,
  ][];

  return conversionFactorsArray.sort((a, b) =>
    sortInOrderOf(mainCategoryIds, a, b, 0),
  );
};

export const getElementCategoryAndSelectionsByProductId = (
  ...productIds: (string | undefined)[]
):
  | {
      category_id: ElementCategoryID;
      selections: ReturnType<IElementCategoryPropertySelectionByProductIdFn>;
    }
  | undefined => {
  const definedProductIds = productIds.filter(isDefined);
  for (const category of elementCategories) {
    const {
      id,
      getElementPropertySelectionByProductId,
      availableProductIds = [],
    } = category;
    if (includesSome(availableProductIds, ...definedProductIds)) {
      // Get which selection that will lead to the specific product id
      if (getElementPropertySelectionByProductId) {
        const selections = definedProductIds.reduce(
          (acc, productId) => {
            const selections =
              getElementPropertySelectionByProductId(productId);
            if (Object.keys(selections).length) {
              return selections;
            }
            return acc;
          },
          {} as ReturnType<typeof getElementPropertySelectionByProductId>,
        );
        return {
          category_id: id,
          selections, //: omitUndefined(selections),
        };
      }
      // If no selection function is defined, return an empty selection
      else {
        return {
          category_id: id,
          selections: {},
        };
      }
    }
  }
  return {
    category_id: ElementCategoryID.OtherProduct,
    selections: {},
  };
};

/**
 * Apply property selections to an element.
 * Will do it in order so any properties not available will be added before choosing that property
 * @param element
 * @param selections
 * @param ignoreMissingProperties If true, will not throw an error if a property is not found
 * @returns
 */
export const applyElementCategoryPropertySelections = (
  element: IElement,
  selections: ReturnType<IElementCategoryPropertySelectionByProductIdFn> = {},
) => {
  let changes = propertyChangeRecordToArray(selections);
  const properties = getElementProperties(element);

  if (!properties.length || !changes.length) {
    return element;
  }

  // Take one property change at a time and apply it to the element and update the element to get new properties applied to the next iteration
  while (changes.length) {
    try {
      const existingChange = required(
        changes.find((c) => element.properties?.some((p) => p.name === c.name)),
        'could not find existing change',
      );

      element = updateElementCategoryProperties(
        setElementProperties(element, element, existingChange),
      );
      changes = cloneRemoveItems(changes, existingChange);
    } catch (error) {
      console.warn('Error applying property selections', selections, changes);
      changes.reverse(); // Try from the other end
      console.error('Error applying property selections', error);
      throw error;
    }
  }

  const updatedProps = getCategoryPropertyValueRecord(element);

  if (!isPartialEqual(updatedProps, selections)) {
    throw new Error('Property selections do not match');
  }

  return element;
};
