import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { required } from '../../../../shared/helpers/function_helpers';
import { openEditProductDialog } from '../../projects/EditProject/EditProductDialog';
import { useProductsLookup } from '../../store/product';
import { useSelectedVersion, useUIState } from '../../store/ui';
import EPDSelectMenu from './EPDSelectMenu';
import EPDButtonMenu from './EPDButtonMenu';
import { useEPDMenuItems } from './EPDMenuItems';
import { ProductID } from '../../../../shared/models/product.interface';
import { getProductListElementId } from '../../helpers/product-list.helpers';
import { isProductElement } from '../../../../shared/helpers/recursive_element_helpers';
import { ProductSettingsProps } from '../SidePanel/ProductSettings';
import { useNavigateTo } from '../../hooks/router.hooks';
import {
  getGenericProductId,
  hasProductElementEpd,
} from '../../../../shared/helpers/product-element.helpers';
import {
  useAppliedEpdsFromGenericProduct,
  useClearEpdOnProductElements,
  useSelectProductElementEpds,
  useSetEpdOnProductElements,
} from '../../hooks/product-switch.hook';
import { useUpdateProductInVersion } from '../../hooks/useProducts';

interface EPDMenuProps
  extends Pick<
    ProductSettingsProps,
    | 'product_id'
    | 'generic_product_id'
    | 'productCategoryElement'
    | 'generatedElements'
  > {
  variant?: 'button' | 'select';
}

const EPDMenu: React.FC<EPDMenuProps> = ({
  variant = 'select',
  product_id,
  generic_product_id,
  productCategoryElement,
  generatedElements,
}) => {
  const { setSelectedProductListElementId, selectedPage } = useUIState(
    'setSelectedProductListElementId',
    'selectedPage',
  );
  const navigateTo = useNavigateTo();
  const updateProductInVersion = useUpdateProductInVersion();

  const selectedVersion = required(useSelectedVersion());
  const productsLookup = useProductsLookup();

  const hasEPD = hasProductElementEpd({ product_id, generic_product_id });
  const genericProductId = getGenericProductId({
    product_id,
    generic_product_id,
  });

  const productCategoryElementChildren = useMemo(
    () =>
      productCategoryElement?.elements
        .filter(isProductElement)
        .filter((child) => getGenericProductId(child) === genericProductId) ??
      [],
    [productCategoryElement, genericProductId],
  );
  const productElements = generatedElements ?? productCategoryElementChildren;

  /* 
  Id of the EPD item that the kebab sub menu has been opened from. 
  Used to prevent closing the parent menu when the kebab menu is closed.
  */
  const [kebabMenuAnchorId, setKebabMenuAnchorId] = useState('');
  const [selectMenuValue, setSelectMenuValue] = useState('');
  const [isSelectedButtonMenuValue, setIsSelectedButtonMenuValue] =
    useState(false);

  const appliedEPD = useMemo(
    () => (hasEPD ? selectedVersion.products[product_id] : undefined),
    [hasEPD, product_id, selectedVersion.products],
  );

  const mappedEPDs = useAppliedEpdsFromGenericProduct(
    genericProductId,
    appliedEPD,
  );

  const setProductListElementId = useCallback(
    (id: ProductID) => {
      if (selectedPage === 'products') {
        const elementId = getProductListElementId(id, genericProductId);
        setSelectedProductListElementId(elementId);
        navigateTo({ elementId });
      }
    },
    [
      selectedPage,
      genericProductId,
      setSelectedProductListElementId,
      navigateTo,
    ],
  );

  const clearEPD = useClearEpdOnProductElements();
  const selectEpd = useSelectProductElementEpds();
  const setEpdOnProductElements = useSetEpdOnProductElements();

  const handleSelectProduct = useCallback(
    (id: ProductID) => {
      setEpdOnProductElements(productElements, id);
      setSelectMenuValue(id);
      setProductListElementId(id);
    },
    [productElements, setEpdOnProductElements, setProductListElementId],
  );

  const handleClearEPD = useCallback(
    async (id?: ProductID) => {
      if (!id || id === selectMenuValue) {
        await clearEPD(productElements);
        setSelectMenuValue('');
        setProductListElementId(genericProductId);
      }
      setKebabMenuAnchorId('');
    },
    [
      selectMenuValue,
      clearEPD,
      productElements,
      setProductListElementId,
      genericProductId,
    ],
  );

  const handleEditEPD = useCallback(
    async (editId?: ProductID) => {
      const product = await openEditProductDialog({
        parent: !editId ? productCategoryElement : undefined,
        product: editId ? productsLookup[editId] : undefined,
      });

      // If not cancelled
      if (product) {
        setKebabMenuAnchorId('');
        updateProductInVersion(product);
        handleSelectProduct(product.id);
      }
    },
    [
      handleSelectProduct,
      productCategoryElement,
      productsLookup,
      updateProductInVersion,
    ],
  );

  const handleSelectEPD = useCallback(
    async (id: string) => {
      // set this to trigger the button menu to close
      setIsSelectedButtonMenuValue(true);

      if (id === 'none') return handleClearEPD();
      if (id === 'other') return selectEpd(productElements);
      if (id === 'new') return handleEditEPD();

      try {
        // id = product id
        handleSelectProduct(id);
      } catch {
        setSelectMenuValue(appliedEPD?.id ?? '');
      }
    },
    [
      appliedEPD?.id,
      handleClearEPD,
      handleEditEPD,
      handleSelectProduct,
      productElements,
      selectEpd,
    ],
  );

  const handleOpenKebab = useCallback(
    (id: ProductID) => {
      setKebabMenuAnchorId(id);
    },
    [setKebabMenuAnchorId],
  );

  const handleCloseKebab = useCallback(() => {
    setKebabMenuAnchorId('');
  }, [setKebabMenuAnchorId]);

  const menuItems = useEPDMenuItems({
    variant,
    genericProductId,
    kebabMenuAnchorId,
    parentMenuValue: selectMenuValue,
    epds: mappedEPDs,
    onSelect: handleSelectEPD,
    onEdit: handleEditEPD,
    onRemove: handleClearEPD,
    onOpenKebab: handleOpenKebab,
    onCloseKebab: handleCloseKebab,
  });

  useEffect(() => {
    const mappedIds = mappedEPDs.map(({ id }) => id);
    const appliedId = appliedEPD?.id ?? '';

    setSelectMenuValue(mappedIds.includes(appliedId) ? appliedId : '');
  }, [appliedEPD, mappedEPDs, selectMenuValue]);

  return (
    <>
      {variant === 'select' && (
        <EPDSelectMenu
          selectedEpdId={
            selectMenuValue === appliedEPD?.id ? selectMenuValue : ''
          }
          items={menuItems}
          epds={mappedEPDs}
          kebabMenuAnchorId={kebabMenuAnchorId}
          selectEPD={handleSelectEPD}
        />
      )}

      {variant === 'button' && (
        <EPDButtonMenu
          items={menuItems}
          selectedEPDName={appliedEPD?.name}
          preventClose={!isSelectedButtonMenuValue || !!kebabMenuAnchorId}
          setIsSelectedMenuValue={setIsSelectedButtonMenuValue}
        />
      )}
    </>
  );
};

export default EPDMenu;
