import { isObject, mapValues } from 'lodash';
import { countOccurrences } from './string_helpers';
import { co2Units, costUnits, QuantityUnit } from '../models/unit.interface';
import { includesAll, isOneOf } from './array_helpers';
import {
  filterPropertiesByType,
  getKeys,
  isNonArrayObject,
  pick,
} from './object_helpers';
import { NotArray, TypeFilter } from '../models/type_helpers.interface';
import {
  isValidNumber,
  validateFiniteNumber,
} from '../validation/number.validation';

const LARGE_NUMBER = 999999999;

export const roundToDecimals = (v: number, decimals = 2): number =>
  Math.round(v * Math.pow(10, decimals)) / Math.pow(10, decimals);

/**
 * Supported currencies
 */
export type FormatUnit =
  | 'SEK'
  | 'kSEK'
  | 'k'
  | 'GFA'
  | 'co2e'
  | 'co2e_A'
  | 'co2e_B'
  | 'sek'
  | 'sek_A'
  | 'sek_B'
  | QuantityUnit;

interface IFormatValueOptions {
  formatCurrencyAsKSEK?: boolean;
  perUnit?: FormatUnit | { unit?: FormatUnit; value?: number };
  showUnit?: boolean;
}

const costFormatUnits = [...costUnits, 'sek', 'sek_A', 'sek_B'] as const;
const isCostFormatUnit = (unit: FormatUnit): boolean =>
  isOneOf(costFormatUnits, unit);

const co2FormatUnits = [...co2Units, 'co2e', 'co2e_A', 'co2e_B'] as const;
const isCo2FormatUnit = (unit: FormatUnit): boolean =>
  isOneOf(co2FormatUnits, unit);

/**
 * Format a number as a currency string.
 * @param value The value to format
 * @param currency The currency to use. Defaults to SEK
 * @returns A formatted currency string
 */
export const formatValue = (
  value: number = 0,
  unit?: FormatUnit,
  options: IFormatValueOptions = {},
): string => {
  if (!unit) {
    return formatThousands(value);
  }

  // Make sure to not output co2e_A1-A3 etc as unit
  unit = getFormattedUnit(unit, options);

  // Convert to thousands if currency is kSEK or k
  if (unit.startsWith('kSEK') || unit === 'k') {
    value /= 1000;
  }

  const { perUnit, showUnit = true } = options;

  // If perUnit is an object, divide value by the value of the unit
  if (isObject(perUnit) && perUnit.unit && perUnit.value) {
    value /= perUnit.value ?? 1;
  }

  // Add space if currency is not k for readability 544kSEK
  const space = unit !== 'k' ? ' ' : '';
  const displayUnit = showUnit ? space + unit : '';
  return `${formatThousands(value)}${displayUnit}`;
};

const getFormattedUnit = (
  unit: FormatUnit,
  { formatCurrencyAsKSEK = true, perUnit }: IFormatValueOptions = {},
): FormatUnit => {
  const perUnitString = typeof perUnit === 'string' ? perUnit : perUnit?.unit;
  const unitSuffix = perUnitString ? ` / ${perUnitString}` : '';

  // Cost units are mostly formatted with kSEK (and never with original unit)
  if (isCostFormatUnit(unit)) {
    const sek = formatCurrencyAsKSEK ? 'kSEK' : 'SEK';
    return (sek + unitSuffix) as FormatUnit;
  }
  // CO2 units are mostly formatted with kgCO2e (and never with original unit)
  if (isCo2FormatUnit(unit)) {
    return ('kgCO2e' + unitSuffix) as FormatUnit;
  }

  // Add GFA to unit if it's kg/m²
  if (unit === 'kg/m²') {
    return 'kgCO2e/m² GFA' as FormatUnit;
  }

  return (unit + unitSuffix) as FormatUnit;
};

export const formatThousands = (value: number): string => {
  if (!isFinite(value)) {
    return '' + value;
  }

  // Don't show too small decimal value. They often show up like 1.2345e-7
  if (value !== 0 && Math.abs(value) < 0.0001) {
    return '~0';
  }
  if (value > LARGE_NUMBER || value < -LARGE_NUMBER) {
    return value.toExponential(3);
  }

  value =
    Math.abs(value) >= 100 ? Math.round(value) : Number(value.toPrecision(2));

  let str = value.toString();
  let str_arr: string[];
  const is_negative = value < 0;

  if (is_negative) {
    //Remove minus sign if negative.
    str = str.substr(1);
  }
  const separated = str.split('.'); //Separate decimals; only group the value before the decimal point.
  const first = separated[0];

  str_arr = first ? first.split('') : [];
  str = '';
  while (str_arr.length > 3) {
    str =
      ' ' +
      str_arr[str_arr.length - 3] +
      str_arr[str_arr.length - 2] +
      str_arr[str_arr.length - 1] +
      str;
    str_arr = str_arr.slice(0, str_arr.length - 3);
  }
  str = str_arr.join('') + str;
  str += separated.length > 1 ? '.' + separated[1] : '';
  if (is_negative) {
    //Add minus sign if negative.
    str = '-' + str;
  }

  return str;
};

/**
 * Test if a string can be converted into a number by using +str
 * @param str
 * @returns
 */
export const isNumeric = (str: unknown): boolean =>
  typeof str === 'string' || typeof str === 'number' ? isFinite(+str) : false;

/**
 * Limit a value between a min and max.
 * If min or max is not provided that limit is ignored.
 * @param num
 * @param min
 * @param max
 * @returns
 */
export const clamp = (
  num: number,
  min: number | undefined,
  max: number | undefined,
): number => {
  if (min !== undefined && max !== undefined && min > max) {
    throw new Error('Min cannot be greater than max');
  }
  if (min !== undefined) {
    num = Math.max(num, min);
  }
  if (max !== undefined) {
    num = Math.min(num, max);
  }

  return num;
};

/**
 * Get highest value of  an array of objects containing a number property.
 * Note that is will only check numbers and ignore other properties
 */
export function highest(values: number[]): number;
export function highest<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object,
  K extends keyof T,
>(values: T[], key: K): number;
export function highest<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object | number,
  K extends keyof T,
>(values: T[], key?: K): number {
  return values.reduce((acc, curr) => {
    const value = typeof curr === 'number' ? curr : key && curr[key];
    return typeof value === 'number' ? Math.max(acc, value) : acc;
  }, 0);
}

/**
 * Sum an array of objects containing a number property.
 * Note that is will only sum numbers and ignore other properties
 */
export function sum(values: (number | undefined)[]): number;
export function sum(object: object): number;
export function sum<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object,
>(values: T[], keyOrMapFn?: (value: T) => number): number;
export function sum<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object,
  K extends keyof T,
>(values: T[], key: K): number;
export function sum<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object | number,
  K extends keyof T,
>(values: T[] | object, keyOrMapFn?: K | ((value: T) => number)): number {
  // If key is a function then we are mapping the values
  if (typeof keyOrMapFn === 'function') {
    return sum(Object.values(values).map(keyOrMapFn));
  }
  if (!Array.isArray(values) && isObject(values)) {
    return sum(Object.values(values).filter(Number.isFinite)); // Filter out non-finite values like strings, NaN, etc
  }
  return (values as T[]).reduce((acc, curr) => {
    const value =
      typeof curr === 'number' ? curr : keyOrMapFn && curr[keyOrMapFn];
    return acc + (typeof value === 'number' && isFinite(value) ? value : 0);
  }, 0);
}

export const sumObjects = <T extends object, R extends TypeFilter<T, 'number'>>(
  objects: T[],
): R => {
  return objects.reduce((acc, obj) => {
    const filtered = filterPropertiesByType(obj, 'number');
    getKeys(filtered).forEach((key) => {
      const value = filtered[key];
      if (isValidNumber(value)) {
        acc[key] = ((acc[key] ?? 0) + value) as R[typeof key];
      }
    });
    return acc;
  }, {} as R);
};

/**
 * Sum properties in an object. If no keys are provided, all properties are summed.
 * @param object
 * @param keys properties to sum, defaults to all properties
 * @returns
 */
export const sumProperties = <T extends object>(
  object: T,
  ...keys: (keyof T)[]
): number => {
  return sum(Object.values(keys.length ? pick(object, ...keys) : object));
};

/**
 * Multiply an array of numbers or objects containing a number property.
 * Note that is will only multiply numbers and ignore other properties
 */
export function multiply(values: number[]): number;
export function multiply<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object,
  K extends keyof T,
>(values: T[], key: K): number;
export function multiply<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object | number,
  K extends keyof T,
>(values: T[], key?: K): number {
  return values.reduce((acc, curr) => {
    const value = typeof curr === 'number' ? curr : key && curr[key];
    return acc * (typeof value === 'number' ? value : 1);
  }, 1);
}

/**
 * Make sure the type is used as number.
 * @param value The value to convert to number
 * @param defaultValue
 * @returns
 */
export const getValueAsNumber = (
  value: boolean | string | number | undefined,
  defaultValue = 0,
): number => {
  return value !== undefined && value !== null && isFinite(+value)
    ? +value
    : defaultValue;
};

/**
 * Multiply any number properties in an object by a factor.
 * If a property is not a number, it is kept unmodified.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function multiplyProperties<T extends object, K extends keyof T>(
  obj: T,
  factor: number,
  ...exclude: K[]
): T {
  if (!isNonArrayObject(obj) || factor === 1) {
    return obj;
  }

  const result = mapValues(obj, (value, key) => {
    return typeof value === 'number' && !exclude.includes(key as K)
      ? value * factor
      : value;
  });

  return result as T;
}

/**
 * Get all properties in an object as numbers
 * @param object
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const toNumberObject = <T extends object, K extends keyof T>(
  object: T,
  defaultValue?: number,
): Record<K, number> => {
  return mapValues(object, (value) => toNumber(value, defaultValue));
};

/**
 * Convert value to number or return default value if it can't be done.
 * Will also make sure a number value can't be NaN, Infinity or -Infinity.
 * @param value
 * @param defaultValue
 * @returns
 */
export const toNumber = (value: unknown, defaultValue = 0): number => {
  // Remove formatting to support strings like '1 000'
  if (typeof value === 'string') {
    value = value.trim().replace(/[^\d]+$/g, '');
    value = removeNumberFormatting(value);

    // Empty should be converted to default
    if (!value) {
      return toNumber(defaultValue);
    }
  }

  switch (typeof value) {
    case 'string':
    case 'boolean':
    case 'number': {
      const converted = +value;
      return isFinite(converted) ? converted : toNumber(defaultValue);
    }
  }
  return toNumber(defaultValue);
};

/**
 * Make number parsable
 * Will turn 1 000 into 1000, 1,000.00 into 1000.00
 * and 1,000,000.12 into 1000000.12
 * @param str
 * @returns
 */
export const removeNumberFormatting = <T = string>(input: T): T => {
  // Cases we shouldn't try to fix (can only remove formatting from strings looking like valid numbers)
  if (typeof input !== 'string' || !input || !/^-?[\d.,\s]+$/g.test(input)) {
    return input;
  }

  let str: string = input;

  // Remove all commas if there is a dot or there are more than one comma
  if (
    (str.includes('.') && str.lastIndexOf(',') < str.lastIndexOf('.')) ||
    countOccurrences(str, ',') > 1
  ) {
    str = str.replaceAll(',', '');
  }
  str = str
    .replace(/\s/g, '') // Remove spaces
    .replace(/(?<=\d),(?=\d)/g, '.'); // Replace comma between numbers with dot

  // Remove all dots except the first one
  while (countOccurrences(str, '.') > 1) {
    str = str.replace('.', '');
  }
  return str as T;
};

export const getMedianValue = (array: number[], position = 0.5): number => {
  const sorted = [...array].sort((a, b) => b - a).reverse();
  const middleIndex = (sorted.length - 1) * position;
  const rounded = sorted[Math.round(middleIndex)];
  const floored = sorted[Math.floor(middleIndex)];
  const ceiled = sorted[Math.ceil(middleIndex)];

  // Exactly on a number
  if (middleIndex % 1 === 0 && rounded !== undefined) {
    return rounded;
  } else if (floored !== undefined && ceiled !== undefined) {
    return (floored + ceiled) / 2;
  }
  return NaN;
};

/**
 * Get the mean value of an array of numbers or objects containing a number property.
 * @param array
 * @returns
 */
export const getMean = <
  T extends number | NotArray<object>,
  O = TypeFilter<Exclude<T, number>, 'number'>,
  R = T extends number ? number : O,
>(
  ...array: T[]
): R => {
  if (array.length === 0) {
    return 0 as R;
  }
  const numbers: number[] = array.filter((item) => typeof item === 'number');

  if (numbers.length > 0) {
    if (numbers.length !== array.length) {
      throw new Error('Array contains both numbers and non-numbers');
    }
    return validateFiniteNumber(sum(numbers) / numbers.length) as R;
  }

  const sumObj = sumObjects(array as Exclude<T, number>[]);
  return multiplyProperties(sumObj, 1 / array.length) as R;
};

export const factorToPercentage = (factor: number): number => factor * 100;

export const percentageToFactor = (percentage: number): number =>
  percentage / 100;

/**
 * Check if two numbers are close to each other.
 * @param a
 * @param b
 * @param precision How large the difference can be
 * @returns
 */
export const isCloseTo = (
  a: number | undefined,
  b: number | undefined,
  precision = 0.0001,
): boolean => {
  if (a === undefined || b === undefined) {
    return false;
  }
  return Math.abs(a - b) <= precision;
};

/**
 * Check if two numbers are close to each other by a percentage.
 * @param a
 * @param b
 * @param percentage
 * @returns
 */
export const isCloseToByPercent = (
  a: number,
  b: number,
  percentage: number = 0.01,
): boolean => {
  if (a === b) {
    return true;
  }
  const min = Math.min(a, b);
  const max = Math.max(a, b);
  return min * (1 + percentage) >= max;
};

export const isNumberObjectsCloseTo = (
  recordA: object | undefined,
  recordB: object | undefined,
  percentage?: number,
): boolean => {
  if (!isNonArrayObject(recordA) || !isNonArrayObject(recordB)) {
    return recordA === recordB;
  }
  const keysA = getKeys(recordA);
  const keysB = getKeys(recordB);

  if (!includesAll(keysA, ...keysB)) {
    return false;
  }
  return keysA.every((key) => {
    const valueA = recordA[key];
    const valueB = recordB[key];
    const isNumberA = typeof valueA === 'number';
    const isNumberB = typeof valueB === 'number';

    // At least one of them is not a number
    if (!isNumberA || !isNumberB) {
      // If both are not numbers it's still considered equal
      return isNumberA === isNumberB;
    }
    return isCloseToByPercent(valueA, valueB, percentage);
  });
};

export const hasDecimals = (value: number): boolean => getDecimals(value) !== 0;

/**
 * Get all decimals of a number.
 * 0.123 -> 123
 * @param value
 * @returns
 */
export const getDecimals = (value: number | string): number => {
  const string = value.toString();

  if (isNumeric(value) && string.includes('.')) {
    return Number.parseFloat(string.split('.')[1] ?? '0') || 0;
  }

  return 0;
};

export const getMaxValuesInArray = <
  T extends object,
  R extends TypeFilter<T, 'number'>,
>(
  array: (T | undefined)[],
): R =>
  array.reduce((acc, cur): R => {
    if (!isObject(cur)) {
      return acc;
    }
    getKeys(cur).forEach((key) => {
      const value = cur[key];
      if (typeof value === 'number' && isFinite(value)) {
        const accValue = acc[key as any as keyof R];
        const numberValue =
          typeof accValue === 'number' && isFinite(accValue) ? accValue : 0;

        acc[key as any as keyof R] = Math.max(numberValue, value) as R[keyof R];
      }
    });

    return acc;
  }, {} as R);

export const toFixedLengthInteger = (
  value: number | string,
  length: number,
): string => {
  const integer = clamp(
    parseInt(value.toString()),
    0,
    Math.pow(10, length) - 1,
  );
  return ('0000000000000' + integer).slice(-length);
};
