import React, { useCallback, useState } from 'react';
import { Box, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import {
  getKeys,
  hasDefinedProperties,
} from '../../../../shared/helpers/object_helpers';
import amplitudeLog from '../../amplitude';
import { SortByAlternativeKey } from '../../../../shared/helpers/tree.helpers';
import { PartialRecord } from '../../../../shared/models/type_helpers.interface';
import { capitalize } from 'lodash';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { NodonTheme } from '../../style';

export type SortSelectOptions<T extends object> = PartialRecord<
  SortByAlternativeKey<T>, // Property name
  string // Label
>;

interface SortSelectProps<T extends object> {
  sortBy: SortByAlternativeKey<T>;
  options: SortSelectOptions<T>;
  setSortBy: (sortBy: SortByAlternativeKey<T>) => void;
}

const SortSelect = <T extends object>({
  sortBy,
  options,
  setSortBy,
}: SortSelectProps<T>) => {
  const [anchor, setAnchor] = useState<HTMLElement>();
  const openMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => setAnchor(e.currentTarget),
    [],
  );
  const closeMenu = useCallback(() => setAnchor(undefined), []);
  const handleChange = useCallback(
    (newSorting: SortByAlternativeKey<T>) => {
      if (newSorting && !hasDefinedProperties(options, newSorting)) {
        throw new Error(`Invalid sorting option: ${String(newSorting)}`);
      }
      setSortBy(newSorting as any); // TS goes crazy here (bug?)
      closeMenu();
      amplitudeLog('Elements Sort', {
        Sort: newSorting,
      });
    },
    [closeMenu, options, setSortBy],
  );

  return (
    <Box display="flex" justifyContent="flexEnd" alignItems="center">
      <Box flexGrow={0}>
        <Tooltip title={'Sort by'} disableInteractive>
          <Button
            style={BUTTON_STYLE}
            color="neutral"
            variant="text"
            size="small"
            onClick={openMenu}
            endIcon={anchor ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          >
            {capitalize(options[sortBy])}
          </Button>
        </Tooltip>
        <Menu open={!!anchor} anchorEl={anchor} onClose={closeMenu}>
          {getKeys(options).map((option) => (
            <MenuItem
              key={'option-' + String(option)}
              onClick={() => handleChange(option as any)} // TS Goes crazy here (bug?)
              selected={sortBy === option}
            >
              {options[option]}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

const BUTTON_STYLE = {
  maxWidth: '100%',
  textTransform: 'none',
  minWidth: '100px',
  height: '32px',
  fontSize: NodonTheme.typography.fontSize,
} as const;

export default SortSelect;
