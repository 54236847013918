import { useAuth0 } from '@auth0/auth0-react';
import React, {
  DependencyList,
  Dispatch,
  EffectCallback,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  cacheFactory,
  getTrigger,
} from '../../../shared/helpers/function_helpers';
import shallowEqual from '../../../shared/helpers/array_helpers';
import { isEqual } from 'lodash';

/**
 * Debounce a value
 * @param value Value to debounce
 * @param delay - Optional delay in milliseconds. If undefined, the value will be returned immediately.
 * @returns
 */
export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (typeof delay === 'number') {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [delay, value]);

  // If undefined delay don't debounce (0 will still debounce 0ms and thus trigger outside of scope)
  if (typeof delay !== 'number') {
    setDebouncedValue(value);
    return value; // No delay
  }

  return debouncedValue;
}

export const useToggleState = (
  initialState = false,
  [on, off] = [true, false],
): [boolean, () => void, Dispatch<React.SetStateAction<boolean>>] => {
  const [state, setState] = useState(initialState);

  const toggleState = useCallback(() => {
    setState((s) => (s === on ? off : on));
  }, [on, off]);

  return [state, toggleState, setState];
};

export const useBooleanState = (
  initialState = false,
): [boolean, () => void, () => void] => {
  const [state, setState] = useState(initialState);

  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);

  return [state, setTrue, setFalse];
};

type GetTrigger = typeof getTrigger;

/**
 * Get trigger function that calls provided function with provided arguments
 * Good to use for event handlers like onClick etc
 */
export const useTrigger: GetTrigger = (
  ...args: Parameters<GetTrigger>
): ReturnType<GetTrigger> => {
  // Can't do ESLInts recommendation here because [args] would be a new item every time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cb = useCallback(getTrigger(...args), args);
  return cb;
};

export const useLogoutIfUnauthorized = (): ((error: any) => void) => {
  const { logout } = useAuth0();

  return useCallback(
    (error: any) => {
      if (error?.response?.status === 401) {
        logout();
      }
    },
    [logout],
  );
};

/**
 * Trigger new calculation of value when dependencies change like useMemo
 * but use deep comparison to check if dependencies have changed instead of shallow comparison.
 * Avoid sending in large objects like project or version as dependencies for performance reasons.
 * @param factory
 * @param deps
 * @returns
 */
export function useMemoDeepEqual<T>(factory: () => T, deps: DependencyList): T {
  const prevDepsRef = useRef<DependencyList>();
  const factoryResultRef = useRef<T>();

  const prevDeps = prevDepsRef.current;

  if (deps === undefined) {
    throw new Error('Undefined DependencyList');
  }

  // Run factory function first time or if deps have changed
  if (prevDeps === undefined || !isEqual(prevDeps, deps)) {
    prevDepsRef.current = deps;
    factoryResultRef.current = factory();
  }

  // It should only be undefined if factory returns undefined so cast to T
  return factoryResultRef.current as T;
}

/**
 * Keep track of previous value of a variable
 * @param value
 * @returns The previous value of the variable
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const useHasChanged = (val: unknown): boolean =>
  usePrevious(val) !== val;

type ShallowCompareInput = Parameters<typeof shallowEqual>[0];

/**
 * Check if a value has changed using shallow comparison
 * @param val
 * @returns
 */
export const useHasChangedShallow = (val: ShallowCompareInput): boolean => {
  const prev = usePrevious(val);
  return !shallowEqual(prev, val);
};

/**
 * Check if a value has changed using deep comparison
 * @param val
 * @returns
 */
export const useHasChangedDeep = (val: unknown): boolean => {
  const prev = usePrevious(val);
  return !isEqual(prev, val);
};

export const useGranularEffect = (
  effect: EffectCallback,
  primaryDeps: DependencyList,
  secondaryDeps: DependencyList,
): void => {
  const ref = useRef<DependencyList>();
  const current = ref.current;

  // Run if any property in primary deps have changed
  if (!current || !primaryDeps.every((w, i) => Object.is(w, current[i]))) {
    ref.current = [...primaryDeps, ...secondaryDeps];
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(effect, ref.current);
};

// All types of "typeof variable" can be
type TypeOfTypes =
  | 'string'
  | 'number'
  | 'bigint'
  | 'boolean'
  | 'symbol'
  | 'undefined'
  | 'object'
  | 'function';

/**
 * Default shouldn't check for changed functions
 */
const effectOfTypesDefaults: TypeOfTypes[] = [
  'string',
  'number',
  'bigint',
  'boolean',
  'symbol',
  'undefined',
  'object',
];

export const useEffectOfTypes = (
  effect: EffectCallback,
  deps: DependencyList,
  types: TypeOfTypes[] = effectOfTypesDefaults,
): void => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(
    effect,
    deps.filter((d) => types.includes(typeof d)),
  );
};

export const useSharedMemo = cacheFactory;

/**
 * Run a function only once when the component mounts (regardless of dependencies)
 * TODO: Add tests
 * @param func
 */
export function useOnce(func: () => any) {
  const funcRef = useRef(func);

  useEffect(() => {
    funcRef.current();
  }, []);
}
