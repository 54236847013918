/* eslint-disable @typescript-eslint/no-unsafe-call */

import { getSBEFCode, getSBEFLifetimeById } from '../../sbef_helpers';
import { getNodeValue } from '../utils/get-node-value.helper';
import { BUILDING_LIFETIME_DEFAULT } from '../../project_factory_helpers';
import { required } from '../../function_helpers';

export function getSBEFLifetime(args: any[], math: any, scope: any): number {
  const sbefPropertyValue = getNodeValue(args[0], math, scope);
  const code = getSBEFCode(sbefPropertyValue);
  const sbefLifetime = code ? getSBEFLifetimeById(code) : undefined;

  // Get first defined lifetime value in prio order
  return required(
    [
      sbefLifetime, // 1. From SBEF code
      scope.get('lifetime'), // 2. From parent element
      scope.get('building_lifetime'), // 3. User defined building lifetime
      BUILDING_LIFETIME_DEFAULT, // 4. Fallback to building lifetime default
    ].find((l) => typeof l === 'number'),
  );
}

getSBEFLifetime.rawArgs = true;
