import React from 'react';
import { Results } from '../../../../../../shared/models/unit.interface';
import {
  useProjectListMaxResults,
  useProjectListGFAScale,
  useProjectCostIsDisabled,
} from '../../../../store/project';
import ResultsBarCharts from '../../../charts/ResultsBarCharts';
import { PROJECT_CO2_BAR_HEIGHT } from '../../list.constants';
import {
  useCo2eChartData,
  useCostChartData,
  useFilteredResultsBySelectedLifecycles,
  useResultsPerGFA,
} from '../../../../hooks/results.hook';
import { getConversionFactorValue } from '../../../../../../shared/helpers/conversion-factors.helpers';
import { ResultsTooltip } from '../../../ResultsTooltip';

interface ProjectListItemBarChartsProps {
  gfa: number;
  results?: Results;
}

const ProjectListItemBarCharts: React.FC<ProjectListItemBarChartsProps> = ({
  gfa,
  results,
}) => {
  const filteredResults = useFilteredResultsBySelectedLifecycles(results);
  const maxResults = useProjectListMaxResults();
  const gfaScale = useProjectListGFAScale(gfa);
  const disableCost = useProjectCostIsDisabled();
  const resultsPerGFA = useResultsPerGFA(filteredResults, gfa);
  const co2ePerGfaMax = getConversionFactorValue(maxResults, 'co2e');
  const costPerGfaMax = getConversionFactorValue(maxResults, 'sek');

  const co2ePerGFAData = useCo2eChartData(resultsPerGFA);
  const costPerGFAData = useCostChartData(resultsPerGFA);

  return (
    <ResultsTooltip results={resultsPerGFA} perUnit="GFA">
      <ResultsBarCharts
        pl={2}
        disableCost={disableCost}
        co2e={co2ePerGFAData}
        co2eMax={co2ePerGfaMax}
        cost={costPerGFAData}
        costMax={costPerGfaMax}
        co2eHeight={gfaScale * PROJECT_CO2_BAR_HEIGHT}
      />
    </ResultsTooltip>
  );
};

export default ProjectListItemBarCharts;
