import React from 'react';
import { Typography, Box } from '@mui/material';
import { required } from '../../../../../../shared/helpers/function_helpers';
import {
  isProductListCategoryGroup,
  isProductListItem,
} from '../../../../../../shared/helpers/recursive_element_helpers';
import {
  getProductListElementName,
  PRODUCT_LIST_PATH_MAX_LENGTH,
  getProductListEPDNames,
} from '../../../../helpers/product-list.helpers';
import { useSelectedVersion } from '../../../../store/ui';
import { NodonTheme } from '../../../../style';
import { EllipsisText } from '../../../EllipsisText';
import { CONTENT_CELL_WIDTH } from '../../list.constants';
import { OneOfProductListChildren } from '../../../../../../shared/models/project.interface';

interface ProductListItemNameProps {
  element: OneOfProductListChildren;
}

const ProductListItemName: React.FC<ProductListItemNameProps> = ({
  element,
}) => {
  const version = required(useSelectedVersion());

  if (isProductListCategoryGroup(element) || isProductListItem(element)) {
    const name = getProductListElementName(element, version.products);

    if (isProductListCategoryGroup(element)) {
      return (
        <Typography variant="h6" textTransform="uppercase">
          {name}
        </Typography>
      );
    }

    const tooltipTitle = getProductListElementName(element, {
      disableTruncation: true,
    });

    return (
      <Typography variant="body2" display="flex" overflow="auto">
        <EllipsisText
          tooltipTitle={
            tooltipTitle.length > PRODUCT_LIST_PATH_MAX_LENGTH
              ? tooltipTitle
              : undefined
          }
        >
          {name}
        </EllipsisText>
      </Typography>
    );
  }

  const [name, genericName] = getProductListEPDNames(element, version.products);

  if (genericName) {
    return (
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        maxWidth={CONTENT_CELL_WIDTH.NAME * 2}
      >
        <Typography
          variant="body2"
          display="flex"
          color={NodonTheme.palette.neutral.light}
        >
          <EllipsisText>{genericName}: </EllipsisText>
        </Typography>

        <Typography variant="body2" display="flex" overflow="auto">
          <EllipsisText>{name}</EllipsisText>
        </Typography>
      </Box>
    );
  }
  return <Typography variant="body2">{name}</Typography>;
};

export default ProductListItemName;
