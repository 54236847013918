import { FormControlLabel, FormControlLabelProps, Switch } from '@mui/material';
import React, { useCallback } from 'react';

interface NodonSwitchProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const NodonSwitch = ({ label, checked, onChange }: NodonSwitchProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(checked);
    },
    [onChange],
  );

  return (
    <FormControlLabel
      label={label}
      slotProps={slotProps}
      control={
        <Switch size="small" checked={checked} onChange={handleChange} />
      }
    />
  );
};

const slotProps: FormControlLabelProps['slotProps'] = {
  typography: {
    variant: 'body2',
    color: 'textSecondary',
    fontWeight: 500,
  },
};

export default NodonSwitch;
