import React, { useCallback, useMemo } from 'react';
import { ElementSelectPropertyCountType } from '../../../../../../../shared/models/element_property.interface';
import { ButtonProps, TypographyProps } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { createPropertySelectOptions } from '../../../../../../../shared/helpers/element_property_factory_helpers';
import { SBEF_MENU_VALUES } from '../../../../../../../shared/templates/categories/categories-properties.model';
import PropertyMenu from '../../../../menus/TextMenu/PropertyMenu';

interface RecipeSelectorSBEFMenuProps {
  selectedSBEF: string;
  onSelect: (sbef: string) => void;
}

const RecipeSelectorSBEFMenu: React.FC<RecipeSelectorSBEFMenuProps> = ({
  selectedSBEF,
  onSelect,
}) => {
  const sbefOptions = useMemo(
    () => createPropertySelectOptions(SBEF_MENU_VALUES),
    [],
  );

  const handleSelect = useCallback(
    (value: ElementSelectPropertyCountType) => {
      if (typeof value === 'string') {
        onSelect(value === 'none' ? '' : value);
      }
    },
    [onSelect],
  );

  const buttonLabelProps = useMemo<TypographyProps>(() => {
    return {
      textTransform: 'uppercase',
      color: 'neutral.main',
      fontSize: 12,
      fontWeight: 500,
      maxWidth: 500,
    };
  }, []);
  const buttonProps = useMemo<ButtonProps>(
    () => ({ endIcon: <ArrowDropDown /> }),
    [],
  );

  return (
    <PropertyMenu
      selectedValue={selectedSBEF || 'SBEF Code'}
      options={sbefOptions}
      onSelect={handleSelect}
      buttonLabelProps={buttonLabelProps}
      buttonProps={buttonProps}
    />
  );
};

export default RecipeSelectorSBEFMenu;
