import {
  isEqual,
  isMap,
  isObject,
  isObjectLike,
  mapKeys,
  size,
  uniq,
} from 'lodash';
import {
  DeepPartial,
  Entries,
  ItemOrItemId,
  Merge,
  NotArray,
  PartialRecord,
  PrimitiveType,
  ValidIdItem,
  TypeMap,
  TypeFilter,
  TypeFilterMap,
} from '../models/type_helpers.interface';
import shallowEqual, { isOneOf } from './array_helpers';

/**
 * Create a new object including a subset of propetries from an object
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function pick<T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Pick<T, K> {
  return keys.reduce(
    (result, prop) => {
      if (prop in obj) {
        result[prop] = obj[prop];
      }
      return result;
    },
    {} as Pick<T, K>,
  );
}

/**
 * Create a new object including a subset of propetries from an object
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function filterObject<T extends object, K extends keyof T>(
  obj: T,
  predicate: (value: T[K], key: K) => boolean | undefined,
): Partial<T> {
  if (!isObject(obj)) {
    throw new Error('Not an object');
  }

  return Object.entries(obj).reduce((result, [key, value]) => {
    if (predicate(value, key as K)) {
      result[key as K] = value;
    }
    return result;
  }, {} as Partial<T>);
}

/**
 * Clone an object and excluding a set of properties from the original
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function omit<T extends object, K extends keyof Merge<NonNullable<T>>>(
  obj: T = {} as T,
  ...keys: (K | undefined)[]
): Omit<T, K> {
  // Filter out undefined keys and keys that don't exist on the object
  const definedKeys = keys.filter(isDefined).filter((k) => k in obj);

  // Only create new object if we have keys to remove
  if (definedKeys.length) {
    const result = { ...obj };
    definedKeys.forEach((key) => {
      delete result[key];
    });
    return result;
  }
  // If no keys are provided, return the original object
  return obj;
}

/**
 * Remove properties with a specific value from an object.
 * If no keys are removed, return the original object.
 * @param obj
 * @param values Values to exclude
 * @returns
 */
export const omitValues = <T extends object>(
  obj: T,
  ...values: unknown[]
): Partial<T> => {
  if (!isNonArrayObject(obj)) {
    throw new Error('Not an object');
  }
  const result = { ...obj } as Partial<T>;
  getKeys(result).forEach((key) => {
    const value = result[key];
    if (isOneOf(values, value)) {
      delete result[key];
    }
  });
  // If no keys are removed, return the original object to not trigger rerenders
  return size(result) === size(obj) ? obj : result;
};

/**
 * Remove any undefined/null values from an object.
 * If no keys are removed, return the original object.
 * @param obj
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const omitUndefined = <T extends object>(obj: T): Partial<T> =>
  omitValues(obj, null, undefined);

/**
 * Get new object only containing properties not false, null, 0, undefined or empty string
 * @param obj
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function omitFalsy<T extends object>(obj: T): Partial<T> {
  if (typeof obj !== 'object') {
    throw new Error('Not an object');
  }
  const result = { ...obj } as Partial<T>;
  getKeys(result).forEach((key) => {
    const value = key in result && result[key];
    if (!value) {
      delete result[key];
    }
  });
  return result;
}

/**
 * Check if properties exist on object
 * @param obj
 * @param keys
 * @returns
 */
export function hasProperties<T extends object, K extends keyof T>(
  obj: T | undefined,
  ...keys: K[]
): obj is T & Pick<T, K> {
  if (!isObject(obj)) {
    return false;
  }
  return keys.every((key) => key in obj);
}

/**
 * Check if properties exist on object and is defined (not undefined or null)
 * @param obj
 * @param keys
 * @returns
 */
export function hasDefinedProperties<T extends object, K extends keyof T>(
  obj: Partial<T> | undefined,
  ...keys: K[]
): obj is T & Required<Pick<T, K>> {
  return (
    hasProperties(obj, ...keys) && keys.every((key) => isDefined(obj[key]))
  );
}

/**
 * Check if one or more properties exist on object and is defined (not undefined or null)
 * @param obj
 * @param keys
 * @returns
 */
export function hasSomeDefinedProperties<T extends object, K extends keyof T>(
  obj: Partial<T> | undefined,
  ...keys: K[]
): obj is T & Required<Pick<T, K>> {
  if (!isObject(obj)) {
    return false;
  }
  return Object.keys(obj)
    .filter((key) => keys.includes(key as K))
    .some((key) => isDefined(obj[key as K]));
}

/**
 * Check if properties exist on object and is defined
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function getMissingProperties<T extends object, K extends keyof T>(
  obj: Partial<T> | undefined,
  ...keys: K[]
): K[] {
  return keys.filter((key) => !isDefined(obj?.[key]));
}

/**
 * Check if properties exist on object and is truty (not false, null, 0, undefined or empty string)
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function hasTruthyProperties<T extends object, K extends keyof T>(
  obj: Partial<T>,
  ...keys: K[]
): obj is T & Required<Pick<T, K>> {
  return hasProperties(obj, ...keys) && keys.every((key) => !!obj[key]);
}

/**
 * Detect if subset of an object is the same as the original property
 * @param element
 * @param changes
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const isPartialEqual = <T extends object>(
  element: T,
  changes: Partial<T>,
): boolean => {
  return isEqual(changes, pick(element, ...getKeys(changes)));
};

const isDefined = <T>(value: T | undefined | null): value is T =>
  value !== undefined && value !== null;

/**
 * Typesafe version of Object.keys.
 * Will sort keys alphabetically as default to simplify comparison
 * @param obj
 * @param sort Sort keys alphabetically (easier to compare)
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const getKeys = <T extends object>(
  obj: T | null | undefined,
  sort = true,
): Array<keyof T> => {
  const keys = isDefined(obj) ? (Object.keys(obj) as Array<keyof T>) : [];
  return sort ? keys.sort() : keys;
};

/**
 * Get unique keys from an array of objects
 * @param array
 * @returns
 */
export const getKeysInObjectArray = <T extends object>(
  ...array: T[]
): (keyof T)[] => uniq(array.flatMap((obj) => getKeys(obj)));

/**
 * Typesafe version of Object.entries
 * @param obj
 * @returns
 */
export const getEntries = <T extends object>(obj: T): Entries<T> =>
  Object.entries(obj) as Entries<T>;

// eslint-disable-next-line @typescript-eslint/ban-types
export const enumToRecord = <T extends object>(
  enumObj: T,
): Record<keyof T, T[keyof T]> => {
  if (typeof enumObj !== 'object' || !enumObj) {
    throw new Error('Not an enum');
  }
  return getKeys(enumObj).reduce(
    (acc, key) => {
      // Remove the numeric keys from the enum
      if (typeof key === 'string' && !isFinite(+key)) {
        acc[key] = enumObj[key];
      }
      return acc;
    },
    {} as Record<keyof T, T[keyof T]>,
  );
};

/**
 * Map a record to a new record and allow filtering of items.
 * @param record Record to map/filter
 * @param valueMap Function to map values, return undefined to filter out
 * @returns
 */
export const mapFilterRecord = <
  T extends Record<K, T[K]> | Partial<Record<K, T[K]>>,
  K extends keyof T,
  R,
>(
  record: T,
  valueMap: (item: T[K], key: K) => R | undefined,
): Record<K, R> => {
  const clone = {} as Record<K, R>;

  getKeys(record).forEach((key) => {
    const value = valueMap(record[key] as T[K], key as K);

    if (value !== undefined) {
      clone[key as K] = value;
    }
  });
  return clone;
};

/**
 * Similar to Object.assign but will apply changes deeply. Note: Can not be used to delete properties since it's only merging in the changes
 * TODO: Rename to mergeChanges or deepObjectAssign?
 * @param original
 * @param changes A subset of the original object to change. Try to keep as small as possible
 * @returns
 */
export const applyChanges = <T>(original: T, changes: DeepPartial<T>): T => {
  if (changes === original) {
    return original;
  }

  // Update primitives & functions right away
  if (isPrimitive(changes) || typeof changes === 'function') {
    return changes as T;
  }
  // Overwrite completely if type changed
  if (
    Array.isArray(original) !== Array.isArray(changes) ||
    typeof original !== typeof changes
  ) {
    return changes as T;
  } else if (Array.isArray(changes)) {
    const array = Array.isArray(original) ? (original as unknown[]) : [];

    const updated = changes.map((change, i) =>
      applyChanges(array[i], change as DeepPartial<T>),
    );

    return (shallowEqual(array, updated) ? array : updated) as T;
  } else if (typeof changes === 'object') {
    const keys = getKeys(changes);

    // Only update if needed to not trigger to many rerenders of views
    if (changes && keys.length > 0) {
      keys.forEach((key) => {
        const change = changes[key] as DeepPartial<T[keyof T]>;
        const current = isObject(original) ? original[key] : undefined;
        const updated = applyChanges(current, change);

        // Equals, no need to update
        if (current !== updated) {
          original = { ...original, [key]: updated };
        }
      });
    }
  }
  return original;
};

/**
 * Replace properties in an object with new values if anything has changed.
 * Will use isEqual to detect changes so new arrays/objects will not cause changes unless their content has changed
 * @param obj
 * @param changes
 * @returns
 */
export const replaceProperties = <T extends object>(
  obj: T,
  changes: Partial<T>,
  ignoreUndefined = false,
): T => {
  const keys = ignoreUndefined
    ? getKeys(changes).filter((key) => changes[key])
    : getKeys(changes);
  return keys.reduce((result, key) => {
    const value = changes[key];
    return isEqual(obj[key], value) ? result : { ...result, [key]: value };
  }, obj);
};

export const isPrimitive = (value: unknown): value is PrimitiveType => {
  const type = typeof value;
  return !value || (type !== 'object' && type !== 'function');
};

export const getId = <T extends ValidIdItem>(
  itemOrId: ItemOrItemId<T>,
): T['id'] => (isPrimitive(itemOrId) ? itemOrId : itemOrId.id);

export const getName = <T extends { name?: string }>(
  itemOrName: T | T['name'],
): T['name'] => (isPrimitive(itemOrName) ? itemOrName : itemOrName.name);

export const compareObjectsWithAllKeys = <T extends Record<string, unknown>>(
  a: T,
  b: T,
): string[] =>
  Array.from(new Set([...Object.keys(a), ...Object.keys(b)])).filter(
    (key) => !isEqual(a[key], b[key]),
  );

export const getNumber = <
  T extends PartialRecord<K, T[K] | undefined>,
  K extends keyof T,
>(
  obj: T,
  key: K,
  defaultValue = 0,
): number => {
  const value = obj[key];
  return typeof value === 'number' && isFinite(value) ? value : defaultValue;
};

export const isEmptyObject = (obj: unknown): boolean =>
  isNonArrayObject(obj) && Object.keys(obj).length === 0;

/**
 * Test if an object is an object and not an null or array (array is a type of object)
 * @param obj
 * @returns
 */
export const isNonArrayObject = (obj: unknown): obj is NotArray<object> =>
  isObjectLike(obj) && !Array.isArray(obj);

/**
 * Get common keys from multiple objects.
 * Will ignore properties that are undefined
 * @param objects
 * @returns
 */
export const getCommonKeys = <T extends object>(
  ...objects: T[]
): (keyof T)[] => {
  if (objects.length === 0) {
    return [];
  }

  return objects.reduce((acc, obj) => {
    return getKeys(obj).filter(
      (key) => isDefined(obj[key]) && acc.includes(key),
    );
  }, getKeys(objects[0]));
};

/**
 * Get all keys from multiple objects
 * @param objects
 * @returns
 */
export const getAllKeys = <T extends object>(...objects: T[]): (keyof T)[] =>
  uniq(objects.flatMap((obj) => getKeys(omitUndefined(obj))));

/**
 * A more accurate type of function than typeof.
 * Introduces new types for null and array (typeof null/array is object)
 * @param value
 * @returns
 */
export const getTypeOf = (value: unknown): keyof TypeMap => {
  const type = typeof value;

  if (type === 'object') {
    if (value === null) {
      return 'null';
    }
    if (Array.isArray(value)) {
      return 'array';
    }
    return 'object';
  }
  return type;
};

/**
 * Check if value is of a specific type
 * @param value
 * @param expectedType
 * @returns
 */
export const isType = <T extends keyof TypeMap, V>(
  value: V | TypeMap<V>[T],
  ...expectedTypes: T[]
): value is TypeMap<V>[T] => isOneOf(expectedTypes, getTypeOf(value));

/**
 * Todo Fix typing to return correct type only (not original type)
 * @param obj
 * @param type
 * @returns
 */
export const filterPropertiesByType = <
  O extends object,
  T extends keyof TypeFilterMap<O>,
>(
  obj: O,
  type: T,
): TypeFilter<O, T> => {
  return mapFilterRecord(obj, (value) =>
    isType(value as TypeFilterMap<O>[keyof TypeFilterMap<O>], type)
      ? value
      : undefined,
  ) as TypeFilter<O, T>;
};

/**
 * Remap keys of an object
 * @param obj
 * @param keyMap
 * @returns
 */
export const mapRecordKeys = <
  T extends Record<string, unknown>,
  K extends keyof T,
>(
  obj: T,
  keyMap: Record<K, string> | Map<K, string>,
): T => {
  const recordMap = isMap(keyMap) ? Object.fromEntries(keyMap) : keyMap;
  return mapKeys(obj, (value, key) => recordMap[key] ?? key) as T;
};

export const mapObject = <T extends object, K extends keyof T, R>(
  obj: T,
  map: (value: T[K], key: K) => R,
): Record<K, R> => {
  if (!size(obj)) {
    return obj as Record<K, R>;
  }
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, map(value, key as K)]),
  ) as Record<K, R>;
};
