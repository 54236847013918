export enum NodonHost {
  Localhost = 'localhost',
  Development = 'test.nodon.se',
  Production = 'app.nodon.se',
}

export enum NodonEnvironment {
  Localhost = 'localhost',
  Development = 'development',
  Production = 'production',
}

type APITestAudience = 'nodon-api-test';
type APIProdAudience = 'nodon-api-prod';

type LocalUIBaseUrl = `http://${NodonHost.Localhost}:50000`;
type DevelopmentUIBaseUrl = `https://${NodonHost.Development}`;
type ProductionUIBaseUrl = `https://${NodonHost.Production}`;

type Environment = `${NodonEnvironment}`;

/**
 * Environment variables available on node server
 */
export interface ServerEnvironment extends NodeJS.ProcessEnv {
  ENVIRONMENT: Environment;

  UI_BASE_URL: LocalUIBaseUrl | DevelopmentUIBaseUrl | ProductionUIBaseUrl;

  API_KEY: string;
  AUTH0_ISSUER: string;
  AUTH0_DOMAIN: 'nodon.eu.auth0.com';
  AUTH0_AUDIENCE: APITestAudience | APIProdAudience;
  AUTH0_CLIENT_ID: string;
  AUTH0_CLIENT_SECRET: string;

  /**
   * Server port (to call for client)
   */
  PORT?: string;

  /**
   * Database url. Either DATABASE_URL or all DB_HOST, DB_PORT, DB_USER, DB_PASSWORD, DB_DATABASE must be set.
   */
  DATABASE_URL?: string;

  /**
   * Set these if DATABASE_URL is not set.
   */
  DB_HOST?: string;
  DB_PORT?: string;
  DB_USER?: string;
  DB_PASSWORD?: string;
  DB_DATABASE?: string;

  /**
   * Email settings
   */
  SMTP_FROM_ADDRESS?: string;
  SMTP_TRANSPORT_URL?: string;
}
