import {
  ElementPropertyType,
  IFactorySelectProperty,
} from '../../../models/element_property.interface';
import { ProductTree } from '../processor.model';

// Property Names

export enum MetalPropertyName {
  MetalMaterial = 'material',
  MetalForm = 'form',
  AmountScrapBased = 'amount_scrap_based',
}

export enum MetalSpecialPropertyName {
  ScrapBased = 'scrap_based',
}

// Parent types marked by /// sign

export enum MetalMaterial {
  Aluminum = 'Aluminum', ///
  Copper = 'Copper', ///
  Steel = 'Steel', ///
}

// Nested types

export enum Aluminum {
  Profile = 'Profile', ///
  Sheet = 'Sheet', ///
}
export enum Copper {
  Pipe = 'Pipe', ///
  Sheet = 'Sheet', ///
  Wire = 'Wire', ///
}
export enum Steel {
  FastenersAndFitting = 'Fasteners and fittings',
  LightWeightProfile = 'Light-weight profile',
  PipeStainless = 'Pipe, stainless',
  Rebar = 'Rebar',
  RebarStainless = 'Rebar, stainless',
  Sheet = 'Sheet',
  SheetStainless = 'Sheet, stainless',
  Structural = 'Structural',
  Wire = 'Wire',
}

export enum AluminumProfile {
  None = 'None',
  Percent100 = '100%',
}
export enum AluminumSheet {
  None = 'None',
}
export enum CopperPipe {
  Percent51 = '51%',
  Percent100 = '100%',
}
export enum CopperSheet {
  Percent51 = '51%',
  Percent97 = '97%',
}
export enum SteelStructural {
  None = 'None',
  Percent100 = '100%',
}

// productTree

export const metalProductTree: ProductTree = {
  [MetalMaterial.Aluminum]: {
    [Aluminum.Profile]: {
      [AluminumProfile.None]: 'boverket_sv-SE_6000000159',
      [AluminumProfile.Percent100]: 'boverket_sv-SE_6000000160',
    },
    [Aluminum.Sheet]: 'boverket_sv-SE_6000000161',
  },
  [MetalMaterial.Copper]: {
    [Copper.Pipe]: {
      [CopperPipe.Percent51]: 'boverket_sv-SE_6000000164',
      [CopperPipe.Percent100]: 'boverket_sv-SE_6000000165',
    },
    [Copper.Sheet]: {
      [CopperSheet.Percent51]: 'boverket_sv-SE_6000000162',
      [CopperSheet.Percent97]: 'boverket_sv-SE_6000000163',
    },
    [Copper.Wire]: 'boverket_sv-SE_6000000166',
  },
  [MetalMaterial.Steel]: {
    [Steel.FastenersAndFitting]: 'boverket_sv-SE_6000000191',
    [Steel.LightWeightProfile]: 'boverket_sv-SE_6000000152',
    [Steel.PipeStainless]: 'boverket_sv-SE_6000000157',
    [Steel.Rebar]: 'boverket_sv-SE_6000000154',
    [Steel.RebarStainless]: 'boverket_sv-SE_6000000156',
    [Steel.Sheet]: 'boverket_sv-SE_6000000153',
    [Steel.SheetStainless]: 'boverket_sv-SE_6000000158',
    [Steel.Structural]: {
      [SteelStructural.None]: 'boverket_sv-SE_6000000150',
      [SteelStructural.Percent100]: 'boverket_sv-SE_6000000151',
    },
    [Steel.Wire]: 'boverket_sv-SE_6000000155',
  },
};

// Properties

export const materialProperty: IFactorySelectProperty = {
  name: MetalPropertyName.MetalMaterial,
  type: ElementPropertyType.Select,
  count: MetalMaterial.Steel,
  options: Object.values(MetalMaterial),
};

export const formProperty: IFactorySelectProperty = {
  name: MetalPropertyName.MetalForm,
  type: ElementPropertyType.Select,
  count: Steel.LightWeightProfile,
  options: Object.values(Steel),
};

export const amountProperty: IFactorySelectProperty = {
  name: MetalPropertyName.AmountScrapBased,
  type: ElementPropertyType.Select,
  count: SteelStructural.None,
  options: Object.values(SteelStructural),
};
