import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyType,
  IFactoryProperty,
} from '../../../models/element_property.interface';
import { createProductTreeProcessor } from '../processor';
import { ProductTree } from '../processor.model';
import { getElementPropertyResolvedCountByNameOrId } from '../../../helpers/element_property_helpers';
import { isOneOf } from '../../../helpers/array_helpers';
import { NODON_AIR_HANDLING_UNIT_ID } from '../../../constants';
import { ElementKind } from '../../../models/project.interface';
import { calculateVentilationEnergy } from '../../../helpers/energy/ventilation-energy.helpers';
import { isEnvironment } from '../../../helpers/environment.helpers';
import { requiredType } from '../../../helpers/function_helpers';
import {
  getConversionFactorValue,
  removeConversionFactorsByKey,
} from '../../../helpers/conversion-factors.helpers';
const ELECTRICITY_PRODUCT_ID = 'boverket_sv-SE_6000000008';

/**
 * The mass of the air handling unit in kg per m³/s of airflow.
 * Value based on some swegon AHU averages: https://docs.google.com/spreadsheets/d/14sK0tG090cT9Mo2-cFtbftd9Nwg0HnicHwgm-vBduwQ/edit?usp=sharing
 */
const AHU_MASS_PER_AIRFLOW = 0.2 / 1000;

export enum VentilationPropertyName {
  Products = 'products',
  VentilationArea = 'ventilation_area',
  SpecificFanPower = 'specific_fan_power',
  HeatRecoveryEfficiency = 'heat_recovery_efficiency',
  SupplyAirFlowPerGFA = 'supply_air_flow_per_gfa',
  ExhaustAirFlowPerGFA = 'exhaust_air_flow_per_gfa',
  OperationSchedule = 'operation_schedule',
  SupplyAirPostHeating = 'supply_air_post_heating',
  PostHeatingTemperature = 'post_heating_temperature',
  PostHeatingHours = 'post_heating_hours',
  PeakHeatingSource = 'peak_heating_source',
  SupplyAirPreCooling = 'supply_air_pre_cooling',
  PreCoolingTemperature = 'pre_cooling_temperature',
  PreCoolingHours = 'pre_cooling_hours',
  Airflow = 'airflow',
}

enum HeatingSourceOptions {
  ExistingHeatingSystems = 'existing_heating_systems',
  DirectElectricHeating = 'direct_electric_heating',
  NoPeakHeating = 'no_peak_heating',
}

const properties: IFactoryProperty[] = [
  {
    name: VentilationPropertyName.VentilationArea,
    type: ElementPropertyType.Expression,
    fallbackCount: 'gfa * 0.9',
    unit: 'm²',
  },
  {
    name: VentilationPropertyName.SpecificFanPower,
    fallbackCount: 1.8,
    type: ElementPropertyType.Expression,
    unit: 'kW/m³s',
  },
  {
    name: VentilationPropertyName.HeatRecoveryEfficiency,
    type: ElementPropertyType.Expression,
    fallbackCount: 0.8,
    unit: '%',
  },
  {
    name: VentilationPropertyName.SupplyAirFlowPerGFA,
    type: ElementPropertyType.Expression,
    fallbackCount: 1.3,
    unit: 'l/m²s',
  },
  {
    name: VentilationPropertyName.ExhaustAirFlowPerGFA,
    type: ElementPropertyType.Expression,
    fallbackCount: 1.3,
    unit: 'l/m²s',
  },
  {
    name: VentilationPropertyName.OperationSchedule,
    type: ElementPropertyType.Expression,
    fallbackCount: 5000,
    unit: 'hr',
    displayUnit: 'hrs/year',
  },
  {
    name: VentilationPropertyName.SupplyAirPostHeating,
    type: ElementPropertyType.Switch,
    count: false,
  },
  {
    name: VentilationPropertyName.PostHeatingTemperature,
    type: ElementPropertyType.Expression,
    unit: '°C',
    fallbackCount: 18,
  },
  {
    name: VentilationPropertyName.PostHeatingHours,
    type: ElementPropertyType.Expression,
    unit: 'hrs/year',
    fallbackCount: VentilationPropertyName.OperationSchedule,
  },
  // {
  //   name: VentilationPropertyName.PeakHeatingSource,
  //   type: ElementPropertyInputType.Select,
  //   options: Object.values(HeatingSourceOptions),
  // },
  {
    name: VentilationPropertyName.SupplyAirPreCooling,
    type: ElementPropertyType.Switch,
    count: false,
  },
  {
    name: VentilationPropertyName.PreCoolingTemperature,
    type: ElementPropertyType.Expression,
    unit: '°C',
    fallbackCount: 18,
  },
  {
    name: VentilationPropertyName.PreCoolingHours,
    type: ElementPropertyType.Expression,
    unit: 'hr',
    displayUnit: 'hrs/year',
    fallbackCount: 1000,
  },
  {
    name: VentilationPropertyName.Airflow,
    type: ElementPropertyType.Expression,
    unit: 'm³/s',
    fallbackCount: `max(${VentilationPropertyName.SupplyAirFlowPerGFA}, ${VentilationPropertyName.ExhaustAirFlowPerGFA}) * ${VentilationPropertyName.VentilationArea} * 0.001`,
  },
];

const ENERGY_UNIT = 'kWh';
const TOTAL_ANNUAL_ENERGY_VARIABLE = 'total_annual_energy';

const productTree: ProductTree = {
  [HeatingSourceOptions.ExistingHeatingSystems]: 'boverket_sv-SE_6000000143', // FAKE
  [HeatingSourceOptions.DirectElectricHeating]: 'boverket_sv-SE_6000000144',
  [HeatingSourceOptions.NoPeakHeating]: 'boverket_sv-SE_6000000145',
};

const treeProcessor = createProductTreeProcessor({
  levelProperties: properties,
  productTree,
});

const SHOW_ONLY_WITH_POST_HEATING = [
  VentilationPropertyName.PostHeatingTemperature,
  VentilationPropertyName.PostHeatingHours,
  VentilationPropertyName.PeakHeatingSource,
];

const SHOW_ONLY_WITH_PRE_COOLING = [
  VentilationPropertyName.PreCoolingTemperature,
  VentilationPropertyName.PreCoolingHours,
];

const mapResults: IElementCategory['mapResults'] = (results, context) => {
  // Only move the energy results to B6 (keep the construction cost of the Air handling unit)
  if (context.productElement.unit === ENERGY_UNIT) {
    // Move all emissions/costs from A to B6 (Operational energy use)

    return removeConversionFactorsByKey(
      {
        ...results,
        co2e_B6: getConversionFactorValue(results, 'co2e_A'),
        sek_B6: getConversionFactorValue(results, 'sek_A'),
      },
      'co2e_A',
      'sek_A',
    );
  }

  return results;
};

const mapExpressionVariables: IElementCategory['mapExpressionVariables'] = (
  variables,
) => {
  const postHeating = requiredType(
    variables[VentilationPropertyName.SupplyAirPostHeating],
    'boolean',
    'undefined',
  );
  const preCooling = requiredType(
    variables[VentilationPropertyName.SupplyAirPreCooling],
    'boolean',
    'undefined',
  );

  const coordinates = variables.building_position;

  // Calculate total annual energy
  const { totalAnnualEnergy } = calculateVentilationEnergy({
    specificFanPower: requiredType(
      variables[VentilationPropertyName.SpecificFanPower],
      'number',
      'undefined',
    ),
    heatRecoveryEfficiency: requiredType(
      variables[VentilationPropertyName.HeatRecoveryEfficiency],
      'number',
      'undefined',
    ),
    operationHours: requiredType(
      variables[VentilationPropertyName.OperationSchedule],
      'number',
      'undefined',
    ),
    postHeatingTemp: postHeating
      ? requiredType(
          variables[VentilationPropertyName.PostHeatingTemperature],
          'number',
          'undefined',
        )
      : undefined,

    postHeatingHours: postHeating
      ? requiredType(
          variables[VentilationPropertyName.OperationSchedule],
          'number',
          'undefined',
        )
      : undefined,

    airflow: requiredType(
      variables[VentilationPropertyName.Airflow] ?? 0,
      'number',
    ),

    // peakHeatingSource: requiredType(
    //   variables[VentilationPropertyName.PeakHeatingSource],
    //   'string',
    //   'undefined',
    // ),
    preCoolingTemp: preCooling
      ? requiredType(
          variables[VentilationPropertyName.PreCoolingTemperature],
          'number',
          'undefined',
        )
      : undefined,
    preCoolingHours: preCooling
      ? requiredType(
          variables[VentilationPropertyName.PreCoolingHours],
          'number',
          'undefined',
        )
      : undefined,
    coordinates,
  });

  // Add the airflow to the variables
  return {
    ...variables,
    [TOTAL_ANNUAL_ENERGY_VARIABLE]: totalAnnualEnergy,
  };
};

export const ventilation: IElementCategory = {
  ...treeProcessor,
  id: ElementCategoryID.AirHandlingUnit,
  name: 'Air handling unit',
  color: 'rgba(255, 165, 0, 1)',
  defaultSelectedQuantity: 'mass',
  disabled: isEnvironment('production'),
  mapResults,
  mapExpressionVariables,
  getQuantityProperties: () => ({
    mass: {
      fallbackCount: `${VentilationPropertyName.Airflow} / ${AHU_MASS_PER_AIRFLOW}`,
    },
  }),
  getElementProperties: (element) => {
    const supplyPostHeating = getElementPropertyResolvedCountByNameOrId(
      element,
      VentilationPropertyName.SupplyAirPostHeating,
      false,
    );
    const supplyPreCooling = getElementPropertyResolvedCountByNameOrId(
      element,
      VentilationPropertyName.SupplyAirPreCooling,
      false,
    );

    return properties.filter((property) => {
      if (isOneOf(SHOW_ONLY_WITH_POST_HEATING, property.name)) {
        return supplyPostHeating; // { ...property, hidden: !supplyPostHeating };
      }

      if (isOneOf(SHOW_ONLY_WITH_PRE_COOLING, property.name)) {
        return supplyPreCooling; // { ...property, hidden: !supplyPreCooling };
      }

      return true;
    });
  },
  getChildElements: () => {
    return [
      {
        kind: ElementKind.Product,
        product_id: NODON_AIR_HANDLING_UNIT_ID,
        count: 'mass',
        unit: 'kg',
      },
      {
        kind: ElementKind.Product,
        product_id: ELECTRICITY_PRODUCT_ID,
        count: `${TOTAL_ANNUAL_ENERGY_VARIABLE} * building_lifetime`,
        unit: ENERGY_UNIT,
      },
    ];
  },
};
