import React, { useEffect } from 'react';
import VisibilityButton from '../../Buttons/VisibilityButton';
import { IProposal } from '../../../../../../shared/models/proposals.interface';
import {
  setHiddenProposal,
  useHiddenProposal,
  useProposals,
  useSelectProposal,
} from '../../../../hooks/proposals.hook';
import { useMouseEventCallback } from '../../../../hooks/events.hook';
import { getNextItem } from '../../../../../../shared/helpers/array_helpers';

interface ProposalVisibilityToggleProps {
  proposal: IProposal;
}

const ProposalVisibilityToggle: React.FC<ProposalVisibilityToggleProps> = ({
  proposal,
}) => {
  const hidden = useHiddenProposal(proposal.id);
  const proposals = useProposals();
  const selectProposal = useSelectProposal();

  const handleClick = useMouseEventCallback(() => {
    const nextProposal = getNextItem(proposals, proposal.id);

    if (!proposal.active && proposals.length > 1) {
      setHiddenProposal(proposal.id, !hidden);
    }
    if (proposal.active && nextProposal) {
      setHiddenProposal(nextProposal.id, false);
      setHiddenProposal(proposal.id, true);
      selectProposal(nextProposal.id);
    }
  });

  useEffect(() => {
    if (proposals.length < 2 && hidden) {
      setHiddenProposal(proposal.id, false);
    }
  }, [proposals, proposal.id, hidden]);

  return (
    <VisibilityButton
      visible={!hidden}
      onClick={handleClick}
      sx={{ visibility: proposals.length > 1 ? 'visible' : 'hidden' }}
    />
  );
};

export default ProposalVisibilityToggle;
