import React, { useCallback } from 'react';
import { IRowInput, Row } from '../Row';
import { RowCell } from '../RowCell';
import { Recipe } from '../../../../../shared/models/recipe.interface';
import { ELEMENT_LIST_ITEM_HEIGHT, LIST_SPACING } from '../list.constants';
import { ListItemButton, Typography } from '@mui/material';
import { useApplyRecipe } from '../../../hooks/useRecipes';
import { IElement } from '../../../../../shared/models/project.interface';
import { useLastSelectedRecipe } from '../../../store/recipe/recipe.hook';
import SBEFLabel from '../../SBEFLabel';
import { getSBEFCountValue } from '../../../../../shared/helpers/sbef_helpers';

interface RecipeListItemProps extends IRowInput {
  recipe: Recipe;
  element: IElement;
  onSelect: () => void;
}

const RecipeListItem: React.FC<RecipeListItemProps> = ({
  recipe,
  element,
  onSelect,
  ...rowProps
}) => {
  const { addRecipeToLastSelected } = useLastSelectedRecipe();
  const applyRecipe = useApplyRecipe();
  const sbefValue = getSBEFCountValue(recipe);

  const selectRecipe = useCallback(async () => {
    if (recipe.id === element.recipe_id) {
      return;
    }
    const updatedElement = await applyRecipe(element, recipe.id);
    if (updatedElement) {
      addRecipeToLastSelected(recipe.id);
      onSelect();
    }
  }, [applyRecipe, element, recipe.id, onSelect, addRecipeToLastSelected]);

  return (
    <Row
      {...rowProps}
      padding
      height={ELEMENT_LIST_ITEM_HEIGHT}
      spacing={LIST_SPACING.DEFAULT}
      selected={recipe.id === element.recipe_id}
    >
      <ListItemButton
        onClick={selectRecipe}
        disableRipple={recipe.id === element.recipe_id}
      >
        <RowCell>
          <SBEFLabel value={sbefValue} />
          <Typography>{recipe.name}</Typography>
        </RowCell>
      </ListItemButton>
    </Row>
  );
};

export default RecipeListItem;
