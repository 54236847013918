import { Tabs, Tab } from '@mui/material';
import React, { SyntheticEvent, useCallback } from 'react';

export const tabNames = ['all', 'generic', 'ökobaudat', 'epd'] as const;

export type TabName = (typeof tabNames)[number];

interface ProductSelectorTabMenuProps {
  selectedTab: TabName;
  setSelectedTab?: (tab: TabName) => void;
}

const ProductSelectorTabMenu: React.FC<ProductSelectorTabMenuProps> = ({
  selectedTab,
  setSelectedTab,
}) => {
  const handleChange = useCallback(
    (event: SyntheticEvent<Element, Event>, value: TabName) => {
      if (setSelectedTab) {
        setSelectedTab(value);
      }
    },
    [setSelectedTab],
  );

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      {tabNames.map((value) => (
        <Tab
          key={value}
          value={value}
          label={value}
          sx={{ pl: 12, pr: 12 }}
          disabled={!setSelectedTab && value !== selectedTab}
        />
      ))}
    </Tabs>
  );
};

export default ProductSelectorTabMenu;
