import { Box } from '@mui/material';
import React from 'react';
import GroupProductListByCategorySwitch from '../components/switches/GroupByCategorySwitch';
import SearchElements from '../components/filtering/SearchElements';
import PageNavigation from '../components/PageNavigation';
import SortElementsSelect from '../components/sorting/SortElementsSelect';
import { useUIState } from '../store/ui';
import ProductListEmissionsPerMassSwitch from '../components/switches/ProductListPerUnitSwitch';

const ListHeader = () => {
  const { selectedPage } = useUIState('selectedPage');

  return (
    <Box display="flex" justifyContent="space-between" pr={2} pl={2}>
      <PageNavigation />

      <Box display="flex" alignItems="center" gap={2}>
        {selectedPage === 'products' && (
          <Box display="flex" alignItems="center" gap={2}>
            <ProductListEmissionsPerMassSwitch />
            <GroupProductListByCategorySwitch />
          </Box>
        )}
        <SortElementsSelect />
        <SearchElements placeholder={`Search ${selectedPage}`} />
      </Box>
    </Box>
  );
};

export default ListHeader;
