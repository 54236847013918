import { isExpressionValue } from '../helpers/expression_factory_helpers';
import { hasTruthyProperties } from '../helpers/object_helpers';
import { isSupportedProductElementUnit } from '../helpers/product-element.helpers';
import { ProductRecordOwners } from '../helpers/product_helpers';
import { getBuildingVersionFromProductRecordOwners } from '../helpers/recursive_element_helpers';
import { isSelectableQuantityUnit } from '../helpers/unit_helpers';
import { IProductElement } from '../models/project.interface';
import { selectableQuantityUnits } from '../models/unit.interface';
import { isValidGenericProductId } from './product.validation';
import { ProjectValidationErrors } from './project.validation';
import { ValidationTypes } from './validation.helpers';

/**
 * Check that a IProductElement is valid
 * @param element
 * @returns true if valid, error message if invalid
 */
export const isValidProductElement = (
  element: IProductElement,
  productRecordOwner: ProductRecordOwners,
): ValidationTypes => {
  const { count, unit, generic_product_id, product_id } = element;

  // Only productElements should have a valid count
  if (!isExpressionValue(count)) {
    return ProjectValidationErrors.INVALID_COUNT;
  }
  if (!isSelectableQuantityUnit(unit)) {
    return `Invalid productElement unit "${String(unit)}", expected one of ${selectableQuantityUnits.join(', ')}`;
  }
  if (!hasTruthyProperties(element, 'product_id')) {
    return ProjectValidationErrors.PRODUCT_ELEMENT_MISSING_ID_OR_PRODUCT_ID;
  }
  if (!product_id) {
    return 'Product element product reference is required';
  }
  if (generic_product_id) {
    const validId = isValidGenericProductId(generic_product_id);
    if (validId !== true) {
      return validId;
    }
    // Generic id always comes with a product_id
    if (!product_id) {
      return 'Product_id must be set if generic_product_id is set';
    }
  }

  const version = getBuildingVersionFromProductRecordOwners(productRecordOwner);

  // If version is defined, we can validate the connection to the product as well (only when validating the entire building version)
  if (version) {
    const versionProducts = version.products;
    if (
      // Make sure unit exists in the conversion factors of select products
      !isSupportedProductElementUnit(versionProducts, element)
    ) {
      // TODO: Fix https://nodon.atlassian.net/browse/DEC-1800 and migrate then turn this on
      console.warn(
        `Invalid productElement unit "${String(unit)}". It doesn't exist in the conversion factors of select products`,
      );
    }
  }
  return true;
};
