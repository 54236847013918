import React, { useCallback, useState } from 'react';
import {
  getElementSearchString,
  setElementSearchString,
} from '../../hooks/filter-elements.hook';
import SearchField from '../SearchField';
import { TextFieldProps } from '@mui/material';

interface SearchElementsProps {
  placeholder: string;
}

const SearchElements: React.FC<SearchElementsProps> = ({ placeholder }) => {
  const initialSearchString = getElementSearchString();
  const [searchString, setSearchString] = useState(initialSearchString ?? '');

  const handleSearch = useCallback(
    (str: string) => {
      setSearchString(str);
      setElementSearchString(str);
    },
    [setSearchString],
  );

  return (
    <SearchField
      placeholder={placeholder}
      debounce={300}
      slotProps={slotProps}
      value={searchString}
      onChange={handleSearch}
    />
  );
};

const slotProps: TextFieldProps['slotProps'] = {
  input: { sx: { padding: '0 4px 0 0' } },
  htmlInput: { sx: { padding: '5px 0px' } },
} as const;

export default SearchElements;
