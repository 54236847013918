import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import deepmerge from 'deepmerge';
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, Close } from '@mui/icons-material';
import {
  IProduct,
  ProductID,
  ProductSources,
  ProductSourcesUnion,
} from '../../../../shared/models/product.interface';
import SelectProductList from './SelectProductList';
import ProductCategorySelector from './ProductCategorySelector';
import SearchField from '../../components/SearchField';
import NodonSelect from '../../components/NodonSelect';
import {
  QuantityUnit,
  selectableQuantityUnits,
} from '../../../../shared/models/unit.interface';
import { IElement } from '../../../../shared/models/project.interface';
import { useProducts, useProductStoreState } from '../../store/product';
import { orderBy } from 'lodash';
import ProductSelectorTabMenu, { TabName } from './ProductSelectorTabMenu';
import { useUsedProductIds } from '../../hooks/useUsedProductIds';
import { TEMPLATE_ORGANIZATION } from '../../../../shared/constants';
import { useSelectedVersion } from '../../store/ui';
import {
  getProductByIdDeprecated,
  isOneOfProductSources,
} from '../../../../shared/helpers/product_helpers';
import { openEditProductDialog } from './EditProductDialog';
import { useSearch } from '../../hooks/search.hooks';
import { create } from 'zustand';

export type ProductSelectorTabMenuValue =
  | 'all'
  | 'generic'
  | 'ökobaudat'
  | 'epd'
  | 'recent';

export type SelectableSource =
  | ProductSourcesUnion
  | ProductSources
  | ProductSelectorTabMenuValue;

interface IProductSelectorOptions {
  /**
   * Current selected product id
   */
  productId?: ProductID;
  lockedTab?: TabName;
  productCategoryElement?: IElement;
}

const ProductSelector: React.FC = () => {
  const {
    productId,
    lockedTab = 'epd',
    productCategoryElement: parent,
  } = useProductSelectorOptions() ?? {};
  const open = useIsProductSelectorOpen();
  const { classes } = useStyles();
  const selectedVersion = useSelectedVersion();
  const { productsLookup, deleteProduct } = useProductStoreState(
    'productsLookup',
    'deleteProduct',
  );
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined,
  );
  const [selectedTab, setSelectedTab] = useState<TabName>(lockedTab ?? 'all');

  const products = useProductsByByTab(selectedTab);
  const [searchString, setSearchString] = useState('');
  const filteredProducts = useSearch(
    useProductsFilteredByCategory(products, selectedCategory),
    searchString,
    {
      debounce: 300,
    },
  );

  const changeTab = useCallback(
    (tab: TabName) => {
      setSelectedCategory(lockedTab ?? tab);
    },
    [lockedTab],
  );

  const [selectedUnit, setSelectedUnit] = useState('kg' as QuantityUnit);

  const [expandedCategory, setExpandedCategory] = useState<string[]>([
    ProductSources.Boverket,
  ]);

  const product = useMemo(() => {
    if (productId) {
      return getProductByIdDeprecated(
        productsLookup,
        selectedVersion,
        productId,
      );
    }
  }, [productsLookup, productId, selectedVersion]);

  const categories = useMemo(() => getProductCategories(products), [products]);

  const handleDeleteProduct = useCallback(
    (product: IProduct): Promise<void> => deleteProduct(product.id),
    [deleteProduct],
  );

  const handleClose = useCallback(() => {
    closeProductSelector();
  }, []);

  const handleSelectProduct = useCallback((product: IProduct) => {
    closeProductSelector(product);
  }, []);

  const openCreateProductDialog = useCallback(async () => {
    const product = await openEditProductDialog({ parent });
    if (product) {
      handleSelectProduct(product);
    }
  }, [handleSelectProduct, parent]);

  const handleChange = useCallback(
    (value: string) => setSelectedUnit(value as QuantityUnit),
    [],
  );

  useEffect(() => {
    setSearchString('');
  }, [open]);

  // Change tab if empty empty
  useEffect(() => {
    if (products.length === 0 && !lockedTab) {
      changeTab('all');
    }
  }, [lockedTab, changeTab, products]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <Box
        mt={2}
        display="flex"
        flexDirection="column"
        height="100vh"
        alignItems="center"
      >
        <Box
          width="100%"
          height="150px"
          maxHeight={200}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="flex-start"
            width="100%"
            mr={2}
            position="absolute"
            top={0}
          >
            <IconButton onClick={handleClose} size="large">
              <Close />
            </IconButton>
          </Box>

          <Typography variant="h5">
            {product?.name ?? parent?.name ?? parent?.fallbackName}
          </Typography>

          <Box width="80%" display="flex" m={2}>
            <Box flex={1} mr={2}>
              <SearchField
                value={searchString}
                onChange={setSearchString}
                label={
                  <FormattedMessage
                    id="product_selector.search"
                    defaultMessage="Search"
                  />
                }
                sx={{ width: '100%' }}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Add />}
                  onClick={openCreateProductDialog}
                >
                  <FormattedMessage
                    id="product_selector.new_product"
                    defaultMessage="New product"
                  />
                </Button>
              </Box>
            </Box>
          </Box>
          <Box display="flex" gap="2rem">
            <Paper>
              <ProductSelectorTabMenu
                selectedTab={selectedTab}
                setSelectedTab={!lockedTab ? setSelectedTab : undefined}
              />
            </Paper>
            <NodonSelect
              buttonLabel={`kg CO2e per ${selectedUnit}`}
              options={selectableQuantityUnits}
              fullWidth
              onChange={handleChange}
            />
          </Box>
        </Box>

        <Box width="100%" mt={2}>
          <Divider />
        </Box>

        <Box display="flex" flex={1} mt={2} width="100%" height="80%">
          {Object.keys(categories).length > 0 ? (
            <Box minWidth="20%" height="100%" pb={2}>
              <ProductCategorySelector
                categories={categories}
                onChange={setSelectedCategory}
                selectedCategory={selectedCategory}
                expandedCategory={expandedCategory}
                setExpandedCategory={setExpandedCategory}
              />
            </Box>
          ) : null}
          <Divider orientation="vertical" flexItem />
          <Box flex={1}>
            <SelectProductList
              products={filteredProducts}
              productSelectorIsOpen={open}
              productId={productId}
              selectedUnit={selectedUnit}
              onSelect={handleSelectProduct}
              onDelete={handleDeleteProduct}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles()(() => ({
  dialog: {
    overflow: 'hidden',
  },
}));

const useProductsFilteredByCategory = (
  products: IProduct[],
  selectedCategory: string = '',
): IProduct[] => {
  return useMemo(() => {
    if (selectedCategory) {
      const parts = selectedCategory.split('.');
      return products.filter((product) => {
        return selectedCategory === 'Library'
          ? product.organizations?.[0] === TEMPLATE_ORGANIZATION
          : hasProps(product, parts) &&
              product.organizations?.[0] !== TEMPLATE_ORGANIZATION;
      });
    }
    return products;
  }, [products, selectedCategory]);
};

export const useRecentProducts = (): IProduct[] => {
  const [usedProductIds] = useUsedProductIds();
  const products = useProductsByByTab('all');

  return useMemo(() => {
    return products.filter((product) => usedProductIds.includes(product.id));
  }, [products, usedProductIds]);
};

const useProductsByByTab = (tab: TabName): IProduct[] => {
  const products = useProducts();

  return useMemo(
    () =>
      orderBy(products, ['name'], ['asc']).filter((product) =>
        isProductOfSource(product, tab),
      ),
    [products, tab],
  );
};

const isProductOfSource = (
  product: IProduct,
  sourceOrTab: SelectableSource | ProductSources,
): boolean => {
  if (sourceOrTab === 'all' || sourceOrTab === 'recent') {
    return true;
  }
  const source = isOneOfProductSources(sourceOrTab)
    ? sourceOrTab
    : getSourceByTabName(sourceOrTab);
  return product.source === source;
};

const getSourceByTabName = (
  tabName: Exclude<TabName, 'recent' | 'all'>,
): ProductSources => {
  if (tabName === 'generic') {
    return ProductSources.Boverket;
  }
  if (tabName === 'ökobaudat') {
    return ProductSources.Ökobaudat;
  }
  if (tabName === 'epd') {
    return ProductSources.Custom;
  }
  return ProductSources.Custom;
};

const hasProps = (product: IProduct, parts: string[]): boolean => {
  let cur = product.categories as Record<string, unknown>;
  for (const part of parts) {
    if (part === parts[parts.length - 1]) {
      return !!cur[part];
    }
    if (!cur[part]) {
      return false;
    }
    cur = cur[part] as Record<string, unknown>;
  }

  return false;
};

const getProductCategories = (products: IProduct[]): Record<string, unknown> =>
  products.reduce(
    (categories, product) =>
      deepmerge(
        categories,
        product.organizations?.[0] === TEMPLATE_ORGANIZATION
          ? { ...product.categories, Custom: false, Library: true }
          : product.categories,
      ),
    {},
  );

const productSelectorStore = create<{
  isOpen: boolean;
  options?: IProductSelectorOptions;
}>()(() => ({
  isOpen: false,
  options: undefined,
}));

const setState = productSelectorStore.setState;

const useIsProductSelectorOpen = () =>
  productSelectorStore((state) => !!state.isOpen);

const useProductSelectorOptions = () =>
  productSelectorStore((state) => state.options);

let resolveOpenProduct:
  | ((value: IProduct | undefined | PromiseLike<IProduct | undefined>) => void)
  | undefined;

/**
 * Close the product selector and resolve the promise with the product
 * @param product
 */
export const closeProductSelector = (product?: IProduct) => {
  resolveOpenProduct?.(product ?? undefined);
  resolveOpenProduct = undefined;
  setState({ isOpen: false, options: undefined });
};

export const openProductSelector = (
  options: IProductSelectorOptions = {},
): Promise<IProduct | undefined> => {
  setState({ isOpen: true, options });
  return new Promise<IProduct | undefined>((resolve) => {
    resolveOpenProduct = resolve;
  });
};

const ConditionalProductSelector: React.FC = () => {
  const isOpen = useIsProductSelectorOpen();

  return isOpen ? <ProductSelector /> : null;
};

export default ConditionalProductSelector;
