import {
  NodonRecipeID,
  Recipe,
  RecipeID,
  IFactoryRecipe,
  RecipeRecord,
} from '../models/recipe.interface';
import { ProductID, ProductRecord } from '../models/product.interface';
import {
  getProductByIdDeprecated,
  getProductIdsInElement,
} from './product_helpers';
import {
  ElementKind,
  IBuildingVersion,
  IElement,
  OneOfChildElements,
  OneOfElements,
  Project,
} from '../models/project.interface';
import {
  flattenElements,
  getChildElements,
  isElement,
  isProductElement,
} from './recursive_element_helpers';
import { Results } from '../models/unit.interface';
import { createElementOfType, createElements } from './element_factory_helpers';
import {
  ElementPropertyName,
  ElementPropertySource,
  IElementProperty,
  IFactoryProperty,
  PropertyResolvedCountRecord,
} from '../models/element_property.interface';
import { isEqualElements, isGenerated } from './element_helpers';
import { cloneDeep, isObject, uniq } from 'lodash';
import { generateElementConversionFactorSum } from './results.helpers';
import {
  applyElementPropertiesOfSource,
  getElementAndQuantityProperties,
  getElementProperties,
  getElementPropertiesNotOfSource,
  hasFallbackCount,
  isElementPropertyListEqual,
  isElementPropertyOfSource,
} from './element_property_helpers';
import {
  elementCategories,
  getAvailableCategories,
} from '../templates/categories';
import {
  applyElementCategory,
  applyElementCategoryPropertySelections,
  getCategoryPropertyValueRecord,
  getElementCategory,
} from './element_category_helpers';
import {
  hasDefinedProperties,
  isPrimitive,
  mapFilterRecord,
  omit,
  omitUndefined,
  replaceProperties,
} from './object_helpers';
import { createElementProperties } from './element_property_factory_helpers';
import {
  ElementCategoryID,
  mainCategoryIds,
} from '../models/element_categories.interface';
import { getTimestamps } from './date.helpers';
import shallowEqual, { EMPTY_ARRAY } from './array_helpers';
import { getDefaultFallbackExpression } from './auto-quantity.helpers';
import { createExpression } from './expression_factory_helpers';
import { enrichElementStructure } from './project_helpers';
import { cacheFactory, required } from './function_helpers';
import { sumConversionFactors } from './conversion-factors.helpers';
import { multiplyConversionFactors } from './conversion_helpers';
import { isElementQuantityExpressionProperty } from './element_quantity_helpers';
import { OneOfFactoryElements } from '../models/factory-element.interface';

const AUTO_RECIPE_ID_PREFIX = 'auto:';

/**
 * Test if element and recipe are equal (to show modified asterix etc)
 * @param recipe
 * @param element
 * @param ignoreCategoryProperties If comparison should ignore category properties
 * @returns
 */
export const isRecipeAndElementEqual = (
  recipe: Recipe,
  element: IElement,
): boolean => {
  // Create an element based on the recipe to compare with (more stable than comparing the raw recipe, but expesive so do last)
  const recipeElement = cacheFactory(
    () =>
      enrichElementStructure(
        applyRecipe(createElementOfType(ElementKind.Element), recipe),
      ),
    `isRecipeAndElementEqual[${recipe.id}]`,
    [recipe],
  );

  if (isRecipeCategoryPropertyValueRecordModified(element, recipe)) {
    return false;
  }

  const childIngredients = getChildElements(recipeElement).filter(
    (c) => !isGenerated(c),
  );
  const childElements = getChildElements(element).filter(
    (c) => !isGenerated(c),
  );

  // If length of children differs, elements are not equal
  if (childIngredients.length !== childElements.length) {
    return false;
  }

  const recipeProperties = getElementPropertiesNotOfSource(
    recipe,
    ElementPropertySource.Category,
  );

  const elementProperties = getElementPropertiesNotOfSource(
    element,
    ElementPropertySource.Category,
  );

  if (!isElementPropertyListEqual(recipeProperties, elementProperties)) {
    return false;
  }

  return recipeElement.elements.every((ingredient, index) => {
    const childElement = childElements[index];
    return childElement ? isEqualElements(childElement, ingredient) : false;
  });
};

/**
 * Test if the category property value record of an element is modified compared to a recipe.
 * IE. That a category property value has been changed
 * @param element
 * @param recipe
 * @returns
 */
export const isRecipeCategoryPropertyValueRecordModified = (
  element: IElement,
  recipe: Recipe,
): boolean => {
  const filter = (record: PropertyResolvedCountRecord) =>
    mapFilterRecord(record, (value) => {
      if (!value || value === 'none') {
        return undefined;
      }
      return value;
    });
  const elementRecord = filter(getCategoryPropertyValueRecord(element));
  const recipeRecord = filter(recipe.category_property_value_record);

  return !shallowEqual(elementRecord, recipeRecord);
};

/**
 * Create a new recipe from an element
 * @param owner Auth0 id of the user
 * @param element
 * @returns
 */
export const createRecipeFromElement = (
  owner: string | undefined,
  element: IElement,
): Recipe => {
  const category_property_value_record =
    getCategoryPropertyValueRecord(element);

  const results = element.results
    ? multiplyConversionFactors(element.results, 1 / (element.results.kg ?? 1))
    : undefined;

  return createRecipe({
    category_id: element.category_id,
    category_property_value_record,
    owner,
    organizations: [],
    results,
    elements: getRecipeElementsFromElement(element),
    properties: element.properties,
    ...getTimestamps(),
  });
};

export const createRecipe = (defaults: IFactoryRecipe = {}): Recipe => {
  const properties: IFactoryProperty[] = (defaults.properties ?? [])
    // Don't include category properties
    .filter(
      (p) => !isElementPropertyOfSource(p, ElementPropertySource.Category),
    )
    // Remove id and recipe id to avoid cunfusion. These should be applied when recipe is applied instead
    .map(
      (p) =>
        ({
          ...omit(p, ElementPropertySource.Recipe),
          id: '',
        }) as IFactoryProperty,
    );

  return {
    name: '',
    description: '',
    category_id: ElementCategoryID.None,
    category_property_value_record: {},
    organizations: [],
    ...omitUndefined(defaults),
    elements: createElements(defaults.elements),
    id: '',
    properties: createElementProperties(properties),
    ...getTimestamps(),
  };
};

export const updateRecipeFromElement = (
  recipe: Recipe,
  element: IElement,
): Recipe => {
  const newRecipe = createRecipeFromElement(recipe.owner, element);

  const updated: Recipe = {
    ...recipe,
    ...omitUndefined(
      omit(
        newRecipe,
        'id',
        'created_at',
        'organizations',
        'description',
        'category_id',
        'name',
      ),
    ),
  };
  return updated;
};

/*
TODO: Since recipe.ingredients has been changed to recipe.elements, 
these helpers might not be needed any longer? 
*/
const getRecipeElementsFromElement = (
  element: IElement,
): OneOfChildElements[] => elementsToIngredients(getChildElements(element));

/**
 * Make sure to remove ids and relations that should not exist later
 * @param elements
 * @returns
 */
const elementsToIngredients = (
  elements: OneOfChildElements[] = [],
): OneOfChildElements[] => {
  // Make a clone to not affect original element and exclude generated elements
  const clones: OneOfElements[] = cloneDeep(elements).filter(
    (e) => !isGenerated(e),
  );
  flattenElements(...clones).forEach((element) => {
    getElementAndQuantityProperties(element).forEach((p) => {
      // Reset any auto values on quantity properties of type "expression"
      if (isElementQuantityExpressionProperty(p) && hasFallbackCount(p)) {
        p.fallbackCount = createExpression(getDefaultFallbackExpression(p));
      }
    });
  });

  return clones as OneOfChildElements[];
};

export const isElementCategoryMatch = (
  recipe: Recipe,
  elementCategoryId?: ElementCategoryID,
): boolean => {
  if (recipe?.category_id && elementCategoryId) {
    return recipe.category_id === elementCategoryId;
  }
  return false;
};

export const sortRecipesByCO2eTotalRecord = (
  recipes: Recipe[],
  co2eRecord: Record<RecipeID, number>,
): Recipe[] =>
  [...recipes].sort(
    (recipeA, recipeB) =>
      (co2eRecord[recipeA.id] || Number.MAX_VALUE) -
      (co2eRecord[recipeB.id] || Number.MAX_VALUE),
  );

/**
 * Make an element use a recipe. Can be used to reset an element to a recipe.
 * @param recipe
 * @param element
 * @returns
 */
export const applyRecipe = (element: IElement, recipe: Recipe): IElement => {
  const recipe_id = recipe.id;
  if (!recipe_id) {
    throw new Error('Recipe id is required to update element from recipe');
  }

  // Don't auto-swap category for not disabled categories
  const category = elementCategories.find(
    (cat) => cat.id === recipe.category_id && !cat.disabled,
  );

  // If recipe belongs to a new category make element change category.
  if (category && element.category_id !== category.id) {
    element = applyElementCategory(element, category.id);
  }
  element = applyElementCategoryPropertySelections(
    element,
    recipe.category_property_value_record,
  );

  // Create products or elements based on element kind
  const elements = createElements(recipe.elements, true);

  return applyElementPropertiesOfSource(
    { ...element, elements, recipe_id },
    ElementPropertySource.Recipe,
    getElementProperties(recipe).map((p) => ({
      ...p,
      [ElementPropertySource.Recipe]: undefined,
    })),
  );
};

/**
 * Detach a recipe from an element if it has one.
 * This will leave the recipe properties and child elements intace but remove the connection to the recipe.
 * @param element
 * @returns
 */
export const detachRecipe = (element: IElement): IElement => {
  // Keep old recipe properties but remove their connection to the recipe
  const properties = getElementProperties(element).map((p) =>
    isElementPropertyOfSource(p, ElementPropertySource.Recipe)
      ? { ...p, recipe_id: undefined } // Note that we need to set it do undefined rather than removing it for applyChanges to pick it up
      : p,
  );

  return replaceProperties(element, {
    recipe_id: undefined,
    properties,
  });
};

/**
 * Clear everything related to recipes from an element.
 * @param element
 * @param keepId If we suspect that the reason for clearing is that the recipe is temporary unavailable we can keep the recipe id to be able to restore it later
 * @returns
 */
export const clearRecipe = (element: IElement): IElement => {
  // Remove any properties that are connected to a recipe
  const properties = getElementProperties(element).filter(
    (p) => !isElementPropertyOfSource(p, ElementPropertySource.Recipe),
  );

  return replaceProperties(element, {
    recipe_id: undefined,
    properties,
    elements: element.recipe_id ? [] : element.elements, // Don't clear elements if we didn't have a recipe
  });
};

/**
 * Clears the element of previous recipe and applies the new one
 * @param element
 * @param recipe
 * @returns
 */
export const overwriteRecipe = (
  element: IElement,
  recipe: Recipe,
): IElement => {
  // Remove any properties that are connected to the element only
  const properties = getElementProperties(element).filter(
    (p) => !isElementPropertyOfSource(p, undefined),
  );

  return applyRecipe(clearRecipe({ ...element, properties }), recipe);
};

/**
 * Apply a recipe to an element based on an id.
 * Supports the 'none' | 'detach' | 'auto' id's.
 * @param recipes List of recipes sorted by lowest co2e to highest
 * @param element
 * @param id New id. Can be 'none' | 'detach' | 'auto' or a regular recipe id
 * @returns
 */
export const applyRecipeById = (
  recipes: Recipe[],
  element: IElement,
  id: RecipeID | undefined,
): IElement => {
  if (id === 'detach') {
    return detachRecipe(element);
  } else if (id === 'none' || !id) {
    return clearRecipe(element);
  }
  const recipe = required(recipes.find((r) => r.id === id));
  return applyRecipe({ ...element, recipe_id: id }, recipe);
};

/**
 * Get record containing conversion factors of each recipe.
 * Note that these are only per unit of a recipe
 * @param recipes
 * @param products
 * @param version
 * @returns
 */
export const getRecipeConversionFactorRecord = (
  recipes: Recipe[],
  products: ProductRecord,
  version: IBuildingVersion,
): Record<RecipeID, Results> => {
  return recipes.reduce(
    (acc, recipe) => ({
      ...acc,
      [recipe.id]: getRecipeConversionFactorTotals(recipe, products, version),
    }),
    {},
  );
};

/**
 * Get ConversionFactors for a single unit of a Recipe.
 * To get CO2e this value can be multiplied with an element count
 * @param recipes
 * @param products
 * @param version
 * @returns
 */
export const getRecipeConversionFactorTotals = (
  recipe: Recipe,
  products: ProductRecord,
  version: IBuildingVersion,
): Results => {
  const factors = flattenElements(recipe)
    .filter(isProductElement)
    .map((ingredient) => {
      const product = getProductByIdDeprecated(
        products,
        version,
        ingredient.product_id,
      );
      return generateElementConversionFactorSum(version, ingredient, product);
    });
  return sumConversionFactors(...factors);
};

/**
 * Detect if a recipe is fetched from DB or hardcoded by us
 * @param str
 * @returns
 */
export const isNodonRecipeID = (
  str: string | NodonRecipeID | undefined,
): str is NodonRecipeID => {
  return (
    !!str &&
    Object.values(NodonRecipeID as Record<string, string>).includes(str)
  );
};

export const getRecipeIdsInUse = (
  root: Project | IBuildingVersion | OneOfElements,
  allowAutoRecipes = false,
): RecipeID[] =>
  uniq(
    flattenElements(root)
      .map(getRecipeId)
      .filter((id): id is string => isValidStoreRecipeId(id, allowAutoRecipes)),
  );

/**
 * Get all recipes used in element
 * @param element
 * @param recipes All recipes in the store
 * @returns
 */
export const getRecipesUsedInElement = (
  element: OneOfElements,
  recipes: Recipe[] = [],
): Recipe[] => {
  if (recipes.length === 0) {
    return EMPTY_ARRAY as Recipe[];
  }
  const recipeIds = getRecipeIdsInUse(element);
  return recipes.filter(
    (recipe) => 'owner' in recipe && recipeIds.includes(recipe.id),
  );
};

/**
 * Get all recipes used in project
 * @param project
 * @param recipes All recipes in the store
 * @returns
 */
export const getRecipesUsedInProject = (
  project: Project,
  recipes: Recipe[],
): Recipe[] => {
  const recipeIds = getRecipeIdsInUse(project, true);
  return recipes.filter(
    (recipe) =>
      'owner' in recipe && recipeIds.some((id) => id.includes(recipe.id)),
  );
};

/**
 * Test if id is an id that comes from a recipe in the DB (not hardcoded or auto etc)
 * @param id
 * @returns
 */
const isValidStoreRecipeId = (
  id: RecipeID | undefined,
  allowAutoRecipe = false,
): id is RecipeID =>
  !!id &&
  !isNodonRecipeID(id) &&
  (allowAutoRecipe || !isAutoRecipeId(id)) &&
  id !== 'none' &&
  id !== 'detach';

/**
 * Get recipe id from an element or property.
 * Note that it will be the raw id (like 'auto', 'auto:123jlhq54' or 'none') and not the resolved id
 * @param elementOrProperty
 * @returns
 */
export const getRecipeId = (
  elementOrProperty:
    | OneOfElements
    | OneOfFactoryElements
    | IElementProperty
    | RecipeID
    | undefined,
): RecipeID | undefined => {
  if (isPrimitive(elementOrProperty)) {
    return elementOrProperty;
  }
  return ElementPropertySource.Recipe in elementOrProperty
    ? elementOrProperty.recipe_id
    : undefined;
};

/**
 * Get the name of a recipe from an element or factory element
 * @param element
 * @param recipeLookup
 * @returns
 */
export const getRecipeName = (
  element: OneOfElements | OneOfFactoryElements,
  recipeLookup?: RecipeRecord,
): string | undefined => {
  const recipeId = getRecipeId(element);

  if (!recipeId || !isElement(element)) {
    return undefined;
  }
  return recipeLookup ? recipeLookup[recipeId]?.name : element.fallbackName;
};

/**
 * Get all product ids that exist within a recipe or list of recipes
 * @param recipes
 * @returns
 */
export const getProductIdsInRecipes = (...recipes: Recipe[]): ProductID[] => {
  const ingredients = recipes.flatMap((r) => r.elements);
  return uniq(ingredients.flatMap((el) => getProductIdsInElement(el)));
};

/**
 * Check if a property derives from a NodonRecipe
 * and hence should not be allowed to remove or change name on
 */
export const isNodonRecipeProperty = (prop?: IElementProperty): boolean =>
  isNodonRecipeID(prop?.recipe_id);

/**
 * Get all recipes that can be applied to an element (keep order by co2)
 * @param sortedRecipes
 * @param categoryID
 * @param groupUnmappedRecipesInOther If true all recipes that can't be mapped to a enabled category will be grouped in the "Other" category
 * @returns
 */
export const getApplicableRecipes = (
  sortedRecipes: Recipe[],
  element: OneOfElements | undefined,
  groupUnmappedRecipesInOther = false,
): Recipe[] => {
  if (!isElement(element)) {
    return EMPTY_ARRAY as Recipe[];
  }
  return sortedRecipes.filter((recipe) =>
    isApplicableRecipe(element, recipe, groupUnmappedRecipesInOther),
  );
};

const isApplicableRecipe = (
  element: IElement,
  recipe: Recipe,
  groupUnmappedRecipesInOther = false,
): boolean => {
  const category = getElementCategory(element);
  const filterFn = category?.recipeFilter;
  if (recipeHasCategoryId(recipe, category?.id, groupUnmappedRecipesInOther)) {
    return filterFn ? filterFn(recipe, element) : true;
  }
  return false;
};

/**
 * Get the recipe to use based on ID.
 * If auto id return first applicable recipe (which has lowest co2e).
 * If regular id return the recipe with that id if it is applicable else undefined.
 * @param sortedRecipes
 * @param element
 * @param id Pass an id to override the id on the element
 * @returns
 */
export const getApplicableRecipe = (
  sortedRecipes: Recipe[],
  element: IElement,
  id: RecipeID | undefined = element.recipe_id,
  groupUnmappedRecipesInOther = false,
): Recipe | undefined => {
  if (id) {
    const applicableRecipes = getApplicableRecipes(
      sortedRecipes,
      element,
      groupUnmappedRecipesInOther,
    );

    // Always return the first recipe if id is any auto value
    if (isAutoRecipeId(id)) {
      return applicableRecipes[0];
    }
    return applicableRecipes.find((recipe) => recipe.id === id);
  }
};

/**
 * Test if recipe belongs to the given category_id
 * @param recipe
 * @param id Id of the category
 * @param groupUnmappedRecipesInOther If true all recipes that can't be mapped to a enabled category will be grouped in the "Other" category
 * @returns
 */
const recipeHasCategoryId = (
  recipe: Recipe,
  id: ElementCategoryID | undefined,
  groupUnmappedRecipesInOther = false,
): boolean => {
  if (recipe.category_id === id) {
    return true;
  }

  // Other category should pick up any recipes that are not in any other visible category
  if (
    groupUnmappedRecipesInOther &&
    recipe.category_id &&
    id === ElementCategoryID.Other
  ) {
    const categoryIds = getAvailableCategories(...mainCategoryIds).map(
      (category) => category.id,
    );
    return !categoryIds.includes(recipe.category_id);
  }
  return false;
};

/**
 * Check if id is an auto id: "auto" or "auto:recipeId"
 * @param element
 * @returns
 */
export const isAutoRecipeId = (element?: OneOfElements | RecipeID): boolean => {
  const id = getRecipeId(element);
  return id === 'auto' || id?.indexOf(AUTO_RECIPE_ID_PREFIX) === 0;
};

/**
 * Get which auto element that is currently in use.
 * Older recipes might have "auto" as id and then we don't know which one to use
 * @param elementOrId
 * @returns If possible to determine which id is used, return that. Otherwise undefined
 */
export const resolveAutoRecipeId = (
  elementOrId?: OneOfElements | RecipeID,
): RecipeID | undefined => {
  const id = getRecipeId(elementOrId);
  if (id && isAutoRecipeId(id)) {
    return id === 'auto' || id === AUTO_RECIPE_ID_PREFIX
      ? undefined
      : id.substring(AUTO_RECIPE_ID_PREFIX.length);
  }
  return id;
};

/**
 * Check if an unkonwn type is a recipe
 * @param element
 * @returns
 */
export const isRecipe = (element: unknown): element is Recipe => {
  return (
    isObject(element) &&
    hasDefinedProperties(
      element as Recipe,
      'id',
      'name',
      'category_id',
      'category_property_value_record',
    )
  );
};

export const isNotInSortedRecipes = (
  recipeId: string | undefined,
  sortedRecipes: Recipe[],
): boolean =>
  !isAutoRecipeId(recipeId) &&
  !sortedRecipes.some((recipe) => recipe.id === recipeId);

/**
 * Compare if two category property value records are equal
 * @param prev
 * @param next
 * @param ignoreKeys List of keys to ignore when comparing
 * @returns
 */
export const isEqualCategoryPropertyValueRecord = (
  prev: PropertyResolvedCountRecord,
  next: PropertyResolvedCountRecord,
  ignoreKeys: (keyof PropertyResolvedCountRecord)[] = [
    ElementPropertyName.Lifetime,
    ElementPropertyName.SBEFCode,
  ],
): boolean => {
  const filterRecord = (record: PropertyResolvedCountRecord) =>
    mapFilterRecord(omit(record, ...ignoreKeys), (value) => {
      if (['number', 'undefined'].includes(typeof value)) {
        return undefined;
      }
      return value;
    });
  return shallowEqual(filterRecord(prev), filterRecord(next));
};
