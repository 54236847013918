/**
 * Life cycle stages according to EN 15978.
 * Unsupported stages are commented out.
 */
export enum Lifecycle {
  /**
   * A1-A3: Product stage (raw material supply, transport, manufacturing)
   */
  A1A3 = 'A1-A3',

  /**
   * A4: Transport to construction site
   */
  A4 = 'A4',

  /**
   * A5: Construction/installation waste
   */
  A5 = 'A5',

  /**
   * B1: Use (emissions from installed products)
   */
  //   B1 = 'B1',

  /**
   * B2: Maintenance
   */
  B2 = 'B2',

  /**
   * B3: Repair
   */
  //   B3 = 'B3',

  /**
   * B4: Replacement
   */
  B4 = 'B4',

  /**
   * B5: Refurbishment
   */
  //   B5 = 'B5',

  /**
   * B6: Operational energy use
   */
  B6 = 'B6',
  /**
   * B7: Operational water use
   */
  //   B7 = 'B7',

  /**
   * C1: Deconstruction/demolition
   */
  //   C1 = 'C1',

  /**
   * C2: Transport to waste processing
   */
  //   C2 = 'C2',

  /**
   * C3: Waste processing
   */
  //   C3 = 'C3',

  /**
   * C4: Disposal
   */
  //   C4 = 'C4',

  /**
   * D: Benefits and loads beyond system boundary (reuse, recovery, recycling)
   */
}

export const lifecycles = Object.values(Lifecycle);
