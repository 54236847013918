import { omit } from '../../../helpers/object_helpers';
import {
  ElementCategoryID,
  IElementCategory,
} from '../../../models/element_categories.interface';
import { DEFAULT_QUANTITY_PROPERTIES } from '../../../models/element_quantities.interface';
import { Orientation } from '../../../models/orientation.interface';

export const beam: IElementCategory = {
  id: ElementCategoryID.Beam,
  name: 'Beam',
  orientation: Orientation.Horizonal,
  defaultSelectedQuantity: 'mass',
  getQuantityProperties: () =>
    omit(DEFAULT_QUANTITY_PROPERTIES, 'density', 'mass', 'fill'),
};
