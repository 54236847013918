import {
  IElementCategory,
  ElementCategoryID,
  IElementCategoryQuantityPropertiesFn,
} from '../../../models/element_categories.interface';
import { IFactoryProperty } from '../../../models/element_property.interface';
import {
  insulationProductTree,
  insulationMaterialProperty,
  insulationFormProperty,
  propertiesOptions,
  insulationDensityCount,
} from './insulation.model';
import { createProductTreeProcessor } from '../processor';
import { DEFAULT_QUANTITY_PROPERTIES } from '../../../models/element_quantities.interface';

const levelProperties: IFactoryProperty[] = [
  insulationMaterialProperty,
  insulationFormProperty,
];

const processor = createProductTreeProcessor({
  levelProperties,
  propertiesOptions,
  productTree: insulationProductTree,
});

const getQuantityProperties: IElementCategoryQuantityPropertiesFn = () => ({
  ...DEFAULT_QUANTITY_PROPERTIES,
  density: {
    fallbackCount: insulationDensityCount,
  },
});

export const insulation: IElementCategory = {
  ...processor,
  defaultSelectedQuantity: 'mass',
  id: ElementCategoryID.Insulation,
  name: 'Insulation',
  color: '#C4C758',
  getQuantityProperties,
};
